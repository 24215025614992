import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import puzzleDefault from "../../assets/img/buymenu/bg.png";
import puzzleDark from "../../assets/img/buymenu/bg_dark.png";
import puzzleExpanded from "../../assets/img/buymenu/bg4x4.png";
import puzzleDarkExpanded from "../../assets/img/buymenu/bg4x4_dark.png";
import { withNamespaces } from "react-i18next";
import { setSelectedApplication } from "~/store/ducks/application/actionTypes";
import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
import { hasPermissions } from "~/components/security/utils";
import { getUserTheme } from "~/utils";
import uuid from "uuid/v1";
import "../../assets/css/styles.css";
import "../../assets/css/icons.css";
import { GrUserSettings } from "react-icons/gr";

const CreatePuzzleItem = (props) => {
  const {
    className,
    item,
    isExpand,
    setExpand,
    setSelectedApp,
    handleShow,
    tooltip,
  } = props;
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { themeToUse } = useSelector((state) => state.udwReducer);
  const { selectedApplication } = useSelector(
    (state) => state.applicationReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const ishandlingRoute = useRef(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [applicationRoute, setApplicationRoute] = useState(
    item.location ? item.location : ""
  );

  useEffect(() => {
    if (ishandlingRoute.current === true) {
      if (selectedApplication.label === "uniksystem") {
        history.push({ pathname: "/uniksystem" });
        if (!isMobile) setExpand(false);
        if (document.getElementById("globalsolver"))
          document.getElementById("globalsolver").style.display = "none";
      } else if (menu && menu[0]) {
        if (menu[0]?.application?.label === selectedApplication?.label) {
          let menu_ = [];
          menu.forEach((menuItem) => {
            const permissions = menuItem.permissions
              ? menuItem.permissions
              : [];
            const isAvailable = () => {
              return hasPermissions(user, permissions);
            };
            if (isAvailable()) menu_.push(menuItem);
          });

          let defaultApllicationRoute = true;
          const handleRoute = (menuItem) => {
            defaultApllicationRoute = false;
            const location = { pathname: `/uniksystem/${menuItem.route}` };
            if (history.pathname !== location.pathname) history.push(location);
          };
          menu_.forEach((menuItem) => {
            if (menuItem.extraConfiguration) {
              const extraConfiguration_ = JSON.parse(
                menuItem.extraConfiguration || false
              );
              if (isMobile) {
                const mobileApplicationRoute_ = JSON.parse(
                  (extraConfiguration_ &&
                    extraConfiguration_.mobileApplicationRoute) ||
                    false
                );
                if (mobileApplicationRoute_) {
                  handleRoute(menuItem);
                }
              } else {
                const applicationRoute_ = JSON.parse(
                  (extraConfiguration_ &&
                    extraConfiguration_.applicationRoute) ||
                    false
                );
                if (applicationRoute_) {
                  handleRoute(menuItem);
                }
              }
            }
          });
          if (defaultApllicationRoute) {
            if (applicationRoute.pathname.includes("adatacapture"))
              window.location.href = "#/uniksystem/adatacapture";
            else {
              history.push(applicationRoute);
            }
          }
          if (!isMobile) setExpand(false);

          if (document.getElementById("globalsolver"))
            document.getElementById("globalsolver").style.display = "none";
          /*for Portal, iFlow iframes start*/
          if (document.getElementById(selectedApplication.label))
            document.getElementById(selectedApplication.label).style.display =
              "none";
          /*for Portal, iFlow iframes end*/
        }
      }
    }
  }, [menu, selectedApplication, ishandlingRoute, user]);

  const handleApplicationRoute = (application) => {
    if (application.label !== undefined && application.label !== "") {
      ishandlingRoute.current = true;
      dispatch(findMenuByApplicationName(application.label));
    }
  };

  const handleClick = () => {
    if (item.application.label === "toggle") {
      setExpand(!isExpand);
    } else {
      if (item.active && item.hasAccess) {
        dispatch(setSelectedApplication(item.application));
        handleApplicationRoute(item.application);
      } else {
        setSelectedApp(item);
        handleShow();
      }
    }
  };
  let image;
  if (themeToUse === "default") {
    image = isExpand ? `url(${puzzleExpanded})` : `url(${puzzleDefault})`;
  } else if (themeToUse === "dark") {
    image = isExpand ? `url(${puzzleDarkExpanded})` : `url(${puzzleDark})`;
  } else if (themeToUse === "cma" || themeToUse === "nblp") {
    image = "";
  }

  function renderTooltip(tooltip) {
    if (tooltip) {
      let content = tooltip.showCart ? (
        <>
          {" "}
          {tooltip.description}
          <b className="text-success">
            {" "}
            | <i className="icon-cart mr-2"></i>COMPRAR!
          </b>
        </>
      ) : (
        tooltip.description
      );
      return <Tooltip>{content}</Tooltip>;
    }
  }

  return tooltip ? (
    <OverlayTrigger
      key={uuid()}
      placement="right"
      delay={{ show: 250, hide: 10 }}
      overlay={renderTooltip(tooltip)}
    >
      <div
        className={className}
        onClick={() => handleClick()}
        style={{ backgroundImage: image }}
      >
        {item.icon === "icon-rh puzzle-item" ? (
          <GrUserSettings className={item.icon} style={{ fontSize: "16pt", verticalAlign:"baseline" }} color="white" />
        ) : (
          <i className={item.icon} style={{ fontSize: "18pt" }}></i>
        )}
      </div>
    </OverlayTrigger>
  ) : (
    <div
      key={uuid()}
      className={className}
      onClick={() => handleClick()}
      style={{ backgroundImage: image }}
    >
      {item.icon === "icon-rh puzzle-item" ? (
        <GrUserSettings className={item.icon} style={{ fontSize: "18pt" }} color="white" />
      ) : (
        <i className={item.icon} style={{ fontSize: "18pt" }}></i>
      )}
    </div>
  );
};

export default withNamespaces()(CreatePuzzleItem);
