import { put } from "redux-saga/effects";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import http from "~/utils/config/http";
import {
  doneDeleteWorkplan,
  doneGetAllBusinesses,
  doneGetAllCategories,
  doneGetAllClientDirectors,
  doneGetAllClients,
  doneGetAllCollaborators,
  doneGetAllDepartments,
  doneGetAllExpenditureTypes,
  doneGetAllExpenses,
  doneGetAllFactoring,
  doneGetAllFunctions,
  doneGetAllInvoicingMode,
  doneGetAllLift,
  doneGetAllPartners,
  doneGetAllPaymentTypes,
  doneGetAllProbabilities,
  doneGetAllProjectTypes,
  doneGetAllProposals,
  doneGetAllProviders,
  doneGetAllProviderTypes,
  doneGetAllStates,
  doneGetAllTeams,
  doneGetDocumentInfo,
  doneGetProposal,
  doneMakeActive,
  doneUpdateWorkplan,
  setAllBusinesses,
  setAllCategories,
  setAllClients,
  setAllCollaborators,
  setAllDepartments,
  setAllExpenditureTypes,
  setAllExpenses,
  setAllFactoring,
  setAllFunctions,
  setAllInvoicingMode,
  setAllProposalTypes,
  doneGetAllProposalTypes,
  setAllPartners,
  setAllPaymentTypes,
  setAllProbabilities,
  setAllProjectTypes,
  setAllProposals,
  setAllProviders,
  setAllProviderTypes,
  setAllStates,
  setAllTeams,
  setAllWorkplans,
  setAllWorkplansObj,
  setWorkplanById,
  setClientDirectors,
  setDocumentInfo,
  setProposal,
  setTriggerLoading,
  setWorkplan,
  setworkplanNewVersion,
  setContact,
  setProvider,
  doneSaveProvider,
  doneSaveContact,
  doneGetAllCompanies,
  setAllCompanies,
  setAddWorkplanTrigger,
  setErrorUpdating,
  setNumGanho,
  setDocument,
  doneGetDocument,
  setCurrentDocuments,
  doneDeleteDocument,
  setClients,
  setProviders,
  stopEditingProviders,
  stopEditingClients,
  doneSaveClients,
  doneSaveProviders,
  doneUpdateClienteId,
  doneUpdateProviderId,
  doneDeleteClientData,
  doneDeleteProviderData,
  doneGetAllByClientDirector,
  setUpdateDone,
  setUpdateSubmit,
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
/**
 * this endpoint does not exist in java,
 * not used in component
 * Gets all Lift Items by organization id
 */
export function* getAllWorkplans({ id }) {
  try {
    if (id) {
      const resp = yield http.get(`/budgetmgr/all?organizationId=${id}`);
      yield put(setAllWorkplans(resp?.data));
      yield put(doneGetAllLift());
    } else {
      yield put(setAllWorkplans([]));
      yield put(doneGetAllLift());
    }
  } catch (e) {
    yield put(doneGetAllLift());
    yield put(setAllWorkplans([]));
  }
}

/**
 * Gets all Lift Items by org group
 */
export function* getAllWorkplansByOrgGroup() {
  try {
    const resp = yield http.get(`/budgetmgr/findAll`);
    yield put(setAllWorkplans(resp?.data))
    devLogConsole(resp.data)
    yield put(doneGetAllLift());
  } catch (e) {
    yield put(doneGetAllLift());
    yield put(setAllWorkplans([]));
  }
}

/**
 * Updates item
 */
export function* updateWorkplan({ workplan }) {
  try {
    const resp = yield http.post(`/budgetmgr/save`, workplan);
    yield put(setWorkplan(resp?.data));
    yield put(doneUpdateWorkplan());
    yield put(setTriggerLoading(true));
    yield put(setAddWorkplanTrigger(true));
  } catch (e) {
    yield put(setAddWorkplanTrigger(false));
    yield put(doneUpdateWorkplan());
    yield put(setWorkplan([]));
  }
}

/**
 * Creates new client
 */
export function* saveContact({ client }) {
  try {
    const resp = yield http.post(`/api/budgetmgr/client/saveContact`, client);

    yield put(setContact(resp?.data));
    yield put(doneSaveContact());
  } catch (e) {
    yield put(doneSaveContact());
    yield put(setContact([]));
  }
}

/**
 * Creates new provider
 */
export function* saveProvider({ provider }) {
  try {
    const resp = yield http.post(`/api/budgetmgr/provider/save`, provider);
    yield put(setProvider(resp?.data));
    yield put(doneSaveProvider());
  } catch (e) {
    yield put(doneSaveProvider());
    yield put(setProvider([]));
  }
}

/**
 * Saves changes to workplan
 */
export function* saveChangesWorkplan({ workplan }) {
  try {
    const resp = yield http.post(`/budgetmgr/update`, workplan.content);
    if (workplan.show) {
      yield put(setErrorUpdating(false));
    } else {
      yield put(setErrorUpdating(null));
    }
    if (resp?.data?.numeroGanho) {
      yield put(setNumGanho(resp?.data.numeroGanho));
    }
    yield put(setWorkplan(resp?.data));
    yield put(doneUpdateWorkplan());
    if (workplan.setUpdate) yield put(setUpdateDone("success"));

    if (workplan.setSubmit) yield put(setUpdateSubmit("success"));
  } catch (e) {
    yield put(doneUpdateWorkplan());
    yield put(setWorkplan([]));
    if (workplan.setUpdate) yield put(setUpdateDone("error"));
    if (workplan.setSubmit) yield put(setUpdateSubmit("error"));
    if (workplan.show) {
      yield put(setErrorUpdating(true));
    } else {
      yield put(setErrorUpdating(null));
    }
  }
}

/**
 * Make new WorkplanVersion
 */
export function* makeNewVersion({ workplan }) {
  try {
    const resp = yield http.post(`/budgetmgr/version`, workplan);
    yield put(setWorkplan(resp?.data));
    yield put(setworkplanNewVersion(resp?.data))
    yield put(doneUpdateWorkplan());
    yield put(setTriggerLoading("1"));
    yield put(setAddWorkplanTrigger(true));
  } catch (e) {
    yield put(setAddWorkplanTrigger(false));
    yield put(doneUpdateWorkplan());
    yield put(setWorkplan([]));
    yield put(setworkplanNewVersion(undefined))
  }
}

export function* getWorkplanById({queryParams}){
  try{
    const resp = yield http.get(`/budgetmgr/findById`, {
      params: {
        ...queryParams.params,
      },
    });
    yield put(setWorkplanById(resp?.data));
    yield put(setTriggerLoading(true));
  } catch (e) {
    yield put(setWorkplanById([]));
  }
}

/**
 * Make a selected workplan active
 */
export function* makeWorkplanActive({ queryParams }) {
  try {
    const resp = yield http.get(`/budgetmgr/active`, {
      params: {
        ...queryParams.params,
      },
    });
    var resp2;
    try {
      if (queryParams.isManager) {
        resp2 = yield http.get(`/budgetmgr/findAllByOrgGroup`);
      } else {
        resp2 = yield http.get(
          `/budgetmgr/all?organizationId=${queryParams.id}`
        );
      }
      yield put(setAllWorkplans(resp2?.data));
      yield put(doneGetAllLift());
    } catch (e) {
      yield put(doneGetAllLift());
      yield put(setAllWorkplans([]));
    }
    yield put(doneMakeActive());
    yield put(setTriggerLoading(true));
  } catch (e) {
    yield put(doneMakeActive());
  }
}

/**
 * Get proposal with Workplan ID
 */
export function* getProposalByWorkplan({ queryParams }) {
  try {
    const resp = yield http.get(`/budgetmgr/proposal/findByWorkPlan`, {
      params: {
        ...queryParams,
      },
    });
    yield put(setProposal(resp?.data));
    yield put(doneGetProposal());
    yield put(setTriggerLoading(true));
  } catch (e) {
    yield put(setProposal([]));
    yield put(doneGetProposal());
  }
}

/**
 * Get document with Workplan ID
 */
export function* getDocumentByWorkplan({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/document/findByWorkPlan?workPlanId=${queryParams.workPlanId}`
    );
    yield put(setDocumentInfo(resp?.data));
    yield put(doneGetDocumentInfo());
  } catch (e) {
    yield put(setDocumentInfo([]));
    yield put(doneGetDocumentInfo());
  }
}

/**
 * Get all workplans by client Director
 */
export function* getAllByClientDirector({ queryParams }) {
  devLogConsole(queryParams);
  try {
    const resp = yield http.get(
      `budgetmgr/findAllByClientDirector?clientDirectorId=${queryParams.id}`
    );
    devLogConsole(resp);
    yield put(setAllWorkplans(resp?.data));
    yield put(doneGetAllByClientDirector());
  } catch (e) {
    yield put(setAllWorkplans([]));
    yield put(doneGetAllByClientDirector());
  }
}

/**
 * Gets all Lift Collaborators
 */
export function* getAllCollaborators({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/collaborator/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setAllCollaborators(resp?.data));
    yield put(doneGetAllCollaborators());
  } catch (e) {
    yield put(doneGetAllCollaborators());
    yield put(setAllCollaborators([]));
  }
}

/**
 * Gets all Lift Proposal Types
 */
export function* getAllProposalTypes() {
  try {
    const resp = yield http.get(`api/budgetmgr/proposalTypes/all`);
    yield put(setAllProposalTypes(resp?.data));
    yield put(doneGetAllProposalTypes());
  } catch (e) {
    yield put(doneGetAllProposalTypes());
    yield put(setAllProposalTypes([]));
  }
}

/**
 * Gets all Lift Factoring
 */
export function* getAllFactoring() {
  try {
    const resp = yield http.get(`api/budgetmgr/factoring/all`);
    yield put(setAllFactoring(resp?.data));
    yield put(doneGetAllFactoring());
  } catch (e) {
    yield put(doneGetAllFactoring());
    yield put(setAllFactoring([]));
  }
}

/**
 * Gets all Lift Clients
 */
export function* getAllClients({ queryParams }) {
  try {
    var resp;
    if (queryParams) {
      resp = yield http.get(
        `api/budgetmgr/client/findByOrgId?organizationId=${queryParams}`
      );
    } else {
      resp = yield http.get(`api/budgetmgr/client/all`);
    }
    yield put(setAllClients(sortBudgetMgrUsers(resp?.data)));
    yield put(doneGetAllClients());
  } catch (e) {
    yield put(doneGetAllClients());
    yield put(setAllClients([]));
  }
}

/**
 * Gets all Lift Client Directors
 */
export function* getAllClientDirectors({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/clientDirector/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setClientDirectors(resp?.data));
    yield put(doneGetAllClientDirectors());
  } catch (e) {
    yield put(doneGetAllClientDirectors());
    yield put(setClientDirectors([]));
  }
}

/**
 * Gets all Lift Partners
 */
export function* getAllPartners({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/partner/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setAllPartners(resp?.data));
    yield put(doneGetAllPartners());
  } catch (e) {
    yield put(doneGetAllPartners());
    yield put(setAllPartners([]));
  }
}

/**
 * Gets all Lift Payment Types
 */
export function* getAllPaymentTypes() {
  try {
    const resp = yield http.get(`api/budgetmgr/paymentType/all`);
    yield put(setAllPaymentTypes(resp?.data));
    yield put(doneGetAllPaymentTypes());
  } catch (e) {
    yield put(doneGetAllPaymentTypes());
    yield put(setAllPaymentTypes([]));
  }
}

/**
 * Gets all Lift Teams
 */
export function* getAllTeams({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/team/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setAllTeams(resp?.data));
    yield put(doneGetAllTeams());
  } catch (e) {
    yield put(doneGetAllTeams());
    yield put(setAllTeams([]));
  }
}

/**
 * Gets all Lift Companies
 */
export function* getAllCompanies() {
  try {
    const resp = yield http.get(`api/budgetmgr/empresa/all`);
    yield put(setAllCompanies(resp?.data));
    yield put(doneGetAllCompanies());
  } catch (e) {
    yield put(doneGetAllCompanies());
    yield put(setAllCompanies([]));
  }
}

/**
 * Gets all Lift Departments
 */
export function* getAllDepartments({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/department/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setAllDepartments(resp?.data));
    yield put(doneGetAllDepartments());
  } catch (e) {
    yield put(doneGetAllDepartments());
    yield put(setAllDepartments([]));
  }
}

/**
 * Gets all Lift Providers
 */
export function* getAllProviders({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/provider/findByOrgId?organizationId=${queryParams}`
    );
    var tempProviders = resp?.data;
    tempProviders.push({
      empresa: "LIFT",
      morada: null,
      nome: "Novo fornecedor",
      idFornecedor: 1,
      numFornecedor: 1,
      prazoPagamento: 1,
      year: 2022,
    });

    yield put(setAllProviders(tempProviders));
    yield put(doneGetAllProviders());
  } catch (e) {
    yield put(doneGetAllProviders());
    yield put(setAllProviders([]));
  }
}

/**
 * Gets all Lift ProviderTypes
 */
export function* getAllProviderTypes() {
  try {
    const resp = yield http.get(`api/budgetmgr/providerType/all`);
    yield put(setAllProviderTypes(resp?.data));
    yield put(doneGetAllProviderTypes());
  } catch (e) {
    yield put(doneGetAllProviderTypes());
    yield put(setAllProviderTypes([]));
  }
}

/**
 * Gets all Lift Expenditure Types
 */
export function* getAllExpenditureTypes() {
  try {
    const resp = yield http.get(`api/budgetmgr/expenditureType/all`);
    yield put(setAllExpenditureTypes(resp?.data));
    yield put(doneGetAllExpenditureTypes());
  } catch (e) {
    yield put(doneGetAllExpenditureTypes());
    yield put(setAllExpenditureTypes([]));
  }
}

/**
 * Gets all Lift Expenses
 */
export function* getAllExpenses({ queryParams }) {
  try {
    const resp = yield http.get(
      `api/budgetmgr/expense/findByOrgId?organizationId=${queryParams}`
    );
    yield put(setAllExpenses(resp?.data));
    yield put(doneGetAllExpenses());
  } catch (e) {
    yield put(setAllExpenses());
    yield put(doneGetAllExpenses([]));
  }
}

/**
 * Gets all Lift Functions
 */
export function* getAllFunctions() {
  try {
    const resp = yield http.get(`api/budgetmgr/function/all`);
    yield put(setAllFunctions(resp?.data));
    yield put(doneGetAllFunctions());
  } catch (e) {
    yield put(setAllFunctions());
    yield put(doneGetAllFunctions([]));
  }
}

/**
 * Gets all Lift Categories
 */
export function* getAllCategories({ queryParams }) {
  try {
    const resp = yield http.get(`api/budgetmgr/category/all`);
    yield put(setAllCategories(resp?.data));
    yield put(doneGetAllCategories());
  } catch (e) {
    yield put(setAllCategories());
    yield put(doneGetAllCategories([]));
  }
}

/**
 * Gets all Lift States
 */
export function* getAllStates() {
  try {
    const resp = yield http.get(`api/budgetmgr/state/all`);
    yield put(setAllStates(resp?.data));
    yield put(doneGetAllStates());
  } catch (e) {
    yield put(doneGetAllStates());
    yield put(setAllStates([]));
  }
}

/**
 * Gets all Lift Proposals
 */
export function* getAllProposals() {
  try {
    const resp = yield http.get(`api/budgetmgr/proposal/all`);
    yield put(setAllProposals(resp?.data));
    yield put(doneGetAllProposals());
  } catch (e) {
    yield put(doneGetAllProposals());
    yield put(setAllProposals([]));
  }
}

/**
 * Gets all Lift Invoicing Modes
 */
export function* getAllInvoicingMode() {
  try {
    const resp = yield http.get(`api/budgetmgr/invoicingMode/all`);
    yield put(setAllInvoicingMode(resp?.data));
    yield put(doneGetAllInvoicingMode());
  } catch (e) {
    yield put(doneGetAllInvoicingMode());
    yield put(setAllInvoicingMode([]));
  }
}

/**
 * Gets all Lift Businesses
 */
export function* getAllBusinesses() {
  try {
    const resp = yield http.get(`api/budgetmgr/business/all`);
    yield put(setAllBusinesses(resp?.data));
    yield put(doneGetAllBusinesses());
  } catch (e) {
    yield put(setAllBusinesses());
    yield put(doneGetAllBusinesses([]));
  }
}

/**
 * Gets all Lift Project Types
 */
export function* getAllProjectTypes() {
  try {
    const resp = yield http.get(`api/budgetmgr/projectType/all`);
    yield put(setAllProjectTypes(resp?.data));
    yield put(doneGetAllProjectTypes());
  } catch (e) {
    yield put(setAllProjectTypes());
    yield put(doneGetAllProjectTypes([]));
  }
}

/**
 * Gets all Lift Probabilities
 */
export function* getAllProbabilities() {
  try {
    const resp = yield http.get(`api/budgetmgr/probability/all`);
    yield put(setAllProbabilities(resp?.data));
    yield put(doneGetAllProbabilities());
  } catch (e) {
    yield put(setAllProbabilities());
    yield put(doneGetAllProbabilities([]));
  }
}

/**
 * Deletes workplan by id
 */
export function* deleteWorkplan({ queryParams }) {
  try {
    yield http.delete(`/budgetmgr/delete?workPlanId=${queryParams.workPlanId}`);
    yield put(doneDeleteWorkplan(true));
    var resp;
    try {
      if (queryParams.isManager) {
        resp = yield http.get(`/budgetmgr/findAllByOrgGroup`);
      } else {
        resp = yield http.get(
          `/budgetmgr/all?organizationId=${queryParams.id}`
        );
      }
      yield put(setAllWorkplans(resp?.data));
      yield put(doneGetAllLift());
    } catch (e) {
      yield put(doneGetAllLift());
      yield put(setAllWorkplans([]));
    }
    yield put(setTriggerLoading(true));
  } catch (exception) {
    yield put(doneDeleteWorkplan(false));
  }
}

/**
 * Gets Document
 */
export function* getDocument({ queryParams }) {
  try {
    const resp = yield http.get(
      `/budgetmgr/document/get?documentId=${queryParams.id}`
    );
    yield put(setDocument(resp?.data));
    yield put(doneGetDocument());
  } catch (e) {
    yield put(setDocument());
    yield put(doneGetDocument([]));
  }
}

/**
 * Saves Document
 */
export function* saveDocument({ queryParams }) {
  try {
    const data = new FormData();
    data.append("file", queryParams.file);
    data.append("workplanId", queryParams.workplanId);
    const resp = yield http.post(`/budgetmgr/document/save`, data);
    yield put(setCurrentDocuments(resp?.data));
    yield put(doneGetDocument());
  } catch (e) {
    yield put(setCurrentDocuments());
    yield put(doneGetDocument([]));
  }
}

/**
 * Deletes document
 */
export function* deleteDocument({ queryParams }) {
  try {
    const resp = yield http.delete(
      `/budgetmgr/document/delete?documentId=${queryParams.id}&workPlanId=${queryParams.workplanId}`
    );
    yield put(doneDeleteDocument(true));
    yield put(setCurrentDocuments(resp?.data));
    yield put(setTriggerLoading(true));
  } catch (exception) {
    yield put(doneDeleteWorkplan(false));
  }
}

export function* getClients() {
  try {
    const resp = yield http.get("/api/budgetmgr/client/all");
    yield put(setClients(sortBudgetMgrUsers(resp.data)));
  } catch (error) {
    devLogConsole(error);
  }
}

export function* getProviders() {
  try {
    const resp = yield http.get("/api/budgetmgr/provider/all");
    yield put(setProviders(sortBudgetMgrUsers(resp.data)));
  } catch (error) {
    devLogConsole(error);
  }
}

const sortBudgetMgrUsers = (array) => {
  return array.sort(function (a, b) {
    return b.year - a.year || a.nome.localeCompare(b.nome);
  });
};

export function* updateClientId({ data }) {
  try {
    const resp = yield http.post(`/api/budgetmgr/client/updateContact/`, data);
    yield put(activeSuccess("Client updated successfully!"));
    yield put(doneUpdateClienteId());
  } catch (error) {
    yield put(activeError("Error updating client!"));
    devLogConsole(error);
  }
}

export function* updateProviderId({ data }) {
  try {
    const resp = yield http.post(`/api/budgetmgr/provider/update/`, data);
    yield put(activeSuccess("Provider updated successfully!"));
    yield put(doneUpdateProviderId());
  } catch (error) {
    yield put(activeError("Error updating provider!"));
    devLogConsole(error);
  }
}

export function* saveClientsData({ data, show }) {
  try {
    devLogConsole(show);
    const resp = yield http.post("/api/budgetmgr/client/saveContact", data);
    yield put(doneSaveClients());
    yield put(setContact(resp?.data));
    if (show === true) yield put(activeSuccess("Client saved successfully!"));
  } catch (error) {
    if (show === true) yield put(activeError("Error saving client!"));
    devLogConsole(error);
  }
}

export function* saveProvidersData({ data }) {
  try {
    const resp = yield http.post("/api/budgetmgr/provider/save", data);
    yield put(doneSaveProviders());
    yield put(setProvider(resp?.data));
    yield put(activeSuccess("Provider saved successfully!"));
  } catch (error) {
    yield put(activeError("Error saving provider!"));
    devLogConsole(error);
  }
}

export function* deleteClientData({ id }) {
  try {
    yield http.delete(`/api/budgetmgr/client/delete/${id}`);
    yield put(stopEditingClients());
    yield put(activeSuccess("Client deleted successfully!"));
  } catch (error) {
    yield put(activeError("Error deleting client!"));
    devLogConsole(error);
  }
}

export function* deleteProviderData({ id }) {
  try {
    yield http.delete(`/api/budgetmgr/provider/delete/${id}`);
    yield put(stopEditingProviders());
    yield put(activeSuccess("Provider deleted successfully!"));
  } catch (error) {
    yield put(activeError("Error deleting provider!"));
    devLogConsole(error);
  }
}
