import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCollaborators, getUserPlafondAndExpenses, messagePlafond} from "~/store/ducks/portalrh/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getCollaboratorData } from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { getRHAdminConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { Form, Col, Row, Button } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SpinnerNew from "~/components/SpinnerNew";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";
import ModalPlafond from "./ModalPlafond";

function TableList({ t }) {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [collaboratorsFiltered, setCollaboratorsFiltered] = useState([]);
  const [rhadminConfig, setrhadminConfig] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const dispatch = useDispatch();
  const { collaborators, isLoadingUserPlafondAndExpenses } = useSelector((state) => state.portalrhReducer);
  const { collaboratorData } = useSelector((state) => state.personalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { rhadminConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOrganizationById(user?.organization?.id));
    }
    dispatch(getCollaboratorData());
  }, [dispatch]);

  useEffect(() => {
    if (showToast)
      setTimeout(() => {
        setShowToast(false);
        dispatch(getCollaborators());
        dispatch(getCollaboratorData());
      }, 5000);
  }, [showToast]);

  useEffect(() => {
    if (Object.keys(organization).length > 0)
      dispatch(getRHAdminConfiguration(organization?.id));
  }, [organization]);

  useEffect(() => {
    if (Object.keys(rhadminConfiguration).length > 0) {
      setrhadminConfig(rhadminConfiguration.body);
    }
  }, [rhadminConfiguration]);

  //adding new propertie lepPssNum and filtering
  useEffect(() => {
    if (collaboratorData.length > 0 && collaborators.length > 0) {
      for (let i = 0; i < collaboratorData.length; i++) {
        for (let k = 0; k < collaborators.length; k++) {
          if (
            collaboratorData[i].lepNum === collaborators[k].funcionarioNum &&
            collaboratorData[i].lepEmpCode === collaborators[k].codEntidade
          ) {
            collaborators[k].pssNum = collaboratorData[i].lepPssNum;
            let newCollab = collaborators.filter((e) => e.funcionarioNum);
            setCollaboratorsFiltered(newCollab);
          }
        }
      }
    }
  }, [collaboratorData, collaborators]);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "username",
      text: "Username",
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "funcionarioNum",
      text: t(`portalrh.NavBar.collaboratorNum`),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "nome",
      text: t(`portalrh.NavBar.Name`),
      sort: true,
      style: { fontSize: "12px" },
    },
  ];

  const mobileColumns = [
    {
      dataField: "info",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        const headers = formatExtraData.headers;
        return (
          <div>
            <br />
            {headers &&
              headers.map((header, index) => {
                const key = header.dataField;
                if (key && row[key] !== undefined && !header.hidden) {
                  const value =
                    typeof row[key] === "boolean"
                      ? row[key].toString()
                      : row[key];
                  return (
                    <div key={index}>
                      <b>{header.text}: </b>
                      {value}
                    </div>
                  );
                }
                return null;
              })}
          </div>
        );
      },
      formatExtraData: {
        headers: columns,
      },
    },
    {
      dataField: "state",
      text: "",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // handleRowClick(row);
              }}
            >
              Detalhe
            </a>
          </div>
        );
      },
    },
  ];

const rowEventEditMod = {
    onClick: async (e, row, rowIndex) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(messagePlafond("", ""));
        setSelectedUser(row.id)
        await dispatch(getUserPlafondAndExpenses(row.id));
        setShowModal(true);
    },
};

const modalClose = () => {
    dispatch(getUserPlafondAndExpenses(selectedUser))
    setShowModal(false);
    setSelectedUser(null);
}

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setMessageAlert("");
    setTypeAlert("");
  };

  const rowStyle = { cursor: "pointer" };
  return (
    <>
    <div className="custom-table custom-table-small cursor-pointer">
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <SpinnerNew open={isLoadingUserPlafondAndExpenses} />
      {collaboratorsFiltered.length > 0 ? (
        <ToolkitProvider
          keyField="id"
          data={collaboratorsFiltered}
          columns={isMobile ? mobileColumns : columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ marginBottom: "20px" }}>
                <Row>
                  {!isMobile && (
                    <Col lg="3" sm="12">
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <hr />
              {isMobile && (
                <div>
                  <SearchBar
                    {...props.searchProps}
                    placeholder={t("general.search")}
                  />
                </div>
              )}
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEventEditMod}
                striped
                hover
                condensed
                bootstrap4
                noDataIndication={t("general.dataNotFound")}
                rowStyle={rowStyle}
                pagination={
                  isMobile
                    ? paginationFactory(mobilePagination)
                    : collaborators.length > 10
                    ? paginationFactory(pagination)
                    : null
                }
              />
            </div>
          )}
        </ToolkitProvider>
      ) : null}
    </div>
    <ModalPlafond open={showModal} handleClose={modalClose} isMobile={isMobile} selectedUser={selectedUser} />
    </>
  );
}

export default withNamespaces()(TableList);
