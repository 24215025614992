import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  setIsEditingAnnotation,
  setSelectedRow,
} from "~/store/ducks/processAnnotations/actionTypes";
import { iconmap } from "./utils";

import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import { getUserTheme } from "~/utils";

const AnnotationColumn = (props) => {
  const { t, row, formatExtraData, isMobile } = props;
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { themeToUse } = useSelector((state) => state.udwReducer);

  const dispatch = useDispatch();
  const foundIcon = iconmap(themeToUse).find(
    (i) => i.id === parseInt(row?.labelid)
  );

  const renderDeadline = () => {
    if (row && row.iconid !== "" && row.iconid !== undefined) {
      let deadline = "";
      if (row.deadline) {
        const arr = row.deadline.split("/");
        deadline = arr[2] + "/" + arr[1];
      }
      return <div className="tasks-deadline-date column-icon">{deadline}</div>;
    }
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }
  return foundIcon ? (
    <div
      className="column-icon"
      onClick={() => {
        let clonedRow = JSON.parse(JSON.stringify(row));
        clonedRow.isPreview = true;
        formatExtraData.selectedRow &&
        formatExtraData.selectedRow.pnumber === row.pnumber
          ? dispatch(setIsEditingAnnotation(false))
          : dispatch(setSelectedRow(clonedRow));
      }}
    >
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("taskPage.annotation.previewAnnotation"))}
      >
        {foundIcon.faIcon ? (
          <i
            className={`${foundIcon.faIcon} column-icon`}
            style={{ fontSize: isMobile ? "large" : "" }}
          />
        ) : (
          <img
            src={foundIcon.image}
            style={{ width: "18px", height: "16px" }}
            alt="annotation-preview"
          />
        )}
      </OverlayTrigger>
      {renderDeadline()}
    </div>
  ) : null;
};
export default withNamespaces()(AnnotationColumn);
