import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { msalInstance, msalInstanceNB } from "~/utils/msalOAuth";
import { loginRequest } from "~/utils/msalOAuth/AuthConfig";
import { microsoftSignIn } from "../actions";
import { ReactComponent as MicrosoftLogo } from "~/assets/img/newLoginimg/microsoft.svg";
import { Typography } from "@mui/material";

const MicrosoftAuthentication = ({ old = false, t, isNB }) => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    const instance = isNB ? msalInstanceNB : msalInstance;
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        if (response && response.idToken) {
          dispatch(microsoftSignIn(response));
        } else {
          const currentAccounts = instance.getAllAccounts();
          if (currentAccounts && currentAccounts.length === 1) {
            const username = currentAccounts[0].username;
            // Handle single account case
          } else if (currentAccounts && currentAccounts.length > 1) {
            console.warn("Multiple accounts detected.");
            // Handle multiple accounts case
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {old ? (
        <button
          className="btn-microsoft"
          onClick={() => handleLogin()}
          style={isNB ? { backgroundColor: "#00b9b2", color: "white" } : {}}
        >
          <div style={{ display: "flex" }}>
            <object
              type="image/svg+xml"
              data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
              className="x-icon"
            ></object>
            <div
              style={{
                flexGrow: "1",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isNB ? "Login Integrado" : "Microsoft"}
            </div>
          </div>
        </button>
      ) : (
        <div
          style={{ textAlign: "center", pointerEvents: "auto" }}
          onClick={handleLogin}
          role="button"
          aria-label={isNB ? "Login Integrado" : "Microsoft"}
        >
          <MicrosoftLogo />
          <Typography variant="caption" display="block" gutterBottom>
            {isNB ? "Login Integrado" : "Microsoft"}
          </Typography>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(MicrosoftAuthentication);
