import React, { useEffect, useState } from "react";
import { reactI18nextModule, withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "~/pages/Easypay/assets/css/styles.css";
import iconWarning from "~/pages/Easypay/assets/img/icons/icon-warning.png";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/scss/_backofficeEasypay.scss";
import Button from "~/pages/Easypay/components/Button";
import BackOfficeInput from "~/pages/Easypay/components/BackOfficeInputDocs";
import Header from "~/pages/Easypay/components/Header";
import Table from "~/pages/Easypay/components/Table";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import SpinnerNew from "~/components/SpinnerNew";
import SimpleInput from "~/pages/Easypay/components/SimpleInput";
import InputDropdown from "~/pages/Easypay/components/InputDropdown";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import Text from "~/pages/Easypay/components/Text";
import Modal from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import InputSearch from "~/pages/Easypay/components/InputSearch";
import {
  getPrecario,
  updatePrecario,
  setPrecario,
  getPrecarioNifAccount,
  savePrecario,
  getAllUsers,
} from "~/store/ducks/onboarding/actionTypes";

const BackOfficePrecario = ({ t, application }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { users, isLoadingGetAllUsers, documents, document, precario } =
    useSelector((state) => state.onboardingReducer);

  var isFirstTime = true;

  function handleSelectChangeMain(value) {
    setCurrentPid(parseInt(value));
  }
  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const [leadsList, setLeadsList] = useState([]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (
      history?.location?.precarioPid != "" &&
      history?.location?.precarioPid != undefined &&
      history?.location?.precarioPid != null
    ) {
      dispatch(getPrecario(history?.location?.precarioPid));
    }
  }, []);

  useEffect(() => {
    var newUsersArray = [];
    for (var i = 0; i < users.length; i++) {
      if (users[i].account.id)
        newUsersArray.push({
          label: users[i].account?.pid?.toString(),
          value: users[i].account?.pid?.toString(),
          selected: false,
        });
    }
    setLeadsList(newUsersArray);
  }, [users]);

  const [isModalShowing, setIsModalShowing] = useState(false);
  const [currentNif, setCurrentNif] = useState("");
  const [currentPid, setCurrentPid] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [isModalError1Showing, setIsModalError1Showing] = useState(false);
  const [isModalError2Showing, setIsModalError2Showing] = useState(false);
  const [isModalError3Showing, setIsModalError3Showing] = useState(false);
  const [isModalErrorSaveShow, setIsModalErrorSaveShow] = useState(false);

  useEffect(() => {
    if (
      precario != null &&
      precario?.length === 0 &&
      (history?.location?.precarioPid === "" ||
        history?.location?.precarioPid === undefined ||
        history?.location?.precarioPid === null)
    ) {
      setIsModalError2Showing(true);
      dispatch(setPrecario(null));
    }
  }, [precario]);

  useEffect(() => {
    setCurrentPrecario(precario);
    if (precario === null || precario?.length === 0) {
      setCurrentTitle("Escolha um preçário::card-text-title");
      return;
    }
    if (precario[0].pid != null) {
      setCurrentTitle(
        "Está a ver o preçário assignado ao nº de processo " +
          precario[0].pid.toString() +
          "::card-text-title"
      );
      return;
    } else {
      setCurrentTitle(
        "Está a ver o preçário default para o tipo de conta " +
          precario[0].accountType +
          "::card-text-title"
      );
      return;
    }
  }, [precario]);

  function handleBlurInputs(name, e) {
    if (name === "pid") {
      setCurrentPid(e.target.value);
      setCurrentNif("");
      setCurrentAccount("");
    } else if (name === "nif") {
      setCurrentNif(e.target.value);
    } else {
      setCurrentCreatePid(e.target.value);
      setCurrentNif("");
      setCurrentAccount("");
      setCurrentPid("");
    }
  }

  function escolherPrecario() {
    if (currentPid != "") {
      dispatch(getPrecario(currentPid));
    } else if (currentAccount != "") {
      dispatch(
        getPrecarioNifAccount({
          nif: currentNif != "" ? currentNif : null,
          tipoConta: currentAccount,
        })
      );
    } else {
      setIsModalError1Showing(true);
    }
  }

  function descFormatter(cell) {
    var currentIndex = 0;
    var currentDesc = "";
    for (var i = 0; i < precario.length; i++) {
      if (precario[i].id === cell) {
        currentDesc = precario[i].pt_description;
        currentIndex = i;
      }
    }

    return (
      <SimpleInput
        field={buildInputField("", currentDesc)}
        key={"accordion_benef_nome"}
        handleOnBlur={(e) => handleDescChange(currentIndex, e)}
      ></SimpleInput>
    );
  }

  function handleDescChange(index, e) {
    precario[index].pt_description = e.target.value;
    precario[index].en_description = e.target.value;
    dispatch(setPrecario(precario));
  }

  function meioPagamentoFormatter(cell) {
    for (var i = 0; i < meiosPagamento.length; i++) {
      if (meiosPagamento[i].idMeiosPagamento === cell) {
        return meiosPagamento[i].pt_description;
      }
    }
  }

  function nifFormatter(cell) {
    return currentNif;
  }

  const columns = [
    {
      dataField: "idMeiosPagamento",
      text: "Meio Pagamento",
      sort: true,
      formatter: meioPagamentoFormatter,
    },
    {
      dataField: "id",
      text: "Descrição",
      sort: true,
      formatter: descFormatter,
    },
  ];

  const [tableSize, setTableSize] = useState(10);
  const [currentPrecario, setCurrentPrecario] = useState(precario);

  function handleTypeSearch(e) {
    setCurrentAccount(e);
    setCurrentCreateAccount("");
    setCurrentCreatePid("");
    setCurrentPid("");
  }

  function handleTypeCreate(e) {
    setCurrentCreateAccount(e);
    setCurrentPid("");
    setCurrentAccount("");
    setCurrentNif("");
  }

  const meiosPagamento = [
    {
      idMeiosPagamento: 1,
      pt_description: "Cartão Visa & MasterCard [NÃO SEPA Empresas]",
      en_description: "Visa & MasterCard [Non SEPA Business]",
    },
    {
      idMeiosPagamento: 2,
      pt_description: "Cartão Visa & MasterCard [NÃO SEPA Particulares]",
      en_description: "Visa & MasterCard [Non SEPA Private]",
    },
    {
      idMeiosPagamento: 3,
      pt_description: "Cartão Visa & MasterCard [SEPA Empresas]",
      en_description: "Visa & MasterCard [SEPA Business]",
    },
    {
      idMeiosPagamento: 4,
      pt_description: "Cartão Visa & MasterCard [SEPA Particulares]",
      en_description: "Visa & MasterCard [SEPA Private]",
    },
    {
      idMeiosPagamento: 5,
      pt_description: "MBWAY",
      en_description: "MBWAY",
    },
    {
      idMeiosPagamento: 6,
      pt_description: "Referências Multibanco",
      en_description: "Referências Multibanco ",
    },
    {
      idMeiosPagamento: 7,
      pt_description: "Iniciação de Pagamentos",
      en_description: "Payment Initiation",
    },
    {
      idMeiosPagamento: 8,
      pt_description: "Transferências Instantâneas",
      en_description: "Instant Transfers",
    },
    {
      idMeiosPagamento: 9,
      pt_description: "IBAN Digital",
      en_description: "Digital IBAN",
    },
    {
      idMeiosPagamento: 10,
      pt_description: "Boleto Bancário",
      en_description: "Boleto Bancário",
    },
    {
      idMeiosPagamento: 11,
      pt_description: "Edenred",
      en_description: "Edenred",
    },
    {
      idMeiosPagamento: 12,
      pt_description: "Universo Flex",
      en_description: "Universo Flex",
    },
    {
      idMeiosPagamento: 13,
      pt_description: "Santander Consumer Finance",
      en_description: "Santander Consumer Finance",
    },
    {
      idMeiosPagamento: 14,
      pt_description: "Transferência de Fundos para Conta Bancária SEPA",
      en_description: "Transfer of Funds to SEPA Bank Account",
    },
  ];

  const organizationTypeList = [
    { label: "Ocasional", value: "Ocasional", selected: false },
    { label: "Demo", value: "Demo", selected: false },
    { label: "Pagamentos", value: "Pagamentos", selected: false },
  ];

  function updatePrecarios() {
    if (precario != null && precario != undefined && precario?.length > 0) {
      dispatch(
        updatePrecario({
          nif: null,
          tipoConta: null,
          pid: "5247",
          prices: precario,
        })
      );
      setIsModalErrorSaveShow(true);
    }
  }

  function createBodyError1() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida
          text="Tem de preencher o tipo de conta e/ou NIF , ou o Nº de processo"
          status="error"
        />
      </div>
    );
  }

  function createBodyError2() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida
          text="Não existe preçário ou existe mais de um para os dados inseridos"
          status="error"
        />
      </div>
    );
  }

  function createBodyError3() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida
          text="Para criar um novo preçário é preciso escolher um Nº de processo"
          status="error"
        />
      </div>
    );
  }

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }

  const [currentCreatePid, setCurrentCreatePid] = useState("");
  const [currentCreateAccount, setCurrentCreateAccount] = useState("");
  const [currentTitle, setCurrentTitle] = useState("Escolha um preçário");
  function createPrecario() {
    if (currentPid === "") {
      setIsModalError3Showing(true);
      return;
    }
    var accountType = "";
    for (var i = 0; i < users.length; i++) {
      if (users[i].account?.pid === currentPid) {
        accountType = users[i].account?.tipoConta;
      }
    }

    dispatch(savePrecario({ pid: currentPid, tipoConta: accountType }));
    setCurrentPid("");
  }

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Modal
        className="easypay-modal"
        showModal={isModalError1Showing}
        setShowModal={setIsModalError1Showing}
        headerContent={"Escolher preçário"}
        bodyContent={createBodyError1()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalError2Showing}
        setShowModal={setIsModalError2Showing}
        headerContent={"Escolher preçário"}
        bodyContent={createBodyError2()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalError3Showing}
        setShowModal={setIsModalError3Showing}
        headerContent={"Escolher preçário"}
        bodyContent={createBodyError3()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorSaveShow}
        setShowModal={setIsModalErrorSaveShow}
        headerContent={"Guardar alterações"}
        bodyContent={createBodySave()}
        key={uuid()}
      ></Modal>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-content easypay-backoffice-tab">
        <TabSeparatorWrapper defaultActive={"tab4"}>
          <TabSeparator
            text="Leads"
            name="tab1"
            redirect={"/#/uniksystem/onboarding/backoffic-organization/leads"}
          />
          <TabSeparator
            text="Onboarding"
            name="tab2"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/onboarding"
            }
          ></TabSeparator>
          <TabSeparator
            text="Documentos"
            name="tab3"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/documents"
            }
          ></TabSeparator>
          <TabSeparator text="Preçário" name="tab4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", padding: "20px" }}>
                <InputDropdown
                  text={t("Tipo de conta")}
                  required={true}
                  placeholder="N/a"
                  list={organizationTypeList}
                  handleSubmit={(e) => handleTypeSearch(e)}
                  initialValue={currentAccount}
                />
                <div
                  style={{
                    display: "flex",
                    paddingRight: "30px",
                    paddingLeft: "20px",
                  }}
                >
                  <SimpleInput
                    field={buildInputField("NIF", currentNif)}
                    key={"accordion_benef_nome"}
                    handleOnBlur={(e) => handleBlurInputs("nif", e)}
                  />
                  <div
                    style={{
                      display: "flex",
                      paddingRight: "15px",
                      paddingLeft: "10px",
                      paddingTop: "35px",
                    }}
                  >
                    <Text text={"ou::card:text-light"} />
                  </div>
                  <InputSearch
                    field={{ text: "inputLabel" }}
                    required={true}
                    text={t(`bpm.onboarding.backoffice.user.labelIDLeadClient`)}
                    placeholder={t(
                      `bpm.onboarding.backoffice.user.labelIndicateID`
                    )}
                    list={leadsList}
                    handleSubmit={(value) => handleSelectChangeMain(value)}
                  />
                </div>
                <div style={{ display: "flex", paddingTop: "26px" }}>
                  <SimpleButton
                    text={"Escolher preçário"}
                    variant={"easypay-button-primary"}
                    onClick={() => escolherPrecario()}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    paddingTop: "26px",
                    paddingLeft: "15px",
                  }}
                >
                  <SimpleButton
                    text={"Guardar alterações"}
                    variant={"easypay-button-primary"}
                    onClick={() => updatePrecarios()}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    paddingTop: "26px",
                    paddingLeft: "15px",
                  }}
                >
                  <SimpleButton
                    text={"Criar preçário"}
                    variant={"easypay-button-primary"}
                    onClick={() => createPrecario()}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text text={currentTitle} />
            </div>
            <Table
              sort={{ dataField: "id", order: "cresc" }}
              docs={precario != null && precario != undefined ? precario : []}
              columns={columns}
              tableSize={tableSize}
              setTableSize={setTableSize}
              key="onboarding-table"
            />
          </TabSeparator>
        </TabSeparatorWrapper>
        <SpinnerNew open={Object.keys(users).length === 0 && isLoadingGetAllUsers}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BackOfficePrecario);
