import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { Card } from "react-bootstrap";
import { useInterval } from "~/components/Notifications/utils/useInterval";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBPMNotifications,
  getUserPortalRHNotifications,
} from "~/store/ducks/notifications/actionTypes";
import { BPM_MIDDLEWARE, PORTALRH_MIDDLEWARE } from "~/utils/constants";
import Notifications from "./Notifications";

const NotificationWidget = ({
  apagarWidget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  t,
}) => {
  const dispatch = useDispatch();
  const {
    notificationUpdated,
    isDeletingNotification,
    portalRHNotifications,
    bpmNotifications,
  } = useSelector((state) => state.notificationsReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const getUserDetails = (middleware) => {
    const userDetail = user?.legacyUsers?.find(
      (item) => item.legacyApplication.label === middleware.context
    );
    return {
      username: userDetail?.username,
      appId: userDetail?.legacyApplication?.id,
    };
  };

  const { username: usernamePortalRH, appId: appIdPortalRH } = getUserDetails(PORTALRH_MIDDLEWARE);
  const { username: usernameBPM, appId: appIdBPM } = getUserDetails(BPM_MIDDLEWARE);

  const legacyNotifications = {
    ...portalRHNotifications,
    ...bpmNotifications,
  };

  const updateLegacyNotifications = () => {
    if (usernamePortalRH && appIdPortalRH && usernamePortalRH !== "easypay_user") {
      dispatch(getUserPortalRHNotifications(appIdPortalRH, usernamePortalRH));
    }
    if (usernameBPM && appIdBPM && usernameBPM !== "easypay_user") {
      dispatch(getUserBPMNotifications(appIdBPM, user?.username));
    }
  };

  useEffect(() => {
    updateLegacyNotifications();
  }, [dispatch, user, notificationUpdated, isDeletingNotification]);

  useInterval(updateLegacyNotifications, 1000 * 30);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
           Notification Widget
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body style={{ padding: "10px" }}>
            <Notifications notifications={legacyNotifications} />
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(NotificationWidget);