import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";
import Dropdown from "~/components/Dropdown";
import { Row, Col, Form } from "react-bootstrap";

const ConsumosTable = ({ t }) => {
  const dispatch = useDispatch();
  const { consumosList, isLoadingConsumosList } = useSelector(
    (state) => state.processesReducer
  );
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [value, setValue] = useState("0");
  const [tableValues, setTableValues] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setTableValues(consumosList);
  }, [consumosList]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
      hidden: true,
    },
    // {
    //   dataField: "ativo",
    //   text: t("portalrh.rhadmin.gestaoTemplates.active"),
    //   sort: true,
    //   formatter: buildAtiveColumn,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "110px", textAlign: "center" };
    //   },
    //   hidden: true,
    // },
    {
      dataField: "descricao",
      text: t("portalrh.rhadmin.gestaoTemplates.description"),
      sort: true,
    },
    // {
    //   dataField: "accao",
    //   text: t("portalrh.rhadmin.gestaoTemplates.action"),
    //   formatter: buildActionsColumn,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "110px", textAlign: "center" };
    //   },
    // },
  ];

  const mobileColumns = [
    // {
    //   dataField: "info",
    //   sort: false,
    //   text: "",
    //   formatter: (cellContent, row, rowIndex, formatExtraData) => {
    //     const headers = formatExtraData.headers;
    //     if (dynamicColumns.length > 0)
    //       return (
    //         <MobileCard
    //           row={row}
    //           cell={cellContent}
    //           rowIndex={rowIndex}
    //           formatExtraData={formatExtraData}
    //           t={t}
    //           headers={headers}
    //           buildStatusColumn={buildStatusColumn}
    //           handleRowClick={handleRowClick}
    //         />
    //       );
    //     return (
    //       <div>
    //         <br />
    //         {headers && headers && headers.map((header, index) => {
    //           const key = header.dataField;
    //           if (key && row[key] !== undefined && !header.hidden) {
    //             const value =
    //               typeof row[key] === "boolean"
    //                 ? row[key].toString()
    //                 : row[key];
    //             return (
    //               <div key={index}>
    //                 <b>{header.text}: </b>
    //                 {value}
    //               </div>
    //             );
    //           }
    //           return null;
    //         })}
    //       </div>
    //     );
    //   },
    //   formatExtraData: {
    //     headers: columns,
    //   },
    // },
    // {
    //   dataField: "state",
    //   text: "",
    //   sort: false,
    //   formatter: (cellContent, row) => {
    //     return (
    //       <div>
    //         <a
    //           href="#"
    //           onClick={(e) => {
    //             e.preventDefault();
    //             handleRowClick(row);
    //           }}
    //         >
    //           Detalhe
    //         </a>
    //       </div>
    //     );
    //   },
    // },
  ];

const dropdownListConsumos = [
  { label: "Todos", value: "0", selected: value === "0" },
  { label: "Adiantamentos", value: "1", selected: value === "1" },
  { label: "Devoluções", value: "2", selected: value === "2" },
  { label: "Consumos", value: "3", selected: value === "3" },
];

  const handleChange = (newValue) => {
    setValue(newValue);
    let filteredList;
    switch (newValue) {
      case "1":
        filteredList = consumosList.filter(
          (consumo) => consumo.tipo === "adiantamentos"
        );
        break;
      case "2":
        filteredList = consumosList.filter(
          (consumo) => consumo.tipo === "devoluções"
        );
        break;
      case "3":
        filteredList = consumosList.filter(
          (consumo) => consumo.tipo === "consumos"
        );
        break;
      default:
        filteredList = consumosList;
    }
    setTableValues(filteredList);
  };

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={tableValues}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex" }}>
                <Row>
                  <Col>
                    <SearchBar
                      {...props.searchProps}
                      placeholder={t(
                        "portalrh.rhadmin.gestaoTemplates.searchInput"
                      )}
                    />
                  </Col>
                  <Col>
                    <div style={{ display: "inline-flex", marginTop: isMobile ? "1rem" : "" }}>
                      <Form.Label style={{ marginRight: "10px" }}>
                        Filtro
                      </Form.Label>
                      <Dropdown
                        haveWidth={true}
                        width="100%"
                        list={dropdownListConsumos}
                        handleSubmit={handleChange}
                        emptyDefault={true}
                        resetDropdown={cleanDropdown}
                        setResetDropdown={setCleanDropdown}
                        flowId={value}
                        orderedAlphabetical={true}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              {!isLoadingConsumosList && (
                <BootstrapTable
                  {...props.baseProps}
                  responsive
                  bootstrap4
                  pagination={paginationFactory(
                    isMobile ? mobilePagination : pagination
                  )}
                  noDataIndication={t("portalrh.rhadmin.consumos.emptyTable")}
                  striped={isMobile ? false : true}
                  hover={isMobile ? false : true}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default withNamespaces()(ConsumosTable);
