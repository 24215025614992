import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo from "~/assets/img/logos/unik-logo.png";

const LoginLogo = () => {
  const [logoImage, setLogoImage] = useState(logo);
  const { imageConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  useEffect(() => {
    if (imageConfiguration === "") {
      setLogoImage(logo);
    } else {
      setLogoImage(imageConfiguration);
    }
  }, [imageConfiguration]);

  return (
    <div className="row">
      <div className="col-12 mt-3 mb-2 text-center">
        <img className="img-fluid unik-logo" src={logoImage} alt="Uniksystem" />
      </div>
    </div>
  );
};

export default LoginLogo;