import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import SpinnerNew from "~/components/SpinnerNew";
import oauth from "~/utils/oauth";
import ModalExtraction from "./AutomatedDatacapture/components/ModalExtraction";
import {
  saveDocumentIntoBatch,
  clearSavedBatch,
} from "~/pages/DataCapture/actions";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {
  saveDocumentExpenses,
  setDocumentExpensesResult,
  setAlertDocumentExpenses,
  setPidExpenses,
  setCreateDocumentExpenses,
} from "~/store/ducks/openai/actionTypes";

const GeneralDocumentExpense = ({
  selectedDocType,
  datasetSelected,
  handleClose,
  experiencedUser,
  setShowModalInitial,
  t,
}) => {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const dispatch = useDispatch();

  const [onDragOver, setOnDragOver] = useState(false);
  const [maxFileSize, setMaxFileSize] = useState(1024 * 1024 * 15);
  const [maxFiles, setMaxFiles] = useState(experiencedUser ? 100 : 1);
  const [documents, setDocuments] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [documentName, setDocumentName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlertDocumentExpense, setMessageAlertDocumentExpense] =
    useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const [openModalExtraction, setOpenModalExtraction] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [documentValues, setDocumentValues] = useState({});
  const [editMode, setEditMode] = useState(false);

  const inputFile = useRef(null);

  const { batch, isUploadingFile, toRemoveFiles } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { user } = useSelector((state) => state.globalReducer);

  const {
    isLoadingSaveDocumentExpenses,
    documentExpensesResult,
    alertDocumentExpense,
    documentExpensesList,
    documentExpensesSaved,
    documentExpensesSavedId,
    pidExpenses,
  } = useSelector((state) => state.openaiReducer);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResetState();
  }, []);

  useEffect(() => {
    if (alertDocumentExpense != "") {
      setOpenAlert(true);
      if (alertDocumentExpense === "success") {
        setMessageAlertDocumentExpense(t(`openai.${alertDocumentExpense}`));
      } else {
        setMessageAlertDocumentExpense(t(`openai.${alertDocumentExpense}`));
      }
      setTypeAlert(alertDocumentExpense);
    }
  }, [alertDocumentExpense]);

  useEffect(() => {
    if (toRemoveFiles) {
      handleRemoveFile();
      dispatch(clearSavedBatch());
    }
  }, [toRemoveFiles]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlertDocumentExpenses(""));
    setOpenAlert(false);
    setMessageAlertDocumentExpense("");
    setTypeAlert("");
    dispatch(setPidExpenses({}));
  };

  const getPreviewImage = () => {
    const file = documents[0];
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : "data:image/jpg;base64," + document.data;
    }
  };

  const handleRemoveFile = () => {
    setDocuments([]);
    setDocumentName("");
    if (inputFile.current) {
      inputFile.current.value = "";
    }
    dispatch(setDocumentExpensesResult({}));
  };

  const handleResetState = () => {
    setDocumentValues({});
    handleRemoveFile();
    setImageBase64("");
    setEditMode(false);
    setOpenModalExtraction(false);
    setDocumentName("");
    dispatch(setDocumentExpensesResult({}));
    dispatch(setCreateDocumentExpenses("", null));
  };

  const handleOnChoose = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile.type === "image/jpeg" ||
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg" ||
      selectedFile.type === "application/pdf"
    ) {
      handleMultipleFiles([selectedFile]);
    } else {
      setWarningMessage(t("portalrh.expenses.fileType"));
    }
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleMultipleFiles = (files) => {
    let totalfilesize = files.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );
    if (totalfilesize > maxFileSize) {
      setWarningMessage(t("portalrh.expenses.fileSize"));
      return;
    }
    setDocuments(files);
    setDocumentName(files[0].name);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleSaveDocs = () => {
    const docList = [];
    documents.forEach((document, index, array) => {
      if (document.type.includes("image")) {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        docList.push({
          file: e.target.result,
        });
        if (index === array.length - 1) {
          if (docList.length > 0) {
            const base64String = docList[0].file.split("base64,")[1];
            const payload = {
              userId: user.id,
              organizationId: user.organization.id,
              base64: base64String,
              docType: datasetSelected.documentType,
              openaiId: documentExpensesResult.openaiId || null,
            };
            setImageBase64(base64String);
            dispatch(saveDocumentExpenses(payload));
          }
          setDocuments([]);
        }
      };
      reader.readAsDataURL(document);
    });
  };

  useEffect(() => {
    if (Object.keys(documentExpensesResult).length > 0) {
      //setShowModalInitial(false);
      setOpenModalExtraction(true);
    } else {
      if (documentExpensesResult == "") {
        setOpenAlert(true);
        setMessageAlertDocumentExpense(t("openai.errorReadingDocument"));
        setTypeAlert("error");
        handleRemoveFile();
      }
      handleRemoveFile();
    }
  }, [documentExpensesResult]);

  useEffect(() => {
    if (documentExpensesSavedId != null) {
      if (documentExpensesSaved == "success") {
        handleResetState();
      } else if (documentExpensesSaved == "error") {
        handleResetState();
        setOpenAlert(true);
        setMessageAlertDocumentExpense(t("openai.errorReadingDocument"));
        setTypeAlert("error");
      }
    }
  }, [documentExpensesSaved]);

  const isDragAndDropEnabled = documents.length === 0;

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlertDocumentExpense}
        </Alert>
      </Snackbar>
      <SpinnerNew open={isUploadingFile || isLoadingSaveDocumentExpenses} />
      <div
        className="main-card-v2"
        style={{ display: "block", padding: "0px" }}
      >
        <Card bsPrefix="card-flat">
          <Card.Header
            className="justify-content-between"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={isMobile ? { fontSize: "18px" } : { margin: "0" }}>
              {" "}
              {t("datacapture.general.fileUploadTitle")}
            </h4>
          </Card.Header>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", padding: "0px" }}
          >
            <div
              style={{
                margin: "0px 0px 2rem",
                width: "100%",
                alignSelf: isMobile ? "auto" : "center",
              }}
            >
              <div style={{ display: !isMobile ? "flex" : "" }}>
                <div
                  className="dropAreaExpenses"
                  onDrop={(e) => {
                    if (isDragAndDropEnabled) handleOnDrop(e);
                  }}
                  onClick={() => {
                    if (isDragAndDropEnabled) handleClick();
                  }}
                  onDragEnter={() => {
                    if (isDragAndDropEnabled) setOnDragOver(true);
                  }}
                  onDragLeave={() => {
                    if (isDragAndDropEnabled) setOnDragOver(false);
                  }}
                  style={
                    isDragAndDropEnabled && onDragOver
                      ? { background: "#fff" }
                      : { background: "#efefef" }
                  }
                >
                  <span>
                    <i className={"icon-submit portal-image-upload-icon"} />
                    <div
                      className={"portal-label-main"}
                      style={{ padding: "2rem" }}
                    >
                      {t("portalrh.documentManagement.dropToUpload")}
                    </div>
                  </span>
                  <input
                    multiple={false}
                    id="file"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(e) => handleOnChoose(e)}
                    accept=".pdf, .png,.jpg,.jpeg"
                    disabled={!isDragAndDropEnabled}
                  />
                </div>
                {isMobile && (
                  <div className="dc-label">
                    {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    width: isMobile ? "100%" : "50%",
                    textAlignLast: "center",
                    marginTop: isMobile ? "1rem" : "",
                    height: "0px",
                  }}
                >
                  {(!isMobile || documents.length > 0) && (
                    <h6 style={{ placeContent: "center", padding: "0px" }}>
                      {t("openai.expenseLoaded")}
                    </h6>
                  )}
                  {documents.length > 0 && (
                    <>
                      <img
                        src={getPreviewImage()}
                        className={"portal-image-preview-one"}
                        alt="preview"
                      />
                      <CancelIcon color="error" onClick={handleRemoveFile} />
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={<Tooltip>{documents[0].name}</Tooltip>}
                      >
                        <small>{abbreviateString(documents[0].name, 28)}</small>
                      </OverlayTrigger>
                    </>
                  )}
                </div>
              </div>
              {!isMobile && (
                <div className="dc-label">
                  {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <div
              style={{
                width: "100%",
                textAlignLast: "right",
                marginTop: "3rem",
              }}
            >
              <Button
                className="card-button"
                disabled={
                  documents.length > 0 && selectedDocType != "" ? false : true
                }
                onClick={() => {
                  documents.length > 0 && handleSaveDocs();
                }}
              >
                {t("openai.upload")}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </div>
      <ModalExtraction
        openModal={openModalExtraction}
        setOpenModal={setOpenModalExtraction}
        documentValues={documentValues}
        setDocumentValues={setDocumentValues}
        handleResetState={handleResetState}
        isImage={isImage}
        imageBase64={imageBase64}
        documentName={documentName}
        datasetSelected={datasetSelected}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </>
  );
};

export default withNamespaces()(GeneralDocumentExpense);
