import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  getUserPlafondAndExpenses,
  postUserPlafondAndExpenses,
  deleteUserPlafond,
  messagePlafond,
} from "~/store/ducks/portalrh/actionTypes";
import Dropdown from "~/components/Dropdown";
import { IoIosRemoveCircleOutline, IoMdAddCircleOutline } from "react-icons/io";
import { FaTrash } from "react-icons/fa";

const AddNewModal = ({ show, setShowAddNewModal, selectedUser, t }) => {
  const dispatch = useDispatch();
  const [dropdownValue, setDropdownValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [items, setItems] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const { userExpenses } = useSelector((state) => state.portalrhReducer);
  const [filteredExpenses, setFilteredExpenses] = useState(userExpenses);

  useEffect(() => {
    setFilteredExpenses(userExpenses);
  }, [userExpenses]);

  const handleAdd = () => {
    const selectedExpense = filteredExpenses.find(
      (expense) => expense.id.toString() === dropdownValue
    );
    if (selectedExpense) {
      const newItem = {
        dropdownValue: selectedExpense.descricao,
        textValue,
        dropdownId: selectedExpense.id.toString(),
      };
      setItems([...items, newItem]);
      setDropdownValue("");
      setTextValue("");
      setCleanDropdown(true);
      setFilteredExpenses(
        filteredExpenses.filter(
          (expense) => expense.id.toString() !== dropdownValue
        )
      );
    }
  };

  const handleSave = () => {
    const allItemsFilled = items.every(
      (item) => item.dropdownValue && item.textValue
    );

    if (!allItemsFilled || items.length === 0) {
      return;
    }

    const payload = items.map((item) => ({
      userId: selectedUser,
      plafondId: Number(item.dropdownId),
      valor: item.textValue,
    }));
    dispatch(postUserPlafondAndExpenses(payload, selectedUser));
    handleClose();
  };

  const handleEdit = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleClose = () => {
    setCleanDropdown(true);
    setItems([]);
    setShowAddNewModal(false);
  };

  const renderList = () =>
    filteredExpenses.map((expense) => ({
      label: expense.descricao,
      value: expense.id.toString(),
      selected: expense.id.toString() === dropdownValue,
    }));

  const handleSelectDrop = (value) => {
    setDropdownValue(value);
  };

  const handleRemoveAdded = (item) => {
    const newItems = items.filter((i) => i !== item);
    setItems(newItems);
    setFilteredExpenses([
      ...filteredExpenses,
      userExpenses.find((expense) => expense.id.toString() === item.dropdownId),
    ]);
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("portalrh.plafonds.despesaTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form className="d-flex align-items-center">
            <Form.Group controlId="formDropdown" className="mr-3 flex-grow-1">
              <Form.Label>{t("portalrh.plafonds.despesa")}</Form.Label>
              <Dropdown
                list={renderList()}
                flowId={dropdownValue}
                handleSubmit={handleSelectDrop}
                emptyDefault={true}
                resetDropdown={cleanDropdown}
                setResetDropdown={setCleanDropdown}
              />
            </Form.Group>
            <Form.Group controlId="formText" style={{ marginBottom: "2rem" }}>
              <Form.Label>{t("Texto")}</Form.Label>
              <Form.Control
                type="text"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                style={{ width: "10rem" }}
              />
            </Form.Group>
          </Form>
          <IoMdAddCircleOutline
            onClick={handleAdd}
            style={{ fontSize: "xx-large" }}
          />
        </Form>
        <ListGroup className="mt-3">
          {items.map((item, index) => (
            <ListGroup.Item key={index} className="d-flex align-items-center">
              <div className="flex-grow-1">
                <span>{item.dropdownValue}</span>
              </div>
              <div>
                <Form.Control
                  type="text"
                  value={item.textValue}
                  onChange={(e) =>
                    handleEdit(index, "textValue", e.target.value)
                  }
                  style={{ width: "10rem" }}
                />
              </div>
              <div className="ml-1">
                <IoIosRemoveCircleOutline
                  onClick={() => handleRemoveAdded(item)}
                  style={{
                    cursor: "pointer",
                    color: "red",
                    fontSize: "larger",
                  }}
                />
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="contained"
          className="card-button-red"
          onClick={handleClose}
        >
          {t("portalrh.rhadmin.gestaoTemplates.close")}
        </Button>
        <Button
          variant="contained"
          className="card-button"
          onClick={handleSave}
        >
          {t("portalrh.plafonds.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalPlafond = ({ open, handleClose, isMobile, selectedUser, t }) => {
  const { SearchBar } = Search;
  const { userPlafond, plafondType, plafondMessage } = useSelector(
    (state) => state.portalrhReducer
  );
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false); // Novo estado para a modal de confirmação de salvar
  const [selectedRow, setSelectedRow] = useState(null);
  const [editedValues, setEditedValues] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const inputRefs = useRef({});
  const dispatch = useDispatch();

useEffect(() => {
  if (plafondType !== "" && plafondMessage !== "") {
    setTypeAlert(plafondType);
    setOpenAlert(true);

    const messages = {
      get: {
        error: t("portalrh.plafonds.noGetPlafonds"),
      },
      post: {
        error: t("portalrh.plafonds.noPostOrUpdate"),
        success: t("portalrh.plafonds.PostOrUpdate"), // Corrigido aqui
      },
      delete: {
        error: t("portalrh.plafonds.noDelete"),
        success: t("portalrh.plafonds.Delete"), // Corrigido aqui
      },
    };

    const message = messages[plafondMessage]?.[plafondType];
    setMessageAlert(message || "");
  }
}, [plafondType, plafondMessage]);

  useEffect(() => {
    if (userPlafond) {
      clearRefs();
      const initialEditedValues = userPlafond.map((item) => ({
        ...item,
        valor: item.valor.toString(),
      }));
      setEditedValues(initialEditedValues);
    }
  }, [userPlafond]);

  useEffect(() => {
    if (focusedInput && inputRefs.current[focusedInput]) {
      inputRefs.current[focusedInput].focus();
    }
  }, [editedValues, focusedInput]);

  const clearRefs = () => {
    inputRefs.current = {};
    setFocusedInput(null);
  };

  const handleOpenDeleteModal = (row) => {
    setSelectedRow(row);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUserPlafond(selectedRow.userPlafondId, selectedUser));
    setShowConfirmModal(false);
    setSelectedRow(null);
    clearRefs();
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setSelectedRow(null);
    clearRefs();
  };

  const handleValueChange = (userPlafondId, value) => {
    setFocusedInput(userPlafondId);
    setEditedValues((prevValues) =>
      prevValues.map((item) =>
        item.userPlafondId === userPlafondId ? { ...item, valor: value } : item
      )
    );
  };

  const handleSaveAll = () => {
    setShowSaveConfirmModal(true); // Exibir a modal de confirmação de salvar
  };

  const handleConfirmSave = () => {
    const payload = editedValues.map((item) => ({
      userId: selectedUser,
      plafondId: item.tipoDespesa.id,
      valor: parseFloat(item.valor),
    }));

    dispatch(postUserPlafondAndExpenses(payload, selectedUser));
    setEditedValues([]);
    clearRefs();
    setShowSaveConfirmModal(false); // Ocultar a modal de confirmação de salvar
  };

  const handleCancelSave = () => {
    dispatch(getUserPlafondAndExpenses(selectedUser));
    setShowSaveConfirmModal(false); // Ocultar a modal de confirmação de salvar
  };

  const handleModalClose = () => {
    handleClose();
    clearRefs();
  };

  const columns = [
    {
      dataField: "tipoDespesa.descricao",
      text: t("portalrh.plafonds.descricao"),
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "valor",
      text: t("portalrh.plafonds.valor"),
      sort: true,
      style: {
        fontSize: "12px",
        display: "grid",
        placeItems: "center",
        alignContent: "center",
      },
      formatter: (cell, row) => (
        <>
          <Form.Control
            type="text"
            value={
              editedValues.find(
                (item) => item.userPlafondId === row.userPlafondId
              )?.valor || row.valor
            }
            onChange={(e) =>
              handleValueChange(row.userPlafondId, e.target.value)
            }
            style={{ width: "10rem" }}
            ref={(el) => (inputRefs.current[row.userPlafondId] = el)}
          />
        </>
      ),
    },
    {
      dataField: "actions",
      text: t("portalrh.plafonds.actions"),
      formatter: (_, row) => (
        <div
          className="table-icon-wrapper"
          style={{
            display: "grid",
            placeItems: "center",
            alignContent: "center",
          }}
        >
          <FaTrash
            onClick={() => handleOpenDeleteModal(row)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
      hidden: isMobile,
    },
  ];

  const rowStyle = { cursor: "pointer" };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(messagePlafond("", ""));
    setOpenAlert(false);
    setMessageAlert("");
    setTypeAlert("");
  };

  return (
    <>
              <Snackbar
            open={openAlert}
            autoHideDuration={5000}
            onClose={closeAlert}
          >
            <Alert
              onClose={closeAlert}
              severity={typeAlert}
              sx={{ width: "100%" }}
            >
              {messageAlert}
            </Alert>
          </Snackbar>
      <Modal show={open} onHide={handleModalClose} centered size="lg">
        <Modal.Header closeButton />
        <Modal.Body>

          <ToolkitProvider
            keyField="userPlafondId"
            data={editedValues} // Use editedValues aqui
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div style={{ paddingBottom: "20px", display: "flex" }}>
                  <SearchBar
                    {...props.searchProps}
                    placeholder={t(
                      "portalrh.rhadmin.gestaoTemplates.searchInput"
                    )}
                  />
                  <div style={{ paddingLeft: "30px" }}>
                    <ButtonAdd
                      text={t("portalrh.rhadmin.gestaoTemplates.add")}
                      onClick={() => setShowAddNewModal(true)}
                    />
                  </div>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  bootstrap4
                  noDataIndication={t("general.dataNotFound")}
                  rowStyle={rowStyle}
                  pagination={
                    isMobile
                      ? paginationFactory(mobilePagination)
                      : userPlafond.length > 10
                      ? paginationFactory(pagination)
                      : null
                  }
                />
              </div>
            )}
          </ToolkitProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            className="card-button-red"
            onClick={handleModalClose}
          >
            {t("portalrh.rhadmin.gestaoTemplates.close")}
          </Button>
          <Button
            variant="contained"
            className="card-button"
            onClick={handleSaveAll}
          >
            {t("portalrh.plafonds.guardarAlteracoes")}
          </Button>
        </Modal.Footer>
      </Modal>
      <AddNewModal
        show={showAddNewModal}
        setShowAddNewModal={setShowAddNewModal}
        selectedUser={selectedUser}
        t={t}
      />
      <Modal show={showConfirmModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("portalrh.plafonds.removePlafondTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("portalrh.plafonds.confirmRemovePlafond")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            className="card-button-red"
            onClick={handleCancelDelete}
          >
            {t("portalrh.plafonds.cancel")}
          </Button>
          <Button
            variant="contained"
            className="card-button"
            onClick={handleConfirmDelete}
          >
            {t("portalrh.plafonds.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSaveConfirmModal} onHide={handleCancelSave} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("portalrh.plafonds.saveAlteracoesTitle")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("portalrh.plafonds.saveAlteracoes")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            className="card-button-red"
            onClick={handleCancelSave}
          >
            {t("portalrh.plafonds.cancel")}
          </Button>
          <Button
            variant="contained"
            className="card-button"
            onClick={handleConfirmSave}
          >
            {t("portalrh.plafonds.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withNamespaces()(ModalPlafond);
