import React, { useMemo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import Header from "~/pages/Easypay/components/Header";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import "~/pages/Easypay/assets/css/styles.css";
import iconWarning from "~pages/Easypay/assets/img/icons/icon-warning.png";
import "~pages/Easypay/assets/scss/_backofficeEasypay.scss";
import BackOfficeInput from "~pages/Easypay/components/BackOfficeInput";
import Link from "~pages/Easypay/components/Link";
import Table from "~pages/Easypay/components/Table";
import Tag from "~pages/Easypay/components/Tag";
import { jsonData } from "~pages/Easypay/testData";

//filters that store what is put in /BackOfficeInput
const EasypayBackOffice = () => {
  const history = useHistory();

  const dateFormatter = (cell) => {
    if (cell === "") {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const idFormatterLeads = (cell) => {
    return (
      <Link
        text={cell}
        onClick={() =>
          history.push({
            pathname: `/uniksystem/easypay/backoffice-leads-page/${cell}`,
          })
        }
      />
    );
  };

  const idFormatterOnboarding = (cell) => {
    return (
      <Link
        text={cell}
        onClick={() =>
          history.push({
            pathname: `/uniksystem/easypay/backoffice-onboarding-page/${cell}`,
          })
        }
      />
    );
  };

  const statusFormatter = () => {
    return <img src={iconWarning} />;
  };

  const resultFormatter = (cell) => {
    return (
      <Tag text={cell} variant="easypay-tag-label" type="easypay-approved" />
    );
  };

  const stageFormatter = (cell) => {
    return cell;
  };

  const columns1 = [
    {
      dataField: "statusIcon",
      text: "",
      formatter: statusFormatter,
    },
    {
      dataField: "id",
      text: "ID Lead", //t("bpm.onboarding.backoffice.administration.tableIdLead")
      sort: true,
      formatter: idFormatterLeads,
    },
    {
      dataField: "name",
      text: "Nome Lead", //t("bpm.onboarding.backoffice.administration.tableNameLead")
      sort: true,
    },
    {
      dataField: "account",
      text: "Conta", //t("bpm.onboarding.backoffice.administration.tableAccount")
      sort: false,
    },
    {
      dataField: "mobile",
      text: "Telemóvel", //t("bpm.onboarding.backoffice.administration.tablePhone")
      sort: false,
    },
    {
      dataField: "email",
      text: "E-mail", //t("bpm.onboarding.backoffice.administration.tableEmail")
      sort: false,
    },
    {
      dataField: "partner",
      text: "Parceiro", //t("bpm.onboarding.backoffice.administration.tablePartner")
      sort: false,
    },
    {
      dataField: "created_at",
      text: "Data de Criação", //t("bpm.onboarding.backoffice.administration.tableCreationDate")
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "created_by",
      text: "Criado por", //t("bpm.onboarding.backoffice.administration.tableCreatedBy")
      sort: false,
    },
    {
      dataField: "department",
      text: "Departamento", //t("bpm.onboarding.backoffice.administration.tableDepartment")
      sort: false,
    },
    {
      dataField: "stage",
      text: "Etapa", //t("bpm.onboarding.backoffice.administration.tableStage")
      sort: false,
      formatter: stageFormatter,
    },
    {
      dataField: "result",
      text: "Resultado", //t("bpm.onboarding.backoffice.administration.tableResult")
      sort: false,
      formatter: resultFormatter,
    },
  ];
  const columns2 = [
    {
      dataField: "statusIcon",
      text: "",
      formatter: statusFormatter,
    },
    {
      dataField: "id",
      text: "ID User", //t("bpm.onboarding.backoffice.administration.tableIdUser")
      sort: true,
      formatter: idFormatterOnboarding,
    },
    {
      dataField: "name",
      text: "Nome", //t("bpm.onboarding.backoffice.administration.tableName")
      sort: true,
    },
    {
      dataField: "entity",
      text: "Entidade", //t("bpm.onboarding.backoffice.administration.tableEntity")
      sort: false,
    },
    {
      dataField: "partner",
      text: "Parceiro", //t("bpm.onboarding.backoffice.administration.Partner")
      sort: false,
    },
    {
      dataField: "created_at",
      text: "Data de Criação", //t("bpm.onboarding.backoffice.administration.CreationDate")
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "days",
      text: "Dias", //t("bpm.onboarding.backoffice.administration.tableDays")
      sort: false,
    },
    {
      dataField: "created_by",
      text: "Criado por", //t("bpm.onboarding.backoffice.administration.CreatedBy")
      sort: false,
    },
    {
      dataField: "department",
      text: "Departamento", //t("bpm.onboarding.backoffice.administration.Department")
      sort: false,
    },
    {
      dataField: "stage",
      text: "Etapa", //t("bpm.onboarding.backoffice.administration.tableStage")
      sort: false,
      formatter: stageFormatter,
    },
    {
      dataField: "result",
      text: "Resultado", //t("bpm.onboarding.backoffice.administration.tableResult")
      sort: false,
      formatter: resultFormatter,
    },
  ];

  //translates json data into elements readable by the table
  const createDocs = (docData, columnData) => {
    let data = [];
    let index = 0;
    docData.forEach((dataRow) => {
      columnData.forEach((column) => {
        var dataAux = {
          ...data[index],
          [column.dataField]: dataRow[column.dataField],
        };
        data[index] = dataAux;
      });
      index++;
    });
    return data;
  };

  //stores data in memo variables so that createDocs isnt called every time a state is changed
  const data1 = useMemo(() => createDocs(jsonData, columns1), jsonData);
  const data2 = useMemo(() => createDocs(jsonData, columns2), jsonData);

  const [tableSize1, setTableSize1] = useState(10);
  //const [tableSize2, setTableSize2] = useState(10);// if we want independent page sizes

  const [filters1, setFilters1] = useState([
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
  ]);
  const [filters2, setFilters2] = useState([
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
  ]);

  //filters all the data using the filters, this runs anytime the filter is altered
  const filterData = (data, givenFilter) => {
    let result = data;
    var index = 0;
    givenFilter.forEach((filter) => {
      if (!(filter.value === "")) {
        result = result.filter((element) => {
          let accepted = false;
          filter.type.forEach((type) => {
            if (element[type] != undefined) {
              if (
                index == 0 &&
                element[type]?.toLowerCase().includes(filter.value?.toLowerCase())
              ) {
                accepted = true;
              } else if (index == 2) {
                const dateAux = new Date(element[type]);
                if (filter.value[0] <= dateAux && filter.value[1] >= dateAux) {
                  accepted = true;
                }
              }
              if (element[type] === filter.value) {
                accepted = true;
              }
            }
          });
          return accepted;
        });
      }
      index++;
    });
    return result;
  };

  //these are called when an input from /BackOfficeInput is changed, they alter the filters
  const setFilters1Handler = (filter, filterNum) => {
    if (filters1[filterNum] != filter.value) {
      setFilters1((prev) => {
        let newFilter = [...prev];
        newFilter[filterNum] = filter;
        return newFilter;
      });
    }
  };
  const setFilters2Handler = (filter, filterNum) => {
    setFilters2((prev) => {
      let newFilter = [...prev];
      newFilter[filterNum] = filter;
      return newFilter;
    });
  };

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header />
      <div className="easypay-backoffice-content easypay-backoffice-tab">
        <TabSeparatorWrapper>
          <TabSeparator text="Leads" name="tab1">
            <BackOfficeInput
              firstDropdownLabel={"Conta"} //t("bpm.onboarding.backoffice.administration.tableAccount")
              firstDropdownType={"account"}
              tableData={data1}
              onSetFilters={setFilters1Handler}
              savedFilters={filters1}
              key={"leads-input"}
            />
            <Table
              docs={filterData(data1, filters1)}
              columns={columns1}
              tableSize={tableSize1}
              setTableSize={setTableSize1}
              key="leads-table"
            />
          </TabSeparator>
          <TabSeparator text="Onboarding" name="tab2">
            <BackOfficeInput
              firstDropdownLabel={"Entidade"}
              firstDropdownType={"entity"} //t("bpm.onboarding.backoffice.administration.tableEntity")
              tableData={data2}
              onSetFilters={setFilters2Handler}
              savedFilters={filters2}
              key={"onboarding-input"}
            />
            <Table
              docs={filterData(data2, filters2)}
              columns={columns2}
              tableSize={tableSize1}
              setTableSize={setTableSize1}
              key="onboarding-table"
            />
          </TabSeparator>
        </TabSeparatorWrapper>
      </div>
    </div>
  );
};
export default withNamespaces()(EasypayBackOffice);
