import React from "react";
import { withNamespaces } from "react-i18next";
import SpinnerNew from "~/components/SpinnerNew";

const LoadingPage = () => {
  return (
    <div className="overlay">
      <SpinnerNew open={true} />
    </div>
  );
};
export default withNamespaces()(LoadingPage);
