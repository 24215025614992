export const SET_PROCESSES = "processes@SET_PROCESSES";
export const SET_PROCESSES_HISTORY = "processes@SET_PROCESSES_HISTORY";
export const SET_PROCESSES_COUNT = "processes@SET_PROCESSES_COUNT";
export const SET_PROCESSES_COUNT_TOTAL = "processes@SET_PROCESSES_COUNT_TOTAL";
export const SET_HISTORY = "processes@SET_HISTORY";
export const FIND_IFLOW_PROCESSES_BY_FILTERS =
  "processes@FIND_IFLOW_PROCESSES_BY_FILTERS";
export const FIND_IFLOW_PROCESSES_HISTORY =
  "processes@FIND_IFLOW_PROCESSES_HISTORY";
export const FIND_IFLOW_PROCESSES_COUNT =
  "processes@FIND_IFLOW_PROCESSES_COUNT";
export const FIND_IFLOW_PROCESSES_HISTORY_TASKS =
  "processes@FIND_IFLOW_PROCESSES_HISTORY_TASKS";

export const GET_IFLOW_FORMDATA = "processes@GET_IFLOW_FORMDATA";
export const SET_IFLOW_FORMDATA = "processes@SET_IFLOW_FORMDATA";
export const POST_IFLOW_FORMDATA = "processes@POST_IFLOW_FORMDATA";
export const POST_IFLOW_FORM = "processes@POST_IFLOW_FORM";
export const SET_IFLOW_PID = "processes@SET_IFLOW_PID";

export const GET_IFLOW_DOCUMENT = "processes@GET_IFLOW_DOCUMENT";
export const SET_IFLOW_DOCUMENTS = "processes@SET_IFLOW_DOCUMENTS";
export const SET_SELECTED_TOSIGN = "processes@SET_SELECTED_TOSIGN";
export const RESET_IFLOW_DOCUMENTS = "processes@RESET_IFLOW_DOCUMENTS";

export const SET_IFLOW_TRIGGER_LOADING = "onboarding@SET_IFLOW_TRIGGER_LOADING";

export const SET_IFLOW_DONE = "onboarding@SET_IFLOW_DONE";

export const SUBSTITUTE_IFLOW_DOCUMENT = "processes@SUBSTITUTE_IFLOW_DOCUMENT";

export const GET_BUTTONSAVE_TRIGGER = "processes@GET_BUTTONSAVE_TRIGGER";
export const SET_BUTTONSAVE_TRIGGER = "processes@SET_BUTTONSAVE_TRIGGER";
export const CLEAR_IFLOW_FORMDATA = "processes@CLEAR_IFLOW_FORMDATA";

export const GET_GREYED_OUT = "processes@GET_GREYED_OUT";
export const SET_GREYED_OUT = "processes@SET_GREYED_OUT";

export const POST_ADMISSAO_COLLAB = "processes@POST_ADMISSAO_COLLAB";
export const GET_COMPANIES_NEW_COLLAB = "processes@GET_COMPANIES_NEW_COLLAB";
export const SET_COMPANIES_NEW_COLLAB = "processes@SET_COMPANIES_NEW_COLLAB";

export const SET_ADMISSAO_COLLAB_MESSAGE =
  "processes@SET_ADMISSAO_COLLAB_MESSAGE";
export const SET_ADMISSAO_COLLAB_MESSAGE_ALREADY_EXISTS =
  "processes@SET_ADMISSAO_COLLAB_MESSAGE_ALREADY_EXISTS";

export const GET_TEMPLATES_LIST = "GET_TEMPLATES_LIST";
export const SET_TEMPLATES_LIST = "SET_TEMPLATES_LIST";
export const UPDATE_TEMPLATE_PROFILE = "UPDATE_TEMPLATE_PROFILE";
export const UPDATE_TEMPLATE_PROFILE_DONE = "UPDATE_TEMPLATE_PROFILE_DONE";
export const GET_TEMPLATE_DETAIL = "GET_TEMPLATE_DETAIL";
export const SET_TEMPLATE_DETAIL = "SET_TEMPLATE_DETAIL";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const SET_TEMPLATE_DOCUMENTS_ALL = "SET_TEMPLATE_DOCUMENTS_ALL";
export const GET_CATALOGO_FORMACAO = "GET_CATALOGO_FORMACAO";
export const SET_CATALOGO_FORMACAO = "SET_CATALOGO_FORMACAO";
export const GET_PLANO_FORMACAO = "GET_PLANO_FORMACAO";
export const UPLOAD_PLANO_FORMACAO = "UPLOAD_PLANO_FORMACAO";
export const SET_UPLOAD_PLANO_FORMACAO = "SET_UPLOAD_PLANO_FORMACAO";
export const SET_DOWNLOAD_PLANO_FORMACAO = "SET_DOWNLOAD_PLANO_FORMACAO";
export const DELETE_CATALOGO_FORMACAO = "DELETE_CATALOGO_FORMACAO";
export const GET_CATALOGO_FORMACAO_DROPDOWNS =
  "GET_CATALOGO_FORMACAO_DROPDOWNS";
export const SET_CATALOGO_FORMACAO_DROPDOWNS =
  "SET_CATALOGO_FORMACAO_DROPDOWNS";
export const UPDATE_CREATE_CATALOGO_FORMACAO =
  "UPDATE_CREATE_CATALOGO_FORMACAO";
export const UPDATE_CREATE_CATALOGO_FORMACAO_DONE =
  "UPDATE_CREATE_CATALOGO_FORMACAO_DONE";

export const GET_IFLOW_PROPERTIES = "GET_IFLOW_PROPERTIES";
export const SET_IFLOW_PROPERTIES = "SET_IFLOW_PROPERTIES";

export const GET_ENTITIES_FORMACAO = "GET_ENTITIES_FORMACAO";
export const SET_ENTITIES_FORMACAO = "SET_ENTITIES_FORMACAO";

export const SET_DELETE_CATALOGO_FORMACAO_MESSAGE = "SET_DELETE_CATALOGO_FORMACAO_MESSAGE";

export const GET_CONSUMOS_LIST = "GET_CONSUMOS_LIST";
export const SET_CONSUMOS_LIST = "SET_CONSUMOS_LIST";

export const setProcesses = (processes) => {
  return { type: SET_PROCESSES, data: processes };
};

export const setHistory = (history) => {
  return { type: SET_HISTORY, data: history };
};
export const findIflowProcessesByFilters = (payload) => ({
  type: FIND_IFLOW_PROCESSES_BY_FILTERS,
  payload,
});
export const setProcessesHistory = (processes) => {
  return { type: SET_PROCESSES_HISTORY, data: processes };
};
export const setProcessesCountTotal = (processes) => {
  return { type: SET_PROCESSES_COUNT_TOTAL, data: processes };
};
export const setProcessesCount = (processes) => {
  return { type: SET_PROCESSES_COUNT, data: processes };
};
export const findIflowProcessesHistory = (payload) => ({
  type: FIND_IFLOW_PROCESSES_HISTORY,
  payload,
});
export const findIflowProcessesCount = (payload) => ({
  type: FIND_IFLOW_PROCESSES_COUNT,
  payload,
});

export const findIflowProcessesByHistory = (payload) => ({
  type: FIND_IFLOW_PROCESSES_HISTORY_TASKS,
  payload,
});

export const getIflowFormdata = (payload, isNewForm) => ({
  type: GET_IFLOW_FORMDATA,
  data: payload,
  isNewForm: isNewForm,
});


export const cleariflowFormadata = () => ({ type: CLEAR_IFLOW_FORMDATA });

export const setIflowFormdata = (formdata) => ({
  type: SET_IFLOW_FORMDATA,
  formdata,
});

export const setGreyedOut = (greyedout) => ({
  type: SET_GREYED_OUT,
  greyedout,
});

export const setCompaniesNewCollab = (companies) => ({
  type: SET_COMPANIES_NEW_COLLAB,
  companies,
});

export const setAdmissaoCollabMessage = (message) => ({
  type: SET_ADMISSAO_COLLAB_MESSAGE,
  message,
});

export const setAdmissaoCollabMessageAlreadyExists = (collabExists) => ({
  type: SET_ADMISSAO_COLLAB_MESSAGE_ALREADY_EXISTS,
  collabExists,
});

export const getGreyedout = () => ({
  type: GET_GREYED_OUT,
});

export const getCompaniesNewCollab = () => ({
  type: GET_COMPANIES_NEW_COLLAB,
});

export const postAdmissaoCollab = (data) => ({
  type: POST_ADMISSAO_COLLAB,
  data,
});

export const setIflowPidScheduler = (pid) => ({
  type: SET_IFLOW_PID,
  data: pid,
});

export const postIflowFormData = (formdata, url, isNewForm) => ({
  type: POST_IFLOW_FORMDATA,
  formdata,
  url,
  isNewForm,
});

export const postIflowForm = (payload, url) => ({
  type: POST_IFLOW_FORM,
  payload,
  url,
});

export const getIflowDocument = (payload) => ({
  type: GET_IFLOW_DOCUMENT,
  data: payload,
});

export const setSelectedTosign = (data) => ({
  type: SET_SELECTED_TOSIGN,
  data,
});

export const setIflowDone = (message) => ({
  type: SET_IFLOW_DONE,
  message,
});

export const setIflowDocuments = (document, docid, filename) => ({
  type: SET_IFLOW_DOCUMENTS,
  document,
  docid,
  filename,
});

export const resetIflowDocuments = () => ({
  type: RESET_IFLOW_DOCUMENTS,
});

export const substituteIflowDocument = (url, data, updateCoords) => ({
  type: SUBSTITUTE_IFLOW_DOCUMENT,
  url,
  data,
  updateCoords,
});

export const setIflowTriggerLoading = (iflowTriggerLoading) => ({
  type: SET_IFLOW_TRIGGER_LOADING,
  iflowTriggerLoading,
});

export const SET_IFLOW_METADATA_PREVIEW =
  "processes@SET_IFLOW_METADATA_PREVIEW";
export const GET_IFLOW_METADATA_PREVIEW =
  "processes@GET_IFLOW_METADATA_PREVIEW";
export const getIflowMetadataPreview = (payload) => ({
  type: GET_IFLOW_METADATA_PREVIEW,
  data: payload,
});
export const setIflowMetadataPreview = (data) => ({
  type: SET_IFLOW_METADATA_PREVIEW,
  data,
});

export const SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE =
  "processes@SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE";

export const setIsSubstitutingIflowDocumentDone = (data) => ({
  type: SET_IS_SUBSTITUTE_IFLOW_DOCUMENT_DONE,
  data,
});

export const getButtonSaveTrigger = () => {
  return { type: GET_BUTTONSAVE_TRIGGER };
};

export const setButtonSaveTrigger = () => {
  return { type: SET_BUTTONSAVE_TRIGGER };
};

export const getTemplatesList = () => {
  return { type: GET_TEMPLATES_LIST };
};

export const setTemplatesList = (list) => {
  return { type: SET_TEMPLATES_LIST, list };
};

export const getCatalogoFormacao = () => {
  return { type: GET_CATALOGO_FORMACAO };
};
export const deleteCatalogoFormacao = (id) => {
  return { type: DELETE_CATALOGO_FORMACAO, id };
};

export const getCatalogoFormacaoDropdowns = () => {
  return { type: GET_CATALOGO_FORMACAO_DROPDOWNS };
};
export const setCatalogoFormacaoDropdowns = (data) => {
  return { type: SET_CATALOGO_FORMACAO_DROPDOWNS, data };
};

export const setCatalogoFormacao = (catalogo) => {
  return { type: SET_CATALOGO_FORMACAO, catalogo };
};

export const getPlanoFormacao = (payload) => {
  return { type: GET_PLANO_FORMACAO, payload };
};

export const getEntitiesFormacao = () => {
  return { type: GET_ENTITIES_FORMACAO };
};

export const setEntitiesFormacao = (data) => {
  return { type: SET_ENTITIES_FORMACAO, data };
};

export const setDeleteCatalogoFormacaoMessage = (message) => {
  return { type: SET_DELETE_CATALOGO_FORMACAO_MESSAGE, message };
};

export const uploadPlanoFormacao = (payload) => {
  return { type: UPLOAD_PLANO_FORMACAO, payload };
};

export const setUploadPlanoFormacao = (data) => {
  return { type: SET_UPLOAD_PLANO_FORMACAO, data };
};

export const setDownloadPlanoFormacao = (data) => {
  return { type: SET_DOWNLOAD_PLANO_FORMACAO, data };
};

export const updateTemplateProfile = (payload) => {
  return { type: UPDATE_TEMPLATE_PROFILE, payload };
};
export const updateCreateCatalogoFormacao = (payload) => {
  return { type: UPDATE_CREATE_CATALOGO_FORMACAO, payload };
};
export const updateCreateCatalogoFormacaoDone = () => {
  return { type: UPDATE_CREATE_CATALOGO_FORMACAO_DONE };
};

export const updateTemplateProfileDone = () => {
  return { type: UPDATE_TEMPLATE_PROFILE_DONE };
};

export const getTemplateDetail = (id) => {
  return { type: GET_TEMPLATE_DETAIL, id };
};
export const setTemplateDetail = (data) => {
  return { type: SET_TEMPLATE_DETAIL, data };
};

export const setTemplateDocumentsAll = (data) => {
  return { type: SET_TEMPLATE_DOCUMENTS_ALL, data };
};

export const deleteTemplate = (id) => {
  return { type: DELETE_TEMPLATE, id };
};

export const getConsumosList = (id) => {
  return { type: GET_CONSUMOS_LIST, id };
}

export const setConsumosList = (data) => {
  return { type: SET_CONSUMOS_LIST, data };
}
