import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Badge,
  Button,
  Form,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";

import "~/assets/css/icons.css";
import "~pages/Anacom/assets/scss/_anacomTable.scss";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import ButtonUpload from "~/components/Buttons/UploadButton";
import SpinnerNew from "~/components/SpinnerNew";
import {
  deleteDocument,
  getMetadataDocument,
  submitToiFlow,
} from "~/pages/DataCapture/actions";
import {
  exportClaims,
  getAllResultsCategories,
  getDataResults,
  getSubDataResults,
  getDataTops,
} from "~/store/ducks/claimsManagement/actionTypes";
import "~/pages/DataCapture/scss/datacapture.scss";
import { toLocaleString } from "~/utils";
import "~/pages/ADataCapture/components/AutomatedDatacapture/AutomatedDataCapture.css";
import Table from "~/pages/Easypay/components/Table";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import SubTabelaResultados from "./SubTabelaResultados";
import Dropdown from "~/components/Dropdown";
import { set } from "lodash";
import { BsFillEyeFill, BsPlusCircleFill } from "react-icons/bs";
import ModalTopDynamic from "./ModalTopDynamic";
const devLogConsole = require("~/utils/devLog");
const Resultados = ({
  t,
  setShowUploadModal,
  category,
  resetDropdown,
  changeReset,
}) => {
  const [test, setTest] = useState([]);
  const [dataRow, setDataRow] = useState({});
  const [resultCategoriesList, setResultCategoriesList] = useState([]);
  const [categoriSelected, setCategoriSelected] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [showModalClicked, setShowModalClicked] = useState(false);
  const [headersSubTable, setHeadersSubTable] = useState([]);
  const [newData, setNewData] = useState({});
  const [newColumn, setNewColumn] = useState({});
  const [goBack, setGoBack] = useState(false);
  const [contador, setContador] = useState(0);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [checkActive, setCheckActive] = useState(false);
  const [dataPoc, setDataPoc] = useState([]);
  const [useVisionButton, setUseVisionButton] = useState(true);

  const { SearchBar } = Search;
  const dispatch = useDispatch();

  const { resultsCategories, data, isLoadingDataResults, reset } = useSelector(
    (state) => state.claimsManagementReducer
  );

  useEffect(() => {
    if (resetDropdown) {
      setCategoriSelected("");
    }
  }, [resetDropdown]);

  const downloadXls = () => {
    //1 = EXCEL
    dispatch(exportClaims(1, category, null));
  };

  useEffect(() => {
    dispatch(getAllResultsCategories());
  }, []);

  useEffect(() => {
    if (resultsCategories.length > 0 && resultsCategories) {
      const newArr = resultsCategories.map((element) => {
        return {
          value: element.id,
          label: element.label,
        };
      });

      setResultCategoriesList(newArr);
    }
  }, [resultsCategories]);

  const handleCategories = (e) => {
    dispatch(getDataResults(e));
    setCategoriSelected(e);
    if (e === 7) {
      setUseVisionButton(false);
    } else {
      setUseVisionButton(true);
    }
    changeReset(false);
    setDataRow();
    setRowClicked(false);
    setShowModalClicked(false);
    setHeadersSubTable([]);
    setNewData({});
    setContador(0);
    setCheckActive(false);
  };

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      setDataRow(row);
    },
  };

  function handleSeeMoreClick(event, index) {
    devLogConsole(event?.claimId, "event?.claimId");
    devLogConsole(data?.categoryId, "data?.categoryId");
    devLogConsole(index, "index");
    setTimeout(() => {
      let obj = {
        claimId: event?.claimId,
        categoryId: data?.categoryId,
        boolean: true,
        index: index,
      };
      dispatch(getDataTops(obj));
      setDataRow(event);
      setShowModalClicked(true);
    }, 100);
  }

  function handleVisionClick(event) {
    setTimeout(() => {
      dispatch(getSubDataResults(event.claimId, data.categoryId));
      setRowClicked(true);
    }, 100);
  }

  const takeClickedBoolean = (boolean) => {
    // setRowClicked(boolean);
  };

  // useEffect(() => {
  //   if (dataRow) {
  //     dispatch(getSubDataResults(dataRow.claimId, data.categoryId));
  //   }
  // }, [dataRow, data]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const removeFirstObj = data?.subTable?.headers.filter(
        (e) => e.dataField !== "getAssunto"
      );

      setHeadersSubTable(removeFirstObj);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      if (data?.rows.length > 0) {
        for (let i = 0; i < data?.rows.length; i++) {
          // data.rows[i].getTop2.toFixed(1);
          /* data.rows[i].getTop1 = data.rows[i].getTop1.slice(0, -1);
          data.rows[i].getTop2 = data.rows[i].getTop2.slice(0, -1);
          data.rows[i].getTop3 = data.rows[i].getTop3.slice(0, -1);
          data.rows[i].getRelevante = data.rows[i].getRelevante.slice(0, -1);

          data.rows[i].getTop1 = Number(data.rows[i].getTop1).toFixed(1) + "%";
          data.rows[i].getTop2 = Number(data.rows[i].getTop2).toFixed(1) + "%";
          data.rows[i].getTop3 = Number(data.rows[i].getTop3).toFixed(1) + "%";
          data.rows[i].getRelevante =
            Number(data.rows[i].getRelevante).toFixed(1) + "%"; */

          if (useVisionButton) {
            data.rows[i].getVisionButton = (
              <BsFillEyeFill
                onClick={() => handleVisionClick(data.rows[i])}
                size={25}
                style={{ cursor: "pointer" }}
              />
            );
          }
          /*     data.rows[i].getModalButton = (
            <BsPlusCircleFill
              onClick={() => handleSeeMoreClick(data.rows[i], i)}
              size={25}
            />
          ); */
        }

        let auxData = data;
        devLogConsole(auxData, "auxData");

        // if (contador === 1) {
        let newHeaders = auxData.headers;
        newHeaders = newHeaders.filter((element, index) => {
          return index < 7 || index > 24;
        });

        auxData.headers = newHeaders;

        let fieldsToDelete = [
          "getTop4",
          "getTop5",
          "getTop6",
          "getTop7",
          "getTop8",
          "getTop9",
          "getTop10",
          "getTop11",
          "getTop12",
          "getTop13",
          "getTop14",
          "getTop15",
          "getTop16",
          "getTop17",
          "getTop18",
          "getTop19",
          "getTop20",
        ];

        for (var r = 0; r < auxData.rows.length; r++) {
          for (var d = 0; d < fieldsToDelete.length; d++) {
            delete auxData.rows[r][fieldsToDelete[d]];
          }
        }
        // }

        setNewData(auxData);

        // setContador(contador + 1);
      } else {
        let auxData = data;

        let newHeaders = auxData.headers;
        newHeaders = newHeaders.filter((element, index) => {
          return index < 7 || index > 24;
        });

        auxData.headers = newHeaders;

        let fieldsToDelete = [
          "getTop4",
          "getTop5",
          "getTop6",
          "getTop7",
          "getTop8",
          "getTop9",
          "getTop10",
          "getTop11",
          "getTop12",
          "getTop13",
          "getTop14",
          "getTop15",
          "getTop16",
          "getTop17",
          "getTop18",
          "getTop19",
          "getTop20",
        ];

        for (var r = 0; r < auxData?.rows?.length; r++) {
          for (var d = 0; d < fieldsToDelete?.length; d++) {
            delete auxData.rows[r][fieldsToDelete[d]];
          }
        }

        setNewData(auxData);
      }
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const visionButton = {
        dataField: "getVisionButton",
        text: "",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      };

      const modalButton = {
        dataField: "getModalButton",
        text: "",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      };

      if (useVisionButton) {
        let insertHeader = data.headers.unshift(visionButton);
      }

      //const newInsert = data.headers.push(modalButton);
    }
  }, [data]);

  const handleGoBackAction = () => {
    setRowClicked(false);
    setDataRow(null);
  };

  useEffect(() => {
    if (checkActive) {
      const dataPoc = newData?.rows?.filter(
        (e) =>
          e?.getNome?.toLowerCase()===
            "atraso na entrega de objetos postais" ||
            e?.getNome && e?.getNome?.toLowerCase()=== "contratação de serviços" ||
            e?.getNome && e?.getNome?.toLowerCase()=== "avaria e venda de equipamentos"
      );

      const dataFiltered = newData?.rows?.filter(
        (e) =>
          e?.getNome?.toLowerCase()!==
            "atraso na entrega de objetos postais" &&
          e?.getNome?.toLowerCase()!== "contratação de serviços" &&
          e?.getNome?.toLowerCase()!== "avaria e venda de equipamentos"
      );

      const newDataPoc = dataPoc?.concat(dataFiltered);

      setDataPoc(newDataPoc);
    }
  }, [checkActive, newData]);

  const rowStyle2 = (row, rowIndex) => {
    const style = {};

    if (
      row?.getNome?.toLowerCase()=== "atraso na entrega de objetos postais" ||
      row?.getNome?.toLowerCase()=== "contratação de serviços" ||
      row?.getNome?.toLowerCase()=== "avaria e venda de equipamentos"
    ) {
      style.backgroundColor = "#00CC66";
    }

    return style;
  };

  if (data?.headers?.length > 0) {
    for (let i = 0; i < data?.headers?.length; i++) {
      if (data?.headers[i].text !== "Assuntos")
        data.headers[i].style = { textAlign: "center" };
    }
  }

  return (
    <>
      {rowClicked ? (
        <div className="main_card">
          <SubTabelaResultados
            dataRowFromParent={dataRow}
            takeClickedBoolean={takeClickedBoolean}
            assunto={dataRow?.getNome}
            handleGoBackAction={handleGoBackAction}
            setRowClicked={setRowClicked}
          />
        </div>
      ) : (
        <div className="main_card" style={{ minHeight: "400px" }}>
          {Object.keys(data).length <= 0 || categoriSelected === "" ? (
            <div className="Resultados_dropdownCategories">
              <Col xl="4" md="6">
                <Form.Label style={{ paddingTop: "30px", paddingLeft: "15px" }}>
                  Tipo de tabela
                </Form.Label>
                <Dropdown
                  overridePlaceholder={true}
                  list={resultCategoriesList}
                  handleSubmit={handleCategories}
                  defaultValue={categoriSelected}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
              </Col>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ width: "300px" }}>
                {/* <Col xl="4" md="4" xs={"5"} style={{ paddingLeft: "0px" }}> */}
                <Form.Label style={{ paddingTop: "30px", paddingLeft: "10px" }}>
                  Tipo de tabela
                </Form.Label>
                <Dropdown
                  list={resultCategoriesList}
                  handleSubmit={handleCategories}
                  defaultValue={categoriSelected}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
                {/* </Col> */}
              </div>
              {useVisionButton ? (
                <div style={{ paddingTop: "65px", paddingLeft: "10px" }}>
                  {" "}
                  <Form.Check
                    className="rhcollaborator__labels__"
                    inline
                    type="checkbox"
                    label="Assuntos para efeitos de POC primeiro"
                    checked={checkActive}
                    style={{
                      paddingBottom: 10,
                      paddingLeft: 50,
                      color: "#212529",
                    }}
                    onChange={() =>
                      setCheckActive((checkActive) => !checkActive)
                    }
                    // disabled={isClicked ? false : true}
                  />
                </div>
              ) : null}
            </div>
          )}

          <SpinnerNew open={isLoadingDataResults} />
          {categoriSelected !== "" ? (
            Object.keys(data).length > 0 ? (
              <div className="custom-table">
                <ToolkitProvider
                  keyField="id"
                  data={
                    checkActive ? dataPoc : newData?.rows ? newData?.rows : []
                  }
                  columns={data?.headers ? data?.headers : []}
                  search={{ searchFormatted: true }}
                >
                  {(props) => (
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {}
                        {useVisionButton ? (
                          <div>
                            <SearchBar
                              {...props.searchProps}
                              placeholder={t("general.search")}
                            />
                          </div>
                        ) : null}
                        <div style={{ paddingLeft: "20px" }}>
                          {/* <SimpleButton
                          variant={"liftworld-button-primary"}
                          disabled={!newData?.rows}
                          onClick={() => {
                            downloadXls();
                          }}
                          text={"Exportar"}
                        /> */}
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="id"
                        responsive
                        bootstrap4
                        rowEvents={rowEventEditMod}
                        rowStyle={rowStyle2}
                        pagination={paginationFactory({
                          sizePerPage: 5,
                          sizePerPageList: [],
                        })}
                        noDataIndication={t("datacapture.general.dataNotFound")}
                        //rowEvents={rowEvents}
                        pageSize={5}
                        hover
                        striped
                      />
                    </div>
                  )}
                </ToolkitProvider>
                {showModalClicked ? (
                  <ModalTopDynamic
                    setShowModalClicked={setShowModalClicked}
                    waitForResults={true}
                    dataRow={dataRow}
                  />
                ) : null}
              </div>
            ) : null
          ) : null}
          {/* <SimpleButton
       variant={"liftworld-button-primary"}
        onClick={() => {
          downloadXls();
        }}
        text={"Exportar"}
      /> */}

          {/* <TabelaResultados /> */}
        </div>
      )}
    </>
  );
};
export default withNamespaces()(Resultados);
