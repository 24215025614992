import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Card, Col, Button, Form } from "react-bootstrap";
import logo from "~/assets/img/logos/unik-logo.png";

import {
  persistOrgAdmin,
  persistOrgAdminDone,
} from "~/store/ducks/registerOrgAdmin/actionTypes";

import SpinnerNew from "~/components/SpinnerNew";
import Toast from "~/components/Toast";

import "~/pages/Register/styles.css";

const RegisterOrgAdmin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = props;
  const { isLoadingPersistOrgAdmin, successRegisteringUser } = useSelector(
    (state) => state.registerOrgAdminReducer
  );

  useEffect(() => {
    successRegisteringUser && goToConfirmEmail();
    return () => {
      dispatch(persistOrgAdminDone(false));
    };
  }, [successRegisteringUser]);

  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);

  /**
   * Runs when user clicks next to submit form
   */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (password === confirmPassword) {
        persisOrgAdmin();
      } else setError(t("register.passwordsNotMatch"));
    }
  };

  /**
   * Used to create a payload in the desired format for backend.
   */
  function persisOrgAdmin() {
    const payload = {
      password,
      first_name: firstName,
      email,
      last_name: lastName,
      org_description: description,
      org_label: label,
    };
    dispatch(persistOrgAdmin(payload));
  }

  /**
   * Used to create a description and a label from a name
   * name: Test Name
   * description = name
   * label = test-name
   */
  const nameToDescriptionAndLabel = (name) => {
    setDescription(name);
    const label = name.replace(/\s+/g, "-") && name.replace(/\s+/g, "-")?.toLowerCase();
    setLabel(label);
  };

  /**
   * Used to redirect user when clicking the back button
   */
  const backToSearch = () => {
    const payload = {
      pathname: `/login`,
      state: { name: "Login" },
    };
    history.replace(payload);
  };

  /**
   * Used to redirect the user when a status 200 message is received while creating an org user.
   */
  const goToConfirmEmail = () => {
    const payload = {
      pathname: `/confirm`,
      state: { name: "Confirm" },
    };
    history.push(payload);
  };

  return (
    <div className={"register-form-wrapper"}>
      <div className="overlay">
        <div className="row">
          <div className="col-12 mt-3 mb-2 text-center">
            <img className="img-fluid unik-logo" src={logo} alt="Uniksystem" />
          </div>
        </div>
        <div className="col-xl-4 my-2 register-form">
          <div
            className="main-card-v2"
            style={{ padding: "0", backgroundColor: "white" }}
          >
            <Card style={{ border: "0px", borderRadius: "0", width: "100%" }}>
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                method="post"
                encType="multipart/form-data"
              >
                <Card.Header className="register-header">
                  <span className="label-register">
                    {t("register.newUser")}
                  </span>
                </Card.Header>
                <Card.Body className="register-body">
                  <Form.Row>
                    <Col sm="12" md="12" lg="12">
                      <Form.Group controlId="validationRegisterName">
                        <Form.Label className="register-form-label">
                          {t("register.firstName")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          autoComplete="off"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register.requiredFirstName")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm="12" md="12" lg="12">
                      <Form.Group controlId="validationRegisterLastName">
                        <Form.Label className="register-form-label">
                          {t("register.lastName")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          autoComplete="off"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register.requiredLastName")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm="12" md="12" lg="12">
                      <Form.Group controlId="validationRegisterCustomEmail">
                        <Form.Label className="register-form-label">
                          {t("register.email")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          autoComplete="off"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register.requiredEmail")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm="12" md="6" lg="12">
                      <Form.Group controlId="validationRegisterPassword">
                        <Form.Label className="register-form-label">
                          {t("register.password")}
                        </Form.Label>
                        <Form.Control
                          required
                          type="password"
                          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setError("");
                          }}
                          value={password}
                          autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register.validPassword")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm="12" md="6" lg="12">
                      <Form.Group controlId="validationRegisterConfirmPassword">
                        <Form.Label className="register-form-label">
                          {t("register.confirmPassword")}{" "}
                        </Form.Label>
                        <Form.Control
                          required
                          type="password"
                          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setError("");
                          }}
                          value={confirmPassword}
                        />
                        {error && (
                          <Form.Text style={{ color: "#dc3545" }}>
                            {error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm="12" md="12" lg="12">
                      <Form.Group controlId="validationRegisterOrganization">
                        <Form.Label className="register-form-label">
                          {t("register.organization")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          value={description}
                          onChange={(e) =>
                            nameToDescriptionAndLabel(e.target.value)
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("register.requiredOrganization")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Card.Body>
                <Card.Footer className="register-form-footer">
                  <Button
                    style={{
                      borderRadius: "20px",
                      height: "44px",
                    }}
                    variant="danger"
                    onClick={() => backToSearch()}
                  >
                    {t("general.back")}
                  </Button>
                  <Button
                    className="card-button"
                    type="submit"
                    style={{
                      marginLeft: "10px",
                      marginRight: "20px",
                      backgroundColor: "#4484f4",
                      borderColor: "#4484f4",
                      borderRadius: "20px",
                      height: "44px",
                    }}
                  >
                    {t("general.next")}
                  </Button>
                </Card.Footer>
              </Form>
            </Card>
            <SpinnerNew open={isLoadingPersistOrgAdmin} />
            <Toast />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(RegisterOrgAdmin);
