import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerNew from "~/components/SpinnerNew";
import { clearSettingsMenuApplication } from "~/store/ducks/applicationMenu/actionTypes";
import MenuFormBase from "./MenuFormBase";




const ApplicationMenu = ({ tabKey }) => {

  const dispatch = useDispatch();

  const { isLoadingMenuOnlyApplications } = useSelector(state => state.applicationReducer);

  const {
    isLoadingSettingsMenu,
    isLoadingSave,
    isLoadingEdit,
    isLoadingDelete
  } = useSelector(state => state.applicationMenuReducer);

  useEffect(() => {
    return () => {
      dispatch(clearSettingsMenuApplication());
    }
  }, []);

  return (
    <>
      <MenuFormBase tabKey={tabKey} />
      <SpinnerNew open={
          isLoadingMenuOnlyApplications ||
          isLoadingSettingsMenu ||
          isLoadingSave ||
          isLoadingEdit ||
          isLoadingDelete
        }
      />
    </>
  )
}

export default withNamespaces()(ApplicationMenu);
