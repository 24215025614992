import {
  CLEAR_RHADMIN_CONFIGURATION,
  SET_RHADMIN_CONFIGURATION,
  PERSIST_RHADMIN_CONFIGURATION,
  SUCCESS_PERSIST_RHADMIN_CONFIGURATION,
  SET_RH_BOLSA_HORAS_CONFIGURATION,
  SET_RH_BOLSA_HORAS_USERS_GROUP,
  SET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP,
  CLEAR_RH_BOLSA_HORAS_CONFIGURATION,
  CLEAR_RH_BOLSA_HORAS_USERS_GROUP,
  CLEAR_RH_BOLSA_HORAS_EXTRA_USERS_GROUP,
  PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  CLEAR_RH_SALDO_BOLSA_HORAS,
  CLEAR_RH_SALDO_HORAS_Extra,
  SET_RH_SALDO_BOLSA_HORAS,
  SET_RH_SALDO_HORAS_Extra,
  GET_RH_SALDO_BOLSA_HORAS,
  GET_RH_SALDO_HORAS_Extra,
  CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
  CLEAR_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA,
  SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
  SET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA,
  SET_OPENAI_CONFIGURATION,
  PERSIST_OPENAI_CONFIGURATION,
  SUCCESS_PERSIST_OPENAI_CONFIGURATION,
  CLEAR_OPENAI_CONFIGURATION,
  DELETE_OPENAI_CONFIGURATION,
  SUCCESS_DELETE_OPENAI_DOCUMENT,
  PERSIST_RHCURRENCY_CONFIGURATION,
  CLEAR_RHCURRENCY_CONFIGURATION,
  SET_RHCURRENCY_CONFIGURATION,
  SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION,
  SET_DISP_RECIBOS,
  GET_DISP_RECIBOS,
  GET_ALLOW_DIS_RECIBOS,
  SET_ALLOW_DIS_RECIBOS,
} from "./actionTypes";

const initialState = {
  rhadminConfiguration: {},
  successPersistRHAdminConfiguration: false,
  rhbolsaHorasConfiguration: [],
  rhcurrencyConfiguration: [],
  rhbolsaHorasUsersGroup: [],
  rhHorasExtraUsersGroup: [],
  successPersistRHBolsaHorasConfiguration: false,
  successPersistRHCurrencyConfiguration: false,
  saldoBolsa: [],
  saldoHorasExtraBolsa: [],
  saldoBolsaInicialFinal: "",
  saldoHorasExtraBolsaInicialFinal: "",
  isLoadingSaldo: false,
  openAIConfiguration: {},
  successPersistOpenAIConfiguration: false,
  successDeleteOpenaiDocument: false,
  isLoadingDispRecibos: false,
  alertDispRecibos: "",
  allowDispRecibos: false,
};

export const rhadminConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RHADMIN_CONFIGURATION:
      return {
        ...state,
        rhadminConfiguration: action.configuration,
      };
    case SET_OPENAI_CONFIGURATION:
      return {
        ...state,
        openAIConfiguration: action.configuration,
      };
    case PERSIST_OPENAI_CONFIGURATION:
      return {
        ...state,
        successPersistOpenAIConfiguration: false,
      };
    case DELETE_OPENAI_CONFIGURATION:
      return {
        ...state,
      };
    case SUCCESS_PERSIST_OPENAI_CONFIGURATION:
      return {
        ...state,
        successPersistOpenAIConfiguration: true,
      };
    case SUCCESS_DELETE_OPENAI_DOCUMENT:
      return {
        ...state,
        successDeleteOpenaiDocument: true,
      };
    case PERSIST_RHADMIN_CONFIGURATION:
      return {
        ...state,
        successPersistRHAdminConfiguration: false,
      };
    case CLEAR_RHADMIN_CONFIGURATION:
      return {
        ...state,
        rhadminConfiguration: initialState.rhadminConfiguration,
      };
    case CLEAR_OPENAI_CONFIGURATION:
      return {
        ...state,
        openAIConfiguration: initialState.openAIConfiguration,
      };
    case SUCCESS_PERSIST_RHADMIN_CONFIGURATION:
      return {
        ...state,
        successPersistRHAdminConfiguration: true,
      };
    //CURENCY
    case CLEAR_RHCURRENCY_CONFIGURATION:
      return {
        ...state,
        rhcurrencyConfiguration: initialState.rhcurrencyConfiguration,
      };
    case SET_RHCURRENCY_CONFIGURATION:
      return {
        ...state,
        rhcurrencyConfiguration: action.configuration,
      };
    case PERSIST_RHCURRENCY_CONFIGURATION:
      return {
        ...state,
        successPersistRHCurrencyConfiguration: false,
      };
    case SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION:
      return {
        ...state,
        successPersistRHCurrencyConfiguration: true,
      };
    case GET_DISP_RECIBOS:
      return {
        ...state,
        isLoadingDispRecibos: true,
      };
    case SET_DISP_RECIBOS:
      return {
        ...state,
        isLoadingDispRecibos: false,
        alertDispRecibos: action.dispRecibos,
      };
      case GET_ALLOW_DIS_RECIBOS:
      return {
        ...state,
      };
    case SET_ALLOW_DIS_RECIBOS:
      return {
        ...state,
        allowDispRecibos: action.allowDisRecibos,
      };

    //BOLSA HORAS

    case SET_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        rhbolsaHorasConfiguration: action.configuration,
      };
    case SET_RH_BOLSA_HORAS_USERS_GROUP:
      return {
        ...state,
        rhbolsaHorasUsersGroup: action.usersGroup,
      };
      case SET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP:
      return {
        ...state,
        rhHorasExtraUsersGroup: action.usersGroup,
      };
    case CLEAR_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        rhbolsaHorasConfiguration: initialState.rhbolsaHorasConfiguration,
      };
    case CLEAR_RH_BOLSA_HORAS_USERS_GROUP:
      return {
        ...state,
        rhbolsaHorasUsersGroup: initialState.rhbolsaHorasUsersGroup,
      };
    case CLEAR_RH_BOLSA_HORAS_EXTRA_USERS_GROUP:
      return {
        ...state,
        rhHorasExtraUsersGroup: initialState.rhHorasExtraUsersGroup,
      };
    case PERSIST_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        successPersistRHBolsaHorasConfiguration: false,
      };
    case SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        successPersistRHBolsaHorasConfiguration: true,
      };
    case CLEAR_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsa: initialState.saldoBolsa,
      };
    case CLEAR_RH_SALDO_HORAS_Extra:
      return {
        ...state,
        saldoHorasExtraBolsa: initialState.saldoHorasExtraBolsa,
      };
    case SET_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsa: action.listSaldo,
        isLoadingSaldo: false,
      };
      case SET_RH_SALDO_HORAS_Extra:
      return {
        ...state,
        saldoHorasExtraBolsa: action.listSaldo,
        isLoadingSaldo: false,
      };
    case GET_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        isLoadingSaldo: true,
      };
      case GET_RH_SALDO_HORAS_Extra:
      return {
        ...state,
        isLoadingSaldo: true,
      };
    case CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsaInicialFinal: initialState.saldoBolsaInicialFinal,
      };
      case CLEAR_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA:
      return {
        ...state,
        saldoHorasExtraBolsaInicialFinal: initialState.saldoHorasExtraBolsaInicialFinal,
      };
    case SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsaInicialFinal: action.listSaldoInicialFinal,
        isLoadingSaldo: false,
      };
      case SET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA:
      return {
        ...state,
        saldoHorasExtraBolsaInicialFinal: action.listSaldoInicialFinal,
        isLoadingSaldo: false,
      };
    default:
      return state;
  }
};
