import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import qs from "qs";
import SpinnerNew from "~/components/SpinnerNew";
import Error404 from "~/components/Exception/Error404";

import { validateAdminReset } from "~/store/ducks/resetPassword/actionTypes";

const ValidationAdminLoading = ({ t, location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoadingValidateAdminReset, successValidateAdminReset } =
    useSelector((state) => state.resetPasswordReducer);
    
  const [validatedParams, setValidatedParams] = useState(true);
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!queryParams.hasOwnProperty("code")) {
      setValidatedParams(false);
      backToLogin();
    } else {
      dispatch(validateAdminReset(queryParams.code, true));
    }
  }, []);

  useEffect(() => {
    if (successValidateAdminReset) {
      backToLogin();
    }
  }, [successValidateAdminReset]);

  const backToLogin = () => {
    const payload = {
      pathname: `/`,
      state: { name: "Login" },
    };
    history.replace(payload);
  };

  return validatedParams ? (
    <SpinnerNew open={validatedParams || isLoadingValidateAdminReset}
    />
  ) : (
    <Error404 />
  );
};
export default withNamespaces()(ValidationAdminLoading);
