import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Tabs, Tab, Badge } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import "../portal.scss";
import "~/assets/css/scrollbar.css";
import { AuthorizationState, AuthorizerApproves } from "../TabsEnum";
import RequestItem from "../RequestWebTimeItem";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { withNamespaces } from "react-i18next";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { getAutorizacoesByUsername } from "~/store/ducks/webTimeWidget/actionTypes";
import { updateUserWidget } from "~/pages/User/actions";

const AuthorizationsWebTimeWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;

  const { allMenus } = useSelector((state) => state.portalRhMenuReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const { autorizacoesByUsername, isLoadingAutorizacoesByUsername, logins } =
    useSelector((state) => state.webTimeDataReducer);

  const usernamePortalRh = user?.legacyUsers?.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;

  const [authorizationKey, setAuthorizationKey] = useState(
    AuthorizationState.PENDING
  );
  const [pendingHolidayCount, setPendingHolidayCount] = useState(0);
  const [nPendingHolidayCount, setNPendingHolidayCount] = useState(0);
  const [pendingHolidaysAuth, setPendingHolidayAuth] = useState([]);
  const [nPendingHolidayAuth, setNPendingHolidayAuth] = useState([]);

  const [pendingAbsenceCount, setPendingAbsenceCount] = useState(0);
  const [nPendingAbsenceCount, setNPendingAbsenceCount] = useState(0);
  const [pendingAbsenceAuth, setPendingAbsenceAutht] = useState([]);
  const [nPendingAbsenceAuth, setNPendingAbsenceAuth] = useState([]);

  const [pendingLabels, setPendingLabels] = useState([]);
  const [nPendingLabels, setNPendingLabels] = useState([]);

  const [dataHolidays, setDataHolidays] = useState([]);
  const [dataAbsences, setDataAbsences] = useState([]);

  let pendingAuthValues = useState([]);
  let approvedAuthValues = useState([]);

  let pendingAuth = useState();
  let approvedAuth = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    dispatch(getAutorizacoesByUsername(usernamePortalRh));
  }, []);

  useEffect(() => {
    if (
      autorizacoesByUsername &&
      Object.keys(autorizacoesByUsername).length > 0
    ) {
      if (autorizacoesByUsername?.ausencias?.length > 0) {
        setDataAbsences(autorizacoesByUsername.ausencias);
      }
    }

    if (autorizacoesByUsername?.ferias?.length > 0) {
      setDataHolidays(autorizacoesByUsername.ferias);
    }
  }, [autorizacoesByUsername]);

  useEffect(() => {
    if (dataHolidays.length > 0) {
      const nPedending = dataHolidays.filter((e) => e.pendente == 0);
      const pending = dataHolidays.filter((e) => e.pendente == 1);
      setNPendingHolidayAuth(nPedending);
      setPendingHolidayAuth(pending);
      setPendingHolidayCount(pending.length);
      setNPendingHolidayCount(nPedending.length);
    }
  }, [dataHolidays]);

  useEffect(() => {
    if (dataAbsences.length > 0) {
      const nPedending = dataAbsences.filter((e) => e.pendente == 0);
      const pending = dataAbsences.filter((e) => e.pendente == 1);
      setNPendingAbsenceAuth(nPedending);
      setPendingAbsenceAutht(pending);
      setPendingAbsenceCount(pending.length);
      setNPendingAbsenceCount(nPedending.length);
    }
  }, [dataAbsences]);

  useEffect(() => {
    let labels = [{}];
    if (pendingHolidaysAuth.length > 0 || pendingAbsenceAuth.length > 0) {
      if (pendingHolidaysAuth.length > 0) {
        labels[0][t("portalrh.requests.descriptions.vacationsManagement")] =
          pendingHolidaysAuth.length;
      }
      if (pendingAbsenceAuth.length > 0) {
        labels[0][t("portalrh.requests.descriptions.absenceManaegement")] =
          pendingAbsenceAuth.length;
      }
      setPendingLabels(labels);
    }
  }, [pendingAbsenceAuth.length, pendingHolidaysAuth.length, t]);

  useEffect(() => {
    let labels = [{}];
    if (nPendingHolidayAuth.length > 0 || nPendingAbsenceAuth.length > 0) {
      if (nPendingHolidayAuth.length > 0) {
        labels[0][t("portalrh.requests.descriptions.vacationsManagement")] =
          nPendingHolidayAuth.length;
      }
      if (nPendingAbsenceAuth.length > 0) {
        labels[0][t("portalrh.requests.descriptions.absenceManaegement")] =
          nPendingAbsenceAuth.length;
      }

      setNPendingLabels(labels);
    }
  }, [nPendingAbsenceAuth.length, nPendingHolidayAuth.length, t]);

  const getRequestItems = (requestValues, textColor, type, state, userType) => {
    return (
      <div>
        {Object.keys(requestValues).map((description, index) => (
          <RequestItem
            key={description}
            description={description}
            num={Object.values(requestValues)[index]}
            textColor={textColor}
            allMenus={allMenus}
            type={type}
            t={t}
            state={state}
            userType={userType}
          />
        ))}
      </div>
    );
  };

  if (pendingLabels.length > 0 || nPendingLabels.length > 0) {
    if (pendingLabels.length > 0) {
      pendingAuthValues = pendingLabels;
    }
    if (nPendingLabels.length > 0) {
      approvedAuthValues = nPendingLabels;
    }

    pendingAuth = getRequestItems(
      pendingAuthValues[0],
      "text-info",
      "aut_ac_",
      AuthorizerApproves.YES,
      "admin"
    );
    approvedAuth = getRequestItems(
      approvedAuthValues[0],
      "text-secondary",
      "aut_ac_",
      AuthorizerApproves.NO,
      "admin"
    );
  }

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ padding: "2rem" }}>
        <Card.Header className="justify-content-between">
        <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("AuthorizationsWebTimeWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>

        {!isResizing && (
          <Card.Body>
            {isLoadingAutorizacoesByUsername === "true" ? (
              <SpinnerNew open={isLoadingAutorizacoesByUsername === "true"} />
            ) : (
              <Tabs
                className="custom-tabs"
                id="authorizationsTab"
                defaultActiveKey="first"
                activeKey={authorizationKey}
                onSelect={(k) => setAuthorizationKey(k)}
              >
                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.authorizationsToApprove") + " "}
                      <Badge pill className="text-white  bg-info">
                        {pendingHolidayCount + pendingAbsenceCount > 0
                          ? pendingHolidayCount + pendingAbsenceCount
                          : ""}
                      </Badge>
                    </React.Fragment>
                  }
                  eventKey={AuthorizationState.PENDING}
                >
                  {pendingAuth}
                </Tab>
                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.finishedRequest") + " "}
                      <Badge pill className="text-white  bg-primary">
                        {nPendingAbsenceCount + nPendingHolidayCount > 0
                          ? nPendingAbsenceCount + nPendingHolidayCount
                          : ""}
                      </Badge>
                    </React.Fragment>
                  }
                  eventKey={AuthorizationState.SEEN}
                >
                  {approvedAuth}
                </Tab>
              </Tabs>
            )}
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(AuthorizationsWebTimeWidget);
