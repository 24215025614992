import React from "react";

const paginationTotalRenderer = (from, to, size, currentPage, totalPages) => (
  totalPages > 0 && (
    <div className="btn-group" role="group">
      <span className="react-bootstrap-table-pagination-total">
        {`${currentPage} página de ${totalPages} páginas`}
      </span>
    </div>
  )
);

export const mobilePagination = {
  paginationSize: 4,
  pageStartIndex: 1,
  alwaysShowAllBtns: false,
  withFirstAndLast: false,
  hideSizePerPage: true,
  showTotal: true,
  sizePerPageList: [],
  sizePerPage: 10,
  paginationTotalRenderer: (from, to, size) => {
    const currentPage = Math.ceil(from / 10);
    const totalPages = Math.ceil(size / 10);
    return paginationTotalRenderer(from, to, size, currentPage, totalPages);
  },
};