import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { ToastContainer } from "react-toastify";
import { Button, Form, Col } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import logo from "~/assets/img/logos/unik-logo.png";
import successEmail from "~/assets/img/resetPassword/success_email.png";

import { resetPassword } from "~/store/ducks/resetPassword/actionTypes";

const SuccessPageResetPassword = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { email, isLoadingResetPassword } = useSelector(
    (state) => state.resetPasswordReducer
  );

  const hiddenEmail = () => {
    const regex = new RegExp(/(?!^).(?=[^@]+@)/, "g");
    return email?.replace(regex, "*");
  };

  useEffect(() => {
    if (!email) {
      history.push("/");
    }
  }, []);

  const handleResetPassword = () => {
    dispatch(resetPassword(email));
  };

  return (
    <>
      <div className="overlay">
        <div className="row">
          <div className="col-12 mt-3 mb-2 text-center">
            <img className="img-fluid unik-logo" src={logo} alt="Uniksystem" />
          </div>
        </div>

        <Form.Row style={{ paddingTop: 20, color: "gray" }}>
          <Col lg="12" style={{ textAlign: "center" }}>
            <img src={successEmail} alt="E-mail" />
            <span>
              <h1 className="display-4">{t("login.successEmail")}</h1>
            </span>
            <small>{t("login.successEmailLabel")}</small>
          </Col>
        </Form.Row>
        <hr />

        <Form.Row style={{ color: "gray" }}>
          <label>
            <i className="icon-mail mr-2" />
            {hiddenEmail()}
          </label>
        </Form.Row>

        <Form.Row style={{ paddingTop: 20, paddingBottom: 10, color: "gray" }}>
          {t("login.emailNotSent")}
        </Form.Row>

        <Form.Row>
          <Col lg="12">
            <div className="buttons">
              <Button
                variant="outline-light"
                className="login-button"
                style={{ backgroundColor: "#4484f4" }}
                onClick={() => handleResetPassword()}
              >
                {t("login.resendEmail")}
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>

      <ToastContainer />
      <SpinnerNew open={isLoadingResetPassword} />
    </>
  );
};

export default withNamespaces()(SuccessPageResetPassword);
