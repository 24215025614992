import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { withNamespaces } from "react-i18next";
import CreateTextInput from "./CreateTextInput";
import CreateSearchableDropdown from "./CreateSearchableDropdown";
import CreateTextArea from "./CreateTextArea";
import CreateLink from "./CreateLink";
import CreateTabs from "./CreateTabs";
import CreateAccordion from "./CreateAccordion";
import CreateMultipleFileInput from "./CreateMultipleFileInput";
import CreateCalculosCusto from "./CreateCalculosCusto";
import Messages from "~/components/Messages";
import { handleButtonClick, handleButtonFormdata } from "../utils/FormButtons";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import { setConfirmProcessMessage } from "~/store/ducks/iflow/actionTypes";
import { transformFormData, hasLinks } from "../utils";
import uuid from "uuid/v1";
import oauth from "~/utils/oauth";
import CreateRowFromColumns from "./CreateRowFromColumns";
import CreateBootstrapTable from "./CreateBootstrapTable";
import CreateDateInput from "./CreateDateInput";
import CreateButtonsFromSelection from "./CreateButtonsFromSelection";
import CreateRichText from "./CreateRichText";
import CreateAlert from "./CreateAlert";
import CreateTextLabel from "./CreateTextLabel";
import CreateGoogleMap from "./CreateGoogleMap";
import CreateConfirmationModal from "./CreateConfirmationModal";
import CreateCloseProcessButton from "./CreateCloseProcessButton";
import PreviewComponent from "./PreviewComponent";
import { setFocusElement, setDocument } from "~/store/ducks/iflow/actionTypes";
import { Modal } from "react-bootstrap";
import { SlPrinter } from "react-icons/sl";
import { setProcessFormUrl } from "~/containers/Layout/actions";
import {
  getOrganizationById,
  clearOrganization,
} from "~/store/ducks/organizations/actionTypes";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AlertMui = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const devLogConsole = require("~/utils/devLog");
const RenderForm = (props) => {
  const { t, setDataLocalStorage } = props;
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const jsonData = props.data;
  const translations = props.translations;
  const styleSheet = props.styleSheet;
  const buttonOperation = useRef(null);
  const buttonName = useRef(null);
  const signRef = useRef([]);
  const buttonId = useRef(null);
  const formDataRef = useRef(null);

  const [validated, setValidated] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [firstHeader, setFirstHeader] = useState(true);
  const [textSize, setTextSize] = useState("");
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [currentInfo, setCurrentInfo] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [openPrint, setOpenPrint] = useState(false);
  const [hideBTNPRINT, sethideBTNPRINT] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let language = userSettings?.language ? userSettings.language : "pt";
  const config = configuration?.loginPage;

  useEffect(() => {
    dispatch(setDocument(null));
  }, []);

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOrganizationById(user?.organization?.id));
    }

    return () => {
      dispatch(clearOrganization());
    };
  }, [dispatch, user]);

  useEffect(() => {
    if (styleSheet?.zoomIn && styleSheet?.zoomInTextSize) {
      setTextSize(`${styleSheet?.zoomInTextSize}`);
    }
  }, [styleSheet]);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const listEntries = JSON.parse(localStorage.getItem("LIST_ENTRIES")) || [];
  const listEntriesReload =
    JSON.parse(localStorage.getItem("LIST_RELOAD_ENTRIES")) || [];

  const handleButtonDetails = (button) => {
    buttonOperation.current = button.operation;
    buttonId.current = button.id;
    buttonName.current = button.name;
  };

  const handleCancelSubmit = () => {
    buttonOperation.current = null;
    buttonId.current = null;
    buttonName.current = null;
    dispatch(setConfirmProcessMessage(null));
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    dispatch(setConfirmProcessMessage(null));
  };

  useEffect(() => {
    if (isConfirmed) {
      document.getElementById(buttonId.current).click();
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (
      (listEntriesReload != null || listEntriesReload != undefined) &&
      listEntriesReload.length > 1
    ) {
      listEntriesReload.pop();
      localStorage.setItem(
        "LIST_RELOAD_ENTRIES",
        JSON.stringify(listEntriesReload)
      );
    }
    const performanceEntries = window.performance.getEntries();
    for (let i = performanceEntries.length - 1; i >= 0; i--) {
      const entry = performanceEntries[i];
      if (
        entry.name.includes("iFlow/Form/detail.jsp") &&
        entry.entryType == "resource" &&
        (entry.name.includes("&procStatus=0") ||
          entry.name.includes("&procStatus=1") ||
          entry.name.includes("&procStatus=2"))
      ) {
        if (
          !listEntriesReload.includes(entry.name) &&
          listEntriesReload.length < 1
        ) {
          listEntriesReload.push(entry.name);
          localStorage.setItem(
            "LIST_RELOAD_ENTRIES",
            JSON.stringify(listEntriesReload)
          );
        }
      }
    }
    localStorage.removeItem("selectedFilesMetadata");
  }, []);

  useEffect(() => {
    const lastUrlforDispatch = localStorage.getItem("URL_LastEntry");
    if (lastUrlforDispatch != null) {
      let newreloadentrie = [];
      let formDataBackButton = new FormData();
      const url = lastUrlforDispatch.split("/iFlow")[1];
      if (formDataRef.current) {
        formDataBackButton = formDataRef.current;
        const pairs = handleButtonFormdata(buttonOperation.current);
        pairs.forEach((p) => {
          formDataBackButton.delete(p.formkey);
          formDataBackButton.append(p.formkey, p.value);
        });
      } else {
        handleButtonClick(buttonOperation.current);
        formDataBackButton = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
      }
      if (listEntries.length < 1) {
        localStorage.removeItem("LIST_ENTRIES");
        localStorage.removeItem("LIST_RELOAD_ENTRIES");
        newreloadentrie.push(lastUrlforDispatch);
        localStorage.setItem(
          "LIST_RELOAD_ENTRIES",
          JSON.stringify(newreloadentrie)
        );
      }
    }
    localStorage.removeItem("URL_LastEntry");
  }, [buttonOperation, formDataRef, dispatch]);

  const verificarTamanhoTotal = () => {
    var fileInputs = document.querySelectorAll('input[type="file"]');
    if (fileInputs.length === 0) {
      return false;
    }
    var tamanhoTotal = 0;
    const maxFileSize = 1024 * 1024 * 100;

    fileInputs.forEach(function (input) {
      var files = input.files;

      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        tamanhoTotal += file.size;
      }
    });

    if (tamanhoTotal > maxFileSize && config === "nblp") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitWrapper = (event, button) => {
    const sizeIsHigher = verificarTamanhoTotal();
    if (window.location.href.includes("procdetail/cma/iFlow/Form/detail.jsp")) {
      procDetailClick();
    } else if (!sizeIsHigher) {
      if (isConfirmed) {
        handleSubmit(event);
      } else {
        handleButtonDetails(button);
        if (buttonOperation.current.includes("if (confirm")) {
          const array = buttonOperation.current.split("if (confirm('");
          if (array) {
            const array2 = array[1].split("'");
            if (array2) {
              let message;
              if (array2[0].includes("::translate")) {
                const parts = array2[0].split("::translate");
                message = t(`general.${parts[0]}`);
              } else {
                message = array2[0];
              }
              dispatch(setConfirmProcessMessage(message));
              const formdata = transformFormData(
                new FormData(document.getElementsByName("dados")[0])
              );
              formDataRef.current = formdata;
            }
          }
        } else {
          handleSubmit(event);
        }
      }
      let formdata = getFormdataVal();
      let errorEmailToSubmit = validateEmailFields(formdata);
      if (!errorEmailToSubmit) dispatch(setFocusElement(null));
    } else if (sizeIsHigher) {
      setWarningMessage(t("portalrh.documentManagement.sizeIsHigherThan100Mb"));
      setShowWarning(true);
    }
  };

  useEffect(() => {
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 10000);
    }
  }, [showWarning]);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const procDetailClick = () => {
    if (listEntries != null) {
      let baseUrl = window.location.href;
      let lastElement;
      const index = baseUrl.indexOf("/iFlow");
      let dispactURL;
      listEntries.pop();
      localStorage.setItem("LIST_ENTRIES", JSON.stringify(listEntries));

      if (listEntries.length < 1) {
        dispactURL = listEntriesReload[0];
      } else {
        lastElement = listEntries[listEntries.length - 1];
        if (index !== -1) {
          const partialUrl = baseUrl.substring(index + 6); // +7 para incluir "/iFlow/" na string
          dispactURL = baseUrl.replace(partialUrl, lastElement);
        }
      }
      const newFlowId = dispactURL.split(/flowid=([^&]*)/)[1];
      const newPid = dispactURL.split(/pid=([^&]*)/)[1];
      baseUrl = baseUrl.replace(/flowid=\d+/, `flowid=${newFlowId}`);
      baseUrl = baseUrl.replace(/pid=\d+/, `pid=${newPid}`);

      localStorage.setItem("URL_LastEntry", dispactURL);
      window.location.href = baseUrl;
      window.location.reload();
    }
  };

  const getFormdataVal = (penultimateEntry) => {
    let formdata;
    if (formDataRef.current) {
      formdata = formDataRef.current;
      const pairs = handleButtonFormdata(buttonOperation.current);
      pairs.forEach((p) => {
        formdata.delete(p.formkey);
        formdata.append(p.formkey, p.value);
      });
    } else {
      handleButtonClick(buttonOperation.current, buttonName.current);
      formdata = transformFormData(
        new FormData(document.getElementsByName("dados")[0])
      );
    }
    return formdata;
  };

  const validateEmailFields = (formdata) => {
    let errorEmailToSubmit = false;
    if (config === "nblp") {
      for (var pair of formdata.entries()) {
        if (pair[0].includes("Email") || pair[0].includes("email")) {
          if (!isEmailValid(pair[1])) {
            errorEmailToSubmit = true;
            break;
          }
        }
      }
    }
    return errorEmailToSubmit;
  };

  const handleSubmit = (event) => {
    const form = document.dados;

    const handleDispatch = (voltarAction) => {
      let formdata = getFormdataVal();
      let errorEmailToSubmit = validateEmailFields(formdata);
      let action;
      if (jsonData?.form?.action === "proc_detail.jsp") {
        action = "/proc_detail.jsp?";
      }
      //TEST NEW FORM ACTION
      else if (jsonData?.form?.action === "forward.jsp") {
        action = "/Forward/forward.jsp?";
      } else if (jsonData?.form?.action === "detail.jsp") {
        action = "/Form/detail.jsp?";
      } else {
        action = "/Form/form.jsp?";
      }
      const url =
        `${action}` +
        "flowid=" +
        formdata.get("flowid") +
        "&pid=" +
        formdata.get("pid") +
        "&subpid=" +
        formdata.get("subpid") +
        //+ "&flowExecType=" + formdata.get("flowExecType")
        "&Authorization=" +
        oauth.getAccessToken();
      if (voltarAction != undefined) {
        if (voltarAction) {
          dispatch(postIflowFormData(formdata, url));
        } else {
          if (!errorEmailToSubmit) dispatch(postIflowFormData(formdata, url));
        }
      } else {
        if (!errorEmailToSubmit) dispatch(postIflowFormData(formdata, url));
      }
    };

    if (
      buttonOperation.current &&
      buttonOperation.current.includes("CheckEmptyFields()")
    ) {
      if (styleSheet?.skipCheckValidity) {
        handleDispatch(true);
      } else {
        if (form.checkValidity() === false) {
          setValidated(true);
        } else {
          handleDispatch();
        }
      }
    } else {
      if (buttonOperation.current) {
        let voltarAction = false;
        if (
          buttonOperation.current.includes("retroceder") ||
          buttonOperation.current.includes("voltar")
        )
          voltarAction = true;

        handleDispatch(voltarAction);
      }
    }
  };

  const getFlowId = () => {
    return jsonData?.form?.hidden?.find((i) => i.name === "flowid")?.value;
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const headerText = (field) => {
    if (!field?.text?.includes("::t::")) {
      if (field.text.includes(":")) {
        return field.text;
      } else {
        return t(field.text);
      }
    }

    const translatedText = translateText(field.text);
    return translatedText;
  };

  const translateText = (textField) => {
    if (!textField.includes("::t::")) {
      return textField;
    }
    const lingua = language.trim();
    const cleanTextField = textField.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translations?.[lingua]) {
          return (
            splitSplitedText[0] +
            translations[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          if (tt.startsWith("nDP::")) {
            return tt.substring(5);
          } else {
            if (tt.includes(": Required")) {
              return t("portalrh.error.required");
            }
            return tt;
          }
        }
      })
      .join("");

    return textoFinal;
  };

  const renderColumnDivisions = (blockdivision, mainindex) => {
    const renderFields = (field, index) => {
      if (field.variable === "googlemapsapi") {
        return <CreateGoogleMap key={index} field={field} />;
      }

      if (field.type === "header") {
        // if (index === 0) {
        //   return <></>;
        // } else {
        return (
          <div
            className="custom-title"
            style={{
              borderRadius: "5px",
              fontSize: `${textSize}px`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: !styleSheet.dontShowPrintButton && "15px",
              paddingLeft: "10px",
            }}
            key={uuid()}
          >
            {ReactHtmlParser(headerText(field))}
            {!hideBTNPRINT && !styleSheet.dontShowPrintButton && (
              <Button
                type="button"
                id="printBTN"
                name={t("portalrh.scheduler.print")}
                onClick={() => {
                  setOpenPrint(true);
                  sethideBTNPRINT(true);
                }}
                style={{
                  right:
                    window.location.hash.includes("processos") ||
                    window.location.hash.includes("processes")
                      ? "60px"
                      : "35px",
                  top:
                    window.location.hash.includes("processos") ||
                    window.location.hash.includes("processes")
                      ? "22%"
                      : "5.3%",
                }}
              >
                <SlPrinter />
              </Button>
            )}
          </div>
        );
        //}
      } else if (field.type === "subheader") {
        return (
          <div key={uuid()}>
            <Form.Group controlId={`formlabel-${field.type}`}>
              <Form.Label column>
                <div
                  style={{
                    borderRadius: "5px",
                    fontSize: `${textSize}px`,
                  }}
                  className="custom-title subheader"
                >
                  {ReactHtmlParser(headerText(field))}
                </div>
              </Form.Label>
            </Form.Group>
            {/* <hr /> */}
          </div>
        );
      } else if (field.type === "arraytable") {
        return (
          <CreateBootstrapTable
            field={field}
            key={index}
            signRef={signRef}
            styleSheet={styleSheet}
            translations={translations}
            language={language}
          />
        );
      } else if (field.type === "image") {
        return (
          <img
            src={"iFlow/" + field.url}
            width={field.width}
            height={field.height}
            alt={field.alt_text}
            key={index}
            style={styleSheet.ultraCompact ? { float: "right" } : {}}
          />
        );
      } else if (field.type === "textmessage") {
        return (
          <CreateAlert
            key={index}
            index={index}
            field={field}
            variant="info"
            translations={translations}
            language={language}
          />
        );
      } else if (field.type === "textlabel") {
        return (
          <CreateTextLabel
            key={index}
            field={field}
            styleSheet={styleSheet}
            setIsModalShowing={setIsModalShowing}
            setCurrentInfo={setCurrentInfo}
            translations={translations}
          />
        );
      } else if (field.type === "filler") {
        return <div style={{ height: "20px" }}></div>;
      } else if (field.type === "link") {
        return (
          <CreateLink
            key={field.text}
            field={field}
            styleSheet={styleSheet}
            translations={translations}
            language={language}
          />
        );
      } else if (field.type === "textbox") {
        const isDate = new Date(field.value).toString();
        const regex = /(\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4})(\s\d{2}:\d{2})?/;
        const isValidDate = regex.test(field.value);
        if (isDate !== "Invalid Date" && isValidDate && field.value.includes("T")) {
          let dateParts = field.value.split("T")[0].split("-");
          field.value = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        }
        return (
          <CreateTextInput
            key={field.variable}
            field={field}
            styleSheet={styleSheet}
            setIsModalShowing={setIsModalShowing}
            setCurrentInfo={setCurrentInfo}
            translations={translations}
            maxWidth={field.disabled === "true" ? "" : "fit-content"}
            language={language}
            t={t}
          />
        );
      } else if (field.type === "selection") {
        if (field.variable === "seccao")
          return (
            <CreateButtonsFromSelection
              key={index}
              field={field}
              noHeight={true}
            />
          );
        else
          return (
            <CreateSearchableDropdown
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
              translations={translations}
              language={language}
              maxWidth="250px"
            />
          );
      } else if (field.type === "document_preview") {
        return <PreviewComponent type={field.type} file={field.file} />;
      } else if (field.type === "datecal") {
        let newDatePicker = false;
        if (field.text.includes("::nDP::")) {
          newDatePicker = true;
        }
        return (
          <CreateDateInput
            key={field.variable}
            field={field}
            styleSheet={styleSheet}
            fontSize={textSize}
            newDatePicker={newDatePicker}
            translations={translations}
          />
        );
      } else if (field.type === "textarea") {
        if (
          (typeof field?.text == "string" &&
            field?.text?.includes("::richText")) ||
          field?.text?.b?.includes("::richText")
        ) {
          return (
            <CreateRichText
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
            />
          );
        } else
          return (
            <CreateTextArea
              key={field.variable}
              field={field}
              styleSheet={styleSheet}
              setIsModalShowing={setIsModalShowing}
              setCurrentInfo={setCurrentInfo}
              translations={translations}
            />
          );
      } else if (field.type === "file") {
        return (
          <CreateMultipleFileInput
            key={index}
            field={field}
            signRef={signRef}
            styleSheet={styleSheet}
            translations={translations}
            language={language}
          />
        );
      } else if (field.type === "custo") {
        return <CreateCalculosCusto key={index} field={field} />;
      } else if (field.type === "button") {
        const fitbutton = field.text.includes("::fitbutton") ? true : false;
        return (
          <>
            {!styleSheet?.isbuttonStyle ? (
              //   <OverlayTrigger
              //   placement="right"
              //   delay={{ show: 250, hide: 400 }}
              //   overlay={renderTooltip(button.tooltip)}
              // >

              //     {button.text}
              //   </Button>

              //   <OverlayTrigger
              //   placement="right"
              //   delay={{ show: 250, hide: 400 }}
              //   overlay={renderTooltip(button.tooltip)}
              // >
              <button
                className="custom-button btn btn-outline-primary"
                style={{
                  margin: "10px",
                  minHeight: "30px",
                  padding: fitbutton ? "auto" : "0 5px 0 5px",
                  marginTop: fitbutton
                    ? "3rem"
                    : styleSheet?.fitButtonMarginTop
                    ? "3rem"
                    : "0",
                }}
                type="submit"
                name={field.name}
                onClick={() => handleButtonDetails(field)}
              >
                {buttonText(field)}
              </button>
            ) : (
              // </OverlayTrigger>
              <Form.Group
                as={Row}
                key={uuid()}
                style={{ marginRight: "0px", marginLeft: "0px", float: "left" }}
              >
                <Form.Label
                  column
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: fitbutton ? "auto" : "0",
                    marginTop: fitbutton
                      ? "3rem"
                      : styleSheet?.fitButtonMarginTop
                      ? "3rem"
                      : "0",
                  }}
                >
                  <Button
                    className="card-button"
                    style={{
                      width: fitbutton ? "100%" : "",
                      fontSize: `${textSize}px`,
                      marginRight: "10px",
                      marginBottom: "15px",
                    }}
                    type="submit"
                    name={field.name}
                    onClick={() => handleButtonDetails(field)}
                  >
                    {buttonText(field)}
                  </Button>
                </Form.Label>
              </Form.Group>
            )}
          </>
        );
      }
    };

    const buttonText = (field) => {
      if (!field?.text?.includes("::t::")) {
        return t(field.text.split("::")[0]);
      }

      const translatedText = translateText(field.text);
      return translatedText;
    };

    const renderColumnContent = (columndivision, styleSheet, renderLinks) => {
      const header = blockdivision?.columndivision?.field;
      return Object.keys(columndivision).map((columnKey) => {
        if (columnKey === "field") {
          let fieldArray = columndivision.field ? columndivision.field : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }

          if (
            styleSheet &&
            styleSheet?.noTransformColumnsIntoRow &&
            !renderLinks &&
            !styleSheet?.transformLinksIntoWrapper
          ) {
            /*novobanco, finantia*/
            return fieldArray.map((field, index) => {
              return (
                <div
                  key={`col_${uuid()}`}
                  style={{
                    flexBasis: `${
                      columndivision.width ? columndivision.width : "100"
                    }%`,
                    textAlignLast:
                      field.type === "document_preview" ? "center" : "initial",
                  }}
                >
                  {renderFields(field, index)}
                </div>
              );
            });
          } else if (
            styleSheet &&
            styleSheet.applyColumnWidth &&
            !styleSheet.noTransformColumnsIntoRow
          ) {
            /*cma*/

            fieldArray = fieldArray.filter(function (obj) {
              return obj.type !== "filler";
            });

            return fieldArray.map((field, index) => {
              return (
                <div
                  key={`col_${uuid()}`}
                  className="renderFieldsParent"
                  style={{
                    flexBasis: `${
                      isMobile
                        ? "100"
                        : columndivision.width
                        ? columndivision.width
                        : "100"
                    }%`,
                    maxWidth: `100%`,
                    paddingLeft: field.type === "link" ? "15px" : "1rem",
                    justifySelf: field.type === "link" && field.align ? field.align : "",
                  }}
                >
                  {renderFields(field, index)}
                </div>
              );
            });
          } else
          /*default*/
            return fieldArray.map((field, index) => renderFields(field, index));

          /*RENDER tabdivision*/
        } else if (columnKey === "tabdivision") {
          let tabDivisionArray = columndivision.tabdivision
            ? columndivision.tabdivision
            : [];
          let widthDivisionArray = columndivision.width
            ? columndivision.width
            : "auto";
          if (!Array.isArray(tabDivisionArray)) {
            tabDivisionArray = [tabDivisionArray];
          }
          let accordionStyle = "DEFAULT";

          const accordion = tabDivisionArray.map((tabDiv) => {
            let tabArray = tabDiv.tab ? tabDiv.tab : [];
            if (!Array.isArray(tabArray)) tabArray = [tabArray];
            tabArray.forEach((tab) => {
              if (tab.name.includes("::tab")) accordionStyle = "TAB";
            });

            if (accordionStyle === "TAB") {
              return (
                <CreateTabs
                  key={uuid()}
                  tabDiv={tabDiv}
                  tabArray={tabArray}
                  renderBlockDivision={renderBlockDivision}
                />
              );
            } else {
              let activeKey = 0;
              //find active tab
              let arrays =
                JSON.parse(localStorage.getItem("iflowAccordion")) || [];
              //find config for this tabDiv
              let found = arrays.find((a) => a.id === tabDiv.id);
              if (found) {
                tabArray.forEach((tab, index) => {
                  if (tab.id === found.key) {
                    activeKey = found.key;
                  }
                });
              }
              return (
                <CreateAccordion
                  key={uuid()}
                  tabDiv={tabDiv}
                  activeKey={activeKey}
                  tabArray={tabArray}
                  renderBlockDivision={renderBlockDivision}
                  renderColumnContent={renderColumnContent}
                  header={header}
                  flowId={getFlowId()}
                  styleSheet={styleSheet}
                  style={{
                    width: `${
                      isMobile
                        ? "100"
                        : widthDivisionArray
                        ? widthDivisionArray
                        : ""
                    }%`,
                    paddingRight: `${widthDivisionArray ? "15px" : ""}`,
                    paddingLeft: `${widthDivisionArray ? "15px" : ""}`,
                  }}
                  translations={translations}
                  language={language}
                />
              );
            }
          });
          return accordion;
        }
      });
    };

    const transformColumnsIntoRow = (columns_) => {
      let colIndex = 0;
      colIndex = columns_.findIndex(
        (col) =>
          col && //null and undefined check
          col !== "" &&
          Object.keys(col).length !== 0
      );
      return Object.keys(columns_[colIndex]).map((columnKey) => {
        if (columnKey === "field") {
          let fieldArray = columns_[colIndex].field
            ? columns_[colIndex].field
            : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }

          return fieldArray.map((field, index) => {
            if (index > 0) return renderFields(field, index);
            else if (index === 0) {
              return (
                <CreateRowFromColumns
                  key={index}
                  field={field}
                  columns={columns_}
                  styleSheet={styleSheet}
                  translations={translations}
                />
              );
            }
          });
        }
      });
    };

    let columndivision = blockdivision.columndivision;
    if (!Array.isArray(columndivision)) {
      columndivision = [columndivision];
    }
    let numColumns = columndivision.length;

    if (numColumns === 1) {
      return (
        <Col
          key={`columndivision_${uuid()}`}
          style={{ flexGrow: "1", padding: "0px" }}
        >
          {renderColumnContent(
            columndivision[0],
            styleSheet,
            hasLinks(columndivision)
          )}
        </Col>
      );
    } else if (numColumns > 1) {
      if (
        styleSheet &&
        (styleSheet.noTransformColumnsIntoRow ||
          (hasLinks(columndivision) && styleSheet?.transformLinksIntoWrapper))
      ) {
        return (
          <div
            key={`columndivision_${uuid()}`}
            style={{
              display: "flex",
              flexWrap: styleSheet?.transformLinksIntoWrapper
                ? "wrap"
                : "nowrap",
              flexDirection: "row",
              flexGrow: "1",
              padding: styleSheet?.transformLinksIntoWrapper ? "" : "0 15px",
              borderBottom: styleSheet?.transformLinksIntoWrapper
                ? "1px solid rgba(0,0,0,.1)"
                : "",
              marginRight: styleSheet?.transformLinksIntoWrapper ? "15px" : "",
              marginLeft: styleSheet?.transformLinksIntoWrapper ? "15px" : "",
              justifyContent:
                hasLinks(columndivision) &&
                styleSheet?.transformLinksIntoWrapper
                  ? "center"
                  : "",
              flexFlow: styleSheet?.transformLinksIntoWrapper
                ? "wrap"
                : "nowrap",
            }}
          >
            {columndivision.map((col) => {
              return renderColumnContent(col, styleSheet);
            })}
          </div>
        );
      } else if (
        styleSheet &&
        styleSheet.applyColumnWidth &&
        !styleSheet.noTransformColumnsIntoRow
      ) {
        return (
          <div
            key={`columndivision_${uuid()}`}
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              flexGrow: "1",
              padding: "0",
              maxWidth: "100%",
              // marginTop: "1rem",
              // marginBottom: "1rem",
              // border: "0",
              // borderBottom: "1px solid rgba(0,0,0,.1)",

              //alignItems: "baseline",
            }}
          >
            {columndivision.map((col) => {
              return renderColumnContent(col, styleSheet);
            })}
          </div>
        );
      } else
        return (
          <Col
            key={`columndivision_${uuid()}`}
            style={{ flexGrow: "1", padding: "0px" }}
          >
            {transformColumnsIntoRow(columndivision)}
          </Col>
        );
    }
  };

  const renderBlockDivision = (blockdivision) => {
    let blockdivisionArray = blockdivision;
    if (!Array.isArray(blockdivisionArray)) {
      blockdivisionArray = [blockdivisionArray];
    }
    return blockdivisionArray.map((blockdivision, index) => {
      return (
        <Row
          key={`blockdivision_${index}`}
          style={{
            width: "100%",
            flexWrap: "nowrap",
            margin: "0px",
            padding: !styleSheet.dontShowPrintButton
              ? "0px 30px 0px 0px"
              : styleSheet?.compact
              ? "0px"
              : blockdivision?.columndivision === ""
              ? "0px"
              : Array.isArray(blockdivision?.columndivision)
              ? blockdivision?.columndivision[0]?.size > 0
                ? "5px"
                : "0px"
              : "0px",
            fontSize: `${textSize}px`,
          }}
        >
          {renderColumnDivisions(blockdivision, index)}
        </Row>
      );
    });
  };

  const buttonTextFormKey = (textButton) => {
    if (textButton.includes("::t::")) {
      const translatedText = translateText(textButton);
      return translatedText;
    } else {
      return textButton;
    }
  };

  const showalertextIflowTranslation = (p_) => {
    if (p_.includes("::t::")) {
      const translatedText = translateText(p_);
      return translatedText;
    } else {
      return p_;
    }
  };

  const RenderFormItems = () => {
    if (jsonData) {
      const form = jsonData.form;
      return Object.keys(form).map((formKey) => {
        if (formKey === "blockdivision") {
          return renderBlockDivision(form.blockdivision);
        }
        if (formKey === "error") {
          let text = form.error?.text.split("<br/>");
          return (
            <Row
              key={`blockdivision_error`}
              style={{
                width: "100%",
                flexWrap: "nowrap",
                margin: "0px",
                padding: "5px",
              }}
            >
              <Col
                key={`columndivision_error`}
                style={{ flexGrow: "1", padding: "0px" }}
              >
                <Alert key={formKey} variant="danger">
                  {text &&
                    text.map((p_, index) => (
                      <p key={index}>{showalertextIflowTranslation(p_)}</p>
                    ))}
                </Alert>
              </Col>
            </Row>
          );
        }
        if (formKey === "button") {
          let buttonArray = form.button ? form.button : [];
          if (!Array.isArray(buttonArray)) {
            buttonArray = [buttonArray];
          }
          return (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop:
                  styleSheet != undefined && styleSheet.ultraCompact
                    ? "0px"
                    : "1rem",
                paddingBottom: "1rem",
                textAlignLast: isMobile ? "center" : "",
                marginTop: isMobile ? "1rem" : "",
              }}
              key={uuid()}
            >
              {buttonArray.map((button) => {
                if (button.text === "Cancelar" || button.text === "Cancel") {
                  if (button?.zotooltipna) {
                    return (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(
                          buttonTextFormKey(button.tooltip)
                        )}
                      >
                        <Button
                          key={uuid()}
                          id={button.id}
                          variant="danger"
                          style={{
                            margin: "10px",
                            width: "auto",
                            minHeight:
                              styleSheet != undefined && styleSheet.ultraCompact
                                ? "35px"
                                : undefined,
                            height:
                              styleSheet != undefined && styleSheet.ultraCompact
                                ? "35px"
                                : undefined,
                          }}
                          onClick={(event) =>
                            handleSubmitWrapper(event, button)
                          }
                        >
                          {buttonTextFormKey(button.text)}
                        </Button>
                      </OverlayTrigger>
                    );
                  } else {
                    return (
                      <Button
                        key={uuid()}
                        id={button.id}
                        variant="danger"
                        style={{ margin: "10px", width: "auto" }}
                        onClick={(event) => handleSubmitWrapper(event, button)}
                      >
                        {buttonTextFormKey(button.text)}
                      </Button>
                    );
                  }
                } else {
                  if (button?.tooltip) {
                    return (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(
                          buttonTextFormKey(button.tooltip)
                        )}
                      >
                        <Button
                          key={uuid()}
                          id={button.id}
                          className="card-button"
                          style={{ margin: "10px", fontSize: `${textSize}px` }}
                          onClick={(event) =>
                            handleSubmitWrapper(event, button)
                          }
                        >
                          {buttonTextFormKey(button.text)}
                        </Button>
                      </OverlayTrigger>
                    );
                  } else {
                    return (
                      <Button
                        key={uuid()}
                        id={button.id}
                        className="card-button"
                        style={{ margin: "10px", fontSize: `${textSize}px` }}
                        onClick={(event) => handleSubmitWrapper(event, button)}
                      >
                        {buttonTextFormKey(button.text)}
                      </Button>
                    );
                  }
                }
              })}
            </Row>
          );
        }
        if (formKey === "hidden") {
          const hiddenArray = form.hidden;
          return hiddenArray.map((field, index) => {
            return (
              <input
                key={index}
                type="hidden"
                value={field.value}
                name={field.name}
              />
            );
          });
        }
      });
    } else return null;
  };

  const modalContentRef = React.useRef(null);
  const handlePrint = async () => {
    try {
      const canvas = await html2canvas(modalContentRef.current);

      if (!canvas.toDataURL().includes("data:image/png;base64,iVBOR")) {
        devLogConsole("Canvas vazio. Não será impresso.");
        return;
      }
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<html><head><title>Imprimir Formulário</title>` +
          `<style>@media print { body { width: 100%; height: 100%; margin: 0; } img { width: 100%; height: auto; page-break-inside: avoid; } }</style>` +
          "</head><body>"
      );

      const img = new Image();
      img.src = canvas.toDataURL();
      img.onload = function printAndClose() {
        printWindow.document.body.appendChild(img);
        printWindow.document.write("</body></html>");
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
        setOpenPrint(false);
        sethideBTNPRINT(false);
        img.onload = null; // Remove o listener do evento onload
      };
    } catch (error) {
      console.error("Erro ao capturar o canvas:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        setOpenPrint(true);
        sethideBTNPRINT(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (openPrint) {
      setTimeout(() => {
        handlePrint();
      }, 2000);
    }
  }, [openPrint]);

  const handleClosePrint = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenPrint(false);
    sethideBTNPRINT(false);
  };

  return (
    <>
      {!props.isModal && styleSheet?.processCloseButton && (
        <CreateCloseProcessButton />
      )}
      <div>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          name="dados"
          encType="multipart/form-data"
          style={{ width: "100%", margin: "auto" }}
          className="process-form"
        >
          <Row>
            <Col>
              {showWarning ? (
                <Alert key={1} variant="danger">
                  {warningMessage}
                </Alert>
              ) : null}
            </Col>
          </Row>
          <div ref={modalContentRef}>{jsonData && <RenderFormItems />}</div>
        </Form>
      </div>
      <CreateConfirmationModal
        handleCancelSubmit={handleCancelSubmit}
        handleConfirm={handleConfirm}
        language={language}
        translations={translations}
      />
      <Modal
        show={isModalShowing}
        onHide={() => setIsModalShowing(false)}
        dialogClassName="custom-modal"
        centered
        backdrop="static"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "white" }}
        ></Modal.Header>
        <Modal.Body>{currentInfo}</Modal.Body>
      </Modal>

      <Messages />
      <Snackbar
        open={openPrint}
        autoHideDuration={5000}
        onClose={handleClosePrint}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "63px" }}
      >
        <AlertMui
          onClose={handleClosePrint}
          severity="info"
          sx={{ width: "100%" }}
        >
          {t("general.printMessage")}
        </AlertMui>
      </Snackbar>
    </>
  );
};

export default withNamespaces()(RenderForm);
