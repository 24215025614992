export const CLEAR_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RHADMIN_CONFIGURATION";
export const GET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@GET_RHADMIN_CONFIGURATION";
export const SET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SET_RHADMIN_CONFIGURATION";
export const PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RHADMIN_CONFIGURATION";
export const SUCCESS_PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RHADMIN_CONFIGURATION";
export const GET_OPENAI_CONFIGURATION =
  "rhadminConfiguration@GET_OPENAI_CONFIGURATION";
export const SET_OPENAI_CONFIGURATION =
  "rhadminConfiguration@SET_OPENAI_CONFIGURATION";
export const PERSIST_OPENAI_CONFIGURATION =
  "rhadminConfiguration@PERSIST_OPENAI_CONFIGURATION";
export const SUCCESS_PERSIST_OPENAI_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_OPENAI_CONFIGURATION";
export const CLEAR_OPENAI_CONFIGURATION =
  "rhadminConfiguration@CLEAR_OPENAI_CONFIGURATION";
export const DELETE_OPENAI_CONFIGURATION =
  "rhadminConfiguration@DELETE_OPENAI_CONFIGURATION";
export const SUCCESS_DELETE_OPENAI_DOCUMENT =
  "rhadminConfiguration@SUCCESS_DELETE_OPENAI_DOCUMENT";

export const GET_DISP_RECIBOS = "rhadminConfiguration@GET_DISP_RECIBOS";
export const SET_DISP_RECIBOS = "rhadminConfiguration@SET_DISP_RECIBOS";
export const GET_ALLOW_DIS_RECIBOS =
  "rhadminConfiguration@GET_ALLOW_DIS_RECIBOS";
export const SET_ALLOW_DIS_RECIBOS =
  "rhadminConfiguration@SET_ALLOW_DIS_RECIBOS";

export const getRHAdminConfiguration = (organizationId) => {
  return { type: GET_RHADMIN_CONFIGURATION, organizationId };
};

export const setRHAdminConfiguration = (configuration) => {
  return { type: SET_RHADMIN_CONFIGURATION, configuration };
};

export const clearRHAdminConfiguration = () => {
  return { type: CLEAR_RHADMIN_CONFIGURATION };
};

export const clearOpenAiConfiguration = () => {
  return { type: CLEAR_OPENAI_CONFIGURATION };
};

export const persistRHAdminConfiguration = (configuration) => {
  return { type: PERSIST_RHADMIN_CONFIGURATION, configuration };
};

export const sucessesPersistRHAdminConfiguration = () => {
  return { type: SUCCESS_PERSIST_RHADMIN_CONFIGURATION };
};

export const getOpenAIConfiguration = (organizationId) => {
  return { type: GET_OPENAI_CONFIGURATION, organizationId };
};

export const setOpenAiConfiguration = (configuration) => {
  return { type: SET_OPENAI_CONFIGURATION, configuration };
};

export const persistOpenAIConfiguration = (configuration) => {
  return { type: PERSIST_OPENAI_CONFIGURATION, configuration };
};
export const deleteOpenAiConfiguration = (id) => {
  return { type: DELETE_OPENAI_CONFIGURATION, id };
};

export const sucessesPersisOpenAIConfiguration = () => {
  return { type: SUCCESS_PERSIST_OPENAI_CONFIGURATION };
};

export const successDeleteOpenaiDocument = () => {
  return { type: SUCCESS_DELETE_OPENAI_DOCUMENT };
};

export const getDispRecibos = (payload) => {
  return { type: GET_DISP_RECIBOS, payload };
};

export const setDispRecibos = (dispRecibos) => {
  return { type: SET_DISP_RECIBOS, dispRecibos };
};

export const getAllowDisRecibos = (empCode) => {
  return { type: GET_ALLOW_DIS_RECIBOS, empCode };
};

export const setAllowDisRecibos = (allowDisRecibos) => {
  return { type: SET_ALLOW_DIS_RECIBOS, allowDisRecibos };
};

//BOLSA DE HORAS

export const GET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_CONFIGURATION";
export const SET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_CONFIGURATION";
export const CLEAR_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_CONFIGURATION";
export const PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const GET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_USERS_GROUP";
export const GET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP";
export const CLEAR_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_USERS_GROUP";
export const CLEAR_RH_BOLSA_HORAS_EXTRA_USERS_GROUP =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_EXTRA_USERS_GROUP";
export const SET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_USERS_GROUP";
export const SET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP";
export const GET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_BOLSA_HORAS";
  export const GET_RH_SALDO_HORAS_Extra =
  "rhadminConfiguration@GET_RH_SALDO_HORAS_Extra";
export const SET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_BOLSA_HORAS";
  export const SET_RH_SALDO_HORAS_Extra =
  "rhadminConfiguration@SET_RH_SALDO_HORAS_Extra";
export const CLEAR_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_BOLSA_HORAS";
  export const CLEAR_RH_SALDO_HORAS_Extra =
  "rhadminConfiguration@CLEAR_RH_SALDO_HORAS_Extra";
export const GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const GET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA =
  "rhadminConfiguration@GET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA";
export const SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const SET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA =
  "rhadminConfiguration@SET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA";
export const CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const CLEAR_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA =
  "rhadminConfiguration@CLEAR_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA";
export const GET_RHCURRENCY_CONFIGURATION =
  "rhadminConfiguration@GET_RHCURRENCY_CONFIGURATION";
export const PERSIST_RHCURRENCY_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RHCURRENCY_CONFIGURATION";
export const CLEAR_RHCURRENCY_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RHCURRENCY_CONFIGURATION";
export const SET_RHCURRENCY_CONFIGURATION =
  "rhadminConfiguration@SET_RHCURRENCY_CONFIGURATION";
export const SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION";

export const getRHCurrencyConfiguration = (organizationId) => {
  return { type: GET_RHCURRENCY_CONFIGURATION, organizationId };
};

export const persistRHCurrencyConfiguration = (configuration) => {
  return { type: PERSIST_RHCURRENCY_CONFIGURATION, configuration };
};
export const clearRHCurrencyConfiguration = () => {
  return { type: CLEAR_RHCURRENCY_CONFIGURATION };
};
export const setRHCurrencyConfiguration = (configuration) => {
  return { type: SET_RHCURRENCY_CONFIGURATION, configuration };
};

export const sucessesPersistRHCurrencyConfiguration = () => {
  return { type: SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION };
};

export const getRHBolsaHorasConfiguration = (organizationId) => {
  return { type: GET_RH_BOLSA_HORAS_CONFIGURATION, organizationId };
};

export const setRHBolsaHorasConfiguration = (configuration) => {
  return { type: SET_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const clearRHBolsaHorasConfiguration = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_CONFIGURATION };
};
export const getRHBolsaHorasUsersGroup = () => {
  return { type: GET_RH_BOLSA_HORAS_USERS_GROUP };
};

export const getRHHorasExtraUsersGroup = () => {
  return { type: GET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP };
};

export const clearRHBolsaHorasUsersGroup = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_USERS_GROUP };
};

export const clearRHHorasExtraUsersGroup = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_EXTRA_USERS_GROUP };
};

export const setRHBolsaHorasUsersGroup = (usersGroup) => {
  return { type: SET_RH_BOLSA_HORAS_USERS_GROUP, usersGroup };
};

export const setRHHorasExtraUsersGroup = (usersGroup) => {
  return { type: SET_RH_BOLSA_HORAS_EXTRA_USERS_GROUP, usersGroup };
}

export const persistRHBolsaHorasConfiguration = (configuration) => {
  return { type: PERSIST_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const sucessesPersistRHBolsaHorasConfiguration = () => {
  return { type: SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION };
};

export const getRHSaldoBolsaHoras = (usrId) => {
  return { type: GET_RH_SALDO_BOLSA_HORAS, usrId };
};

export const getRHSaldoHorasExtra = (payload) => {
  return { type: GET_RH_SALDO_HORAS_Extra, payload };
}

export const setRHSaldoBolsaHoras = (listSaldo) => {
  return { type: SET_RH_SALDO_BOLSA_HORAS, listSaldo };
};

export const setRHSaldoHorasExtra = (listSaldo) => {
  return { type: SET_RH_SALDO_HORAS_Extra, listSaldo };
}

export const clearRHSaldoBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_BOLSA_HORAS };
};

export const clearRHSaldoHorasExtra = () => {
  return { type: CLEAR_RH_SALDO_HORAS_Extra };
}

export const getRHSaldoInicialFinalBolsaHoras = (usrId, data) => {
  return { type: GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS, usrId, data };
};

export const setRHSaldoInicialFinalBolsaHoras = (listSaldoInicialFinal) => {
  return {
    type: SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
    listSaldoInicialFinal,
  };
};

export const setRHSaldoInicialFinalHorasExtra = (listSaldoInicialFinal) => {
  return {
    type: SET_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA,
    listSaldoInicialFinal,
  };
}

export const clearRHSaldoInicialFinalBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS };
};

export const clearRHSaldoInicialFinalHorasExtra = () => {
  return { type: CLEAR_RH_SALDO_INICIAL_FINAL_HORAS_EXTRA };
}