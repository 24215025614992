import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { approveDeleteRemoveMarcacaoFerias } from "~/store/ducks/portalrh/actionTypes";
import { withNamespaces } from "react-i18next";
import { setUpdateVacations } from "~/store/ducks/portalrh/actionTypes";

const PaperYearToRemove = ({
  events,
  setEvents,
  handleClosePopOver,
  eventToRemove,
  dateToCompare,
  setRemoveSomeDays,
  userNamePortalRH,
  t,
}) => {
  const [justOneDayMarcacao, setJustOneDayMarcacao] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (eventToRemove && eventToRemove.marcacaoPedidoEvent) {
      const { FEM_DATA_INICIO, FEM_DATA_FIM } =
        eventToRemove.marcacaoPedidoEvent;
      const sameDay = FEM_DATA_INICIO === FEM_DATA_FIM;
      setJustOneDayMarcacao(sameDay);
    }
  }, [eventToRemove]);

  const handleRemoveDay = () => {
    const payload = {
      ped_id: eventToRemove.idToSelect,
      state: 2,
      username: userNamePortalRH.username,
    };
    dispatch(approveDeleteRemoveMarcacaoFerias(payload));
    handleClosePopOver();
  };

  return (
    <>
      {eventToRemove !== null && (
        <>
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "space-between",
              padding: "0rem 0rem 1rem 0rem",
            }}
          >
            <h4 style={{ color: "#4C4B4B" }}>
              {t("portalrh.workDays.reuqestCanceled")}
            </h4>
            <button
              type="button"
              className="close"
              onClick={handleClosePopOver}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Stack>
          <>
            <p>
              {justOneDayMarcacao
                ? `${t("portalrh.workDays.justOneDayMarcacao.1")} ${moment(
                    dateToCompare
                  ).format("DD MMMM YYYY")}`
                : `${t("portalrh.workDays.justOneDayMarcacao.2")} ${moment(
                    eventToRemove.marcacaoPedidoEvent.FEM_DATA_INICIO
                  ).format("DD MMMM YYYY")} ${t(
                    "portalrh.workDays.justOneDayMarcacao.3"
                  )} ${moment(
                    eventToRemove.marcacaoPedidoEvent.FEM_DATA_FIM
                  ).format("DD MMMM YYYY")}`}
            </p>
            <Stack direction="row-reverse" alignItems="center" spacing={2}>
              {!confirmRemove ? (
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() => setConfirmRemove(true)}
                >
                  {t("portalrh.workDays.calcenRequest")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={handleRemoveDay}
                >
                  {t("portalrh.workDays.confirmCancelRequest")}
                </Button>
              )}
            </Stack>
          </>
        </>
      )}
    </>
  );
};

export default withNamespaces()(PaperYearToRemove);
