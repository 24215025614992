import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckInOutAuthentication from "./components/CheckInOutAuthentication";
import { Card } from "react-bootstrap";
import LoginLogo from "./components/LoginLogo";
import Error500 from "~/components/Exception/Error500";
import { applyColors } from "~/utils";
import {
  requestStylesheetConfiguration,
  requestConfiguration,
} from "~/pages/Admin/SystemSettings/actions";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as actions from "./actions";

const LoginTimeSheetRecord = () => {
  const dispatch = useDispatch();

  const [alertModal, setAlertModal] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severityAlert, setSeverityAlert] = React.useState("");

  const {
    configuration,
    isLoadingConfiguration,
    successRetrieveConfiguration,
    colorsStyleSheet,
  } = useSelector((state) => state.adminConfigReducer);

  const { checkInOutAuthMessage, checkInOutAuthAlertType } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    dispatch(requestConfiguration());
  }, []);

  useEffect(() => {
    if (Object.keys(colorsStyleSheet).length > 0) applyColors(colorsStyleSheet);
  }, [colorsStyleSheet]);

   useEffect(() => {
    if (checkInOutAuthMessage !== "") {
      if (checkInOutAuthMessage !== "NOOK") {
        setAlertMessage("DEU OK " + checkInOutAuthMessage);
        setSeverityAlert(checkInOutAuthAlertType);
      } else {
        setAlertMessage("Erro ao registrar ponto");
        setSeverityAlert(checkInOutAuthAlertType);
      }
  
      setAlertModal(true);
    }
  }, [checkInOutAuthMessage]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModal(false);
    dispatch(actions.clearCheckInOutAuthMessage());
  };

  return (
    <div className="overlay">
      <Snackbar
        open={alertModal}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severityAlert}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {!isLoadingConfiguration && successRetrieveConfiguration && (
        <>
          <Card className="card-login">
            <LoginLogo />
            <hr style={{ marginTop: "1rem" }} />
            <Card.Body>
              <CheckInOutAuthentication
                isStyled={
                  configuration.loginPage !== "default"
                    ? configuration.loginPage
                    : null
                }
              />
            </Card.Body>
          </Card>
        </>
      )}
      {!isLoadingConfiguration && !successRetrieveConfiguration && <Error500 />}
    </div>
  );
};

export default LoginTimeSheetRecord;
