import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Tabs, Tab } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import avatarDefault from "~/assets/img/notifications/empty_notifications.png";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import Messages from "~/components/Messages";
import ProtectedPage from "~/components/security/ProtectedPage";
import SpinnerNew from "~/components/SpinnerNew";
import WarningModal from "~/components/WarningModal/WarningModal";
import BudgetmgrConfiguration from "./BudgetmgrConfiguration";
import RHAdminConfiguration from "./RHAdminConfiguration";
import BolsaHorasConfiguration from "./BolsaHorasConfiguration";
import CurrencyConfiguration from "./CurrencyConfiguration";
import OpenAIConfiguration from "./OpenAIConfiguration";
import {
  clearDatacaptureConfiguration,
  deleteDatacaptureConfiguration,
  getDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";
import {
  clearOrganization,
  getOrganizationById,
  persistOrganization,
  removeOrganizationPicture,
  updateOrganization,
} from "~/store/ducks/organizations/actionTypes";
import { createBase64StringFromBinary } from "~/utils";
import { ROLE_ADMIN } from "~/utils/constants/roles";
import DatacaptureConfiguration from "./DatacaptureConfiguration";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const PersistOrganization = (props) => {
  const {
    datacaptureConfiguration: reduxConfig,
    successPersistDataCaptureConfiguration,
  } = useSelector((state) => state.datacaptureConfigurationReducer);

  const [datacaptureConfiguration, setDatacaptureConfiguration] = useState([]);
  const maxFileSize = 65536; //bytes
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [error, setError] = useState(false);
  const [docDigitizerKey, setDocDigitizerKey] = useState("");
  const [digestState, setDigestState] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [picture, setPicture] = useState(null);
  const [urlPicture, setUrlPicture] = useState(null);
  const [permitOpenNewTab, setPermitOpenNewTab] = useState(false);
  const [datacaptureModeState, setDatacaptureModeState] = useState("");
  const [datacaptureModeStateError, setDatacaptureModeStateError] =
    useState(false);

  /*DatacaptureConfiguration states*/
  const [docTypes, setDocTypes] = useState({});
  const [doclabels, setDocLabels] = useState({});
  const [arrMaxNumFiles, setMaxNumFiles] = useState({});
  const [flowIds, setFlowIds] = useState({});
  const [firstMetadataLabels, setFirstMetadataLabels] = useState({});
  const [firstMetadataValues, setFirstMetadataValues] = useState({});
  const [metadatas, setMetadatas] = useState({});
  const [deprecated, setDeprecated] = useState({});

  const { organization, isLoadingOrganization, isPersisting } = useSelector(
    (state) => state.organizationsReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props.match.params;
  const { t } = props;
  const isEditing = !!id;

  const radios = [
    { name: t("admin.organization.persist.docdigitizer"), value: "docD" },
    { name: t("admin.organization.persist.openai"), value: "openai" },
  ];

  useEffect(() => {
    reduxConfig && setDatacaptureConfiguration(reduxConfig);
  }, [reduxConfig, successPersistDataCaptureConfiguration]);

  useEffect(() => {
    if (id) {
      dispatch(getOrganizationById(id));
    }
    return () => {
      dispatch(clearOrganization());
      dispatch(clearDatacaptureConfiguration());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (Object.keys(organization).length !== 0) {
      setDescription(organization.description);
      setLabel(organization.label);
      setStartAt(organization.start_at && organization.start_at.slice(0, 10));
      setEndAt(organization.end_at && organization.end_at.slice(0, 10));
      setDocDigitizerKey(organization.doc_digitizerapikey);
      setDigestState(organization.emailDigest);
      setDatacaptureModeState(organization.datacaptureMode);
      setPermitOpenNewTab(organization.openNewTab);
      if (organization.picture !== null) {
        setPicture(organization.picture);
        const createdPicture = createBase64StringFromBinary(
          organization.picture
        );
        if (createdPicture) {
          setUrlPicture(createdPicture);
        }
      } else {
        setUrlPicture(avatarDefault);
      }
    } else {
      setUrlPicture(avatarDefault);
    }
    if (!organization?.picture) {
      setUrlPicture(avatarDefault);
    }
  }, [organization]);

  useEffect(() => {
    if (organization && organization.id)
      dispatch(getDatacaptureConfiguration(organization.id));
  }, [organization, successPersistDataCaptureConfiguration]);

  const handleDoctypes = (index, item) =>
    setDocTypes((values) => ({ ...values, [index]: item }));
  const handleLabels = (index, item) =>
    setDocLabels((values) => ({ ...values, [index]: item }));
  const handleNumberOfFiles = (index, item) =>
    setMaxNumFiles((values) => ({ ...values, [index]: item }));
  const handleFlowIds = (index, item) =>
    setFlowIds((values) => ({ ...values, [index]: item }));
  const handleFirstMetadataLabels = (index, item) =>
    setFirstMetadataLabels((values) => ({ ...values, [index]: item }));
  const handleFirstMetadataValues = (index, item) =>
    setFirstMetadataValues((values) => ({ ...values, [index]: item }));
  const handleMetadatas = (index, item) =>
    setMetadatas((values) => ({ ...values, [index]: item }));
  const handleDeprecated = (index, item) => {
    setDeprecated((values) => ({ ...values, [index]: item }));
  };

  useEffect(() => {
    setDocTypes({});
    setDocLabels({});
    setMaxNumFiles({});
    setFlowIds({});
    setFirstMetadataLabels({});
    setFirstMetadataValues({});
    setMetadatas({});
    setDeprecated({});
    if (datacaptureConfiguration && isEditing) {
      datacaptureConfiguration.forEach((config, index) => {
        config.documentType && handleDoctypes(index, config.documentType);
        config.label && handleLabels(index, config.label);
        config.deprecated && handleDeprecated(index, config.deprecated);
        config.numberOfFiles &&
          handleNumberOfFiles(index, config.numberOfFiles);
        config.flowId && handleFlowIds(index, config.flowId);
        if (config.metadata) {
          const firstElement = config.metadata[0];
          if (firstElement) {
            handleFirstMetadataLabels(index, firstElement.label);
            handleFirstMetadataValues(index, firstElement.value);
          }
          const filteredMetadata = config.metadata.filter(
            (item, index) => index !== 0 && item
          );
          filteredMetadata.length > 0 &&
            handleMetadatas(index, filteredMetadata);
        }
      });
    }
  }, [datacaptureConfiguration, successPersistDataCaptureConfiguration]);

  const selectId = (state) => {
    const adatacaptureItem = state.adminConfigReducer.puzzleItems.find(
      (item) => item?.application?.label === "adatacapture"
    );
    return (
      adatacaptureItem &&
      adatacaptureItem.application &&
      adatacaptureItem.application.id
    );
  };
  const selectADatacaptureId = useSelector(selectId);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      if (
        datacaptureModeState === "" ||
        datacaptureModeState === null ||
        datacaptureModeState === undefined
      ) {
        setDatacaptureModeStateError(true);
      }
    } else {
      if (
        datacaptureModeState === "" ||
        datacaptureModeState === null ||
        datacaptureModeState === undefined
      ) {
        setDatacaptureModeStateError(true);
        return;
      }

      persist();
    }
  };

  const checkError = (startDate, endDate) =>
    endDate > startDate ? false : true;

  function persist() {
    const payload = {
      description,
      label,
      start_at: startAt,
      end_at: endAt,
      active: 1,
      doc_digitizerapikey: docDigitizerKey,
      emailDigest: digestState,
      picture: picture,
      openNewTab: permitOpenNewTab,
      datacaptureMode: datacaptureModeState,
    };
    payload.id = isEditing ? parseInt(id) : null;

    const datacaptureConfigPayload = [];
    datacaptureConfiguration.forEach((config, index) => {
      const formMetadata = () => {
        let arr = [
          {
            label: firstMetadataLabels[index],
            value: firstMetadataValues[index],
            order: 1,
          },
        ];
        let count = 0;
        metadatas[index] &&
          metadatas[index].length > 0 &&
          metadatas[index].forEach((i, index) => {
            if (i.label !== "" && i.value !== "" && !i.parentId) {
              if (Array.isArray(i.children) && i.children.length > 0) {
                i.children = i.children.filter(
                  (c) => c.label !== "" && c.value !== ""
                );
                if (i.children.length > 0) {
                  i.order = count + 2;
                  arr.push(i);
                }
              } else {
                i.order = count + 2;
                arr.push(i);
              }
              count = count + 1;
            }
          });
        return arr;
      };
      datacaptureConfigPayload.push({
        id:
          datacaptureConfiguration && datacaptureConfiguration[index]
            ? datacaptureConfiguration[index].id
            : null,
        label: doclabels[index],
        documentType: docTypes[index],
        flowId: flowIds[index],
        numberOfFiles: arrMaxNumFiles[index] || 1,
        application_id: selectADatacaptureId,
        metadata: formMetadata(),
        deprecated: deprecated[index],
      });
    });

    isEditing
      ? dispatch(updateOrganization(payload, datacaptureConfigPayload))
      : dispatch(persistOrganization(payload, datacaptureConfigPayload));
  }

  const backToSearch = () => {
    history.push("/uniksystem/admin/organization");
  };

  //Transforms "Name" into "Description" and "Label"
  const nameToDescriptionAndLabel = (name) => {
    setDescription(name);
    const label = name.replace(/\s+/g, "-") && name.replace(/\s+/g, "-")?.toLowerCase();
    setLabel(label);
  };

  const handleSelectPicture = (e) => {
    const _file = e.target.files[0];
    if (_file.size < maxFileSize) {
      if (_file && _file["type"].split("/")[0] === "image") {
        setPicture(_file);
        setUrlPicture(URL.createObjectURL(_file));
      }
    } else {
      setWarningMessage(_file.name + ": " + t("general.largeFile"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const handleDeleteOrgPicture = () => {
    dispatch(removeOrganizationPicture(organization.id));
    setUrlPicture(avatarDefault);
    setPicture(null);
  };

  const addNewDocType = () => {
    setDatacaptureConfiguration((configs) => [
      ...configs,
      {
        documentType: "",
        label: "",
        numberOfFiles: 1,
        flowId: "",
        metadata: [],
        deprecated: false,
        focus: true,
      },
    ]);
  };

  const deleteDocType = (docIndex) => {
    const foundConfig = datacaptureConfiguration.find(
      (c, index) => index === docIndex
    );
    if (foundConfig) {
      if (foundConfig.id) {
        dispatch(deleteDatacaptureConfiguration(foundConfig.id));
      }
      //clear state
      const removePropHelper = (obj, index) => {
        delete obj[index];
        return obj;
      };
      setDatacaptureConfiguration((datacaptureConfiguration) =>
        datacaptureConfiguration.filter((c, index) => index !== docIndex)
      );
      setDocTypes((obj) => removePropHelper(obj, docIndex));
      setDocLabels((obj) => removePropHelper(obj, docIndex));
      setMaxNumFiles((obj) => removePropHelper(obj, docIndex));
      setFlowIds((obj) => removePropHelper(obj, docIndex));
      setFirstMetadataLabels((obj) => removePropHelper(obj, docIndex));
      setFirstMetadataValues((obj) => removePropHelper(obj, docIndex));
      setMetadatas((obj) => removePropHelper(obj, docIndex));
      setDeprecated((obj) => removePropHelper(obj, docIndex));
    }
  };

  const renderDatacaptureConfigurations = () => {
    return (
      datacaptureConfiguration &&
      datacaptureConfiguration.map((config, index) => {
        return (
          <DatacaptureConfiguration
            key={index}
            docIndex={index}
            docType={docTypes[index]}
            label={doclabels[index]}
            maxNumFiles={arrMaxNumFiles[index]}
            flowId={flowIds[index]}
            firstMetadataLabel={firstMetadataLabels[index]}
            firstMetadataValue={firstMetadataValues[index]}
            metadata={metadatas[index]}
            setDocType={handleDoctypes}
            setLabel={handleLabels}
            setMaxNumFiles={handleNumberOfFiles}
            setFlowId={handleFlowIds}
            setFirstMetadataLabel={handleFirstMetadataLabels}
            setFirstMetadataValue={handleFirstMetadataValues}
            setMetadata={handleMetadatas}
            deleteDocType={deleteDocType}
            deprecated={deprecated[index]}
            setDeprecated={handleDeprecated}
            focus={config.focus}
          />
        );
      })
    );
  };

  const handleChange = (event, newAlignment) => {
    setDatacaptureModeStateError(false);
    setDatacaptureModeState(newAlignment);
  };

  return (
    <ProtectedPage roles={[ROLE_ADMIN]}>
      <div style={{ displa: "flex", flexDirection: "column", width: "100%" }}>
        <div className="main-card-v2">
          <Card bsPrefix="card-flat">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <Card.Header className="justify-content-between">
                <h6>
                  <i className="icon-add mr-2" />
                  {`${isEditing ? t("general.edit") : t("general.add")}  ${t(
                    "admin.general.organization"
                  )}`}
                </h6>
              </Card.Header>

              <Card.Body
                style={isLoadingOrganization ? { display: "none" } : {}}
              >
                {showWarning && (
                  <Alert
                    variant="warning"
                    onClose={() => setShowWarning(false)}
                    dismissible
                  >
                    <strong>Alert: </strong>
                    {warningMessage}
                  </Alert>
                )}
                <Form.Row>
                  <Col lg="6">
                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="name">
                          <Form.Label>
                            {t("admin.organization.persist.name")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={description || ""}
                            disabled={isEditing ? true : false}
                            onChange={(e) =>
                              nameToDescriptionAndLabel(e.target.value)
                            }
                            required
                            autoComplete="off"
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(
                              "admin.organization.persist.requiredDescription"
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="docDigitizerKey">
                          <Form.Label>
                            {t("admin.organization.persist.docDigitizerKey")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={docDigitizerKey || ""}
                            onChange={(e) =>
                              setDocDigitizerKey(e.target.value.trim())
                            }
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("admin.organization.persist.requiredLabel")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="startAt">
                          <Form.Label>
                            {t("admin.organization.persist.startAt")}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={startAt || ""}
                            onChange={(e) => setStartAt(e.target.value)}
                            required
                            placeholder="yyyy-mm-dd"
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("admin.organization.persist.requiredStartAt")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col sm="12" md="8" lg="8">
                        <Form.Group controlId="endAt">
                          <Form.Label>
                            {t("admin.organization.persist.endAt")}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={endAt || ""}
                            onChange={(e) => {
                              setEndAt(e.target.value);
                              checkError(startAt, e.target.value)
                                ? setError(true)
                                : setError(false);
                            }}
                            required
                            min={startAt}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error
                              ? t("admin.organization.persist.errorEndAt")
                              : t("admin.organization.persist.requiredEndAt")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col sm="12" md="6" lg="5">
                        <Form.Label>
                          {t("admin.organization.persist.digest")}
                        </Form.Label>
                        <Form.Check
                          id="toggle-digest"
                          type="switch"
                          label=""
                          checked={digestState}
                          onChange={() => setDigestState(!digestState)}
                        />
                      </Col>
                      {/* <Col sm="12" md="6" lg="5">
                        <Form.Label>
                          {t("admin.organization.persist.permitOpenNewTab")}
                        </Form.Label>
                        <Form.Check
                          id="toggle-digest1"
                          type="switch"
                          label=""
                          checked={permitOpenNewTab}
                          onChange={() => setPermitOpenNewTab(!permitOpenNewTab)}
                        />
                      </Col> */}
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Label style={{ width: "100%" }}>
                          {t("admin.organization.persist.datacaptureMode")}
                        </Form.Label>
                        <ToggleButtonGroup
                          value={datacaptureModeState}
                          exclusive
                          onChange={handleChange}
                          aria-label="text alignment"
                        >
                          {radios.map((radio, idx) => (
                            <ToggleButton
                              key={idx}
                              id={`radio-${idx}`}
                              className={`toogle-button ${idx === 0 ? "rounded-left" : ""
                                } ${idx === radios.length - 1 ? "rounded-right" : ""
                                }`}
                              name="radio"
                              value={radio.value}
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                        <Form.Control.Feedback
                          style={{
                            display: datacaptureModeStateError
                              ? "block"
                              : "none",
                          }}
                          type="invalid"
                        >
                          {t("admin.organization.persist.selectOption")}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Col>

                  <Form.Row>
                    <Col sm="12" md="6" lg="6">
                      <Form.Group controlId="validationCustomPhoto">
                        <Form.Label>
                          {t("admin.organization.persist.picture")}
                        </Form.Label>
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => handleSelectPicture(e)}
                            />
                            <label htmlFor="imageUpload">
                              <i className="icon-add add-picture" />
                            </label>
                          </div>
                          {picture && (
                            <div className="avatar-delete">
                              <i
                                className="icon-trash add-picture"
                                onClick={() => {
                                  setShowDeleteModal(true);
                                }}
                              />
                            </div>
                          )}

                          <div className="avatar-preview-fit-inside">
                            <div
                              style={{
                                backgroundImage: "url(" + urlPicture + ")",
                              }}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Text className="text-muted">
                        {t("general.maxFileSize") +
                          " " +
                          Math.round(maxFileSize / 1024) +
                          "kb"}
                      </Form.Text>
                    </Col>
                  </Form.Row>
                </Form.Row>
                <hr />

                <Tabs
                  defaultActiveKey="Datacapture"
                  className="custom-tabs"
                  id="adminSettingsTab"
                >
                  <Tab eventKey="Datacapture" title="Datacapture">
                    <Form.Row style={{ margin: "20px" }}>
                      <Col sm="12" md="12" lg="12">
                        <Form.Group controlId="validationCustom">
                          <Form.Label style={{ marginTop: "20px" }}>
                            <h5>
                              {t(
                                "admin.organization.settings.adatacaptureConfiguration"
                              )}
                            </h5>
                          </Form.Label>
                          <br />
                          <br />
                          <ButtonAdd
                            text="New document type"
                            onClick={addNewDocType}
                          />
                          {renderDatacaptureConfigurations()}
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Tab>
                  <Tab eventKey="Budget Management" title="Budget Management">
                    {organization && organization.id ? (
                      <BudgetmgrConfiguration
                        organizationId={organization.id}
                      />
                    ) : (
                      <Form.Label style={{ margin: "20px" }}>
                        To add Budget Management configuration save organization
                        first.{" "}
                      </Form.Label>
                    )}
                  </Tab>
                  <Tab
                    eventKey="RH Admin Configuration"
                    title={t(
                      "admin.organization.settings.rhadminConfiguration"
                    )}
                  >
                    {organization && organization.id ? (
                      <RHAdminConfiguration organizationId={organization.id} />
                    ) : (
                      <Form.Label style={{ margin: "20px" }}>
                        To add RH Admin configuration save organization first.{" "}
                      </Form.Label>
                    )}
                  </Tab>
                  <Tab
                    eventKey="RH Scheduler"
                    title={t(
                      "admin.organization.settings.rhbolsaHorasConfiguration"
                    )}
                  >
                    {organization && organization.id ? (
                      <BolsaHorasConfiguration
                        organizationId={organization.id}
                      />
                    ) : (
                      <Form.Label style={{ margin: "20px" }}>
                        To add Bolsa de Horas configuration save organization
                        first.{" "}
                      </Form.Label>
                    )}
                  </Tab>
                  <Tab
                    eventKey="Open AI Configuration"
                    title="Open AI Configuration"
                  >
                    {organization && organization.id ? (
                      <OpenAIConfiguration organizationId={organization.id} />
                    ) : (
                      <Form.Label style={{ margin: "20px" }}>
                        To add Open AI configuration save organization first.{" "}
                      </Form.Label>
                    )}
                  </Tab>
                  <Tab
                    eventKey="Currency Configuration"
                    title={t(
                      "admin.organization.settings.rhCurrencyConfiguration"
                    )}
                  >
                    {organization && organization.id ? (
                      <CurrencyConfiguration organizationId={organization.id} />
                    ) : (
                      <Form.Label style={{ margin: "20px" }}>
                        To add Currency Parameterization save organization
                        first.{" "}
                      </Form.Label>
                    )}
                  </Tab>
                </Tabs>
              </Card.Body>

              <Card.Footer>
                <Button variant="danger" onClick={backToSearch}>
                  {t("general.back")}
                </Button>
                <Button
                  className="card-button"
                  type="submit"
                  style={{ marginLeft: "10px", marginRight: "55px" }}
                >
                  {t("general.save")}
                </Button>
              </Card.Footer>

            </Form>
          </Card>
          <SpinnerNew open={isPersisting || isLoadingOrganization} />
          <Messages afterHideSuccess={backToSearch} />
          <WarningModal
            showDeleteModal={showDeleteModal}
            handleDelete={handleDeleteOrgPicture}
            handleCloseDeleteModal={setShowDeleteModal}
            message={t("admin.organization.persist.warningPicture")}
          />
        </div>
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(PersistOrganization);
