import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button, Alert, Form } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import Messages from "~/components/Messages";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import pagination from "~/components/pagination/currentSizePerPage/10";
import RuleModal from "./RuleModal";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { getRules, findUsersByRule, deleteRule } from "../actions";

const RulesTable = ({ rules, t, persistRule }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoadingRules, isLoadingRulesByID, users, isDeleting } = useSelector(
    (state) => state.ruleReducer
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(undefined);
  const [showRules, setShowRules] = useState(false);

  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className="icon-trash table-action-icon"
        onClick={() => openModal(row)}
      />
      <i
        className="icon-edit-user table-action-icon"
        onClick={() => history.push(`/uniksystem/portalrh/administration/rulesRemoteWork/update/${row.id}`)}
      />
      <i
        className="icon-hr table-action-icon"
        onClick={() => openModalRules(row)}
      />
    </>
  );

  const openModal = (row) => {
    setSelectedRule(row);
    dispatch(findUsersByRule(row.id));
    setShowDeleteModal(true);
  };

  const openModalRules = (row) => {
    setSelectedRule(row);
    setShowRules(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleCloseRules = () => {
    setSelectedRule(undefined);
    setShowRules(false);
  };

  const handleDeleteRule = () => {
    dispatch(deleteRule(selectedRule.id));
    handleClose();
  };

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
    },
    {
      dataField: "nome",
      text: t("admin.rule.list.nome"),
      sort: true,
    },
    {
      dataField: "qtDias",
      text: t("admin.rule.list.qtDias"),
      sort: true,
    },
    {
      dataField: "accao",
      text: t("admin.rule.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
  ];

  return (
    <div className="custom-table">
      <SpinnerNew open={isLoadingRules || isDeleting || isLoadingRulesByID} />
      <ToolkitProvider keyField="id" data={rules} columns={columns} search>
        {(props) => (
          <div>
            <div style={{ paddingBottom: "20px", display: "flex" }}>
              <SearchBar
                {...props.searchProps}
                placeholder={t("admin.rule.list.searchInput")}
              />
              <div style={{ paddingLeft: "30px" }}>
                <ButtonAdd text={t("general.add")} onClick={persistRule} />
              </div>
            </div>

            {!isLoadingRules && (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t("admin.rule.list.emptyTable")}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
      <Messages afterHideSuccess={() => dispatch(getRules())} />
      <RuleModal
        showRules={showRules}
        handleCloseRules={handleCloseRules}
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
      />
      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!isLoadingRulesByID && users.length > 0 && (
            <Alert variant="warning">
              {t("admin.role.delete.existsUsers")}
            </Alert>
          )}
          {!isLoadingRulesByID &&
            `${t("general.remove")}: ${selectedRule?.nome}?`}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => handleDeleteRule()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default withNamespaces()(RulesTable);
