import React from "react";
import { withNamespaces } from "react-i18next";

const MobileTaskCard = ({ row, cell, rowIndex, formatExtraData, t }) => {
  const { headers, formatters } = formatExtraData;
  const renderValue = (key) => {
    switch (key) {
      case "initials":
        return formatters.getColumnFormatter(cell, row, rowIndex, {
          value: "initials",
          app: formatters.app,
          translateText: formatters.translateText,
        });
      case "pendingPeriod":
        return formatters.getColumnFormatter(cell, row, rowIndex, {
          value: "pendingPeriod",
        });
      case "delegated":
        return formatters.buildIconDelegated(row);
        case "description":
          return formatters.translateText(row.description, true)
      case "annotation":
        return formatters.buildAnnotationColumn(
          cell,
          row,
          rowIndex,
          formatExtraData
        );
      case "actions":
        return formatters.buildActionsColumn(
          cell,
          row,
          rowIndex,
          formatExtraData
        );
      default:
        return row[key];
    }
  };
  return (
    <div
      className="card_container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        marginBottom: "1rem",
        backgroundColor: "whitesmoke",
        borderRadius: "12px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Column - Actions */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "1rem",
        }}
      >
        {renderValue("actions")}
      </div>

      {/* Middle Column - Dynamic Content */}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div style={{alignSelf: "self-start"}}>
        {renderValue("initials")}
        </div>
        {headers
          .filter(
            (header) =>
              header.dataField !== "actions" &&
              header.dataField !== "annotation" &&
              header.dataField !== "flowname" &&
              header.dataField !== "initials" &&
              header.dataField !== "keyField" &&
              header.dataField !== "PROCESS_STATUS" &&
              header.dataField !== "deadline" &&
              header.dataField !== "delegated" &&
              header.dataField !== "created"
          )
          .map((header, index) => (
            <div
              key={index}
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#888",
                marginBottom: "0.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={renderValue(header.dataField)}
            >
              <b>{header.text}:</b> <span>{renderValue(header.dataField)}</span>
            </div>
          ))}
      </div>
      {/* Right Column - Status and Amount */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          flexShrink: 0,
          gap: "1rem",
          marginLeft: "2rem",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#f5a623",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={renderValue("annotation")}
        >
          {renderValue("annotation")}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(MobileTaskCard);
