import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { some } from "lodash";
import "~/pages/Easypay/assets/css/styles.css";
import iconWarning from "~pages/Easypay/assets/img/icons/icon-warning.png";
//import iconWarning from "~/pages/Easypay/assets/img/icons/icon-warning.png";
import easypayLogo from "~/pages/Easypay/assets/img/logos/easypay-logotipo.png";
import unikLogo from "~/assets/img/logos/unik-logo.png";
import "~/pages/Easypay/assets/scss/_backofficeEasypay.scss";
import BackOfficeInput from "~/pages/Easypay/components/BackOfficeInput";
import Header from "~/pages/Easypay/components/Header";
import Link from "~/pages/Easypay/components/Link";
import Table from "~/pages/Easypay/components/Table";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import Tag from "~/pages/Easypay/components/Tag";
import SpinnerNew from "~/components/SpinnerNew";
import { getAllUsers ,setAllUsers,setPrecario} from "~/store/ducks/onboarding/actionTypes";
const devLogConsole = require("~/utils/devLog");
const BackofficeOrganization = ({ t, application }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { users, isLoadingGetAllUsers } = useSelector(
    (state) => state.onboardingReducer
  );
  const { user } = useSelector((state) => state.globalReducer);

  var isFirstTime = true;

  useEffect(() => {
    dispatch(setPrecario(null))
    dispatch(setAllUsers([]));
    if (isFirstTime) {
      window.scrollTo(0, 0);
      isFirstTime = false;
    }
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    users.length > 0 && setFilteredList(filterUsers(users));
  }, [users]);

  const filterUsers = (list) =>
    list.filter((user) => user?.account?.lead === 0);

  const [filteredList, setFilteredList] = useState([]);

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  const idFormatterOnboarding = (cell, row, rowIndex, formatExtraData) => {
    if (
      (row.client.departamento === "Comercial" &&
        some(user.roles, { label: "ONBOARDINGComercial" })) ||
      (row.client.departamento === "Compliance" &&
        some(user.roles, { label: "ONBOARDINGCompliance" })) ||
      some(user.roles, { label: "ADMIN" }) ||
      some(user.roles, { label: "ONBOARDINGUser" })
    ) {
      return (
        <Link
          text={cell}
          onClick={() =>
            history.push({
              pathname: `/uniksystem/onboarding/backoffic-organization/onboarding/${cell}`,
            })
          }
        />
      );
    } else return cell;
  };

  /* const statusFormatter = () => {
    return <img src={iconWarning} />;
  }; */

  const resultFormatter = (cell, row) => {
    return (
      <Tag
        text={cell ? Math.round(cell * 20) : 0}
        variant="easypay-tag-label"
        type={`${
          row?.account?.mediaRiskScoring <= 3.75 && cell
            ? "easypay-approved"
            : "easypay-evaluation"
        } `}
      />
    );
  };

  const calcDays = (cell) => {
    if (!cell) {
      return "";
    }
    const modifiedDate = new Date(cell);
    const currentDate = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = currentDate.getTime() - modifiedDate.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const stageFormatter = (cell) => {
    return cell;
  };

  const capitalizeText = (cell) =>
    cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : "";

  const statusFormatter = (cell) => {
    const index = filteredList.findIndex((object) => {
      return object.account.pid === cell;
    });
    const currentUser = filteredList[index];
if (currentUser?.client?.nome === "TESTE ETAPAS E SINAL") {
      devLogConsole(currentUser);
    }
    if (currentUser?.account?.idLead === -1) {
      return "";
    }
    if (currentUser?.account?.aprovado != 1) {
      return <img src={iconWarning} />;
    } else if (currentUser.account.estadoLead != "PDA_REC") {
      return <img src={iconWarning} />;
    } else if (currentUser?.client?.hasDocRejected != 0) {
      return <img src={iconWarning} />;
    }
    return "";
  };

  const columns = [
    {
      dataField: "client.pid",
      text: "",
      formatter: statusFormatter,
      headerStyle: { width: "40px" },
    },
    {
      dataField: "client.pid",
      text: t("bpm.onboarding.backoffice.administration.tableIdUser"),
      sort: true,
      formatter: idFormatterOnboarding,
      sortValue: (cell) => parseInt(cell),
      headerStyle: { width: "110px" },
    },
    {
      dataField: "client.nome",
      text: t("bpm.onboarding.backoffice.administration.tableName"),
      sort: true,
    },
    {
      dataField: "onboardingEntity.tipoEntidade",
      text: t("bpm.onboarding.backoffice.administration.tableEntity"),
      sort: false,

      formatter: capitalizeText,
    },
    {
      dataField: "client.parceiro",
      text: t("bpm.onboarding.backoffice.administration.tablePartner"),
      sort: false,
    },
    {
      dataField: "account.createdDate",
      text: t("bpm.onboarding.backoffice.administration.tableCreationDate"),
      sort: true,
      formatter: dateFormatter,
      headerStyle: { whiteSpace: "normal" },
    },
    {
      dataField: "account.modifiedDate",
      text: t("bpm.onboarding.backoffice.administration.tableDays"),
      sort: false,

      formatter: calcDays,
    },
    {
      dataField: "account.createdBy",
      text: t("bpm.onboarding.backoffice.administration.tableCreatedBy"),
      sort: false,
      formatter: capitalizeText,
    },
    {
      dataField: "client.departamento",
      text: t("bpm.onboarding.backoffice.administration.tableDepartment"),
      sort: false,
    },
    {
      dataField: "account.estadoLead",
      text: t("bpm.onboarding.backoffice.administration.tableStage"),
      sort: false,
      formatter: stageFormatter,
    },
  ];

  if (!some(user.roles, { label: "ONBOARDINGComercial" })) {
    columns.push({
      dataField: "account.mediaRiskScoring",
      text: t("bpm.onboarding.backoffice.administration.tableResult"),
      sort: false,
      formatter: resultFormatter,
    });
  }

  const [tableSize, setTableSize] = useState(10);

  const [filters, setFilters] = useState([
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
    { type: "", value: "" },
  ]);

  //filters all the data using the filters, this runs anytime the filter is changed and has a different value from previous state
  const filterData = (data, filter) => {
    const validFilters = filter.filter((e) => e.value !== "");
    if (validFilters.length > 0) {
      const filteredData = data.filter((user) => {
        //Looping in each user for all filters. returns false if at least one filter value is not found for better performance
        for (let i = 0; i < validFilters.length; i++) {
          if (
            validFilters[i].value.length === 2 &&
            typeof validFilters[i].value === "object"
          ) {
            //Date input takes multiple values
            const userDate = new Date(
              user[validFilters[i].type[0]?.split(".")[0]][
                validFilters[i].type[0]?.split(".")[1]
              ]
            ).getTime();
            const startDate = validFilters[i].value[0];
            const endDate = validFilters[i].value[1];
            if (userDate) {
              if (startDate <= userDate && userDate <= endDate) {
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else if (validFilters[i].type.length === 3) {
            //Search input takes multiple types
            const userParam1 =
              user[validFilters[i].type[0]?.split(".")[0]][
                validFilters[i].type[0]?.split(".")[1]
              ]?.toString();
            const userParam2 =
              user[validFilters[i].type[1]?.split(".")[0]][
                validFilters[i].type[1]?.split(".")[1]
              ]?.toString();
            const userParam3 =
              user[validFilters[i].type[2]?.split(".")[0]][
                validFilters[i].type[2]?.split(".")[1]
              ]?.toString();
            if (
              !userParam1
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam2
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase()) &&
              !userParam3
                ?.toUpperCase()
                ?.includes(validFilters[i].value.toUpperCase())
            ) {
              return false;
            }
          } else {
            if (
              user[validFilters[i].type[0]?.split(".")[0]][
                validFilters[i].type[0]?.split(".")[1]
              ]
                ?.toString()
                .toUpperCase() !==
              validFilters[i].value.toString().toUpperCase()
            ) {
              return false; //case where user does not have at least one filter value (going to return false for data.filter function)
            }
          }
          if (i === validFilters.length - 1) {
            return true;
          }
        }
        return false;
      });
      return filteredData;
    } else {
      return data; //in case there's no filter applied returns data unfiltered
    }
  };

  //these are called when an input from /BackOfficeInput is changed, they alter the filters
  const setFiltersHandler = (filter, filterNum) => {
    if (filters[filterNum]?.value !== filter.value) {
      setFilters((prev) => {
        const newFilter = [...prev];
        newFilter[filterNum] = filter;
        return newFilter;
      });
    }
  };

  return (
    <div style={{ overflowY: "scroll", width: "100%", minHeight: "100%" }}>
      <Header
        redirect={true}
        logo={
          !application
            ? ""
            : application?.applicationName === "easypay"
            ? easypayLogo
            : unikLogo
        }
        height={application?.applicationName === "easypay" ? "30px" : "40px"}
        exit={"#/uniksystem/onboarding/integrations"}
        hideExtraLinks={true}
      />
      <div className="easypay-backoffice-content easypay-backoffice-tab">
        <TabSeparatorWrapper defaultActive={"tab2"}>
          <TabSeparator
            text="Leads"
            name="tab1"
            redirect={"/#/uniksystem/onboarding/backoffic-organization/leads"}
          />
          <TabSeparator text="Onboarding" name="tab2">
            <BackOfficeInput
              firstDropdownLabel={t(
                "bpm.onboarding.backoffice.administration.tableEntity"
              )}
              firstDropdownFilter={"onboardingEntity.tipoEntidade"}
              filterDate={"account.createdDate"}
              filterCreatedBy={"account.createdBy"}
              filterDepartment={"account.departamento"}
              filterPhase={"account.estadoLead"}
              filterResult={"client.resultadoScoringQuestionario"}
              filterColor={"account.cor"}
              tableData={filteredList}
              onSetFilters={setFiltersHandler}
              savedFilters={filters}
              key={"onboarding-input"}
              disabled={isLoadingGetAllUsers && Object.keys(users).length === 0}
            />
            <Table
              sort={{ dataField: "client.pid", order: "desc" }}
              docs={filterData(filteredList, filters)}
              columns={columns}
              tableSize={tableSize}
              setTableSize={setTableSize}
              key="onboarding-table"
            />
          </TabSeparator>
          <TabSeparator
            text="Documentos"
            name="tab3"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/documents"
            }
          />
          <TabSeparator
            text="Preçário"
            name="tab4"
            redirect={
              "/#/uniksystem/onboarding/backoffic-organization/precario"
            }
          ></TabSeparator>
        </TabSeparatorWrapper>
        <SpinnerNew open={Object.keys(users).length === 0 && isLoadingGetAllUsers}
        />
      </div>
    </div>
  );
};
export default withNamespaces()(BackofficeOrganization);
