import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import { setGenericRequestState } from "~/store/ducks/portalrh/actionTypes";
import { getOpenAIConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { Card, Row, Col, Button as ButtonBoot } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  saveDocumentExpenses,
  generatePromptFromPrompts,
} from "~/store/ducks/openai/actionTypes";
import ReactMarkdown from "react-markdown";
import SpinnerNew from "~/components/SpinnerNew";
import ApplicationMenu from "~/containers/Menus_v2/components/ApplicationMenu";
import ProtectedPage from "~/components/security/ProtectedPage";
import ContentContainer from "~/containers/ContentContainer";
import { SYS_ADMIN } from "~/utils/constants";

const Prompts = ({ isMongoOn, styleSheet, translations, t }) => {
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const {
    documentExpensesResult,
    isLoadingSaveDocumentExpenses,
    generatePromptResult,
  } = useSelector((state) => state.openaiReducer);

  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);
  const [promptValue, setPromptValue] = useState("");
  const [fileSelected, setFileSelected] = useState([]);
  const [resultPrompt, setResultPrompt] = useState({});
  const [text, setText] = useState("");
  const [docType, setDocType] = React.useState("");

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOpenAIConfiguration(user?.organization?.id));
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleClearAll();
    setText(`
    Emitting company VAT: emittingVat;
    Emitting company Name: emittingCompany;
    Customer VAT number: customerVat;
    Total amount: totalAmount;
    Date of the invoice: invoiceDate;
  `);
  }, []);

  useEffect(() => {
    if (Object.keys(documentExpensesResult).length > 0) {
      const resultPromptMarkdown =
        "```json\n" +
        JSON.stringify(documentExpensesResult.metadata, null, 2) +
        "\n```";

      setResultPrompt(resultPromptMarkdown);
    }
  }, [documentExpensesResult]);

  useEffect(() => {
    if (generatePromptResult !== "") setPromptValue(generatePromptResult);
  }, [generatePromptResult]);

  // const handleAdd = () => {
  //   if (!key || !value) return;

  //   const itemExists = items.some(
  //     (item) => item.key === key && item.value === value
  //   );

  //   if (!itemExists) {
  //     setItems([...items, { key, value }]);
  //     setKey("");
  //     setValue("");
  //   }
  // };
  const handleAdd = () => {
    const lines = text.split(";");
    const newItems = [];

    for (let line of lines) {
      const parts = line.split(":");
      if (parts.length !== 2) continue;

      const key = parts[0].trim();
      const value = parts[1].trim();

      if (value === "") continue;

      const itemExists = items.some((item) => item.key === key);

      if (!itemExists) {
        newItems.push({ key, value });
      }
    }

    if (newItems.length > 0) {
      setItems((items) => [...items, ...newItems]);
    }
  };

  const handleChange = (event) => {
    setDocType(event.target.value);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleClearAll = () => {
    handleRemoveItems();
    setResultPrompt({});
    handleRemoveFileClick();
  };

  const handleRemoveItems = () => {
    setItems([]);
    setPromptValue("");
    setText("");
    setDocType("");
  };

  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  const handlePromptButtonClick = () => {
    if (items.length < 1) return;
    dispatch(generatePromptFromPrompts(items));
  };

  const handleFileChange = (e) => {
    setFileSelected([e.target.files[0]]);
  };

  const handleRemoveFileClick = () => {
    setFileSelected([]);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const handleGetPromptResult = () => {
    if (
      promptValue === "" ||
      fileSelected.length < 1 ||
      items.length < 1 ||
      docType === ""
    )
      return;
    const docList = [];
    fileSelected.forEach((document, index, array) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        docList.push({
          file: e.target.result,
        });
        if (index === array.length - 1) {
          if (docList.length > 0) {
            const base64String = docList[0].file.split("base64,")[1];
            const payload = {
              userId: user.id,
              organizationId: user.organization.id,
              base64: base64String,
              stringPrompt: promptValue,
              docType: docType,
            };
            dispatch(saveDocumentExpenses(payload));
          }
        }
      };
      reader.readAsDataURL(document);
    });
  };

  return (
    <>
      <ApplicationMenu
        applicationName={SYS_ADMIN}
        showMenu={true}
        translations={translations}
      />
      <ProtectedPage roles={["ADMIN"]}>
        <ContentContainer>
          <SpinnerNew open={isLoadingSaveDocumentExpenses} />
          <div className="main-card-v2">
            <Card bsPrefix="card-flat">
              <Card.Header className="justify-content-between">
                <h6>Prompts</h6>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg="6" md="6" sx="12">
                    <Row>
                      <Col lg="6" md="6" sx="12">
                        <h6
                          style={{
                            marginBottom: "1rem",
                            fontSize: "0.9em",
                            placeContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Campos para Prompt
                        </h6>
                        {/* <Stack
                      spacing={{ xs: 1, sm: 2 }}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <TextField
                        label="Chave"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                      />
                      <TextField
                        label="Valor"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <Button
                        onClick={handleAdd}
                        startIcon={<AddCircleOutlineRoundedIcon />}
                        sx={{ marginTop: "1rem" }}
                      >
                        Adicionar
                      </Button>
                    </Stack> */}
                        <Stack
                          spacing={{ xs: 1, sm: 2 }}
                          useFlexGap
                          flexWrap="wrap"
                        >
                          <div
                            style={{
                              border: "1px solid",
                              borderRadius: "23px",
                              padding: "1rem",
                            }}
                          >
                            <TextField
                              multiline
                              placeholder="Chave: Valor;"
                              rows={10}
                              variant="outlined"
                              fullWidth
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                            />
                          </div>
                          <Button
                            onClick={handleAdd}
                            startIcon={<AddCircleOutlineRoundedIcon />}
                            sx={{ marginTop: "1rem" }}
                          >
                            Adicionar
                          </Button>
                        </Stack>
                      </Col>
                      <Col lg="6" md="6" sx="12">
                        <h6
                          style={{
                            marginBottom: "1rem",
                            fontSize: "0.9em",
                            placeContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          Lista para Prompt
                          {items.length > 0 && (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={handleRemoveItems}
                              style={{ padding: 0 }}
                            >
                              <DeleteForeverRoundedIcon />
                            </IconButton>
                          )}
                        </h6>
                        <div
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          className="scrollBar-visible"
                        >
                          <List>
                            {items.map((item, index) => (
                              <React.Fragment key={index}>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <>
                                        {item.key}{" "}
                                        <em
                                          style={{
                                            fontSize: "0.8em",
                                            color: "gray",
                                          }}
                                        >
                                          (Chave)
                                        </em>
                                      </>
                                    }
                                    secondary={
                                      <>
                                        {item.value}{" "}
                                        <em
                                          style={{
                                            fontSize: "0.9em",
                                            color: "gray",
                                          }}
                                        >
                                          (Valor)
                                        </em>
                                      </>
                                    }
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => handleDelete(index)}
                                    >
                                      <RemoveCircleOutlineRoundedIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="middle" component="li" />
                              </React.Fragment>
                            ))}
                          </List>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" md="6" sx="12">
                    <Row>
                      <h6
                        style={{
                          marginBottom: "1rem",
                          fontSize: "0.9em",
                          placeContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Prompt
                      </h6>
                      <Col>
                        <div
                          style={{
                            border: "1px solid",
                            borderRadius: "23px",
                            padding: "1rem",
                          }}
                        >
                          <TextField
                            multiline
                            placeholder="Prompt..."
                            rows={10}
                            variant="outlined"
                            fullWidth
                            value={promptValue}
                            onChange={(e) => setPromptValue(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "3rem", justifyContent: "right" }}>
                      <Col lg="12" md="12" sx="12">
                        {fileSelected.length > 0 && (
                          <Row style={{ textAlign: "center" }}>
                            <Col lg="12" md="12" sx="12">
                              <p>
                                <strong>File:</strong> {fileSelected[0].name}
                              </p>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginBottom: "2rem" }}>
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            style={{ textAlignLast: "right" }}
                          >
                            <FormControl
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Doc Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={docType}
                                onChange={handleChange}
                                label="DocType"
                                disabled={fileSelected.length === 0}
                              >
                                <MenuItem
                                  value=""
                                  style={{ padding: "8px", display: "block" }}
                                >
                                  <em>None</em>
                                </MenuItem>
                                {openAIConfiguration.datasets &&
                                  openAIConfiguration.datasets.map(
                                    (dataset, index) => (
                                      <MenuItem
                                        key={index}
                                        value={dataset.documentType}
                                        style={{
                                          padding: "8px",
                                          display: "block",
                                        }}
                                      >
                                        {dataset.documentType}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" md="12" sx="12">
                            <Stack
                              spacing={{ xs: 1, sm: 2 }}
                              direction="row"
                              justifyContent="flex-end"
                            >
                              <ButtonBoot
                                className="card-button-orange active"
                                onClick={handleClearAll}
                              >
                                Clear All
                              </ButtonBoot>
                              {fileSelected.length > 0 ? (
                                <>
                                  <ButtonBoot
                                    className="card-button-redStyle"
                                    onClick={handleRemoveFileClick}
                                  >
                                    Remove File
                                  </ButtonBoot>
                                </>
                              ) : (
                                <>
                                  <ButtonBoot
                                    className="card-button"
                                    onClick={handleFileButtonClick}
                                  >
                                    Select File
                                  </ButtonBoot>
                                  <input
                                    type="file"
                                    multiple={false}
                                    style={{ display: "none" }}
                                    ref={fileInput}
                                    onChange={handleFileChange}
                                    accept=".pdf, .png,.jpg,.jpeg"
                                  />
                                </>
                              )}
                              <ButtonBoot
                                className="card-button"
                                onClick={handlePromptButtonClick}
                              >
                                Generate Prompt
                              </ButtonBoot>
                              <ButtonBoot
                                className={`card-button-green ${
                                  promptValue !== "" &&
                                  docType !== "" &&
                                  fileSelected.length > 0 &&
                                  items.length > 0
                                    ? "active"
                                    : ""
                                }`}
                                onClick={handleGetPromptResult}
                              >
                                Extract Data
                              </ButtonBoot>
                            </Stack>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {resultPrompt && Object.keys(resultPrompt).length > 0 && (
                  <Row style={{ marginTop: "3rem" }}>
                    <Col>
                      <h6
                        style={{
                          marginBottom: "1rem",
                          fontSize: "0.9em",
                          placeContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Resultado
                      </h6>
                      <div
                        style={{
                          border: "1px solid",
                          borderRadius: "23px",
                          padding: "1rem",
                        }}
                      >
                        <ReactMarkdown>{resultPrompt}</ReactMarkdown>
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </div>
        </ContentContainer>
      </ProtectedPage>
    </>
  );
};

export default withNamespaces()(Prompts);
