import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { withNamespaces } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import UserTable from "./UserTable";

import SpinnerNew from "~/components/SpinnerNew";
import { Modal, Button, Form, Col } from "react-bootstrap";

import { getRuleInformationById, updateUnikUsersRule } from "../actions";

const RuleModal = (props) => {
  const { t, showRules, handleCloseRules, selectedRule, setSelectedRule } =
    props;

  const { ruleById, isLoadingRulesByID } = useSelector(
    (state) => state.ruleReducer
  );

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [unselectedUsers, setUnselectedUsers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRule) dispatch(getRuleInformationById(selectedRule.id));
  }, [selectedRule]);

  useEffect(() => {
    if (!isEmpty(ruleById)) {
      setSelectedUsers(ruleById.selecionados);
      setUnselectedUsers(ruleById.disponiveis);
    }
  }, [ruleById, dispatch]);

  const addUser = (user) => {
    const index = unselectedUsers.indexOf(user);
    const unselectedUsers_ = update(unselectedUsers, { $splice: [[index, 1]] });
    const selectedUsers_ = update(selectedUsers, { $push: [user] });
    setUnselectedUsers(unselectedUsers_);
    setSelectedUsers(selectedUsers_);
  };

  const removeUser = (user) => {
    const index = selectedUsers.indexOf(user);
    const selectedUsers_ = update(selectedUsers, { $splice: [[index, 1]] });
    const unselectedUsers_ = update(unselectedUsers, { $push: [user] });
    setUnselectedUsers(unselectedUsers_);
    setSelectedUsers(selectedUsers_);
  };

  const handleSave = () => {
    const ruleUsers = {
      ruleId: selectedRule.id,
      grupos: selectedUsers,
    };
    dispatch(updateUnikUsersRule(ruleUsers));
    handleCloseRules();
  };

  return (
    <Modal
      show={showRules}
      onHide={handleCloseRules}
      dialogClassName="custom-modal modal-80w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("admin.rule.modal.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isLoadingRulesByID && (
          <>
            <div>
              <b>{t("admin.rule.modal.rule")}</b>{" "}
              <label>{selectedRule?.nome}</label>
              <hr />
            </div>

            <Form.Row>
              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>{t("admin.rule.usermodal.users")}</Form.Label>
                  <UserTable
                    users={unselectedUsers}
                    action={addUser}
                    actionIcon="icon-add"
                  />
                </Form.Group>
              </Col>

              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>
                    {t("admin.rule.usermodal.usersSelected")}
                  </Form.Label>
                  <UserTable
                    users={selectedUsers}
                    action={removeUser}
                    actionIcon="icon-trash"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          style={{ minHeight: "44px" }}
          onClick={handleCloseRules}
        >
          {t("general.close")}
        </Button>
        <Button className="card-button" onClick={handleSave}>
          {t("general.save")}
        </Button>
      </Modal.Footer>

      <SpinnerNew open={isLoadingRulesByID} />
    </Modal>
  );
};
export default withNamespaces()(RuleModal);
