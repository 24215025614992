import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerNew = ({ open }) => {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: 9999 }} // Define a high zIndex value
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default SpinnerNew;