import * as aliceonboarding from "aliceonboarding";
import "aliceonboarding/dist/aliceonboarding.css";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid/v1";
import iconEmailSent from "~/pages/Easypay/assets/img/icons/icon-email-sent.png";
import iconPhone from "~/pages/Easypay/assets/img/icons/icon-phone.png";
import "~/pages/Easypay/assets/scss/_typography.scss";
import Bullet from "~/pages/Easypay/components/Bullet";
import CustomButton from "~/pages/Easypay/components/Button";
import ButtonUploadDocument from "~/pages/Easypay/components/ButtonUploadDocument";
import Checkbox from "~/pages/Easypay/components/Checkbox";
import Input from "~/pages/Easypay/components/Input";
import InputDate from "~/pages/Easypay/components/InputDate";
import LineBreak from "~/pages/Easypay/components/LineBreak";
import Link from "~/pages/Easypay/components/Link";
import Modal from "~/pages/Easypay/components/Modal.js";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import RadioButtonItem from "~/pages/Easypay/components/RadioButtonItem";
import RadioButton from "~/pages/Easypay/components/RadioButton";
import SingleAccordion from "~/pages/Easypay/components/SingleAccordion";
import SingleDocument from "~/pages/Easypay/components/SingleDocument";
import SpinnerNew from "~/components/SpinnerNew";
import Tab from "~/pages/Easypay/components/Tab";
import TabButton from "~/pages/Easypay/components/TabButton";
import Termos from "~/pages/Easypay/components/Termos";
import Text from "~/pages/Easypay/components/Text";
import VerticalNavItem from "~/pages/Easypay/components/VerticalNavItem";
import Wizard from "~/pages/Easypay/components/Wizard";
import RadioButtonCardWrapper from "~/pages/Easypay/components/RadioButtonCardWrapper";
import CheckboxWrapper from "~/pages/Easypay/components/CheckboxWrapper";
import CreateDocumentNavigation from "~/pages/Easypay/CreateForm/components/CreateDocumentNaviagtion";
import CreateInputDropdown from "~/pages/Easypay/CreateForm/components/CreateInputDropdown";
import CreateInputSearch from "~/pages/Easypay/CreateForm/components/CreateInputSearch";
import CreateRowFromColumns from "~/pages/Easypay/CreateForm/components/CreateRowFromColumns";
import CreatePepTypeDropdown from "../CreateForm/components/CreatePepTypeDropdown";
import CreateSocialMediaInfo from "../CreateForm/components/CreateSocialMediaInfo";
import CreateWrapper from "~/pages/Easypay/CreateForm/components/CreateWrapper";
import SimpleButton from "../components/SimpleButton";
import AliceProcessLink from "./AliceProcess/AliceProcessLink";
import { handleButtonClick } from "~/pages/Easypay/CreateForm/utils/FormButtons";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import {
  clearGetSamlResponse,
  getSaml,
  setAliceUserToken,
} from "~/store/ducks/onboarding/actionTypes";
import oauth from "~/utils/oauth";
import CustomAccordion from "../components/CustomAccordion";
import DocumentsTab from "../components/DocumentsTab";
import SignaturesAccordion from "../components/SignaturesAccordion";
import { transformFormData } from "../CreateForm/utils";
import TextWithLinks from "../components/TextWithLinks";
const devLogConsole = require("~/utils/devLog");
const RenderForm = ({ data, t, flowid }) => {
  const dispatch = useDispatch();
  const jsonData = data;
  const buttonOperation = useRef(null);
  const buttonId = useRef(null);

  const { aliceUserToken, aliceProcessLink } = useSelector(
    (state) => state.onboardingReducer
  );
  const { easypayOnboardingPid } = useSelector((state) => state.easypayReducer);
  const { getAtributesResponse } = useSelector(
    (state) => state.onboardingReducer
  );

  useEffect(() => {
    if (document.dados["isENI"]) {
    }
    if (
      window.location.href.includes("account_verification_report_id") &&
      document?.dados?.tinkReport
    ) {
      document.dados.tinkReport.value = window.location.href.split(
        "account_verification_report_id="
      )[1];
      handleSubmitWrapperLink();
      const currentUrl = window.location.href;
      window.location.href = currentUrl.split(
        "?account_verification_report_id"
      )[0];
    }
    if (window.location.href.includes("?cmd-user-id=")) {
      clearGetSamlResponse();
      const currentUrl = window.location.href;
      if (currentUrl.split("?cmd-user-id=")[1].length > 0) {
        if (document.dados.cmdUserId) {
          document.dados.cmdUserId.value = currentUrl.split("?cmd-user-id=")[1];
          window.location.href = currentUrl.split("?cmd-user-id=")[0];
          handleSubmitWrapperLink();
        }
      } else {
        if (document.dados.acção) {
          window.location.href = currentUrl.split("?cmd-user-id=")[0];
          document.dados.acção.value = "falhou";
          handleSubmitWrapperLink();
        }
      }
    }

    if (!document.getElementById("vertical-nav-kyc")) {
      setNoPadding(false);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(getAtributesResponse).length > 0) {
      if (
        document.dados.NIC &&
        document.dados.DataValidade &&
        document.dados.chaveMovelId
      ) {
        document.dados.NIC.value = getAtributesResponse.NIC;
        document.dados.DataValidade.value = getAtributesResponse.DataValidade;
        handleSubmitWrapperLink();
      }
    }
  }, [getAtributesResponse]);

  useEffect(() => {
    enableChaveMovel && iniciarChaveMovelDigital();
  }, [enableChaveMovel]);

  useEffect(() => {
    if (aliceUserToken && document.dados.aliceToken) {
      if (aliceUserToken === document.dados.aliceToken.value) {
        let config = new aliceonboarding.OnboardingConfig()
          .withUserToken(aliceUserToken)
          .withAddSelfieStage()
          .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD);
        new aliceonboarding.Onboarding("alice-onboarding-mount", config).run(
          onSuccess,
          onFailure,
          onCancel
        );
      }
    }
  }, [aliceUserToken]);

  useEffect(() => {
    if (easypayOnboardingPid) {
      window.localStorage.setItem("pid", easypayOnboardingPid);
    }
  }, [easypayOnboardingPid]);

  useEffect(() => {
    const aliceToken =
      jsonData?.form?.blockdivision?.columndivision?.field[0]?.value;
    aliceToken && dispatch(setAliceUserToken(aliceToken));
  }, [jsonData]);

  useEffect(() => {
    if (getSamlResponse) {
      document.getElementById("submitSamlReqBtnId").click();
      clearGetSamlResponse();
    }
  }, [getSamlResponse]);

  const [canDownloadedFile, setCanDownloadedFile] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [inputDate, setInputDate] = useState();
  const [enableChaveMovel, setEnableChaveMovel] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [getSamlResponse, setGetSamlResponse] = useState("");
  const [cmdRedirectUrl, setCmdRedirectUrl] = useState("");
  const [noPadding, setNoPadding] = useState(true);

  const [showCountryModal, setShowCountryModal] = useState(false);

  const [showReplegAddModal, setShowReplegAddModal] = useState(true);
  const [showBenefAddModal, setShowBenefAddModal] = useState(false);
  const [showEditOrganizacaoModal, setShowEditOrganizacaoModal] =
    useState(false);
  const [showEditNegocioModal, setShowEditNegocioModal] = useState(false);

  function onSuccess() {
    handleSubmitWrapperLink();
    dispatch(setAliceUserToken(""));
  }

  function onFailure() {
    dispatch(setAliceUserToken(""));
    if (document.dados?.acção) {
      document.dados.acção.value = "falhou";
      handleSubmitWrapperLink();
    }
  }

  function onCancel() {
    dispatch(setAliceUserToken(""));
    if (document.dados?.acção) {
      document.dados.acção.value = "cancelar";
      handleSubmitWrapperLink();
    }
  }

  const handleButtonDetails = (button) => {
    buttonOperation.current = button.operation;
    buttonId.current = button.id;
  };

  const iniciarChaveMovelDigital = () => {
    const payload = {
      url: `${window.location.href}?cmd-user-id=`,
      // DocType: 1,
      // NomeCompleto: 1,
      NIC: 1,
      DataValidade: 1,
      //NIF: 1,
      //MoradaXML: 1,
    };
    dispatch(getSaml(payload));
  };

  const handleSubmitWrapper = (event, button) => {
    devLogConsole(button);
    if (isConfirmed) {
      handleSubmitForm(event);
    } else {
      button === "submit"
        ? handleButtonDetails({
            id: "4",
            name: "_avancar",
            operation:
              "if (CheckEmptyFields()) { disableForm(); document.dados.op.value='3'; document.getElementById('_button_clicked_id').value='4'; } else { return false; }",
            text: "Seguinte",
            tooltip: "Seguinte",
          })
        : handleButtonDetails(button);

      if (buttonOperation.current.includes("if (confirm")) {
        const array = buttonOperation.current.split("if (confirm('");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            setConfirmMessage(array2[0]);
            setShowModal(true);
          }
        }
      } else {
        handleSubmitForm(event);
      }
    }
  };

  const monthDiff = (dateFrom, dateTo) => {
    return (
      dateTo.getMonth() -
      new Date(dateFrom).getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  const handleSubmitWrapperLink = (event) => {
    if (isConfirmed) {
      handleSubmitForm(event);
    } else {
      handleButtonDetails({
        id: "4",
        name: "_avancar",
        operation:
          "if (CheckEmptyFields()) { disableForm(); document.dados.op.value='3'; document.getElementById('_button_clicked_id').value='4'; } else { return false; }",
        text: "Seguinte",
        tooltip: "Seguinte",
      });

      if (buttonOperation.current.includes("if (confirm")) {
        const array = buttonOperation.current.split("if (confirm('");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            setConfirmMessage(array2[0]);
            setShowModal(true);
          }
        }
      } else {
        handleSubmitForm(event);
      }
    }
  };

  const finalizeLaterField = {
    type: "link",
    text: "Finalizar mais tarde::easypay-button-link",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='finalizeLater';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };

  const handleSubmitForm = () => {
    handleButtonClick(buttonOperation.current);

    //transform date data
    const formdata = transformFormData(
      new FormData(document.getElementsByName("dados")[0])
    );

    const url =
      "/Form/form.jsp?" +
      "flowid=" +
      flowid +
      "&pid=" +
      jsonData.form.hidden.filter((field) => field.name === "pid")[0]?.value +
      "&subpid=" +
      jsonData.form.hidden.filter((field) => field.name === "subpid")[0]
        ?.value +
      "&Authorization=" +
      oauth.getAccessToken();
    dispatch(postEasypayFormData(formdata, url));
  };

  const renderColumnDivisions = (blockdivision) => {
    const renderFields = (field) => {
      if (field.type === "documentnavigation") {
        return (
          <CreateDocumentNavigation
            documentlist={field.documentlist}
            key={uuid()}
          />
        );
      } else if (field.type === "header") {
        return (
          <div key={uuid()} className={`easypay-${field.text.split("::")[1]}`}>
            {t(field.text.split("::")[0])}
          </div>
        );
      } else if (field.type === "textmessage") {
        if (field?.text?.split("::")[1] === "text-with-links") {
          return (
            <TextWithLinks
              title={field?.text?.split("::")[0]}
              align={field?.align}
              key={uuid()}
            />
          );
        }
        return <Text text={field?.text} align={field?.align} key={uuid()} />;
      } else if (field.type === "textlabel") {
        if (field.text.includes("single-document-link")) {
          return (
            <SingleDocument
              key={uuid()}
              name={field.value}
              variable={field.text.split("::")[1]}
              variableValue={field.text.split("::")[2]}
              submitForm={handleSubmitWrapperLink}
              variant={
                field.text.split("::").length === 4
                  ? field.text.split("::")[3]
                  : ""
              }
            />
          );
        }
        if (field.text.includes("error::prova-de-vida")) {
          return (
            <ProvaDeVida status={"error"} text={field?.value} key={uuid()} />
          );
        }
        if (!field.text.includes("::")) {
          return (
            <div key={uuid()}>
              <Text
                text={
                  (field?.value.includes("::")
                    ? t(field?.value.split("::")[0]) +
                      t(field?.value.split("::")[1])
                    : field?.value) +
                  "::" +
                  field.text
                }
              />
            </div>
          );
        }
        if (field?.text.includes("single-value")) {
          return (
            <div key={uuid()}>
              <Text
                text={
                  field?.value +
                  `${
                    field?.text.split("::").length > 1
                      ? "::" + field?.text.split("::")[1]
                      : "::header-6"
                  }`
                }
                align={field?.align}
              />
            </div>
          );
        }
        if (field?.text.split("::").length > 2) {
          if (field?.text.split("::")[2] === "eni") {
            if (
              field?.text.split("::")[1] ===
              "bpm.onboarding.backoffice.user.labelCertificateCod"
            )
              return (
                <div key={uuid()}>
                  <Text
                    text={
                      "bpm.onboarding.backoffice.user.labelDeclarationActivity::card-text-no-padding"
                    }
                  />
                  <Text text={field?.value} />
                </div>
              );
            else
              return (
                <div key={uuid()}>
                  <Text
                    text={
                      "bpm.onboarding.backoffice.user.labelEmissionDate::card-text-no-padding"
                    }
                  />
                  <Text text={field?.value} />
                </div>
              );
          } else {
            if (
              field?.text.split("::")[1] ===
              "bpm.onboarding.backoffice.user.labelCertificateCod"
            )
              return (
                <div key={uuid()}>
                  <Text
                    text={
                      "bpm.onboarding.backoffice.user.labelCertificateCod::card-text-no-padding"
                    }
                  />
                  <Text text={field?.value} />
                </div>
              );
            else
              return (
                <div key={uuid()}>
                  <Text
                    text={
                      "bpm.onboarding.backoffice.user.labelValidity::card-text-no-padding"
                    }
                  />
                  <Text text={field?.value} />
                </div>
              );
          }
        } else if (field?.value.split("::").length > 2)
          return (
            <div key={uuid()}>
              <Text text={field?.text} />
              <Text text={field?.value} />
            </div>
          );
        else {
          if (
            field?.text.split("::")[0] ===
              "bpm.onboarding.backoffice.user.labelFormaObg" &&
            field?.value === ""
          ) {
            return;
          } else
            return (
              <div key={uuid()}>
                <Text text={field?.text} />
                <Text text={field?.value + "::card-text-light"} />
              </div>
            );
        }
      } else if (field.type === "button") {
        return (
          <CustomButton
            text={field.text.split("::")[0]}
            variant={field.text.split("::")[1]}
            key={uuid()}
            id={field.id}
            className="card-button"
            align={field.tooltip}
            disabledColored={
              field.text.split("::")[2] === "disabledColored" ? "true" : false
            }
            disabled={field.text.split("::")[2] === "disabled" ? true : false}
          />
        );
      } else if (field.type === "arraytable") {
        if (field?.row[0]?.col[0]?.value === "Perguntas") {
          return field?.row.map((resp, i) => {
            return resp?.col.map((r, r_i) => {
              if (i > 0 && r?.value) {
                if (r?.value.includes("::")) {
                  return (
                    <div key={uuid()}>
                      <Text text={r?.value} />
                    </div>
                  );
                } else {
                  return (
                    <div key={uuid()}>
                      <Text
                        align={r?.align}
                        text={
                          r?.value +
                          `${
                            r_i % 2 === 0
                              ? "::body-xl-no-padding"
                              : "::card-text-light-small-padding"
                          }`
                        }
                      />
                    </div>
                  );
                }
              }
            });
          });
        }
        if (field?.row[0]?.col[0]?.value.includes("checkbox-multiple-card")) {
          return <RadioButtonCardWrapper field={field} />;
        }

        if (field?.row[0]?.col[0]?.value.includes("checkbox-multiple")) {
          return <CheckboxWrapper field={field} />;
        }

        if (field?.row[0]?.col?.value === "array") {
          return (
            <Text
              text={field?.row[0]?.col[1].value + "::card-text-light"}
              key={uuid()}
            />
          );
        }
        if (field.datarow_count > 0) {
          const accordionInfo = field?.row[0]?.col[0]?.value.split("::");
          const variant = accordionInfo[0];
          if (variant === "accordion-table" || variant === "accordion-benef") {
            var accordionArray = [];
            var counter = 0;
            devLogConsole(field.row);
            field.row.map((col, index) => {
              if (index > 0) {
                var contentArray = [];
                var infoMap = new Map();
                col.col.map((item, index) => {
                  infoMap.set(field.row[0].col[index].value, item);
                  if (index > 14 && variant === "accordion-table") {
                    contentArray.push({
                      title: field.row[0].col[index].value,
                      value: item.value,
                    });
                  } else if (index > 3 && variant === "accordion-benef") {
                    contentArray.push({
                      title: field.row[0].col[index].value,
                      value: item.value,
                    });
                  }
                });
                accordionArray.push({ info: infoMap, content: contentArray });
                counter++;
              }
            });
            return (
              <CustomAccordion
                variant={variant}
                list={accordionArray}
                handleSubmitWrapperLink={handleSubmitWrapperLink}
                addLinkText={accordionInfo[1]}
                removeLinkText={accordionInfo[2]}
                key={uuid()}
              />
            );
          } else if (variant === "document-tabs") {
            var accordionArray = [];
            var submitedList = [];
            field.row.map((col, index) => {
              if (index > 0) {
                var contentArray = [];
                submitedList.push(col.col[4].value);
                col.col.map((item, index) => {
                  contentArray.push({
                    title: field.row[0].col[index].value,
                    value: item.value,
                  });
                });
                accordionArray.push({ content: contentArray });
              }
            });
            return (
              <DocumentsTab
                key={uuid()}
                list={accordionArray}
                documentsSubmittedList={submitedList}
              />
            );
          } else {
            var accordionArray = [];
            var typeAndStateArray = [];
            field.row.map((col, index) => {
              if (index > 0) {
                var contentArray = [];
                typeAndStateArray.push(col?.col[3]?.value);
                col.col.map((item, index) => {
                  contentArray.push({
                    title: field.row[0].col[index].value,
                    value: item.value,
                  });
                });
                accordionArray.push({ content: contentArray });
              }
            });
            return (
              <SignaturesAccordion
                key={uuid()}
                list={accordionArray}
                typeAndStateList={typeAndStateArray}
                canDownloadedFile={canDownloadedFile}
                setCanDownloadedFile={setCanDownloadedFile}
              />
            );
          }
        }
      }
      if (field?.row?.col[0]?.value === "number") {
        const bulletList = field.row.col
          .filter((column, i) => {
            if (i > 0) return column;
          })
          .map((column) => {
            return column.value;
          });
        return <Bullet key={uuid()} list={bulletList} />;
      }

      if (field?.row?.col[0]?.value?.split("::")[0] === "badge") {
        return (
          <Text
            text={field?.row?.col[1].value}
            icon={field?.row?.col[0].value.split("::")[1]}
            key={uuid()}
          />
        );
      }

      if (field?.row?.col[0]?.value === "document-component") {
        return <SingleDocument key={uuid()} name={field?.row?.col[1].value} />;
      }
      if (field?.row?.col[0]?.value === "redirect") {
        window.location.href = `${field?.row?.col[1]?.value}`;
      }
      if (field?.row?.col[0]?.value === "wizard") {
        return <Wizard key={uuid()} list={field?.row?.col} />;
      }

      if (field.type === "password") {
        return <Input field={field} key={uuid()} type="password" />;
      }
      if (field?.row?.col[0]?.value === "termos") {
        return <Termos key={uuid()} />;
      }
      if (field?.row?.col[0]?.value === "single-accordion") {
        return <SingleAccordion key={uuid()} text={field?.row?.col} />;
      }
      if (field?.row?.col[0]?.value === "single-document-upload") {
        return (
          <ButtonUploadDocument
            key={uuid()}
            variables={field?.row?.col}
            submitForm={handleSubmitWrapperLink}
          />
        );
      }
      if (field?.row?.col[0]?.value.split("::")[0] === "icon") {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "40px",
            }}
          >
            <img src={iconEmailSent} alt="email sent" key={uuid()} />
          </div>
        );
      }
      if (field?.row?.col[0]?.value.split("::")[0] === "icon-phone") {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "40px",
            }}
          >
            <img src={iconPhone} alt="icon phone" key={uuid()} />
          </div>
        );
      }
      if (field?.row?.col[0]?.value.split("::")[0] === "line-break") {
        return <LineBreak text={field?.row?.col[1].value} key={uuid()} />;
      }

      if (field?.row?.col[0]?.value.split("::")[0] === "prova-de-vida") {
        devLogConsole(field)
        return (
          <ProvaDeVida
            status={field?.row?.col[0].value.split("::")[1]}
            text={field?.row?.col[1].value}
            key={uuid()}
          />
        );
      } else if (field.type === "subheader") {
        return <div key={uuid()}>{t(field.text)}</div>;
      } else if (field.type === "link") {
        if (field.text.includes("button")) {
          return (
            <div key={uuid()} style={{ justifyContent: field.align }}>
              <CustomButton
                text={field.text.split("::")[0]}
                variant={field.text.split("::")[1]}
                onclick={
                  !field.onclick
                    ? ""
                    : field.onclick === "guardar"
                    ? "guardar"
                    : eval(field.onclick)
                }
                href={field.href && field.href}
                key={uuid()}
                id={field.id}
                className="card-button"
                align={field.align}
                handleSubmitWrapper={handleSubmitWrapper}
                disabledColored={
                  field.text.split("::")[2] === "disabledColored"
                    ? "true"
                    : false
                }
                disabled={
                  field.text.split("::")[2] === "disabled" ? "true" : false
                }
                field={field}
              />
            </div>
          );
        } else {
          return (
            <div
              className="easypay-link-wrapper"
              key={uuid()}
              style={{ textAlign: field.align, paddingBottom: "30px" }}
            >
              <Link
                text={
                  field.text.includes("::disabled")
                    ? field.text.split("::")[0]
                    : field.text
                }
                disabledColored={
                  field.text.split("::")[1] === "disabledColored"
                    ? "true"
                    : false
                }
                disabled={
                  field.text.split("::")[1] === "disabled" ? "true" : false
                }
                key={uuid()}
              />
            </div>
          );
        }
      } else if (field.type === "textbox") {
        if (field.text?.split("::")[1] === "submit") {
          return (
            <Input
              field={field}
              key={uuid()}
              submitOnChange={true}
              handleSubmitWrapper={handleSubmitWrapper}
            />
          );
        }
        if (field.variable === "triggerTinkRedirect") {
          devLogConsole(window.location.href);
          if (window.location.href.includes("USER_CANCELLED")) {
            return;
          }
          if (
            !window.location.href.includes("account_verification_report_id")
          ) {
            const url = field?.value;
            const urlSplitted = url.split("::REDIRECT::");
            const redirect = encodeURIComponent(window.location.href.trim());
            devLogConsole(`${urlSplitted[0]}${redirect}${urlSplitted[1]}`);
            var newUrl = `${urlSplitted[0]}${redirect}${urlSplitted[1]}`;
            if (newUrl.includes("&test=true")) {
              newUrl = newUrl.replace("&test=true", "");
            }
            window.location.href = newUrl;
          }
          return <SpinnerNew open={true} />;
        }
        if (field.variable === "cmdRequestSaml") {
          if (!window.location.href.includes("?cmd-user-id=")) {
            if (field.value && !getSamlResponse) {
              setGetSamlResponse(field.value);
            }
          }
          return <SpinnerNew open={true} />;
        }
        if (field.variable !== "triggerChaveMovelDigital") {
          if (!field.text.includes("::hidden")) {
            return <Input field={field} key={uuid()} />;
          } else {
            if (field.text === "::hidden::no-input") {
              return "";
            } else {
              return (
                <div style={{ height: "0px" }}>
                  <input
                    style={{
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                      width: "1px",
                      height: "1px",
                      color: "transparent",
                    }}
                    defaultValue={field.value}
                    type="text"
                    name={field.variable}
                    id=""
                  />
                </div>
              );
            }
          }
        }
      } else if (field.type === "checkbox") {
        return (
          <Checkbox
            name={field.name}
            id={field.id}
            value={field.value}
            key={uuid()}
          />
        );
      } else if (field.type === "selection") {
        if (field?.text?.split("::")[1] === "searchable-dropdown") {
          return (
            <CreateInputSearch
              key={field.variable}
              field={field}
              countryList={
                field?.text?.split("::")[0] === "country" ? true : false
              }
              defaultSelected={
                field?.text?.split("::").length >= 3
                  ? field?.text?.split("::")[3]
                  : false
              }
            />
          );
        } else {
          if (field?.text?.split("::")[4] === "radio-button") {
            return field.option.map((op, i) => {
              return (
                <div style={{ paddingBottom: "20px" }} key={uuid()}>
                  <RadioButton
                    disabled={false}
                    name={field.variable}
                    value={op.value}
                    id={uuid()}
                    //checked={i === 0 ? true : false}
                    label={op.text}
                  />
                </div>
              );
            });
          } else if (field?.text?.split("::")[4] === "dropdown") {
            return (
              <CreateInputDropdown
                key={uuid()}
                field={field}
                order={
                  field?.text?.split("::")[2] === "no-order" ? false : true
                }
              />
            );
          } else if (field?.text?.split("::")[4] === "checkbox-multiple") {
          } else if (field?.text?.split("::")[4] === "input-date") {
            const todayDate = new Date();

            return (
              <>
                <input
                  type="hidden"
                  name={field.variable}
                  value={inputDate}
                  style={{ height: "0px", width: "0px", border: "none" }}
                />

                <InputDate
                  key={uuid()}
                  placeholder=""
                  defaultValue={["", ""]}
                  handleSubmit={(dates) =>
                    setInputDate(monthDiff(new Date(dates[0]), todayDate))
                  }
                />
              </>
            );
          } else if (field?.text?.split("::")[4] === "dropdown-input") {
            //text=field?.text?.split("::")[6];
            const inputField = {
              text: "",
              variable: field?.text?.split("::")[5],
            };
            return (
              <CreateInputDropdown
                key={uuid()}
                field={field}
                order={
                  field?.text?.split("::")[2] === "no-order" ? false : true
                }
                inputTextExtra={true}
                inputField={inputField}
              />
            );
          } else if (field?.text?.split("::")[4] === "dropdown-pep") {
            //text=field?.text?.split("::")[6];
            const inputField = {
              text: "",
              variable: field?.text?.split("::")[5],
            };
            return (
              <CreatePepTypeDropdown
                key={uuid()}
                field={field}
                order={
                  field?.text?.split("::")[2] === "no-order" ? false : true
                }
                inputTextExtra={true}
                inputField={inputField}
              />
            );
          } else if (field?.text?.split("::")[4] === "social-media-info") {
            //text=field?.text?.split("::")[6];
            const inputField = {
              text: "",
              variable: field?.text?.split("::")[5],
            };
            return (
              <CreateSocialMediaInfo
                key={uuid()}
                field={field}
                order={
                  field?.text?.split("::")[2] === "no-order" ? false : true
                }
                inputTextExtra={true}
                inputField={inputField}
              />
            );
          }
        }
      }
    };

    const renderColumnContent = (columndivision) => {
      return Object.keys(columndivision).map((columnKey) => {
        if (columnKey === "field") {
          let fieldArray = columndivision.field ? columndivision.field : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }
          return fieldArray.map((field, index) => renderFields(field, index));
        } else if (columnKey === "tabdivision") {
          let tabDivisionArray = columndivision.tabdivision
            ? columndivision.tabdivision
            : [];
          if (!Array.isArray(tabDivisionArray)) {
            tabDivisionArray = [tabDivisionArray];
          }
          var firstTabInfo = [];
          var typeOfTab = "";
          let tabs = "";
          tabs = tabDivisionArray.map((tabDiv) => {
            let tabArray = tabDiv.tab ? tabDiv.tab : [];
            if (!Array.isArray(tabArray)) {
              tabArray = [tabArray];
            }
            firstTabInfo = tabArray[0]?.name.includes("::")
              ? tabArray[0]?.name.split("::")
              : "";
            typeOfTab = tabArray[0]?.name.includes("::") ? firstTabInfo[1] : "";
            if (!Array.isArray(tabArray)) tabArray = [tabArray];
            return createTabs(typeOfTab, tabArray, firstTabInfo);
          });

          return (
            <CreateWrapper
              key={uuid()}
              type={typeOfTab}
              tabs={
                tabDivisionArray[0]?.tab?.name !== "country::country"
                  ? tabs
                  : tabDivisionArray
              }
              variant={firstTabInfo.length > 2 ? firstTabInfo[2] : ""}
              setShowCountryModal={setShowCountryModal}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          );
        }
      });
    };

    const createTabs = (type, tabArray, firstTabInfo) => {
      var name = "";
      return tabArray.map((tab, index) => {
        if (index === 0 && !tab.name.includes("disabled")) {
          name = tab.name.split("::")[0];
        } else name = tab.name;
        switch (type) {
          case "vertical-nav":
            return (
              <VerticalNavItem
                action={tab.action}
                key={uuid()}
                text={t(name)}
                index={index}
                name={t(name)}
                badge={""}
              >
                {renderBlockDivision(tab.blockdivision)}
              </VerticalNavItem>
            );
          case "accordion":
            const accordionInfo = tab.name.split("::");
            return {
              badge: accordionInfo[0],
              title: index === 0 ? accordionInfo[2] : accordionInfo[1],
              role: index === 0 ? accordionInfo[3] : accordionInfo[2],
              checked: index === 0 ? accordionInfo[4] : accordionInfo[3],
              content: renderBlockDivision(tab.blockdivision),
            };
          case "toggle-accordion":
            return {
              name: name,
              content: renderBlockDivision(tab.blockdivision),
            };
          case "tab-button":
            return (
              <TabButton
                action={tab.action}
                text={name}
                index={index}
                name={name}
                key={uuid()}
              >
                {renderBlockDivision(tab.blockdivision)}
              </TabButton>
            );
          case "tab":
            return (
              <Tab
                action={tab.action}
                text={name}
                index={index}
                name={name}
                numberOfTabs={tabArray.length}
                key={uuid()}
              >
                {renderBlockDivision(tab.blockdivision)}
              </Tab>
            );
          case "radio-button":
            const actionArray = tab?.action.split("::");
            const subHeader = `${actionArray[0]}::${actionArray[1]}`;
            const action = `${actionArray[2]}::${actionArray[3]}`;
            return (
              <RadioButtonItem
                key={uuid()}
                text={name}
                index={index}
                uniqueName={firstTabInfo[3]}
                name={name}
                subHeader={subHeader}
                action={action}
                radioButtonLength={tabArray.length}
                disabled={
                  index === 0
                    ? tab.name.split("::")[2]
                    : tab.name.split("::")[1]
                }
              >
                {renderBlockDivision(tab.blockdivision)}
              </RadioButtonItem>
            );
          case "card":
            return (
              <div key={uuid()}>{renderBlockDivision(tab.blockdivision)}</div>
            );
          case "modal-country":
            return (
              <Modal
                headerContent={t("bpm.onboarding.user.modalCountryTitle")}
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowCountryModal}
                showModal={showCountryModal}
                key={uuid()}
              />
            );

          case "modal-add-repleg":
            return (
              <Modal
                headerContent="Adicionar"
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowReplegAddModal}
                showModal={showReplegAddModal}
                key={uuid()}
              />
            );
          case "modal-approved-account":
            return (
              <Modal
                headerContent=""
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowReplegAddModal}
                showModal={showReplegAddModal}
                key={uuid()}
              />
            );

          case "modal-finalized-account":
            return (
              <Modal
                headerContent=""
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowReplegAddModal}
                showModal={showReplegAddModal}
                key={uuid()}
              />
            );
          case "modal-final-later-account":
            return (
              <Modal
                headerContent=""
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowReplegAddModal}
                showModal={showReplegAddModal}
                key={uuid()}
              />
            );
          case "modal-edit-organizacao":
            return (
              <Modal
                headerContent="Alterar"
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowEditOrganizacaoModal}
                showModal={showEditOrganizacaoModal}
                key={uuid()}
              />
            );
          case "modal-edit-negocio":
            return (
              <Modal
                headerContent="Alterar"
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowEditNegocioModal}
                showModal={showEditNegocioModal}
                key={uuid()}
              />
            );
          case "modal-add-benef":
            return (
              <Modal
                headerContent="Adicionar"
                bodyContent={renderBlockDivision(tab.blockdivision)}
                setShowModal={setShowBenefAddModal}
                showModal={showBenefAddModal}
                key={uuid()}
              />
            );
          case "toggle-certidão-permanente":
            return renderBlockDivision(tab.blockdivision);
          default:
            return (
              <TabButton
                action={tab.action}
                text={name}
                index={index}
                name={name}
                key={uuid()}
              >
                {renderBlockDivision(tab.blockdivision)}
              </TabButton>
            );
        }
      });
    };

    const transformColumnsIntoRow = (columns_) => {
      let colIndex = 0;
      colIndex = columns_.findIndex(
        (col) =>
          col && //null and undefined check
          col !== "" &&
          Object.keys(col).length !== 0
      );
      return Object.keys(columns_[colIndex]).map((columnKey) => {
        const currentWidth = columns_[colIndex].width;
        if (columnKey === "field") {
          let fieldArray = columns_[colIndex].field
            ? columns_[colIndex].field
            : [];
          if (!Array.isArray(fieldArray)) {
            fieldArray = [fieldArray];
          }
          return fieldArray.map((field, index) => {
            if (index > 0) return renderFields(field, index);
            if (index === 0) {
              return (
                <CreateRowFromColumns
                  key={uuid()}
                  field={field}
                  columns={columns_}
                  width={currentWidth}
                  showEditNegocioModal={showEditNegocioModal}
                  setShowBenefAddModal={setShowBenefAddModal}
                  setShowReplegAddModal={setShowReplegAddModal}
                  setShowEditNegocioModal={setShowEditNegocioModal}
                  showEditOrganizacaoModal={showEditOrganizacaoModal}
                  setShowEditOrganizacaoModal={setShowEditOrganizacaoModal}
                  showCountryModal={showCountryModal}
                  setShowCountryModal={setShowCountryModal}
                  handleButtonDetails={handleButtonDetails}
                  handleSubmitWrapperLink={handleSubmitWrapperLink}
                  handleSubmitForm={handleSubmitForm}
                />
              );
            }
          });
        }
      });
    };

    let columndivision = blockdivision.columndivision;
    if (!Array.isArray(columndivision)) {
      columndivision = [columndivision];
    }
    let numColumns = columndivision.length;

    if (numColumns === 1) {
      return renderColumnContent(columndivision[0]);
    } else if (numColumns > 1) {
      return transformColumnsIntoRow(columndivision);
    }
  };

  const renderBlockDivision = (blockdivision) => {
    let blockdivisionArray = blockdivision;
    if (!Array.isArray(blockdivisionArray)) {
      blockdivisionArray = [blockdivisionArray];
    }
    return blockdivisionArray.map((blockdivision) => {
      return renderColumnDivisions(blockdivision);
    });
  };

  const renderFormItems = () => {
    if (jsonData) {
      const form = jsonData.form;
      return Object.keys(form).map((formKey) => {
        if (formKey === "blockdivision") {
          return renderBlockDivision(form.blockdivision);
        }
        if (formKey === "error") {
          let text = form.error?.text.split("<br/>");
          return (
            <Row
              key={`blockdivision_error`}
              style={{ width: "100%", flexWrap: "nowrap" }}
            >
              <Col key={`columndivision_error`} style={{ flexGrow: "1" }}>
                <Alert key={formKey} variant="danger">
                  {text &&
                    text.map((p_, index) => {
                      return <p key={index}>{p_}</p>;
                    })}
                </Alert>
              </Col>
            </Row>
          );
        }
        if (formKey === "button") {
          let buttonArray = form.button ? form.button : [];
          if (!Array.isArray(buttonArray)) {
            buttonArray = [buttonArray];
          }
          return (
            <div
              className="easypay-submit-button-wrapper"
              style={{ justifyContent: "center" }}
              key="easypay-submit-key"
            >
              {buttonArray.map((button, i) => {
                if (button.text === "Cancelar")
                  return (
                    <CustomButton key={"button-custom-key-" + i}>
                      {button.text}
                    </CustomButton>
                  );
                if (button.name === "_avancar" && button.text !== "Hide") {
                  return (
                    <CustomButton
                      variant="easypay-button-primary-large submit"
                      onclick={(e) => {
                        handleSubmitWrapper(e, button);
                      }}
                      text={button.text}
                      key={"button-submit-key-" + i}
                      id={button.id}
                      type="submit"
                    />
                  );
                }
                if (button.name.includes("_custom")) {
                  if (button.text.includes("Finalizar mais tarde")) {
                    devLogConsole(button);
                    return (
                      <div
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        <CustomButton
                          text={button.text?.split("::")[0]}
                          variant="easypay-button-primary-large submit"
                          onclick={finalizeLaterField.onclick}
                          key={uuid()}
                          id={finalizeLaterField.id}
                          className="card-button"
                          align={"center"}
                          disabledColored={false}
                          field={finalizeLaterField}
                        />
                      </div>
                    );
                  } else
                    return (
                      <CustomButton
                        variant={button.text?.split("::")[1]}
                        onclick={(e) => {
                          handleSubmitWrapper(e, button);
                        }}
                        text={button.text?.split("::")[0]}
                        key={"button-submit-key-" + i}
                        id={button.id}
                        type="submit"
                        disabled={
                          button.text.split("::")[2] === "disabled"
                            ? true
                            : false
                        }
                      />
                    );
                }
              })}
            </div>
          );
        }
        if (formKey === "hidden") {
          const hiddenArray = form.hidden;
          return hiddenArray.map((field, index) => {
            return (
              <input
                key={field.name + index}
                type="hidden"
                value={field.value}
                name={field.name}
              />
            );
          });
        }
      });
    }
  };

  return (
    <div
      className={
        noPadding ? "easypay-card-wrapper-no-max" : "easypay-card-wrapper"
      }
    >
      <Form
        key={uuid()}
        noValidate
        validated={validated}
        onSubmit={handleSubmitForm}
        name="dados"
        encType="multipart/form-data"
        style={{
          width: "100%",
          margin: "auto",
        }}
      >
        {jsonData && renderFormItems()}
      </Form>
      <form
        id="samlFormId"
        action="https://preprod.autenticacao.gov.pt/fa/Default.aspx"
        method="POST"
      >
        <input type="hidden" name="SAMLRequest" value={getSamlResponse} />
        <input
          type="submit"
          id="submitSamlReqBtnId"
          style={{ display: "none" }}
        />
      </form>
      <div
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div id="alice-onboarding-mount" />
        {document?.dados?.aliceToken && <AliceProcessLink />}
      </div>
    </div>
  );
};

export default withNamespaces()(RenderForm);
