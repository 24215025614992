import React from "react";
import { withNamespaces } from "react-i18next";
import { iconmap } from "./utils";
import { useSelector } from "react-redux";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { getUserTheme } from "~/utils";

const AnnotationIcons = (props) => {
  const { t, iconId, setIconId } = props;
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { themeToUse } = useSelector((state) => state.udwReducer);

  return iconmap(themeToUse)
    .sort((a, b) =>
      t(`taskPage.annotation.${a.name}`).localeCompare(
        t(`taskPage.annotation.${b.name}`)
      )
    )
    .map((icon, index) => {
      let checked = icon.id == iconId ? true : false;

      return (
        icon.id !== 99999 &&
        icon.id !== 0 && (
          <div
            key={index}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <input
              id={icon.id}
              type="checkbox"
              name="annotation-icon"
              checked={checked}
              style={{ marginRight: "10px" }}
              onChange={(e) => {
                setIconId(iconId === icon.id ? null : icon.id);
              }}
            />
            {icon.faIcon ? (
              <i className={icon.faIcon} style={{ marginRight: "10px" }} />
            ) : (
              <img
                src={icon.image}
                style={{ width: "18px", height: "16px", marginRight: "10px" }}
                alt="annotation-preview"
              />
            )}
            <div className="tasks-annotation-iconname">
              {t(`taskPage.annotation.${icon.name}`)}
            </div>
          </div>
        )
      );
    });
};

export default withNamespaces()(AnnotationIcons);
