import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Card, Col, Button } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import Document from "./Document";
import {
  getDatacaptureConfiguration,
  clearDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";

const GeneralDocument = ({
  t,
  experiencedUser,
  show,
  handleCloseUploadModal,
  docTypeSelected,
  docTypeExtraConfiguration
}) => {
  const dispatch = useDispatch();
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [showChooseDocType, setShowChooseDocType] = useState(null);
  const [docType, setDocType] = useState(null);
  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );
  const { user } = useSelector((state) => state.globalReducer);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(()=>{
    if (user && user.organization && user.organization.id)
      dispatch(getDatacaptureConfiguration(user.organization.id));
    else
      dispatch(clearDatacaptureConfiguration());
  }, [user && user.organization && user.organization.id])

  useEffect(() => {
    if (datacaptureConfiguration.length > 0) {
      if (datacaptureConfiguration.length === 1) {
        const docType_ = datacaptureConfiguration[0].documentType;
        if (docType_) {
          setDocType(docType_);
          setShowChooseDocType(false);
        }
      } else setShowChooseDocType(true);
    } else {
      setShowChooseDocType(false);
      setDocType(docTypeSelected);
    }
  }, [datacaptureConfiguration]);

  const handleClick = (type) => {
    setDocType(type);
    setShowChooseDocType(false);
  };

  const renderOptions = (className) => {
    const buttons = [];

    const datacaptureConfigurationFilter = datacaptureConfiguration.filter(
      (e) => docTypeSelected === e.documentType
    );

    if (datacaptureConfigurationFilter && datacaptureConfigurationFilter.length > 0) {
      datacaptureConfigurationFilter.forEach((conf, index) => {
        !conf.deprecated &&
          buttons.push(
            <Button
              key={index}
              className={className}
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                whiteSpace: "nowrap",
              }}
              onClick={() =>
                handleClick(conf.documentType ? conf.documentType : "")
              }
            >
              {conf.label ? conf.label.toUpperCase() : ""}
            </Button>
          );
      });
    }
    return buttons;
  }; 

  const renderModal = () => {
    return (
      <Modal
        show={showChooseDocType}
        dialogClassName="modal-50w"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {t("datacapture.automated.selectDocumentType")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", display: "flex", flexWrap: "nowrap" }}
        >
          {renderOptions("modal-submit-button")}
        </Modal.Body>
      </Modal>
    );
  };

  const renderCard = () => {
    return (
      <div className="main-card-v2" style={{ padding: "0" }}>
        <Card bsPrefix="card-flat">
          <Card.Header
            className="justify-content-between"
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={isMobile ? { fontSize: "18px" } : { margin: "0" }}>
              {t("datacapture.automated.selectDocumentType")}
            </h4>
          </Card.Header>
          <Card.Body style={{ display: "flex", flexDirection: "column" }}>
            <Col
              style={
                isMobile ? { display: "flex", justifyContent: "center" } : {}
              }
            >
              {renderOptions("card-button")}
            </Col>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return experiencedUser ? (
    showChooseDocType && show ? (
      renderCard()
    ) : (
      <Document
        docType={docType}
        experiencedUser={experiencedUser}
        show={show}
        datacaptureConfiguration={datacaptureConfiguration}
        handleCloseUploadModal={handleCloseUploadModal}
        docTypeExtraConfiguration={docTypeExtraConfiguration}
      />
    )
  ) : (
    <>
      {renderModal()}
      {docType && (
        <Document
          docType={docType}
          experiencedUser={experiencedUser}
          show={show}
          datacaptureConfiguration={datacaptureConfiguration}
          docTypeExtraConfiguration={docTypeExtraConfiguration}
        />
      )}
    </>
  );
};

export default withNamespaces()(GeneralDocument);
