import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getPersonalData,
  getPersonalDataDropdown,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import "~/assets/css/styles.css";
import { withNamespaces } from "react-i18next";
import Hamburguer from "./MenuHamburguer";
import Media from "react-media";
import SpinnerNew from "~/components/SpinnerNew";
import RhAssiduidade from './RhAssiduidade'
import RhAjudasCusto from './RhAjudasCusto'
import RhHorasExtra from './RhHorasExtra'
import RhRecibos from './RhRecibos'


const RhNavBar = ({ rowCollaborator, t }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const { isLoadingCollaborators } = useSelector(
    (state) => state.personalReducer
  );
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getPersonalData(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      dispatch(getPersonalDataDropdown());
    }
  }, [rowCollaborator]);

  return (
    <div>
      <>
        <SpinnerNew open={isLoadingCollaborators === true} />
        <div className="navigation">
          <div className="hamburguer1">
            <div className="hamburguer" onClick={toggleHamburger}>
              <Hamburguer isOpen={hamburgerOpen} />
            </div>
          </div>
          <Media query={{ maxWidth: 810 }}>
            {(matches) =>
              matches ? (
                <>
                  <div className="collaboratoRH__Button">
                    <h5 className="collaboratorRH__name">
                      {rowCollaborator.nome}
                    </h5>
                  </div>
                  <Tabs
                    defaultActiveKey="RhAssiduidade"
                    className="custom-tabs"
                    style={{
                      display: hamburgerOpen ? "flex" : "none",
                      marginBottom: 25,
                    }}
                  >
                    <Tab
                      eventKey="RhAssiduidade"
                      title={t(`portalrh.NavBar.assiduidade`)}
                    >
                      <RhAssiduidade rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhAjudasCusto"
                      title={t(`portalrh.NavBar.ajudasCusto`)}
                    >
                      <RhAjudasCusto rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhHorasExtra"
                      title={t(`portalrh.NavBar.horasExtra`)}
                    >
                      <RhHorasExtra rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhRecibos"
                      title={t(`portalrh.NavBar.recibos`)}
                    >
                      <RhRecibos rowCollaborator={rowCollaborator} />
                    </Tab>
                  </Tabs>
                </>
              ) : (
                <>
                  <div className="collaboratoRH__Button">
                    <h5 className="collaboratorRH__name">
                      {rowCollaborator.nome}
                    </h5>
                  </div>
                  <Tabs
                    defaultActiveKey="RhAssiduidade"
                    className="custom-tabs"
                    style={{ marginBottom: 25 }}
                  >
                    <Tab
                      eventKey="RhAssiduidade"
                      title={t(`portalrh.NavBar.assiduidade`)}
                    >
                      <RhAssiduidade rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhAjudasCusto"
                      title={t(`portalrh.NavBar.ajudasCusto`)}
                    >
                      <RhAjudasCusto rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhHorasExtra"
                      title={t(`portalrh.NavBar.horasExtra`)}
                    >
                      <RhHorasExtra rowCollaborator={rowCollaborator} />
                    </Tab>
                    <Tab
                      eventKey="RhRecibos"
                      title={t(`portalrh.NavBar.recibos`)}
                    >
                      <RhRecibos rowCollaborator={rowCollaborator} />
                    </Tab>
                  </Tabs>
                </>
              )
            }
          </Media>
        </div>
      </>
    </div>
  );
};
export default withNamespaces()(RhNavBar);