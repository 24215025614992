import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, Alert } from "react-bootstrap";
import MyTeamRequestTable from "./MyTeamRequestTable";
import FlowModal from "~/components/FlowModal";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import NavTabs from "../../NavTabs";
import SpinnerNew from "~/components/SpinnerNew";
import "~/assets/css/icons.css";
import { setGenericAuthorizationState } from "~/store/ducks/portalrh/actionTypes";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import ModalRemoteWork from "./ModalRemoteWork";
import ModalMarcacaoFerias from "./ModalMarcacaoFerias";
import ModalAuthorizationRequests from "./ModalAuthorizationRequests";

import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

import {
  setTrabalhoRemoto,
  setTrabalhoRemotoInfoSaldo,
  getGruposTrabalhoRemoto,
} from "~/store/ducks/portalrh/actionTypes";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const MyTeamRequestPage = ({
  t,
  showFlowModal,
  handleOpenFlowModal,
  handleCloseFlowModal,
  selectedProcess,
  searchFilter,
  sectionName,
  portalRhMenus,
  activeSelected,
  designacao,
  handleOpenModalRemoteWork,
  showModalRemoteWork,
  handleCloseModalRemoteWork,
  path,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  handleOpenModalMarcacaoFerias,
  showModalMarcacaoFerias,
  handleCloseModalMarcacaoFerias,
  setShowModalMarcacaoFerias,
  makeUpdate,
  setmakeUpdate,
  callGetRequestsByUsername,
  handleOpenModalAuthorizationsRequests,
  showModalAuthorizationsRequests,
  handleCloseModalAuthorizationsRequests,
}) => {
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageToast, setmessageToast] = useState("");
  const [styleSheet, setFormStyleSheet] = useState("");
  const [typeRequest, setTypeRequest] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu) {
      let app = "";
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/");
        app = arr3[0];
      }

      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        setFormStyleSheet(styleSheet_);
      }
    }
  }, [menu]);

  useEffect(() => {
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
    let requestState = localStorage.getItem("authorizationState");
    if (requestState != null) {
      if (requestState.startsWith('"') && requestState.endsWith('"')) {
        requestState = JSON.parse(requestState);
      }
      dispatch(setGenericAuthorizationState(requestState));
    }
    dispatch(getCollaborators());
    dispatch(setTrabalhoRemoto({}));
    dispatch(setTrabalhoRemotoInfoSaldo({}));
    if (searchFilter === "remoteWork" || searchFilter === "marcacaoFerias") {
      const payload = {
        label:
          searchFilter === "marcacaoFerias"
            ? "vacationsManagement"
            : searchFilter,
        typeData: "AC",
      };
      dispatch(getGruposTrabalhoRemoto(payload));
    }
    //localStorage.removeItem("authorizationState")
  }, []);

  useEffect(() => {
    if (messageToast !== "") {
      setOpen(true);
    }
  }, [messageToast]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setmessageToast("");
    setTypeRequest("");
    setOpen(false);
  };
  const handlecloseModalMyTeamRequest = (searchFilter) => {
    handleCloseFlowModal(searchFilter, 0);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerNew open={isLoading} />
      ) : (
        <>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} variant={typeRequest}>
              {messageToast}
            </Alert>
          </Snackbar>
          <div className="main-card-v2" style={{ width: "100%" }}>
            <Card
              bsPrefix="card-flat"
              style={{ minHeight: "80vh", overflow: "auto" }}
            >
              <Card.Header className="justify-content-between">
                <NavTabs
                  titleMenu={t(`${sectionName}`)}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
              </Card.Header>
              <Card.Body>
                <MyTeamRequestTable
                  handleOpenFlowModal={handleOpenFlowModal}
                  searchFilter={searchFilter}
                  handleOpenModalRemoteWork={handleOpenModalRemoteWork}
                  handleOpenModalMarcacaoFerias={handleOpenModalMarcacaoFerias}
                  makeUpdate={makeUpdate}
                  setmakeUpdate={setmakeUpdate}
                  setmessageToast={setmessageToast}
                  setTypeRequest={setTypeRequest}
                  callGetRequestsByUsername={callGetRequestsByUsername}
                  handleOpenModalAuthorizationsRequests={
                    handleOpenModalAuthorizationsRequests
                  }
                />
                {showFlowModal && (
                  <FlowModal
                    showModal={showFlowModal}
                    closeModal={handlecloseModalMyTeamRequest}
                    process={selectedProcess}
                    isPortal={true}
                    searchFilter={searchFilter}
                  />
                )}
                {showModalRemoteWork && (
                  <ModalRemoteWork
                    process={selectedProcess}
                    closeButton
                    showModalRemoteWork={showModalRemoteWork}
                    handleCloseModalRemoteWork={handleCloseModalRemoteWork}
                    setmakeUpdate={setmakeUpdate}
                    path={path}
                    isEditable={true}
                    showCalendarAndTable={true}
                    loadEventsStart={true}
                    portalRhMenus={portalRhMenus}
                    designacao={designacao}
                    styleSheet={styleSheet}
                    searchFilter={searchFilter}
                  />
                )}
                {showModalMarcacaoFerias && (
                  <ModalMarcacaoFerias
                    process={selectedProcess}
                    closeButton
                    showModalMarcacaoFerias={showModalMarcacaoFerias}
                    handleCloseModalMarcacaoFerias={
                      handleCloseModalMarcacaoFerias
                    }
                    setmakeUpdate={setmakeUpdate}
                    path={path}
                    isEditable={true}
                    showCalendarAndTable={true}
                    loadEventsStart={true}
                    portalRhMenus={portalRhMenus}
                    designacao={designacao}
                    styleSheet={styleSheet}
                    searchFilter={searchFilter}
                  />
                )}

                {showModalAuthorizationsRequests && (
                  <ModalAuthorizationRequests
                    process={selectedProcess}
                    closeButton
                    showModalAuthorizationsRequests={
                      showModalAuthorizationsRequests
                    }
                    handleCloseModalAuthorizationsRequests={
                      handleCloseModalAuthorizationsRequests
                    }
                    setmakeUpdate={setmakeUpdate}
                    path={path}
                    portalRhMenus={portalRhMenus}
                    designacao={designacao}
                    styleSheet={styleSheet}
                    searchFilter={searchFilter}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </>
      )}
    </>
  );
};
export default withNamespaces()(MyTeamRequestPage);
