import {
  SET_PORTALRH_MENU,
  FIND_PORTALRH_MENU,
  SET_PORTALRH_ALL_MENUS,
} from "./actionTypes";
import { isEmpty, find } from "lodash";

const initialState = {
  menu: [],
  isLoadingPortalRhMenu: false,
  allMenus: [],
};

const generateTreeMenu = (menu, isMobile) => {
  if (isEmpty(menu)) return [];
  const treeMenu = {};
  menu.forEach((item) => {
    if (
      isMobile &&
      (item.designacao === "RH Admin" ||
        item.designacao === "Administração" ||
        item.designacao === "Desempenho" ||
        item.designacao === "Siadapra" ||
        item.designacao === "Comunicações")
    )
      return;
    if (treeMenu[item.id] && treeMenu[item.id].children) {
      item.children = treeMenu[item.id] && treeMenu[item.id].children;
    }
    treeMenu[item.id] = item;
    treeMenu[item.paiId] = treeMenu[item.paiId] || {};
    treeMenu[item.paiId].children = treeMenu[item.paiId].children || [];
    if (!find(treeMenu[item.paiId].children, { id: item.id })) {
      treeMenu[item.paiId].children.push(item);
    }
  });
  if (treeMenu[154] && treeMenu[154].children != (undefined || null))
    treeMenu[0].children.push(treeMenu[154].children);
  return treeMenu[0].children;
};

const removeNullAccao = (menus, isMobile) => {
  if (!isMobile) return menus;
  return menus.map((menu) => {
    if (menu.children) {
      menu.children = menu.children.filter(child => {
        if (child.accao === null) {
          
          return child.children ? true : false;
        } else {
          return true;
        }
      });
    }
    return menu;
  });
};

export function portalRhMenuReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_PORTALRH_MENU:
      return { ...state, isLoadingPortalRhMenu: true };

    case SET_PORTALRH_MENU:
      let menusTree = generateTreeMenu(action.menu, action.isMobile);
      const finalMenusTree = removeNullAccao(menusTree, action.isMobile);
      return {
        ...state,
        menu: finalMenusTree,
        isLoadingPortalRhMenu: false,
      };

    case SET_PORTALRH_ALL_MENUS:
      return {
        ...state,
        allMenus: action.allMenus,
        isLoadingPortalRhMenu: false,
      };

    default:
      return state;
  }
}
