import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { pagination } from "~/components/pagination/currentSizePerPage/10_wideRange";
import sortIcon from "../assets/img/icons/icon-sort.png";
import "../assets/scss/_table.scss";
import { withNamespaces } from "react-i18next";

const Table = ({
  docs,
  columns,
  sort,
  hidePagination,
  filter,
  t,
  setShowWorkPlan,
  setWorkplanId,
  key,
  getiFlowActivate,
  workplans,
}) => {
  function customCarret() {
    return <img src={sortIcon}></img>;
  }

  function customiseColumns() {
    const result = columns;
    result.forEach((element) => {
      element.sortCaret = customCarret;
    });
    return result;
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (row.account?.cor === 1) {
      style = { backgroundColor: "#FFEDED" };
    } else if (row.account?.cor === 2) {
      style = { backgroundColor: "#FFFBDE" };
    } else if (row.account?.cor === 3) {
      style = { backgroundColor: "#E9F5F0" };
    } else if (row.account?.cor === 4) {
      style = { backgroundColor: "#f9ddb1" };
    } else if (!row.active) {
      if (rowIndex % 2 === 0) {
        style = { backgroundColor: "#F6F6F6" };
      }
    } else {
      style = { backgroundColor: "#b8dce4" };
    }
    return style;
  };

  const tableRowEvents = {
    onClick: (event, row, __) => {
      event.preventDefault(); // Prevent the default action
  
      if (getiFlowActivate) {
        getiFlowActivate(row.id);
      }
      if (false && setWorkplanId != null && setShowWorkPlan != null) {
        setWorkplanId(row.id);
        setShowWorkPlan(true);
      }
    },
  };

  return (
    <div className="easypay-table table-responsive">
      <ToolkitProvider
        keyField={key ? key : "id"}
        data={docs}
        columns={customiseColumns(columns)}
        search={{ searchFormatted: true }}
      >
        {(props) => (
          <BootstrapTable
            {...props.baseProps}
            id="document-table-2"
            bootstrap4
            pagination={hidePagination ? false : paginationFactory(pagination)}
            noDataIndication={t("onboarding.general.noData")}
            pageSize={hidePagination ? false : 5}
            hover
            rowStyle={(row, rowStyles) =>
              rowStyle(row, rowStyles, ["list of colors"])
            }
            sort={sort}
            filter={filter}
            rowEvents={tableRowEvents}
          />
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(Table);
