import React from "react";
import { useSelector } from "react-redux";
import RenderForm from "~/pages/Easypay/pages/RenderForm";
import SpinnerNew from "~/components/SpinnerNew";

const CreateForm = (props) => {
  const { jsonData, flowid } = props;
  const { isLoadingEasypayFormdata } = useSelector(
    (state) => state.easypayReducer
  );

  const renderResponse = () => {
    if (jsonData) {
      if (jsonData.responseMessage) {
        const substrings = ["<div>", "<table>", "p"];
        if (new RegExp(substrings.join("|")).test(jsonData.responseMessage)) {
          // At least one match
          return (
            <div
              dangerouslySetInnerHTML={{ __html: jsonData.responseMessage }}
            />
          );
        } else return <div>{jsonData.responseMessage}</div>;
      } else if (jsonData.form) return <RenderForm data={jsonData} flowid={flowid} />;
    } else return <div></div>;
  };

  return (
    <>
      {!isLoadingEasypayFormdata ? (
        renderResponse()
      ) : (
        <SpinnerNew open={isLoadingEasypayFormdata} />
      )}
    </>
  );
};

export default CreateForm;
