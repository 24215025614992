import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Card } from "react-bootstrap";

import LoginLogo from "./LoginLogo";
import RegularAuthentication from "./RegularAuthentication";
import GoogleAuthentication from "./GoogleAuthentication";
import MicrosoftAuthentication from "./MicrosoftAuthentication";
import UdwADFSAuthentication from "./UdwADFSAuthentication";
import SAMLAuthentication from "./SAMLAuthentication";
import Toast from "~/components/Toast";
import SpinnerNew from "~/components/SpinnerNew";
import Error500 from "~/components/Exception/Error500";

import RegularAuthenticationNB from "./RegularAuthenticationNB";
import RegularAuthenticationISEL from "./RegularAuthenticationISEL";
import RegularAuthenticationLIFT from "./RegularAuthenticationLIFT";
import RegularAuthenticationFuture from "./RegularAuthenticationFuture";
import RegularAuthenticationMapei from "./RegularAuthenticationMapei";
import RegularAuthenticationEdenred from "./RegularAuthenticationEdenred";
import LoginLogoNB from "./LoginLogoNB";
import SignInWithSection from "./SignInWithSection";
import RegularAuthenticationSpark from "./RegularAuthenticationSpark";
import FullSpinner from "~/components/Spinner";
import DrpaFooter from "~/pages/Login/components/DrpaFooter";
import DrpaLogo from "~/assets/img/logos/acores-pesca.png";
import ISELLogo from "~/assets/img/logos/isel-logo.png";
import LIFTLogo from "~/assets/img/logos/logo-lift.png";
import UnikPeopleLogo from "~/assets/img/logos/unikpeople-logo.png";
import SparkLogo from "~/assets/img/logos/spark.png";
import MarquesLogo from "~/assets/img/logos/marques.png";
import FHCLogo from "~/assets/img/logos/fhc.png";
import IPSLogo from "~/assets/img/logos/ips.png";
import ADCLogo from "~/assets/img/logos/adc.png";
import LusitecaLogo from "~/assets/img/logos/lusiteca.png";
import BuzzLogo from "~/assets/img/logos/buzz.png";
import EdenRedLogo from "~/assets/img/logos/edenred.png";
import MapeiLogo from "~/assets/img/logos/logo-mapei.png";
import EdenredLogo from "~/assets/img/logos/logo-endenred.png";

import "~/assets/css/styles.css";

import { requestConfiguration } from "~/pages/Admin/SystemSettings/actions";
import EasyPaySSO from "./EasyPaySSO";
import WindowsAuthentication from "./WindowsAuthentication";
import KeycloakAuthentication from "./KeycloakAuthentication";
import * as authDomain from "../utils/AuthDomain";
import * as authDomainOld from "../utils/AuthDomainOld";

import { Divider, Chip, Box, Stack, Typography } from "@mui/material";

const LoginForm = (props) => {
  const { t, setKeycloakLoginClicked, setWindowsLoginClicked } = props;
  const dispatch = useDispatch();
  const {
    configuration,
    isLoadingConfiguration,
    successRetrieveConfiguration,
  } = useSelector((state) => state.adminConfigReducer);

  const [counter, setCounter] = useState(0);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    dispatch(requestConfiguration());
  }, []);

  authDomain.setConfiguration(configuration);
  authDomainOld.setConfiguration(configuration);

  function clickKeycloakLogin() {
    setKeycloakLoginClicked(true);
  }

  function clickWindowsLogin() {
    setWindowsLoginClicked(true);
  }

  useEffect(() => {
    if (counter === 0) {
      setFirstRender(true);
      setCounter(1);
    }

    if (counter >= 1) {
      setFirstRender(false);
    }
  }, [counter]);

  useEffect(() => {
    if (configuration && configuration.browserTabName) {
      document.title = configuration.browserTabName || "";
    }
  }, [configuration]);

  const renderLoginForm = () => {
    return (
      <div className="overlay">
        {!isLoadingConfiguration && successRetrieveConfiguration && (
          <>
            <Card className="card-login">
              <LoginLogo />
              <hr style={{ marginTop: "1rem" }} />
              {configuration.portalColaborador && (
                <>
                <hr style={{ marginTop: "1rem" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    minWidth: "fit-content",
                  }}
                >
                  PORTAL DO COLABORADOR
                </div>
                </>
              )}
              <Card.Body>
                {authDomain.isUnikSystem() && (
                  <RegularAuthentication
                    isStyled={
                      configuration.loginPage !== "default"
                        ? configuration.loginPage
                        : null
                    }
                  />
                )}

                {(configuration.createAccont ||
                  configuration.forgotPassword) && (
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "30px",
                      justifyContent: "space-between",
                      justifySelf: "right",
                      width: "100%",
                    }}
                  >
                    {configuration.createAccount && (
                      <span>
                        <small>{`${t("register.noAccount")} `}</small>

                        <Link to="/register" className="text-muted">
                          <small className="create-acc-label">
                            {t("register.createOne")}
                          </small>
                        </Link>
                      </span>
                    )}
                    {configuration.forgotPassword && (
                      <span>
                        <Link to="/forgot-password" className="text-muted">
                          <small>{t("login.forgotPassword")}</small>
                        </Link>
                      </span>
                    )}
                  </div>
                )}

                {authDomain.haveOtherOptions() && (
                  <Divider flexItem style={{ margin: "2rem" }}>
                    <Chip label={t("login.otherOptions")} size="small" />
                  </Divider>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    placeSelf: "center",
                  }}
                >
                  <Stack
                    spacing={4}
                    justifyContent="center"
                    direction="row"
                    alignItems="center"
                  >
                    {authDomain.isGoogleAuthentication() && (
                      <div
                        style={{
                          textAlign: "center",
                          pointerEvents: "auto",
                        }}
                      >
                        <GoogleAuthentication />
                      </div>
                    )}
                    {authDomain.isMicrosoftAuthentication() && (
                      <div
                        style={{
                          textAlign: "center",
                          pointerEvents: "auto",
                        }}
                      >
                        <MicrosoftAuthentication
                          isNB={
                            configuration.loginPage === "nblp"
                              ? configuration.loginPage
                              : null
                          }
                        />
                      </div>
                    )}
                    {authDomain.isKeycloakAuthentication() && (
                      <KeycloakAuthentication
                        auto={authDomain.isAutoLoginKeycloak()}
                        click={clickKeycloakLogin}
                      />
                    )}
                    {authDomain.isWindowsAuthentication() && (
                      <WindowsAuthentication
                        auto={authDomain.isAutoLoginWindows()}
                        click={clickWindowsLogin}
                      />
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    {authDomain.isADFSAuthentication() && (
                      <UdwADFSAuthentication />
                    )}
                  </Stack>
                </Box>
                {configuration.portalColaborador && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        placeSelf: "center",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        marginTop: "3rem",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                          style={{ width: "16rem" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </>
        )}
        {!isLoadingConfiguration && !successRetrieveConfiguration && (
          <Error500 />
        )}
      </div>
    );
  };

  const renderLoginOldForm = () => {
    let config = configuration?.loginPage;

    if (config === "nblp") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <LoginLogoNB />
              <hr style={{ marginTop: "0px" }} />
              <Card className="card-login">
                <Card.Body>
                  {authDomainOld.isRegularAuthentication() && (
                    <RegularAuthenticationNB />
                  )}
                  <SignInWithSection>
                    {authDomainOld.isADFSAuthentication() && (
                      <UdwADFSAuthentication old={true} />
                    )}
                    {authDomainOld.isMicrosoftAuthentication() && (
                      <MicrosoftAuthentication old={true} isNB={true} />
                    )}
                  </SignInWithSection>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "lift") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="lift-logo"
                        src={LIFTLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationLIFT />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "drpa") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={DrpaLogo}
                        alt="açores pesca"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                  </div>
                  <div className="drpa-login-2">
                    A{" "}
                    <a
                      href="https://portal.azores.gov.pt/web/drp"
                      target="_blank"
                    >
                      Direção Regional das Pescas – DRP
                    </a>{" "}
                    é o órgão operativo da{" "}
                    <a
                      href="https://portal.azores.gov.pt/web/srmp"
                      target="_blank"
                    >
                      Secretaria Regional do Mar e das Pescas
                    </a>
                    , que tem em vista a execução das política na área das
                    pescas.
                    <p>
                      Esta plataforma online assume como principal desígnio
                      desburocratizar a execução de protocolos celebrados no
                      âmbito do incentivo à coletividade no setor das pescas.
                    </p>
                    <p>
                      O acesso a esta plataforma é gerido pela DRP, devendo o
                      acesso ser requerido por correio eletrónico.
                    </p>
                    <p>
                      Morada: Colónia Alemã, Rua Consul Dabney 9900-014 Horta{" "}
                    </p>
                    <p>
                      Email:{" "}
                      <a href="mailto:Info.drp@azores.gov.pt">
                        Info.drp@azores.gov.pt
                      </a>{" "}
                    </p>
                    <p>Telefone: 292 202 400</p>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
          {!isLoadingConfiguration && <DrpaFooter />}
        </div>
      );
    } else if (config === "edenred") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="edenred-logo"
                        src={EdenredLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationEdenred />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "isel") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="isel-logo"
                        src={ISELLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationISEL />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "sparkCapital") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={SparkLogo}
                        alt="Spark Capital"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationSpark />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "grupoMarques") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={MarquesLogo}
                        alt="Grupo Marques"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "buzz") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={BuzzLogo}
                        alt="Buzz DMC"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} isStyled={"buzz"} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "edenred") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={EdenRedLogo}
                        alt="EdenRed"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} isStyled={"edenred"} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "lusiteca") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={LusitecaLogo}
                        alt="Lusiteca"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "future") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="isel-logo"
                        src={FHCLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationFuture />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "ips") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={IPSLogo}
                        alt="Politécnico de Snatarém"
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "adc") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={ADCLogo}
                        alt="AD&C - Agência para o Desenvolvimento e Coesão, I.P."
                      />
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthentication old={true} />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "mapei") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="isel-logo"
                        src={MapeiLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomainOld.isRegularAuthentication() && (
                      <RegularAuthenticationMapei />
                    )}
                    {(authDomainOld.isGoogleAuthentication() ||
                      authDomainOld.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomainOld.isGoogleAuthentication() && (
                          <GoogleAuthentication old={true} />
                        )}
                        {authDomainOld.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication old={true} />
                        )}
                        {authDomainOld.isADFSAuthentication() && (
                          <UdwADFSAuthentication old={true} />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomainOld.isSAMLAuthentication() && (
                      <SAMLAuthentication old={true} />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <LoginLogo />
              <hr style={{ marginTop: "0px" }} />
              <Card className="card-login">
                <Card.Body>
                  {authDomainOld.isRegularAuthentication() && (
                    <RegularAuthentication old={true} />
                  )}
                  {(authDomainOld.isGoogleAuthentication() ||
                    authDomainOld.isMicrosoftAuthentication() ||
                    authDomainOld.isWindowsAuthentication() ||
                    authDomainOld.isKeycloakAuthentication()) && (
                    <SignInWithSection>
                      {authDomainOld.isGoogleAuthentication() && (
                        <GoogleAuthentication old={true} />
                      )}
                      {authDomainOld.isMicrosoftAuthentication() && (
                        <MicrosoftAuthentication old={true} />
                      )}
                      {authDomainOld.isWindowsAuthentication() && (
                        <WindowsAuthentication
                          old={true}
                          auto={authDomainOld.isAutoLoginWindows()}
                          click={clickWindowsLogin}
                        />
                      )}
                      {authDomainOld.isKeycloakAuthentication() && (
                        <KeycloakAuthentication
                          old={true}
                          auto={authDomainOld.isAutoLoginKeycloak()}
                          click={clickKeycloakLogin}
                        />
                      )}
                      {authDomainOld.isADFSAuthentication() && (
                        <UdwADFSAuthentication old={true} />
                      )}
                    </SignInWithSection>
                  )}
                  {authDomainOld.isSAMLAuthentication() && (
                    <SAMLAuthentication old={true} />
                  )}
                  {(authDomainOld.isUnikSystem() ||
                    authDomainOld.isUDWGoogle()) &&
                    config !== "cma" && (
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "30px",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          <small>{`${t("register.noAccount")} `}</small>

                          <Link to="/register" className="text-muted">
                            <small className="create-acc-label">
                              {t("register.createOne")}
                            </small>
                          </Link>
                        </span>
                        <span>
                          <Link to="/forgot-password" className="text-muted">
                            <small>{t("login.forgotPassword")}</small>
                          </Link>
                        </span>
                      </div>
                    )}
                </Card.Body>
              </Card>
              {(authDomainOld.isIFlowSaml() || authDomainOld.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    }
  };

  return (
    <>
      {configuration?.newLogin ? renderLoginForm() : renderLoginOldForm()}
      <Toast />
      <SpinnerNew open={isLoadingConfiguration} />

      {firstRender ? <EasyPaySSO /> : null}
    </>
  );
};

export default withRouter(withNamespaces()(LoginForm));
