import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { Box, Stack, Typography } from "@mui/material";
import { withNamespaces } from "react-i18next";
import * as actions from "../actions";
import { GRANT_TYPE_PASSWORD } from "~/utils/constants";

const CheckInOutAuthentication = ({ t, isStyled }) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [customizedLogin, setcustomizedLogin] = useState(isStyled);

  const { isLoadingCheckInOutAuth } = useSelector(
    (state) => state.loginReducer
  );

  const { successRetrieveConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  const handleRegistarPonto = (e) => {
    e.preventDefault();
    const date = new Date();
    const payload = {
      username,
      password,
      grant_type: GRANT_TYPE_PASSWORD,
    };
    dispatch(actions.requestRegistarPonto(payload, date));
  };

  return (
    <Form onSubmit={handleRegistarPonto}>
      <Box
        sx={{ textAlign: "center", marginBottom: "2rem", marginTop: "2rem" }}
      >
        <Typography variant="h5">Registo de ponto</Typography>
      </Box>
      <Stack spacing={4} sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccountCircleOutlinedIcon
            sx={{ color: "action.active", mr: 1, my: 0.5 }}
          />
          <div className="floating-label">
            <Form.Control
              type="text"
              name="new-username"
              id="new-username"
              autoComplete="new-username"
              placeholder=" "
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label>{t("login.username")}</label>
            <Form.Control.Feedback type="invalid">
              {t("login.requiredUsername")}
            </Form.Control.Feedback>
          </div>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LockOpenOutlinedIcon
            sx={{ color: "action.active", mr: 1, my: 0.5 }}
          />
          <div className="floating-label">
            <Form.Control
              type="password"
              name="new-password"
              id="new-password"
              autoComplete="new-password"
              placeholder=" "
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>{t("login.password")}</label>
            <Form.Control.Feedback type="invalid">
              {t("login.requiredPassword")}
            </Form.Control.Feedback>
          </div>
        </Box>
      </Stack>
      <div className="buttons">
        <LoadingButton
          type="submit"
          loading={isLoadingCheckInOutAuth}
          className={`${
            customizedLogin ? `${customizedLogin}Style` : "login-button"
          }`}
          variant="contained"
          disabled={!successRetrieveConfiguration}
          size="medium"
          sx={{ textTransform: "none" }}
        >
          {!isLoadingCheckInOutAuth && "Registar"}
        </LoadingButton>
      </div>
    </Form>
  );
};

export default withNamespaces()(CheckInOutAuthentication);
