import React, { useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
import SpinnerNew from "~/components/SpinnerNew";
import {
  getMetadataDocument,
  refreshDocument,
} from "~/pages/DataCapture/actions";
import {
  getColorByStatusDocument,
  translateStatusDocument,
} from "~/pages/DataCapture/utils";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import { toLocaleString } from "~/utils";
import MetadataModal from "./MetadataModal";

const DocumentTable = ({ t, documents, menuId }) => {
  const dispatch = useDispatch();

  const { isRefreshing, isSavingMetadataDocument } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [isEditingMetadata, setEditingMetadata] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleOpenMetadataModal = (row, isEditing) => {
    setEditingMetadata(isEditing);
    dispatch(getMetadataDocument(row.id));
    setShowMetadataModal(true);
    setSelectedDocument(row);
  };

  const handleCloseDetailModal = () => {
    setShowMetadataModal(false);
  };

  const handleRefreshDocument = (row) => {
    dispatch(refreshDocument(row));
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const buildActionsColumn = (_, row, {}) => (
    <>
      {DocumentStatus.LOADED !== row.statusV1 &&
        DocumentStatus.REVISED !== row.statusV1 &&
        DocumentStatus.REVIEWED !== row.statusV1 && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(t("datacapture.general.refreshDocument"))}
          >
            <i
              className="icon-refresh table-action-icon"
              onClick={() => handleRefreshDocument(row)}
            />
          </OverlayTrigger>
        )}
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("datacapture.general.detailDocument"))}
      >
        <i
          className="icon-detail table-action-icon"
          onClick={() => handleOpenMetadataModal(row, false)}
        />
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("datacapture.general.editDocument"))}
      >
        <i
          className="icon-edit table-action-icon"
          onClick={() => handleOpenMetadataModal(row, true)}
        />
      </OverlayTrigger>
    </>
  );

  const buildStatusColumn = (_, row, {}) => {
    const variant = getColorByStatusDocument(row.statusV1);
    const translatedStatus = translateStatusDocument(row.statusV1);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const buildDocTypeColumn = (_, row, {}) => {
    return row.metadata && row.metadata["document:class"]
      ? t(`datacapture.general.${row.metadata["document:class"]}`)
      : t("datacapture.general.undetermined");
  };

  const columns = [
    {
      dataField: "metadata.documentClass",
      text: t("datacapture.general.documentClass"),
      formatter: buildDocTypeColumn,
    },
    {
      dataField: "createdDate",
      text: t("datacapture.general.created"),
      formatter: (_, row, {}) => toLocaleString(row.createdDate),
    },
    {
      dataField: "filename",
      text: t("datacapture.general.filename"),
      style: {
        overflowWrap: "break-word",
      },
    },
    {
      dataField: "statusV1",
      text: t("datacapture.general.status"),
      formatter: buildStatusColumn,
    },
    {
      dataField: "actions",
      text: t("datacapture.general.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
  ];

  return (
    <div>
      <h1 className="display-4">{t("datacapture.general.uploadedFiles")}</h1>
      <BootstrapTable
        keyField="id"
        data={documents}
        columns={columns}
        noDataIndication={t("datacapture.general.dataNotFound")}
        hover
        striped
      />

      <MetadataModal
        showModal={showMetadataModal}
        closeModal={handleCloseDetailModal}
        isEditing={isEditingMetadata}
        document={selectedDocument}
        menuId={menuId}
      />

      <SpinnerNew open={isRefreshing || isSavingMetadataDocument} />
    </div>
  );
};
export default withNamespaces()(DocumentTable);
