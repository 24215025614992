import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Row } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

const ExportFilter = ({ t, callBack, handleReset, setshowDates }) => {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [variantToday, setVariantToday] = useState("light");
  const [variantThisWeek, setVariantThisWeek] = useState("light");
  const [variantThisMonth, setVariantThisMonth] = useState("light");
  const [variantAll, setVariantAll] = useState("secondary");
  const [variantNew, setVariantNew] = useState("light");
  const [variantDates, setVariantDates] = useState("light");


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {}, [
    variantToday,
    variantThisWeek,
    variantThisMonth,
    variantAll,
    variantDates
  ]);

  useEffect(() => {
    dataToParent("all");
    handleReset()
  }, []);

  const dataToParent = (data) => {
    callBack(data);
  };

  /**Styles */
  const badgeStyle = {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  };

  const unselectBadges = () => {
    if (variantToday === "secondary") {
      setVariantToday("light");
    }
    if (variantThisWeek === "secondary") {
      setVariantThisWeek("light");
    }
    if (variantThisMonth === "secondary") {
      setVariantThisMonth("light");
    }
    if (variantAll === "secondary") {
      setVariantAll("light");
    }
    if (variantNew === "secondary") {
      setVariantNew("light");
    }
    if (variantDates === "secondary") {
      setVariantDates("light");
    }
  };

  const selectToday = () => {
    setVariantToday("secondary");
    dataToParent("today");
    handleReset()
  };

  const selectThisWeek = () => {
    setVariantThisWeek("secondary");
    dataToParent("week");
    handleReset()
  };

  const selectThisMonth = () => {
    setVariantThisMonth("secondary");
    dataToParent("month");
    handleReset()
  };

  const selectAll = () => {
    setVariantAll("secondary");
    dataToParent("all");
    handleReset()
  };

  const selectNew = () => {
    setVariantNew("secondary");
    dataToParent("new");
    handleReset()
  };

  const selectDates = () => {
    setVariantDates("secondary");
    dataToParent("all");
    setshowDates(true)
  };

  return (
    <div style={{ paddingBottom: "5px", marginBottom: "10px" }}>
      <Row
        style={
          isMobile
            ? {
                display: "flex",
                flexWrap: "nowrap",
                margin: "0px",
                height: "30px",
                fontSize: "12px",
              }
            : {
                display: "flex",
                flexWrap: "nowrap",
                margin: "0px",
                height: "30px",
              }
        }
      >
        <Badge
          variant={variantToday}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectToday();
          }}
        >
          {t("datacapture.general.today")}
        </Badge>
        <Badge
          variant={variantThisWeek}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectThisWeek();
          }}
        >
          {t("datacapture.general.thisWeek")}
        </Badge>

        <Badge
          variant={variantThisMonth}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectThisMonth();
          }}
        >
          {t("datacapture.general.thisMonth")}
        </Badge>
        <Badge
          variant={variantAll}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectAll();
          }}
        >
          {t("datacapture.general.all")}
        </Badge>
        <Badge
          variant={variantNew}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectNew();
          }}
        >
          New
        </Badge>
        <Badge
          variant={variantDates}
          style={{ ...badgeStyle, marginRight: "5px" }}
          onClick={() => {
            unselectBadges();
            selectDates();
          }}
        >
          {t("datacapture.general.between")}
        </Badge>

      </Row>
    </div>
  );
};

export default withNamespaces()(ExportFilter);
