import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import windows from "~/assets/img/icons/windows.svg";
import { Typography } from "@mui/material";

const WindowsAuthentication = (props) => {
  const { click, auto, old = false } = props;

  useEffect(() => {
    if (auto) {
      click();
    }
  }, [auto]);

  return (
    <>
      {old ? (
        <button className="btn-windows" onClick={() => click()}>
          <div style={{ display: "flex" }}>
            <div>
              <img className="logo-windows" alt="windows" src={windows} />
            </div>
            <div
              style={{
                flexGrow: "1",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Windows
            </div>
          </div>
        </button>
      ) : (
        <div
          style={{ textAlign: "center", pointerEvents: "auto" }}
          onClick={() => click()}
          role="button"
        >
          <img className="logo-windows" alt="windows" src={windows} />
          <Typography variant="caption" display="block" gutterBottom>
            Windows
          </Typography>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(WindowsAuthentication);
