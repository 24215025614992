import React from "react";
import { TbBellCheck, TbBellFilled } from "react-icons/tb";
import Chip from "@mui/material/Chip";
import { withNamespaces } from "react-i18next";

const Notifications = ({ notifications, t }) => {
  const renderNotificationCard = (notification) => {
    const { createdBy, sentTo, subject, createdDate, active, seen } =
      notification;
    const truncatedSubject =
      subject.length > 20 ? `${subject.substring(0, 20)}...` : subject;
    const formattedDate = createdDate.split("T")[0];

    return (
      <div
        key={notification.id}
        className="card_container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "0.5rem",
          marginBottom: "0.5rem",
          backgroundColor: "whitesmoke",
          borderRadius: "8px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          fontSize: "12px",
        }}
      >
        {/* Top Row */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>
            <strong>{t("widgets.NotificationWidget.createdBy")}:</strong>{" "}
            {createdBy}
          </p>
          <p>
            <strong>{t("widgets.NotificationWidget.sentTo")}:</strong> {sentTo}
          </p>
        </div>

        {/* Subject Row */}
        <div
          style={{
            marginBottom: "0.5rem",
            width: "100%",
          }}
        >
          <p>
            <strong>{t("widgets.NotificationWidget.subject")}:</strong>{" "}
            {truncatedSubject}
          </p>
        </div>

        {/* Bottom Row */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <span style={{ display: "flex", gap: "0.5rem" }}>
            <p>
              <strong>{t("widgets.NotificationWidget.createdDate")}:</strong>{" "}
              {formattedDate}
            </p>
            <div>
              {seen ? (
                <TbBellCheck style={{ fontSize: "large", color: "inherit" }} />
              ) : (
                <TbBellFilled style={{ fontSize: "large", color: "inherit" }} />
              )}
            </div>
          </span>
          <span
            className={`badge ${active ? "badge-green" : "badge-gray"}`}
            style={{
              padding: "2px 5px",
              borderRadius: "5px",
            }}
          >
            {active ? (
              <Chip
                label={t("widgets.NotificationWidget.active")}
                color="success"
                variant="outlined"
              />
            ) : (
              <Chip
                label={t("widgets.NotificationWidget.inactive")}
                color="error"
                variant="outlined"
              />
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="notificationsscroll"
      style={{
        maxHeight: "calc(3 * 6rem)", // Adjust the height as needed
        overflowY: "auto",
      }}
    >
      {Object.keys(notifications).length === 0 ? (
        <p>{t("widgets.NotificationWidget.noNotifications")}</p>
      ) : (
        Object.values(notifications).map(renderNotificationCard)
      )}
    </div>
  );
};

export default withNamespaces()(Notifications);
