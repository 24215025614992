import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withNamespaces } from "react-i18next";
import Breadcrumb from "~/pages/Easypay/components/Breadcrumb";
import Button from "~/pages/Easypay/components/Button";
import Checkbox from "~/pages/Easypay/components/Checkbox";
import Header from "~/pages/Easypay/components/Header";
import InputDate from "~/pages/Easypay/components/InputDate";
import InputSearch from "~/pages/Easypay/components/InputSearch";
import Link from "~/pages/Easypay/components/Link";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import Toggle from "~/pages/Easypay/components/Toggle";
import "~pages/Easypay/assets/css/styles.css";
import Accordion from "~pages/Easypay/components/Accordion";
import Bullet from "~pages/Easypay/components/Bullet";
import DocumentNavigation from "~pages/Easypay/components/DocumentNavigation";
import FeedbackMessage from "~pages/Easypay/components/FeedbackMessage";
import Dropdown from "~pages/Easypay/components/InputDropdown";
import InputPassword from "~pages/Easypay/components/InputPassword";
import ModalContainer from "~pages/Easypay/components/ModalContainer";
import Pagination from "~pages/Easypay/components/Pagination";
import SingleAccordion from "~pages/Easypay/components/SingleAccordion";
import SpinnerNew from "~/components/SpinnerNew";
import Table from "~pages/Easypay/components/Table";
import Tag from "~pages/Easypay/components/Tag";
import {
  accordions,
  breadcrumbItems,
  bulletList,
  columns,
  docs,
  documentsList,
  list,
} from "~pages/Easypay/testData";

const RegisterEasypay = () => {
  const { register, formState, watch } = useForm();

  const [selected, setSelected] = useState("");
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [filteredList, setFilteredList] = useState(list);
  const [filteredListDropdown, setFilteredListDropdown] = useState(list);
  const [pageSelected, setPageSelected] = useState(1);
  const [documentSelected, setDocumentSelected] = useState(documentsList[0]);
  const [pwd, setPwd] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  const paginationList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [isModalShowing, setIsModalShowing] = useState(false);

  useEffect(() => {
    selected && setFilteredList(filterSelected(selected));
  }, [selected]);

  useEffect(() => {
    selectedDropdown &&
      setFilteredListDropdown(filterSelected(selectedDropdown));
  }, [selectedDropdown]);

  const filterSelected = (selected) =>
    list.map((i) => {
      i.value === selected ? (i.selected = true) : (i.selected = false);
      return i;
    });

  const modalContents = {
    modalTitle: "Modal",
    modalBody:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    modalFooter: "Buttons",
  };

  const handleSelectNavItems = (list, activeItem) => {
    return list.map((item) => {
      item.name === activeItem ? (item.active = true) : (item.active = false);
      return item;
    });
  };

  return (
    <div style={{ overflowY: "scroll" }}>
      <Header />
      <div className="easypay-all-components">
        <ModalContainer
          contents={modalContents}
          isShowing={isModalShowing}
          setIsShowing={setIsModalShowing}
        />
        <SpinnerNew open={true} />
        <br />

        <TabSeparatorWrapper>
          <TabSeparator text="Leads" name="tab1">
            <line style={{ display: "flex" }}>
              <div style={{ width: 628 }}>
                <InputSearch
                  field={{ text: "inputLabel" }}
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredList}
                  handleSubmit={(value) => setSelected(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Conta</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Data criação</text>
                <InputDate
                  id="easypay-firstname-2"
                  register={register}
                  formState={formState}
                  errorMessage="This field is required"
                  watch={watch}
                  type="date"
                  placeholder="Insira um novo valor"
                  defaultValue=""
                />
              </div>
              <div style={{ width: 290 }}>
                <Dropdown
                  corAmararela={true}
                  text={"Criado por"}
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
            </line>
            <line style={{ display: "flex" }}>
              <div style={{ width: 290 }}>
                <text>Departamento</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Etapa</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Resultado</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Cor</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
            </line>
            <Table docs={docs} columns={columns} />{" "}
          </TabSeparator>
          <TabSeparator text="Onboarding" name="tab2">
            <line style={{ display: "flex" }}>
              <div style={{ width: 628 }}>
                <text>Pesquisa</text>
                <InputSearch
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredList}
                  handleSubmit={(value) => setSelected(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Conta</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Data criação</text>
                <InputDate
                  id="easypay-firstname-2"
                  register={register}
                  formState={formState}
                  errorMessage="This field is required"
                  watch={watch}
                  type="date"
                  placeholder="Insira um novo valor"
                  defaultValue=""
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Criado por</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
            </line>
            <line style={{ display: "flex" }}>
              <div style={{ width: 290 }}>
                <text>Departamento</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Etapa</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Resultado</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
              <div style={{ width: 290 }}>
                <text>Cor</text>
                <Dropdown
                  required={true}
                  placeholder="Select Dropdown Option"
                  list={filteredListDropdown}
                  handleSubmit={(value) => setSelectedDropdown(value)}
                />
              </div>
            </line>
            <Table docs={docs} columns={columns} />{" "}
          </TabSeparator>
        </TabSeparatorWrapper>

        <br />
        <div style={{ margin: "0px 30px" }}>
          <h4>Buttons and Links</h4>
          <hr />
        </div>
        <br />
        <br />
        <br />

        <Button
          variant={"easypay-button-primary-large"}
          text="Btn primary large"
        />
        <Button
          variant={"easypay-button-primary-large"}
          text="Btn primary large"
          disabled
        />
        <Button
          variant={"easypay-button-secondary-large"}
          text="Btn primary large"
        />
        <Button
          variant={"easypay-button-secondary-large"}
          text="Btn primary large"
          disabled
        />
        <Button
          variant={"easypay-button-secondary-large"}
          text="Btn primary large"
        />
        <Button
          variant={"easypay-button-secondary-large"}
          text="Btn primary large"
          disabled
        />
        <Button variant={"easypay-button-primary"} text="Btn primary" />
        <Button
          variant={"easypay-button-primary"}
          text="Btn primary"
          disabled
        />
        <Button variant={"easypay-button-secondary-icon"} text="Carregar" />
        <Button
          variant={"easypay-button-secondary-icon"}
          text="Carregar"
          disabled
        />
        <br />
        <Button variant={"easypay-button-add"} text="Adicionar" />
        <Button variant={"easypay-button-add"} text="Adicionar" disabled />
        <Toggle id="test-lsbrl" />
        <Toggle id="test2" disabled />
        <Link variant={"easypay-remove"} text="Remover" link="" />
        <Link variant={"easypay-remove"} text="Remover" link="" disabled />
        <Link text="Remover" link="" />
        <Link text="Remover" link="" disabled />
        <Checkbox name="checkbox1" id="check1" value="Label" />
        <Checkbox name="checkbox1" id="check1" value="Label" disabled />
        <Checkbox name="checkbox1" id="check1" value="Label" disabled checked />
        {/* <PEPCheckbox
          id="easypay-pepcheckbox-name"
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="text"
          placeholder="Insira um novo valor"
          defaultValue=""
          size={100}
        ></PEPCheckbox> */}
        <Button
          variant={"easypay-button-add easypay-document"}
          text="Solicitar novo documento"
        />
        <Button
          variant={"easypay-button-add easypay-document"}
          text="Solicitar novo documento"
          disabled
        />
        <Button
          variant={"easypay-button-primary easypay-error"}
          text="Reprovar"
        />
        <Button
          variant={"easypay-button-primary easypay-error"}
          text="Reprovar"
          disabled
        />
        <Button
          variant={"easypay-button-primary easypay-success"}
          text="Aprovar"
        />
        <Button
          variant={"easypay-button-primary easypay-success"}
          text="Aprovar"
          disabled
        />
        <br />
        <div style={{ margin: "50px 30px" }}>
          <h4>Tabs and Navigation</h4>
          <hr />
        </div>
        <Breadcrumb breadcrumbStates={breadcrumbItems} />
        <br />
        <div style={{ margin: "50px 30px" }}>
          <h4>Inputs and dropdowns</h4>
          <hr />
        </div>
        <div style={{ width: "80%", padding: "30px" }}>
          <br />
          <Dropdown
            required={true}
            placeholder="Select Dropdown Option"
            list={filteredListDropdown}
            handleSubmit={(value) => setSelectedDropdown(value)}
          />
          <br />

          <InputSearch
            required={true}
            placeholder="Select Dropdown Option"
            list={filteredList}
            handleSubmit={(value) => setSelected(value)}
          />

          <InputSearch disabled />
        </div>
        <InputDate
          id="easypay-firstname-2"
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="date"
          placeholder="Insira um novo valor"
          defaultValue=""
        />
        <InputDate
          id="easypay-firstname-2"
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="date"
          placeholder="Insira um novo valor"
          defaultValue=""
          disabled
        />
        <br />
        <div style={{ margin: "30px" }}>
          <InputPassword
            pwd={pwd}
            setPwd={setPwd}
            setPasswordValid={setPasswordValid}
          ></InputPassword>
        </div>
        <DocumentNavigation
          selected={documentSelected}
          setSelected={setDocumentSelected}
          documentsList={documentsList}
          disabled
        />
        <DocumentNavigation
          selected={documentSelected}
          setSelected={setDocumentSelected}
          documentsList={documentsList}
        />
        <div style={{ margin: "50px 30px" }}>
          <h4>Content</h4>
          <hr />
        </div>
        <Accordion list={accordions}></Accordion>
        <br />
        <SingleAccordion title={"Titulo teste"} />
        <div style={{ margin: "30px" }}>
          <Tag variant="easypay-tag" text="Tag Small" type="easypay-small" />
          <br />
          <Tag variant="easypay-tag" text="Tag Normal" type="easypay-normal" />
          <br />
          <Tag variant="easypay-tag" text="Tag Large" type="easypay-large" />
          <br />
          <Bullet list={bulletList} />
          <br />
          <Tag variant="easypay-tag-label" text="000" type="easypay-approved" />
          <br />
          <Tag variant="easypay-tag-label" text="000" type="easypay-denied" />
          <br />
          <Tag
            variant="easypay-tag-label"
            text="000"
            type="easypay-evaluation"
          />
          <br />
          <Tag variant="easypay-onboarding-step" text="Questionário" />
          <br />
          <Tag variant="easypay-chip" text="Chip Label" />
        </div>
        <Button
          variant={"easypay-button-secondary-large"}
          text="Open Modal"
          onClick={setIsModalShowing}
        />
        <FeedbackMessage
          text="Beneficiário Efetivo Adicionado"
          variant="easypay-feedback-message-success"
        />
        <FeedbackMessage
          text="Beneficiário Efetivo Adicionado"
          variant="easypay-feedback-message-error"
        />
        <FeedbackMessage
          text="Beneficiário Efetivo Adicionado"
          variant="easypay-feedback-message-warning"
        />
        <FeedbackMessage
          text="Beneficiário Efetivo Adicionado"
          variant="easypay-feedback-message-info"
        />
        <div style={{ margin: "50px 30px" }}>
          <h4>Table Records</h4>
          <hr />
        </div>
        <Pagination
          list={paginationList}
          pageSelected={pageSelected}
          setPageSelected={setPageSelected}
        />
        <br />
        <Table docs={docs} columns={columns} />
        <br />
        <br />
      </div>
    </div>
  );
};
export default withNamespaces()(RegisterEasypay);
