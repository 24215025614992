import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateConfiguration } from "../actions";

const AuthMode = (props) => {
  const { t } = props;
  const [authDomains, setAuthDomains] = useState([]);
  const [isAutoLoginWindowsDisabled, setIsAutoLoginWindowsDisabled] = useState(false);
  const [isAutoLoginKeycloakDisabled, setIsAutoLoginKeycloakDisabled] = useState(false);
  const [isAutoLoginWindowsClicked, setIsAutoLoginWindowsClicked] = useState(false);
  const [isAutoLoginKeycloakClicked, setIsAutoLoginKeycloakClicked] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [newLogin, setNewLogin] = useState(false);

  const dispatch = useDispatch();
  const { authModes, configuration } = useSelector((state) => state.adminConfigReducer);

  useEffect(() => {
    if (configuration && configuration.authDomains) {
      setAuthDomains(
        configuration.authDomains.map((i) => {
          if (i.applicationName === "Google" || i.applicationName === "Microsoft") {
            i.unique = false;
          } else {
            i.unique = true;
          }
          return i;
        })
      );
      setForgotPassword(configuration.forgotPassword || false);
      setCreateAccount(configuration.createAccount || false);
      setNewLogin(configuration.newLogin || false);
    }
  }, [configuration]);

  const getAuthChecked = (id_) => {
    if (authDomains) {
      if (authDomains.some((i) => i.id === id_)) return true;
      else {
        return false;
      }
    } else {
      return false;
    }
  };

  const toggleAuthMode = (id, applicationName) => {
    if (authDomains) {
      let authDomains_ = [...authDomains];
      //untoggle
      if (authDomains_.some((i) => i.id === id)) {
        authDomains_ = authDomains_.filter((item) => item.id !== id);
        setIsAutoLoginKeycloakDisabled(false);
        setIsAutoLoginWindowsDisabled(false);
        //toggle
      } else {
        //item with prop "unique" must be only one
        let unique = false;
        if (applicationName !== "Microsoft" && applicationName !== "Google" && applicationName !== "Windows" && applicationName !== "Keycloak")
          unique = true;
        if (authDomains_.some((i) => i.unique === true) && unique === true) {
          //remove old unique item
          authDomains_ = authDomains_.filter((item) => item.unique === false);
        }
        //add new item
        authDomains_.push({
          id: id,
          applicationName: applicationName,
          unique: unique,
        });
      }
      setAuthDomains(authDomains_);
    }
  };

  const saveAuthMode = () => {
    const newConfiguration = {
      ...configuration,
      authDomains: authDomains,
      autoWindows: isAutoLoginWindowsClicked ? 1 : 0,
      autoKeycloak: isAutoLoginKeycloakClicked ? 1 : 0,
      forgotPassword: forgotPassword,
      createAccount: createAccount,
      newLogin: newLogin,
    };
    
    dispatch(updateConfiguration(newConfiguration));
  };

  const setAutoLoginClicked = (appName) => {
    if (appName === "Windows") {
      if (!isAutoLoginKeycloakDisabled) {
        setIsAutoLoginWindowsClicked(true);
      } else {
        setIsAutoLoginWindowsClicked(false);
      }
      setIsAutoLoginKeycloakDisabled(!isAutoLoginKeycloakDisabled);
    } else if (appName === "Keycloak") {
      if (!isAutoLoginWindowsDisabled) {
        setIsAutoLoginKeycloakClicked(true);
      } else {
        setIsAutoLoginKeycloakClicked(false);
      }
      setIsAutoLoginWindowsDisabled(!isAutoLoginWindowsDisabled);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        {authModes &&
          authModes.map((mode, i) => {
            if (
              mode.applicationName !== "Microsoft" &&
              mode.applicationName !== "Google" &&
              mode.applicationName !== "Windows" &&
              mode.applicationName !== "Keycloak"
            )
              return (
                <Form.Check
                  key={i + 1}
                  type="switch"
                  id={i + 1}
                  label={mode.applicationName}
                  checked={getAuthChecked(mode.id)}
                  onChange={() => toggleAuthMode(mode.id, mode.applicationName)}
                />
              );
          })}
        <br />
        {authModes &&
          authModes.map((mode, i) => {
            if (mode.applicationName === "Microsoft" || mode.applicationName === "Google")
              return (
                <Form.Check
                  key={i + 1}
                  type="switch"
                  id={i + 1}
                  label={mode.applicationName}
                  checked={getAuthChecked(mode.id)}
                  onChange={() => toggleAuthMode(mode.id, mode.applicationName)}
                />
              );
            else if (mode.applicationName === "Windows") {
              return (
                <div key={i} style={{ display: "flex" }}>
                  {getAuthChecked(mode.id) ? (
                    <Form.Check
                      inline
                      label="Auto-Login"
                      name="group1"
                      type="checkbox"
                      id="checkbox-windows"
                      disabled={isAutoLoginWindowsDisabled}
                      onChange={() => setAutoLoginClicked(mode.applicationName)}
                      defaultChecked={mode.auto}
                    />
                  ) : null}
                  <Form.Check
                    key={i + 1}
                    type="switch"
                    id={i + 1}
                    label={mode.applicationName}
                    checked={getAuthChecked(mode.id)}
                    onChange={() => toggleAuthMode(mode.id, mode.applicationName)}
                  />
                </div>
              );
            } else if (mode.applicationName === "Keycloak") {
              return (
                <div style={{ display: "flex" }}>
                  {getAuthChecked(mode.id) ? (
                    <Form.Check
                      defaultChecked={mode.auto}
                      inline
                      label="Auto-Login"
                      name="group1"
                      type="checkbox"
                      id="checkbox-keycloak"
                      onChange={() => setAutoLoginClicked(mode.applicationName)}
                      disabled={isAutoLoginKeycloakDisabled}
                    />
                  ) : null}
                  <Form.Check
                    key={i + 1}
                    type="switch"
                    id={i + 1}
                    label={mode.applicationName}
                    checked={getAuthChecked(mode.id)}
                    onChange={() => toggleAuthMode(mode.id, mode.applicationName)}
                  />
                </div>
              );
            }
          })}
        <br />
        <Button className="card-button" onClick={() => saveAuthMode()}>
          {t("general.save")}
        </Button>
      </div>
      <div style={{ flex: 1 }}>
        <Form.Check
          type="switch"
          id="forgotPassword"
          label="Forgot Password"
          checked={forgotPassword}
          onChange={() => setForgotPassword(!forgotPassword)}
          disabled={!newLogin}
        />
        <Form.Check
          type="switch"
          id="createAccount"
          label="Create Account"
          checked={createAccount}
          onChange={() => setCreateAccount(!createAccount)}
          disabled={!newLogin}
        />
        <Form.Check
          type="switch"
          id="newLogin"
          label="New Login"
          checked={newLogin}
          onChange={() => setNewLogin(!newLogin)}
        />
      </div>
    </div>
  );
};

export default withNamespaces()(AuthMode);