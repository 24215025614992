import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { Modal, Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import Spinner from "../../../../components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import { LiaUserShieldSolid, LiaUserClockSolid } from "react-icons/lia";
import { MdLockClock } from "react-icons/md";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import "dayjs/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SpinnerNew from "~/components/SpinnerNew";
import Dropdown from "~/components/Dropdown";
import {
  deleteUser,
  requestUsers,
  clearDeleteUser,
  assumeCheckOut,
  assumeCheckIn,
  setAssumeCheckout,
  setAssumeCheckin,
} from "../actions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const AlertMui = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const devLogConsole = require("~/utils/devLog");
const UnikUserTable = ({ users, t, addUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const [show, setShow] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [activeInativeUsers, setActiveInativeUsers] = useState(users);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const [localeTranslations, setlocaleTranslations] = React.useState("en");
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [checkoutHour, setCheckoutHour] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [rowCheckOutModal, setRowCheckOutModal] = useState(null);
  const [rowCheckInModal, setRowCheckInModal] = useState(null);
  const [openCheckOutSnackBar, setOpenCheckOutSnackBar] = useState(false);
  const [checkOutSnackBarMessage, setCheckOutSnackBarMessage] = useState("");
  const [checkoutSnackBarTypeAlert, setCheckoutSnackBarTypeAlert] =
    useState("");

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      if (userSettings.language.trim() == "pt") setlocaleTranslations("pt-br");
      if (userSettings.language.trim() == "es") setlocaleTranslations("es");
      if (userSettings.language.trim() == "en") setlocaleTranslations("en");
    }
  }, [userSettings]);

  const getLocaleText = () => {
    let lang = enUS;
    if (
      userSettings &&
      userSettings.language !== undefined &&
      userSettings?.language.trim() === "pt"
    ) {
      lang = ptBR;
    }
    if (
      userSettings &&
      userSettings.language !== undefined &&
      userSettings?.language.trim() === "es"
    ) {
      lang = esES;
    }
    return lang.components.MuiLocalizationProvider.defaultProps.localeText;
  };

  const {
    isLoadingUsers,
    successDeleteUser,
    askedForCheckOut,
    isLoadingAssumedCheckOut,
    askedForCheckIn,
  } = useSelector((state) => state.userManagementReducer);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (askedForCheckOut !== "") {
      if (askedForCheckOut === "success") {
        setOpenCheckOutSnackBar(true);
        setCheckoutSnackBarTypeAlert("success");
        setCheckOutSnackBarMessage("CheckOut realizado com sucesso");
      } else {
        setOpenCheckOutSnackBar(true);
        setCheckoutSnackBarTypeAlert("error");
        setCheckOutSnackBarMessage("Erro ao realizar CheckOut");
      }
    }
  }, [askedForCheckOut]);

  useEffect(() => {
    if (askedForCheckIn !== "") {
      if (askedForCheckIn === "success") {
        setOpenCheckOutSnackBar(true);
        setCheckoutSnackBarTypeAlert("success");
        setCheckOutSnackBarMessage("CheckIn realizado com sucesso");
      } else {
        setOpenCheckOutSnackBar(true);
        setCheckoutSnackBarTypeAlert("error");
        setCheckOutSnackBarMessage("Erro ao realizar CheckIn");
      }
    }
  }, [askedForCheckIn]);

  useEffect(() => {
    if (successDeleteUser) {
      dispatch(requestUsers({ username: "" }));
      dispatch(clearDeleteUser());
    }
  }, [successDeleteUser]);

  useEffect(() => {
    devLogConsole(users);
    if (!checkbox) {
      setActiveInativeUsers(users);
    } else {
      setActiveInativeUsers(users.filter((item) => item.enable == false));
    }
  }, [checkbox, users]);

  const apagarUtilizador = () => {
    const payload = {
      id: selectedUser.id,
    };
    dispatch(deleteUser(payload));
    handleClose();
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setShow(true);
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <i
        className={`icon-trash ${
          !row.enable ? "table-unikuser-action-icon" : "table-action-icon"
        } `}
        onClick={() => openModal(row)}
      />
      <i
        className={`icon-edit-user ${
          !row.enable ? "table-unikuser-action-icon" : "table-action-icon"
        }`}
        onClick={() =>
          history.push(`/uniksystem/admin/edit-unikuser/${row.id}`)
        }
      />
    </>
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenCheckOutModal = (row) => {
    if (rowCheckOutModal !== null) return;
    setShowCheckOutModal(true);
    setRowCheckOutModal(row);
  };
  const handleCloseCheckOutModal = () => {
    setShowCheckOutModal(false);
    setCleanDropdown(true);
    setRowCheckOutModal(null);
  };
  const handleCloseCheckInModal = () => {
    setShowCheckInModal(false);
    setRowCheckInModal(null);
  };

  const handleOpenCheckInModal = (row) => {
    if (rowCheckInModal !== null) return;
    setShowCheckInModal(true);
    setRowCheckInModal(row);
  };

  const buildCheckOutUser = (_, row, {}) =>
    row.checkoutId ? (
      <MdLockClock
        onClick={() => handleOpenCheckInModal(row)}
        fontSize={"large"}
        color="#007bff"
      />
    ) : (
      <LiaUserShieldSolid
        onClick={() => handleOpenCheckOutModal(row)}
        fontSize={"large"}
        color="#007bff"
      />
    );

  const { SearchBar } = Search;
  //TODO: validar o enabled
  const columns = [
    {
      dataField: "username",
      text: t("admin.userManagement.list.username"),
      sort: true,
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          color,
        };
      },
    },
    {
      dataField: "firstName",
      text: t("admin.userManagement.list.name"),
      sort: true,
      formatter: (cell, row, rowIndex, extraData) => (
        <> {`${row.firstName} ${row.lastName}`} </>
      ),
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          color,
        };
      },
    },
    {
      dataField: "lastName",
      hidden: true,
    },
    {
      dataField: "organizationName",
      text: t("admin.userManagement.list.organization"),
      formatter: (cell, row, rowIndex, extraData) => (
        <> {`${row?.organizationName || ""}`} </>
      ),
      sort: true,
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          color,
        };
      },
    },
    {
      dataField: "checkout",
      text: "Checkout de User",
      formatter: buildCheckOutUser,
      headerStyle: (column, columnIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      style: (cell, row, rowIndex, columnIndex) => {
        return {
          textAlignLast: "center",
        };
      },
    },
    {
      dataField: "accao",
      text: t("admin.userManagement.list.action"),
      formatter: buildActionsColumn,
      headerStyle: (column, columnIndex) => {
        return { width: "80px", textAlign: "center" };
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const color = !row.enable && "#AD3E3E";
        return {
          textAlignLast: "center",
          color,
        };
      },
    },
  ];

  const filterUsers = () => {
    setCheckbox(!checkbox);
  };

  const handleAssumeCheckOut = () => {
    if (checkoutHour === "" || password === "") return;
    const payload = {
      expectedDuration: checkoutHour,
      checkoutPassword: password,
      requestedBy: user.id,
      onBehalfOf: rowCheckOutModal.id,
    };
    dispatch(assumeCheckOut(payload));
    setShowCheckOutModal(false);
    setCleanDropdown(true);
  };

  const handleAssumeCheckIn = () => {
    const payload = {
      userId: rowCheckInModal.id,
    };
    dispatch(assumeCheckIn(payload));
    setShowCheckInModal(false);
    dispatch(requestUsers({ username: "" }));
  };

  const handleCloseOpenCheckOutSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenCheckOutSnackBar(false);
    setCheckOutSnackBarMessage("");
    setCheckoutSnackBarTypeAlert("");
    dispatch(setAssumeCheckout(""));
    dispatch(setAssumeCheckin(""));
    setRowCheckOutModal(null);
  };

  const listHoursCheckOut = [
    { label: "1H", value: "1", selected: false },
    { label: "2H", value: "2", selected: false },
    { label: "3H", value: "3", selected: false },
    { label: "4H", value: "4", selected: false },
    { label: "8H", value: "8", selected: false },
  ];

  const handleChangeCheckOut = (event) => {
    setCheckoutHour(event);
  };

  return (
    <>
      <div className="custom-table custom-table-small">
        <ToolkitProvider
          keyField="id"
          data={activeInativeUsers}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <SearchBar
                  {...props.searchProps}
                  placeholder={t("admin.userManagement.list.searchInput")}
                />
                <div style={{ paddingLeft: "30px", marginBottom: "0.5rem" }}>
                  <ButtonAdd text={t("general.add")} onClick={addUser} />
                </div>
                <div style={{ paddingLeft: "30px", marginBottom: "0.5rem" }}>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={t("admin.userManagement.list.checkbox")}
                    value={checkbox}
                    onChange={filterUsers}
                  />
                </div>
              </div>
              {!isLoadingUsers && (
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("admin.userManagement.list.emptyTable")}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        {selectedUser !== undefined ? (
          <Modal.Body>
            {`Apagar utilizador: ${selectedUser.firstName} ${selectedUser.lastName}?`}
          </Modal.Body>
        ) : null}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("general.back")}
          </Button>
          <Button variant="danger" onClick={() => apagarUtilizador()}>
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
      {showCheckOutModal && rowCheckOutModal && (
        <Modal
          show={showCheckOutModal}
          onHide={handleCloseCheckOutModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>CheckOut de: {rowCheckOutModal.username}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <div style={{ display: "inline-flex", width: "100%" }}>
                <Form.Control
                  required
                  disabled={false}
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <button
                  onClick={togglePasswordVisibility}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              </div>
            </Form.Group>
            <Form.Group controlId="formDateTime">
              <Form.Label>Tempo estimado de intervenção</Form.Label>
              <Dropdown
                placeholder={"Selecione o tempo de adiamento"}
                emptyDefault={true}
                list={listHoursCheckOut}
                handleSubmit={handleChangeCheckOut}
                flowId={checkoutHour}
                resetDropdown={cleanDropdown}
                setResetDropdown={setCleanDropdown}
                orderedAlphabetical={true}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="card-button-red active"
              onClick={handleCloseCheckOutModal}
            >
              Cancelar
            </Button>
            <Button className="card-button" onClick={handleAssumeCheckOut}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showCheckInModal && rowCheckInModal && (
        <Modal
          show={showCheckInModal}
          onHide={handleCloseCheckInModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>CheckIn de: {rowCheckInModal.username}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group controlId="formDateTime">
              <Form.Label>Data de CheckOut</Form.Label>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={localeTranslations}
                localeText={getLocaleText()}
              >
                <DateTimePicker
                  className="checkoutDatepicker"
                  disabled={true}
                  value={dayjs(rowCheckInModal.checkoutDate)}
                  ampm={false}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  DateTimeFormat={Intl.DateTimeFormat}
                />
              </LocalizationProvider>
            </Form.Group>
            <Form.Group controlId="formDateTime">
              <Form.Label>Data de CheckIn</Form.Label>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={localeTranslations}
                localeText={getLocaleText()}
              >
                <DateTimePicker
                  className="checkoutDatepicker"
                  disabled={true}
                  value={dayjs(new Date())}
                  ampm={false}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  DateTimeFormat={Intl.DateTimeFormat}
                />
              </LocalizationProvider>
            </Form.Group> */}
            <Form.Label>Deseja realizar o Check-In?</Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="card-button-red active"
              onClick={handleCloseCheckInModal}
            >
              Cancelar
            </Button>
            <Button className="card-button" onClick={handleAssumeCheckIn}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Snackbar
        open={openCheckOutSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseOpenCheckOutSnackBar}
      >
        <AlertMui
          onClose={handleCloseOpenCheckOutSnackBar}
          severity={checkoutSnackBarTypeAlert}
          sx={{ width: "100%" }}
        >
          {checkOutSnackBarMessage}
        </AlertMui>
      </Snackbar>
      <SpinnerNew open={isLoadingAssumedCheckOut || isLoadingUsers} />
    </>
  );
};

export default withNamespaces()(UnikUserTable);
