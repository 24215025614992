import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import { Card, Button, Form, Col } from "react-bootstrap";
import logo from "~/assets/img/logos/unik-logo.png";
import SpinnerNew from "~/components/SpinnerNew";
import Messages from "~/components/Messages";

import {
  validateToken,
  savePassword,
  clearTokenStatus,
} from "~/store/ducks/resetPassword/actionTypes";
import { useHistory } from "react-router-dom";

const ChangePassword = (props) => {
  const { t } = props;
  const { token } = props.match?.params;

  const dispatch = useDispatch();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const { tokenStatus, isLoadingValidateToken } = useSelector(
    (state) => state.resetPasswordReducer
  );

  useEffect(() => {
    dispatch(clearTokenStatus());
    dispatch(validateToken({ token }));
  }, []);

  useEffect(() => {
    if (tokenStatus !== undefined) {
      tokenStatus !== "VALID_TOKEN" && history.push("/");
    }
  }, [tokenStatus]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (newPassword === confirmPassword) {
        dispatch(savePassword({ token, newPassword }));
      } else {
        toast.warning("⚠️ " + t("login.passwordsNotMatch"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      {!isLoadingValidateToken && (
        <div className="overlay">
          <div className="row">
            <div className="col-12 mt-3 mb-2 text-center">
              <img
                className="img-fluid unik-logo"
                src={logo}
                alt="Uniksystem"
              />
            </div>
          </div>

          <Form.Row style={{ paddingTop: 20, color: "gray" }}>
            <Col lg="12" style={{ textAlign: "center" }}>
              <h1 className="display-2">
                {t("login.changePasswordMainLabel")}
              </h1>
              <label>{t("login.changePasswordInfoLabel")}</label>
            </Col>
          </Form.Row>
          <hr />

          <Card className="card-login">
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                method="post"
                encType="multipart/form-data"
              >
                <Form.Row>
                  <Col lg="12">
                    <Form.Group controlId="password">
                      <h1 className="label-credentials">
                        {t("login.password")}
                      </h1>
                      <Form.Control
                        type="password"
                        required
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("admin.userManagement.persist.validPassword")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                      <h1 className="label-credentials">
                        {t("login.confirmPassword")}
                      </h1>
                      <Form.Control
                        type="password"
                        required
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col lg="12">
                    <div className="buttons">
                      <Button
                        type="submit"
                        variant="outline-light"
                        className="login-button"
                        style={{ backgroundColor: "#4484f4" }}
                      >
                        {t("login.changePassword")}
                      </Button>
                    </div>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      <ToastContainer />
      <Messages
        afterHideSuccess={() => history.push("/")}
        afterHideError={() => history.push("/")}
      />
      <SpinnerNew open={isLoadingValidateToken} />
    </>
  );
};

export default withNamespaces()(ChangePassword);
