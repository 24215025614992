export const GET_ALL_WORKPLANS = "onboarding@GET_ALL_WORKPLANS";
export const GET_ALL_WORKPLANS_BY_ORG_GROUP =
  "onboarding@GET_ALL_WORKPLANS_BY_ORG_GROUP";
export const DONE_GET_ALL_LIFT = "onboarding@DONE_GET_ALL_LIFT";
export const GET_WORKPLAN_BYID = "onboarding@GET_WORKPLAN_BYID";
export const SET_ALL_WORKPLANS = "onboarding@SET_ALL_WORKPLANS";

export const SET_WORKPLAN_BYID = "onboarding@SET_WORKPLAN_BYID";
export const MAKE_NEW_VERSION = "onboarding@MAKE_NEW_VERSION";

export const SAVE_CHANGES_WORKPLAN = "onboarding@SAVE_CHANGES_WORKPLAN";

export const MAKE_WORKPLAN_ACTIVE = "onboarding@MAKE_WORKPLAN_ACTIVE";
export const DONE_MAKE_ACTIVE = "onboarding@DONE_MAKE_ACTIVE";

export const GET_ALL_COLLABORATORS = "onboarding@GET_ALL_COLLABORATORS";
export const DONE_GET_ALL_COLLABORATORS =
  "onboarding@DONE_GET_ALL_COLLABORATORS";
export const SET_ALL_COLLABORATORS = "onboarding@SET_ALL_COLLABORATORS";

export const GET_ALL_DEPARTMENTS = "onboarding@GET_ALL_DEPARTMENTS";
export const DONE_GET_ALL_DEPARTMENTS = "onboarding@DONE_GET_ALL_DEPARTMENTS";
export const SET_ALL_DEPARTMENTS = "onboarding@SET_ALL_DEPARTMENTS";

export const GET_ALL_BY_CLIENT_DIRECTOR =
  "onboarding@GET_ALL_BY_CLIENT_DIRECTOR";
export const DONE_GET_ALL_BY_CLIENT_DIRECTOR =
  "onboarding@DONE_GET_ALL_BY_CLIENT_DIRECTOR";
export const SET_ALL_BY_CLIENT_DIRECTOR =
  "onboarding@SET_ALL_BY_CLIENT_DIRECTOR";

export const GET_ALL_PROVIDERS = "onboarding@GET_ALL_PROVIDERS";
export const DONE_GET_ALL_PROVIDERS = "onboarding@DONE_GET_ALL_PROVIDERS";
export const SET_ALL_PROVIDERS = "onboarding@SET_ALL_PROVIDERS";

export const GET_ALL_PROVIDERTYPES = "onboarding@GET_ALL_PROVIDERTYPES";
export const DONE_GET_ALL_PROVIDERTYPES =
  "onboarding@DONE_GET_ALL_PROVIDERTYPES";
export const SET_ALL_PROVIDERTYPES = "onboarding@SET_ALL_PROVIDERTYPES";

export const GET_ALL_EXPENDITURETYPES = "onboarding@GET_ALL_EXPENDITURETYPES";
export const DONE_GET_ALL_EXPENDITURETYPES =
  "onboarding@DONE_GET_ALL_EXPENDITURETYPES";
export const SET_ALL_EXPENDITURETYPES = "onboarding@SET_ALL_EXPENDITURETYPES";

export const SET_UPDATE_DONE = "onboarding@SET_UPDATE_DONE";

export const SET_UPDATE_SUBMIT = "onboarding@SET_UPDATE_SUBMIT";

export const GET_ALL_FUNCTIONS = "onboarding@GET_ALL_FUNCTIONS";
export const DONE_GET_ALL_FUNCTIONS = "onboarding@DONE_GET_ALL_FUNCTIONS";
export const SET_ALL_FUNCTIONS = "onboarding@SET_ALL_FUNCTIONS";

export const GET_ALL_FACTORING = "onboarding@GET_ALL_FACTORING";
export const DONE_GET_ALL_FACTORING = "onboarding@DONE_GET_ALL_FACTORING";
export const SET_ALL_FACTORING = "onboarding@SET_ALL_FACTORING";

export const GET_ALL_EXPENSES = "onboarding@GET_ALL_EXPENSES";
export const DONE_GET_ALL_EXPENSES = "onboarding@DONE_GET_ALL_EXPENSES";
export const SET_ALL_EXPENSES = "onboarding@SET_ALL_EXPENSES";

export const GET_ALL_PROJECT_TYPES = "onboarding@GET_ALL_PROJECT_TYPES";
export const DONE_GET_ALL_PROJECT_TYPES =
  "onboarding@DONE_GET_ALL_PROJECT_TYPES";
export const SET_ALL_PROJECT_TYPES = "onboarding@SET_ALL_PROJECT_TYPES";

export const GET_ALL_CATEGORIES = "onboarding@GET_ALL_CATEGORIES";
export const DONE_GET_ALL_CATEGORIES = "onboarding@DONE_GET_ALL_CATEGORIES";
export const SET_ALL_CATEGORIES = "onboarding@SET_ALL_CATEGORIES";

export const GET_ALL_STATES = "onboarding@GET_ALL_STATES";
export const DONE_GET_ALL_STATES = "onboarding@DONE_GET_ALL_STATES";
export const SET_ALL_STATES = "onboarding@SET_ALL_STATES";

export const GET_ALL_PROBABILITIES = "onboarding@GET_ALL_PROBABILITIES";
export const DONE_GET_ALL_PROBABILITIES =
  "onboarding@DONE_GET_ALL_PROBABILITIES";
export const SET_ALL_PROBABILITIES = "onboarding@SET_ALL_PROBABILITIES";

export const GET_ALL_BUSINESSES = "onboarding@GET_ALL_BUSINESSES";
export const DONE_GET_ALL_BUSINESSES = "onboarding@DONE_GET_ALL_BUSINESSES";
export const SET_ALL_BUSINESSES = "onboarding@SET_ALL_BUSINESSES";

export const GET_ALL_PROPOSALS = "onboarding@GET_ALL_PROPOSALS";
export const DONE_GET_ALL_PROPOSALS = "onboarding@DONE_GET_ALL_PROPOSALS";
export const SET_ALL_PROPOSALS = "onboarding@SET_ALL_PROPOSALS";

export const GET_ALL_INVOICING_MODE = "onboarding@GET_ALL_INVOICING_MODE";
export const DONE_GET_ALL_INVOICING_MODE =
  "onboarding@DONE_GET_ALL_INVOICING_MODE";
export const SET_ALL_INVOICING_MODE = "onboarding@SET_ALL_INVOICING_MODE";

export const UPDATE_WORKPLAN = "onboarding@UPDATE_WORKPLAN";
export const DONE_UPDATE_WORKPLAN = "onboarding@DONE_UPDATE_WORKPLAN";
export const SET_WORKPLAN = "onboarding@SET_WORKPLAN";

export const SET_WORKPLAN_NEW_VERSION = "budget@SET_WORKPLAN_NEW_VERSION";

export const SAVE_CONTACT = "onboarding@SAVE_CONTACT";
export const DONE_SAVE_CONTACT = "onboarding@DONE_SAVE_CONTACT";
export const SET_CONTACT = "onboarding@SET_CONTACT";

export const SAVE_DOCUMENT = "onboarding@SAVE_DOCUMENT";
export const DONE_SAVE_DOCUMENT = "onboarding@DONE_SAVE_DOCUMENT";

export const SAVE_PROVIDER = "onboarding@SAVE_PROVIDER";
export const DONE_SAVE_PROVIDER = "onboarding@DONE_SAVE_PROVIDER";
export const SET_PROVIDER = "onboarding@SET_PROVIDER";

export const SET_CURRENT_DOCUMENTS = "onboarding@SET_CURRENT_DOCUMENTS";

export const SET_TRIGGER_LOADING = "onboarding@SET_TRIGGER_LOADING";

export const SET_ERROR_UPDATNG = "onboarding@SET_ERROR_UPDATNG";

export const SET_NUM_GANHO = "onboarding@SET_NUM_GANHO";

export const SET_ADD_WORKPLAN_TRIGGER = "onboarding@SET_ADD_WORKPLAN_TRIGGER";

export const GET_PROPOSAL = "onboarding@GET_PROPOSAL";
export const DONE_GET_PROPOSAL = "onboarding@DONE_GET_PROPOSAL";
export const SET_PROPOSAL = "onboarding@SET_PROPOSAL";

export const GET_ALL_PROPOSAL_TYPES = "onboarding@GET_PROPOSAL_TYPES";
export const DONE_GET_ALL_PROPOSAL_TYPES = "onboarding@DONE_GET_PROPOSAL_TYPES";
export const SET_ALL_PROPOSAL_TYPES = "onboarding@SET_PROPOSAL_TYPES";

export const GET_DOCUMENT_INFO = "onboarding@GET_DOCUMENT_INFO";
export const DONE_GET_DOCUMENT_INFO = "onboarding@DONE_GET_DOCUMENT_INFO";
export const SET_DOCUMENT_INFO = "onboarding@SET_DOCUMENT_INFO";

export const GET_DOCUMENT = "onboarding@GET_DOCUMENT";
export const DONE_GET_DOCUMENT = "onboarding@DONE_GET_DOCUMENT";
export const SET_DOCUMENT = "onboarding@SET_DOCUMENT";

export const GET_ALL_CLIENT_DIRECTORS = "onboarding@GET_ALL_CLIENT_DIRECTORS";
export const DONE_GET_ALL_CLIENT_DIRECTORS =
  "onboarding@DONE_GET_ALL_CLIENT_DIRECTORS";
export const SET_CLIENT_DIRECTORS = "onboarding@SET_CLIENT_DIRECTORS";

export const GET_ALL_PARTNERS = "onboarding@GET_ALL_PARTNERS";
export const DONE_GET_ALL_PARTNERS = "onboarding@DONE_GET_ALL_PARTNERS";
export const SET_ALL_PARTNERS = "onboarding@SET_ALL_PARTNERS";

export const GET_ALL_COMPANIES = "onboarding@GET_ALL_COMPANIES";
export const DONE_GET_ALL_COMPANIES = "onboarding@DONE_GET_ALL_COMPANIES";
export const SET_ALL_COMPANIES = "onboarding@SET_ALL_COMPANIES";

export const GET_ALL_PAYMENT_TYPES = "onboarding@GET_ALL_PAYMENT_TYPES";
export const DONE_GET_ALL_PAYMENT_TYPES =
  "onboarding@DONE_GET_ALL_PAYMENT_TYPES";
export const SET_ALL_PAYMENT_TYPES = "onboarding@SET_ALL_PAYMENT_TYPES";

export const GET_ALL_TEAMS = "onboarding@GET_ALL_TEAMS";
export const DONE_GET_ALL_TEAMS = "onboarding@DONE_GET_ALL_TEAMS";
export const SET_ALL_TEAMS = "onboarding@SET_ALL_TEAMS";

export const GET_ALL_CLIENTS = "onboarding@GET_ALL_CLIENTS";
export const DONE_GET_ALL_CLIENTS = "onboarding@DONE_GET_ALL_CLIENTS";
export const SET_ALL_CLIENTS = "onboarding@SET_ALL_CLIENTS";

export const DELETE_WORKPLAN = "applicationMenu@DELETE_WORKPLAN";
export const DONE_DELETE_WORKPLAN = "applicationMenu@DONE_DELETE_WORKPLAN";

export const DELETE_DOCUMENT = "applicationMenu@DELETE_DOCUMENT";
export const DONE_DELETE_DOCUMENT = "applicationMenu@DONE_DELETE_DOCUMENT";

export const SET_CLIENTS = "budgetmgr@SET_CLIENTS";
export const GET_CLIENTS = "budgetmgr@GET_CLIENTS";

export const SET_PROVIDERS = "budgetmgr@SET_PROVIDERS";
export const GET_PROVIDERS = "budgetmgr@GET_PROVIDERS";

export const UPDATE_CLIENT_ID = "budgetmgr@UPDATE_CLIENT_ID";
export const DONE_UPDATE_CLIENT_ID = "budgetmgr@DONE_UPDATE_CLIENT_ID";

export const UPDATE_PROVIDER_ID = "budgetmgr@UPDATE_PROVIDER_ID";
export const DONE_UPDATE_PROVIDER_ID = "budgetmgr@DONE_UPDATE_PROVIDER_ID";

export const SAVE_CLIENTS = "budgetmgr@SAVE_CLIENT";
export const DONE_SAVE_CLIENTS = "budgetmgr@DONE_SAVE_CLIENTS";

export const SAVE_PROVIDERS = "budgetmgr@SAVE_PROVIDER";
export const DONE_SAVE_PROVIDERS = "budgetmgr@DONE_SAVE_PROVIDERS";

export const DELETE_CLIENT_DATA = "budgetmgr@DELETE_CLIENT_DATA";
export const DONE_DELETE_CLIENT_DATA = "budgetmgr@DONE_DELETE_CLIENT_DATA";

export const DELETE_PROVIDER_DATA = "budgetmgr@DELETE_PROVIDER_DATA";
export const DONE_DELETE_PROVIDER_DATA = "budgetmgr@DONE_DELETE_PROVIDER_DATA";

export const STOP_EDITING_CLIENTS = "budgetmgr@STOP_EDITING_CLIENTS";

export const STOP_EDITING_PROVIDERS = "budgetmgr@STOP_EDITING_PROVIDERS";
export const SET_ALL_WORKPLANS_OBJ = "budgetmgr@SET_ALL_WORKPLANS_OBJ";

export const getAllWorkplans = (id) => ({ type: GET_ALL_WORKPLANS, id });
export const getAllWorkplansByOrgGroup = () => ({
  type: GET_ALL_WORKPLANS_BY_ORG_GROUP,
});
export const doneGetAllLift = () => ({ type: DONE_GET_ALL_LIFT });
export const setAllWorkplans = (workplans) => ({
  type: SET_ALL_WORKPLANS,
  workplans,
});
export const setAllWorkplansObj = (workplansObj) => ({
  type: SET_ALL_WORKPLANS_OBJ,
  workplansObj,
});

export const setWorkplanById = (workplanById) => ({
  type: SET_WORKPLAN_BYID,
  workplanById,
});

export const getAllCollaborators = (queryParams) => ({
  type: GET_ALL_COLLABORATORS,
  queryParams,
});
export const doneGetAllCollaborators = () => ({
  type: DONE_GET_ALL_COLLABORATORS,
});
export const setAllCollaborators = (collaborators) => ({
  type: SET_ALL_COLLABORATORS,
  collaborators,
});

export const getAllPaymentTypes = () => ({ type: GET_ALL_PAYMENT_TYPES });
export const doneGetAllPaymentTypes = () => ({
  type: DONE_GET_ALL_PAYMENT_TYPES,
});
export const setAllPaymentTypes = (paymentTypes) => ({
  type: SET_ALL_PAYMENT_TYPES,
  paymentTypes,
});

export const setUpdateDone = (message) => ({
  type: SET_UPDATE_DONE,
  message,
});

export const setUpdateSubmit = (message) => ({
  type: SET_UPDATE_SUBMIT,
  message,
});

export const getAllTeams = (queryParams) => ({
  type: GET_ALL_TEAMS,
  queryParams,
});
export const doneGetAllTeams = () => ({ type: DONE_GET_ALL_TEAMS });
export const setAllTeams = (teams) => ({ type: SET_ALL_TEAMS, teams });

export const getAllClients = (queryParams) => ({
  type: GET_ALL_CLIENTS,
  queryParams,
});
export const doneGetAllClients = () => ({ type: DONE_GET_ALL_CLIENTS });
export const setAllClients = (clients) => ({ type: SET_ALL_CLIENTS, clients });

export const getAllPartners = (queryParams) => ({
  type: GET_ALL_PARTNERS,
  queryParams,
});
export const doneGetAllPartners = () => ({ type: DONE_GET_ALL_PARTNERS });
export const setAllPartners = (partners) => ({
  type: SET_ALL_PARTNERS,
  partners,
});

export const getAllDepartments = (queryParams) => ({
  type: GET_ALL_DEPARTMENTS,
  queryParams,
});
export const doneGetAllDepartments = () => ({ type: DONE_GET_ALL_DEPARTMENTS });
export const setAllDepartments = (departments) => ({
  type: SET_ALL_DEPARTMENTS,
  departments,
});

export const getAllByClientDirector = (queryParams) => ({
  type: GET_ALL_BY_CLIENT_DIRECTOR,
  queryParams,
});
export const doneGetAllByClientDirector = () => ({
  type: DONE_GET_ALL_BY_CLIENT_DIRECTOR,
});

export const getAllInvoicingMode = () => ({ type: GET_ALL_INVOICING_MODE });
export const doneGetAllInvoicingMode = () => ({
  type: DONE_GET_ALL_INVOICING_MODE,
});
export const setAllInvoicingMode = (invoicingModes) => ({
  type: SET_ALL_INVOICING_MODE,
  invoicingModes,
});

export const getAllProviders = (queryParams) => ({
  type: GET_ALL_PROVIDERS,
  queryParams,
});
export const doneGetAllProviders = () => ({ type: DONE_GET_ALL_PROVIDERS });
export const setAllProviders = (providers) => ({
  type: SET_ALL_PROVIDERS,
  providers,
});

export const getAllProposalTypes = () => ({ type: GET_ALL_PROPOSAL_TYPES });
export const doneGetAllProposalTypes = () => ({
  type: DONE_GET_ALL_PROPOSAL_TYPES,
});
export const setAllProposalTypes = (proposalTypes) => ({
  type: SET_ALL_PROPOSAL_TYPES,
  proposalTypes,
});

export const getAllFactoring = () => ({ type: GET_ALL_FACTORING });
export const doneGetAllFactoring = () => ({ type: DONE_GET_ALL_FACTORING });
export const setAllFactoring = (factoring) => ({
  type: SET_ALL_FACTORING,
  factoring,
});

export const getAllProviderTypes = () => ({ type: GET_ALL_PROVIDERTYPES });
export const doneGetAllProviderTypes = () => ({
  type: DONE_GET_ALL_PROVIDERTYPES,
});
export const setAllProviderTypes = (providerTypes) => ({
  type: SET_ALL_PROVIDERTYPES,
  providerTypes,
});

export const getAllExpenditureTypes = () => ({
  type: GET_ALL_EXPENDITURETYPES,
});
export const doneGetAllExpenditureTypes = () => ({
  type: DONE_GET_ALL_EXPENDITURETYPES,
});
export const setAllExpenditureTypes = (expenditureTypes) => ({
  type: SET_ALL_EXPENDITURETYPES,
  expenditureTypes,
});

export const getAllFunctions = () => ({ type: GET_ALL_FUNCTIONS });
export const doneGetAllFunctions = () => ({ type: DONE_GET_ALL_FUNCTIONS });
export const setAllFunctions = (functions) => ({
  type: SET_ALL_FUNCTIONS,
  functions,
});

export const getAllCompanies = () => ({ type: GET_ALL_COMPANIES });
export const doneGetAllCompanies = () => ({ type: DONE_GET_ALL_COMPANIES });
export const setAllCompanies = (companies) => ({
  type: SET_ALL_COMPANIES,
  companies,
});

export const getAllExpenses = (queryParams) => ({
  type: GET_ALL_EXPENSES,
  queryParams,
});
export const doneGetAllExpenses = () => ({ type: DONE_GET_ALL_EXPENSES });
export const setAllExpenses = (expenses) => ({
  type: SET_ALL_EXPENSES,
  expenses,
});

export const getAllCategories = (queryParams) => ({
  type: GET_ALL_CATEGORIES,
  queryParams,
});
export const doneGetAllCategories = () => ({ type: DONE_GET_ALL_CATEGORIES });
export const setAllCategories = (categories) => ({
  type: SET_ALL_CATEGORIES,
  categories,
});

export const getAllProjectTypes = () => ({ type: GET_ALL_PROJECT_TYPES });
export const doneGetAllProjectTypes = () => ({
  type: DONE_GET_ALL_PROJECT_TYPES,
});
export const setAllProjectTypes = (projectTypes) => ({
  type: SET_ALL_PROJECT_TYPES,
  projectTypes,
});

export const getAllStates = () => ({ type: GET_ALL_STATES });
export const doneGetAllStates = () => ({ type: DONE_GET_ALL_STATES });
export const setAllStates = (states) => ({ type: SET_ALL_STATES, states });

export const getAllProbabilities = () => ({ type: GET_ALL_PROBABILITIES });
export const doneGetAllProbabilities = () => ({
  type: DONE_GET_ALL_PROBABILITIES,
});
export const setAllProbabilities = (probabilities) => ({
  type: SET_ALL_PROBABILITIES,
  probabilities,
});

export const getAllBusinesses = () => ({ type: GET_ALL_BUSINESSES });
export const doneGetAllBusinesses = () => ({ type: DONE_GET_ALL_BUSINESSES });
export const setAllBusinesses = (businesses) => ({
  type: SET_ALL_BUSINESSES,
  businesses,
});

export const getAllProposals = () => ({ type: GET_ALL_PROPOSALS });
export const doneGetAllProposals = () => ({ type: DONE_GET_ALL_PROPOSALS });
export const setAllProposals = (proposals) => ({
  type: SET_ALL_PROPOSALS,
  proposals,
});

export const updateWorkplan = (workplan) => ({
  type: UPDATE_WORKPLAN,
  workplan,
});

export const makeNewVersion = (workplan) => ({
  type: MAKE_NEW_VERSION,
  workplan,
});

export const saveContact = (client) => ({
  type: SAVE_CONTACT,
  client,
});
export const doneSaveContact = () => ({ type: DONE_SAVE_CONTACT });
export const setContact = (client) => ({ type: SET_CONTACT, client });

export const saveDocument = (queryParams) => ({
  type: SAVE_DOCUMENT,
  queryParams,
});
export const doneSaveDocument = () => ({ type: DONE_SAVE_DOCUMENT });

export const saveProvider = (provider) => ({
  type: SAVE_PROVIDER,
  provider,
});
export const doneSaveProvider = () => ({ type: DONE_SAVE_PROVIDER });
export const setProvider = (provider) => ({ type: SET_PROVIDER, provider });

export const doneUpdateWorkplan = () => ({ type: DONE_UPDATE_WORKPLAN });
export const setWorkplan = (workplan) => ({ type: SET_WORKPLAN, workplan });

export const setworkplanNewVersion = (workplan) => ({
  type: SET_WORKPLAN_NEW_VERSION,
  workplan,
});

export const getProposalByWorkplan = () => ({ type: GET_PROPOSAL });
export const doneGetProposal = () => ({ type: DONE_GET_PROPOSAL });
export const setProposal = (proposal) => ({ type: SET_PROPOSAL, proposal });

export const getDocumentByWorkplan = (queryParams) => ({
  type: GET_DOCUMENT_INFO,
  queryParams,
});
export const doneGetDocumentInfo = () => ({ type: DONE_GET_DOCUMENT_INFO });
export const setDocumentInfo = (documentInfo) => ({
  type: SET_DOCUMENT_INFO,
  documentInfo,
});

export const getDocument = (queryParams) => ({
  type: GET_DOCUMENT,
  queryParams,
});
export const doneGetDocument = () => ({ type: DONE_GET_DOCUMENT });
export const setDocument = (currentDocument) => ({
  type: SET_DOCUMENT,
  currentDocument,
});

export const setCurrentDocuments = (documents) => ({
  type: SET_CURRENT_DOCUMENTS,
  documents,
});

export const getAllClientDirectors = (queryParams) => ({
  type: GET_ALL_CLIENT_DIRECTORS,
  queryParams,
});
export const doneGetAllClientDirectors = () => ({
  type: DONE_GET_ALL_CLIENT_DIRECTORS,
});
export const setClientDirectors = (clientDirectors) => ({
  type: SET_CLIENT_DIRECTORS,
  clientDirectors,
});

export const setTriggerLoading = (triggerLoading) => ({
  type: SET_TRIGGER_LOADING,
  triggerLoading,
});

export const setErrorUpdating = (errorUpdating) => ({
  type: SET_ERROR_UPDATNG,
  errorUpdating,
});

export const setNumGanho = (numGanho) => ({
  type: SET_NUM_GANHO,
  numGanho,
});

export const setAddWorkplanTrigger = (addWorkplanTrigger) => ({
  type: SET_ADD_WORKPLAN_TRIGGER,
  addWorkplanTrigger,
});

export const saveChangesWorkplan = (workplan) => ({
  type: SAVE_CHANGES_WORKPLAN,
  workplan,
});

export const makeWorkplanActive = (queryParams) => ({
  type: MAKE_WORKPLAN_ACTIVE,
  queryParams,
});

export const getWorkplanById = (queryParams) => ({
  type: GET_WORKPLAN_BYID,
  queryParams,
});

export const doneMakeActive = () => ({ type: DONE_MAKE_ACTIVE });

export const deleteWorkplan = (queryParams) => ({
  type: DELETE_WORKPLAN,
  queryParams,
});
export const doneDeleteWorkplan = (successDelete) => ({
  type: DONE_DELETE_WORKPLAN,
  successDelete,
});

export const deleteDocument = (queryParams) => ({
  type: DELETE_DOCUMENT,
  queryParams,
});
export const doneDeleteDocument = (successDeleteDocument) => ({
  type: DONE_DELETE_DOCUMENT,
  successDeleteDocument,
});

export const getClients = (allClients) => ({
  type: GET_CLIENTS,
  allClients,
});
export const setClients = (data) => ({
  type: SET_CLIENTS,
  data,
});

export const getProviders = (allProviders) => ({
  type: GET_PROVIDERS,
  allProviders,
});
export const setProviders = (data) => ({
  type: SET_PROVIDERS,
  data,
});

export const updateClienteId = (data) => {
  return { type: UPDATE_CLIENT_ID, data };
};

export const doneUpdateClienteId = (data) => {
  return { type: DONE_UPDATE_CLIENT_ID, data };
};

export const updateProviderId = (data) => {
  return { type: UPDATE_PROVIDER_ID, data };
};

export const doneUpdateProviderId = (data) => {
  return { type: DONE_UPDATE_PROVIDER_ID, data };
};

export const saveClientsData = (data, show) => {
  return { type: SAVE_CLIENTS, data, show };
};

export const doneSaveClients = (data) => {
  return { type: DONE_SAVE_CLIENTS, data };
};

export const saveProvidersData = (data) => {
  return { type: SAVE_PROVIDERS, data };
};

export const doneSaveProviders = (data) => {
  return { type: DONE_SAVE_PROVIDERS, data };
};

export const deleteClientData = (id) => {
  return { type: DELETE_CLIENT_DATA, id };
};

export const doneDeleteClientData = () => {
  return { type: DONE_DELETE_CLIENT_DATA };
};

export const deleteProviderData = (id) => {
  return { type: DELETE_PROVIDER_DATA, id };
};

export const doneDeleteProviderData = (id) => {
  return { type: DONE_DELETE_PROVIDER_DATA, id };
};

export const stopEditingClients = () => {
  return { type: STOP_EDITING_CLIENTS };
};

export const stopEditingProviders = () => {
  return { type: STOP_EDITING_PROVIDERS };
};
