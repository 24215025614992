import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Tabs, Tab, Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SpinnerNew from "~/components/SpinnerNew";
import "./portal.scss";
import "~/assets/css/scrollbar.css";

import {
  redirectUserTo,
  getRequestsMyTasksWidget,
  getRequestsMyTasksWidgetPIDs,
} from "~/store/ducks/portalrh/actionTypes";

import { PORTALRH_MIDDLEWARE, BPM_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import { withNamespaces } from "react-i18next";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { updateUserWidget } from "~/pages/User/actions";
import RequestItemMyTasksWidget from "./RequestItemMyTasksWidget";

const MyTasksWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  app,
  t,
  ...props
}) => {
  const { allMenus } = useSelector((state) => state.portalRhMenuReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  )?.username;

  const userBPM = user.legacyUsers.find(
    (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
  )?.username;

  const dispatch = useDispatch();
  const history = useHistory();
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const {
    pathToRedirect,
    requestsMyTasksWidget,
    requestsMyTasksWidgetPid,
    isLoadingRequests,
  } = useSelector((state) => state.portalrhReducer);

  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    if (pathToRedirect) {
      if (document.getElementById("portalrh")) {
        /*Display iframe*/
        document.getElementById("portalrh").style.display = "block";
      }
      let config = configuration?.loginPage;
      const context = {
        theme:
          config === "nblp"
            ? "nblp"
            : userSettings.theme
            ? userSettings.theme
            : "default",
        language: userSettings.language ? userSettings.language : "EN",
        location: "",
      };
      try {
        const object = JSON.parse(pathToRedirect);
        if (object.action && object.url) {
          history.push(`/uniksystem/portalrh/${object.url}`);
          context.location = object.action;
        }
      } catch (exception) {
        history.push(`/uniksystem/portalrh/${pathToRedirect}`);
        context.location = "fp_folha_ponto";
      }

      let event = new CustomEvent("UserContextEvent", { detail: context });
      if (document.getElementById("portalrh")) {
        if (document.getElementById("portalrh").contentWindow) {
          document
            .getElementById("portalrh")
            .contentWindow.dispatchEvent(event);
        }
      }
    }

    return () => {
      dispatch(redirectUserTo(""));
    };
  }, [pathToRedirect]);

  useEffect(() => {
    if (userBPM && usernamePortalRh) {
      const payload = {
        usernameBpm: userBPM,
        usernamePortalrh: usernamePortalRh,
        application: app || "portalrh",
      };
      dispatch(getRequestsMyTasksWidget(payload));
    }
  }, [userBPM, usernamePortalRh, app]);

  useEffect(() => {
    if (requestsMyTasksWidget && requestsMyTasksWidget.length > 0) {
      setRequests(requestsMyTasksWidget);
    }
  }, [requestsMyTasksWidget]);

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ padding: "2rem" }}>
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("MyTasksWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        <Card.Body>
          <SpinnerNew open={isLoadingRequests} />
          <div>
            {requests && requests.length > 0 ? (
              requests.map((request, index) => (
                <RequestItemMyTasksWidget
                  key={index}
                  description={request.flowname}
                  num={request.numactivities}
                  textColor={"text-primary"}
                  flowId={request.flowid}
                />
              ))
            ) : (
              <p>{t("taskPage.general.tasksNotFound")}</p>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(MyTasksWidget);
