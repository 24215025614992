import React, { useEffect, useState, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import SpinnerNew from "~/components/SpinnerNew";

import uuid from "uuid/v1";
import greenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import "~pages/Anacom/assets/scss/_anacomTable.scss";
import { getMetadataDocument } from "~/pages/DataCapture/actions";

import {
  getDatacaptureConfiguration,
  clearDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";
import {
  exportClaims,
  getSubClaims,
} from "~/store/ducks/claimsManagement/actionTypes";
import { Alert, Button, Col, Row } from "react-bootstrap";

import "~/assets/css/icons.css";
import "~/pages/DataCapture/scss/datacapture.scss";
import "~/pages/ADataCapture/components/AutomatedDatacapture/AutomatedDataCapture.css";
import Modal from "~/pages/Easypay/components/Modal";
import Pagination from "~/pages/Easypay/components/Pagination";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import PopupTable from "./PopupTable";
const ReclamacoesOnline = ({
  t,
  setShowUploadModal,
  category,
  complaints,
  setBeginIndex,
  setEndIndex,
}) => {
  const { SearchBar } = Search;

  const { isLoadingGetComplaints, subClaims } = useSelector(
    (state) => state.claimsManagementReducer
  );

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);

  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );

  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [isModalWaiting, setIsModalWaiting] = useState(false);
  const [currentText, setCurrentText] = useState("");
  const [currentAnacom, setCurrentAnacom] = useState([]);
  const [currentUnik, setCurrentUnik] = useState([]);
  const [currentAssuntos, setCurrentAssuntos] = useState([]);

  const [unikList, setUnikList] = useState([]);
  const [anacomList, setAnacomList] = useState([]);
  const [assuntosList, setAssuntosList] = useState([]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getDatacaptureConfiguration(user.organization.id));
    return () => dispatch(clearDatacaptureConfiguration());
  }, [user && user.organization && user.organization.id]);

  const handleOpenMetadataModal = (row) => {
    dispatch(getSubClaims(category, 0, 50000, row?.claimId));
    setCurrentText(row?.reclamacao);
    setIsModalWaiting(true);
  };

  useEffect(() => {
    if (subClaims != null) {
      setIsModalWaiting(false);
      if (subClaims === null || subClaims === undefined) return;
      if (subClaims?.length === 0) return;
      if (complaints?.idsValues === null || complaints?.idsValues === undefined)
        return;
      if (complaints?.idsValues?.length === 0) return;

      const currentUnikArray = subClaims?.unikClassifications;

      const currentAnacomArray = subClaims?.anacomClassifications;

      const currentAssuntosArray = subClaims?.assuntosClassifications;

      for (var i = 0; i < currentUnikArray?.length; i++) {
        if (
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != null &&
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != undefined
        ) {
          currentUnikArray[i].check = "t";
        } else {
          currentUnikArray[i].check = "f";
        }
      }

      const unikSize = Math.ceil(currentUnikArray.length / 5);
      const anacomSize = Math.ceil(currentAnacomArray.length / 5);
      const assuntosSize = Math.ceil(currentAssuntosArray.length / 5);

      var currentUnikPagination = [];
      var currentAnacomPagination = [];
      var currentAssuntosPagination = [];

      for (var i = 1; i <= unikSize; i++) {
        currentUnikPagination.push(i);
      }

      for (var i = 1; i <= anacomSize; i++) {
        currentAnacomPagination.push(i);
      }

      for (var i = 1; i <= assuntosSize; i++) {
        currentAssuntosPagination.push(i);
      }
      setUnikList(currentUnikPagination);
      setAnacomList(currentAnacomPagination);
      setAssuntosList(currentAssuntosPagination);
      setCurrentAnacom(currentAnacomArray);
      setCurrentUnik(currentUnikArray);
      setCurrentAssuntos(currentAssuntosArray);
      setIsModalErrorShowing(true);
    } else {
      setIsModalErrorShowing(false);
    }
  }, [subClaims]);

  const buildActionsColumn = (_, row, {}) => (
    <>
      {
        <i
          className="icon-detail table-action-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenMetadataModal(row, false);
          }}
        />
      }
    </>
  );

  function checkFormatter(cell) {
    if (cell === "t")
      return <img className="anacom-checkmark-img" src={greenCheckmark} />;
    else return "";
  }

  function getMotivoDescricao(id) {
    return complaints?.idsValues?.filter((elem) => elem.id === parseInt(id))[0]
      ?.label;
  }

  function formatPercentagem(percentagem) {
    var currentPercentagem = "";
    if (percentagem?.length > 4) {
      currentPercentagem = percentagem?.slice(0, 4);
      if (currentPercentagem === "100.") {
        return "100";
      } else {
        return currentPercentagem;
      }
    }
    return percentagem;
  }

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBody() {
    return (
      <Row>
        <Col lg="7">
          <PopupTable
            naoclass={false}
            isNaoClassificadas={false}
            anacomList={currentAnacom}
            assuntosList={currentAssuntos}
            unikList={currentUnik}
            anacomPagination={anacomList}
            assuntosPagination={assuntosList}
            unikPagination={unikList}
          />
        </Col>
        <Col lg="5">
          <div className={`${"liftworld-textarea-input-field-wrapper"}`}>
            <textarea
              className={`liftworld-textarea-input-field`}
              type={"textarea"}
              disabled="true"
              value={currentText}
            />
          </div>
        </Col>
      </Row>
    );
  }

  function descFormatter(cell) {
    return cell?.slice(0, 15);
  }

  const downloadXls = () => {
    //1 = EXCEL
    dispatch(exportClaims(1, category, null));
  };

  const columns = [
    {
      dataField: "numeroProcesso",
      text: "Número do Processo",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "reclamacao",
      text: "Descrição (15 car)",
      sort: true,
      headerStyle: { width: "fit-content" },
      formatter: descFormatter,
    },
    {
      dataField: "servico",
      text: "Serviço",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "assunto",
      text: "Assunto",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "setor",
      text: "Sector",
      sort: false,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "iDDocumento",
      text: "Ações",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: buildActionsColumn,
    },
  ];

  return (
    <div>
      <div className="main-card-v2">
        <div className="custom-table">
          <ToolkitProvider
            keyField="id"
            data={complaints?.claims ? complaints?.claims : []}
            columns={columns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    {true && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )}
                    <div style={{ paddingLeft: "15px" }} />
                    {/* <SimpleButton
                      variant={"liftworld-button-primary"}
                      disabled={
                        isLoadingGetComplaints ||
                        complaints?.claims?.length === 0
                      }
                      onClick={() => {
                        downloadXls();
                      }}
                      text={"Exportar"}
                    /> */}
                  </div>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table"
                  responsive
                  bootstrap4
                  pagination={paginationFactory({
                    sizePerPage: 5,
                    sizePerPageList: [],
                  })}
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  //rowEvents={rowEvents}
                  hover
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
          <Modal
            className="easypay-modal"
            showModal={isModalErrorShowing}
            setShowModal={setIsModalErrorShowing}
            headerContent={"Classificação"}
            bodyContent={createBody()}
            key={uuid()}
            nomax={true}
          ></Modal>
          <Modal
            className="easypay-modal"
            showModal={isModalWaiting}
            setShowModal={setIsModalWaiting}
            headerContent={"Classificação"}
            bodyContent={createBodyWaiting()}
            key={uuid()}
          ></Modal>
        </div>
      </div>
      <SpinnerNew open={isLoadingGetComplaints} />
    </div>
  );
};
export default withNamespaces()(ReclamacoesOnline);
