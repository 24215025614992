import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { withNamespaces } from "react-i18next";
import isEmpty from "lodash/isEmpty";

import UserTable from "./UserTable";
import SpinnerNew from "~/components/SpinnerNew";
import { Modal, Button, Form, Col } from "react-bootstrap";

import { allUsersByRole, updateUnikUsers } from "../actions";

const UserModal = (props) => {
  const { t, showUsers, handleCloseUsers, selectedRole } = props;
  const dispatch = useDispatch();

  const { roleUsers, isLoadingUsers } = useSelector(
    (state) => state.roleReducer
  );

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [unselectedUsers, setUnselectedUsers] = useState([]);

  useEffect(() => {
    if (selectedRole) {
      dispatch(allUsersByRole(selectedRole.id));
    }
  }, [selectedRole, dispatch]);

  useEffect(() => {
    if (!isEmpty(roleUsers)) {
      setSelectedUsers(roleUsers.selectedUsers);
      setUnselectedUsers(roleUsers.unselectedUsers);
    }
  }, [roleUsers, dispatch]);

  const addUser = (user) => {
    const index = unselectedUsers.indexOf(user);
    const unselectedUsers_ = update(unselectedUsers, { $splice: [[index, 1]] });
    const selectedUsers_ = update(selectedUsers, { $push: [user] });
    setUnselectedUsers(unselectedUsers_);
    setSelectedUsers(selectedUsers_);
  };

  const removeUser = (user) => {
    const index = selectedUsers.indexOf(user);
    const selectedUsers_ = update(selectedUsers, { $splice: [[index, 1]] });
    const unselectedUsers_ = update(unselectedUsers, { $push: [user] });
    setUnselectedUsers(unselectedUsers_);
    setSelectedUsers(selectedUsers_);
  };

  const handleSave = () => {
    const roleUsers = {
      roleId: selectedRole.id,
      selectedUsers,
      unselectedUsers,
    };
    dispatch(updateUnikUsers(roleUsers));
    handleCloseUsers();
  };

  return (
    <Modal
      show={showUsers}
      onHide={handleCloseUsers}
      dialogClassName="custom-modal modal-80w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("general.users")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isLoadingUsers && (
          <>
            <div>
              <b>{t("admin.general.role")}: </b>
              <label>{selectedRole?.description}</label>
              <hr />
            </div>

            <Form.Row>
              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>{t("admin.role.usermodal.users")}</Form.Label>
                  <UserTable
                    users={unselectedUsers}
                    action={addUser}
                    actionIcon="icon-add"
                  />
                </Form.Group>
              </Col>

              <Col sm="12" md="12" lg="6">
                <Form.Group controlId="label">
                  <Form.Label>
                    {t("admin.role.usermodal.usersSelected")}
                  </Form.Label>
                  <UserTable
                    users={selectedUsers}
                    action={removeUser}
                    actionIcon="icon-trash"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          style={{ minHeight: "44px" }}
          onClick={handleCloseUsers}
        >
          {t("general.close")}
        </Button>
        <Button className="card-button" onClick={handleSave}>
          {t("general.save")}
        </Button>
      </Modal.Footer>

      <SpinnerNew open={isLoadingUsers} />
    </Modal>
  );
};
export default withNamespaces()(UserModal);
