import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import {
  getCRUDExpenses,
  deleteCRUDExpenses,
  postADDCRUDExpenses,
  updateCRUDExpenses,
  getCRUDCompanies,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import ExpensesTable from "../components/Expenses/ExpensesTable";
import ExpensesFilters from "../components/Expenses/ExpensesFilters";

const Expenses = ({ t, isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const { expenses_CRUD, isLoadingCRUDExpense, companies_CRUD } = useSelector(
    (state) => state.budgetManagementCRUDReducer
  );

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewExpense, setaddEditNewExpense] = useState(false);
  const [editDeleteExpense, seteditDeleteExpense] = useState(null);
  const [expensesToShow, setexpensesToShow] = useState(expenses_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownExpenses, setcleanDropdownExpenses] = useState(false);
  const [expenseFilter, setexpenseFilter] = useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditExpense, setSelectedEditExpense] = useState(undefined);
  const [SelectedDeleteExpense, setSelectedDeleteExpense] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [expensesToRender, setexpensesToRender] = useState(expenses_CRUD);

  const [selectedExpense, setselectedExpense] = useState({
    empresa: "",
    idGastos: 0,
    tipo: "",
    percentagem: 0,
    estado: 1,
  });

  const resetBackButton = () => {
    setselectedExpense({
      empresa: "",
      idGastos: 0,
      tipo: "",
      percentagem: 0,
      estado: 1,
    });
  };

  const handleTipoChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedExpense((prevCategory) => ({
      ...prevCategory,
      tipo: event.target.value,
    }));
  };
  const handlePercentagemChange = (event) => {
    const inputValue = event.target.value;
    const valueWithoutPercentage = inputValue.endsWith("%")
      ? inputValue.slice(0, -1) // Remove o último caractere (%)
      : inputValue;

    const finalValue = valueWithoutPercentage.replace(",", ".");

    setselectedExpense((prevExpense) => ({
      ...prevExpense,
      percentagem: finalValue,
    }));
  };

  const handleAtivoChange = (estado) => {
    setselectedExpense((prevCategory) => ({
      ...prevCategory,
      estado: parseInt(estado),
    }));
    if (SelectedEditExpense != undefined)
      setselectedExpense((prevCategory) => ({
        ...prevCategory,
        estado: parseInt(estado),
      }));
  };

  const handleEmpresaChange = (empresa) => {
    setselectedExpense((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditExpense != undefined)
      setSelectedEditExpense((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };

  useEffect(() => {
    if (SelectedEditExpense != (null || undefined))
      setselectedExpense(SelectedEditExpense);
  }, [SelectedEditExpense]);

  useEffect(() => {
    dispatch(getCRUDExpenses());
    dispatch(getCRUDCompanies());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDExpenses());
    handleReset();
  };

  const handleReset = () => {
    setexpenseFilter("");
    setexpensesToShow(expenses_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
    setFilterToggle(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditExpense(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeleteExpense(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDExpenses(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdateExpense = (id) => {
    if (id) {
      const payload = {
        idGastos: id,
        tipo: selectedExpense?.tipo,
        empresa: selectedExpense?.empresa,
        percentagem: parseFloat(selectedExpense?.percentagem),
        year: new Date().getFullYear(),
        estado: selectedExpense?.estado,
      };

      dispatch(updateCRUDExpenses(id, payload));
    } else {
      const payload = {
        tipo: selectedExpense?.tipo,
        empresa: selectedExpense?.empresa,
        percentagem: parseFloat(selectedExpense?.percentagem),
        year: new Date().getFullYear(),
        estado: 1,
      };
      dispatch(postADDCRUDExpenses(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    expenses_CRUD.map((expense) => {
      if (expense?.estado == null) expense.estado = 0;
    });
    setexpensesToShow(expenses_CRUD);
  }, [expenses_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditExpense !== undefined &&
        SelectedEditExpense?.empresa === company?.codigoEmpresa,
    }));
  };
  const activeArray = [
    {
      estado: t(`budgetManagement.expenses.ativo`),
      value: 1,
    },
    {
      estado: t(`budgetManagement.expenses.inativo`),
      value: 0,
    },
  ];
  const renderFilterAtivo = () => {
    return activeArray.map((estado) => ({
      label: estado?.estado,
      value: estado?.value,
      selected: selectedExpense?.estado === estado?.value,
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.expenses.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownExpenses}
            resetDropdown={cleanDropdownExpenses}
            disabled={SelectedEditExpense?.empresa ? true : false}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`tipo-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.expenses.tipo`)}:
          </label>
          <input
            id={`tipo-input`}
            className="form-control"
            type="text"
            value={selectedExpense?.tipo}
            onChange={handleTipoChange}
            disabled={SelectedEditExpense?.tipo ? true : false}
            required
          />
        </div>
        <div
          className="inputsLiftCrud"
          style={{ width: "100%", display: "flex" }}
        >
          <label
            htmlFor={`percentagem-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.expenses.percentagem`)}:
          </label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              id={`percentagem-input`}
              className="form-control"
              type="text"
              value={selectedExpense?.percentagem}
              onChange={handlePercentagemChange}
              required
            />
            <span style={{ marginLeft: "5px" }}>%</span>
          </div>
        </div>

        {/* {SelectedEditExpense && (
          <div className="inputsLiftCrud" style={{ width: "100%" }}>
            <label
              htmlFor={`team-input`}
              className="labelsLiftCrud"
              style={{ width: "auto" }}
            >
              {t(`budgetManagement.expenses.estado`)}:
            </label>
            <Dropdown
              width={"100%"}
              list={renderFilterAtivo()}
              handleSubmit={handleAtivoChange}
              emptyDefault={true}
              setResetDropdown={setcleanDropdownExpenses}
              resetDropdown={cleanDropdownExpenses}
            />
          </div>
        )} */}
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setaddEditNewExpense(false);
    setshowEditModal(false);
    resetBackButton();
    setcleanDropdownExpenses(true);
    setSelectedEditExpense(undefined);
    setselectedExpense({
      empresa: "",
      idGastos: 0,
      tipo: "",
      percentagem: 0,
      estado: 1,
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.expenses.btnVoltar`)}
            </Button>
            {addEditNewExpense ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdateExpense()}
              >
                {t(`budgetManagement.expenses.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdateExpense(selectedExpense?.idGastos)
                }
              >
                {t(`budgetManagement.expenses.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.expenses.msgDelete.1`)}{" "}
              {SelectedDeleteExpense?.empresa}
              {t(`budgetManagement.expenses.msgDelete.2`)}{" "}
              {SelectedDeleteExpense?.tipo}{" "}
              {t(`budgetManagement.expenses.msgDelete.3`)}{" "}
              {SelectedDeleteExpense?.percentagem}% ?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.expenses.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() => handleDelete(SelectedDeleteExpense?.idGastos)}
            >
              {t(`budgetManagement.expenses.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDExpense || isLoading} />
          {Object.keys(expenses_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.expenses.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <ExpensesFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      expensesData={expenses_CRUD}
                      setexpensesToShow={setexpensesToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      expenseFilter={expenseFilter}
                      setexpenseFilter={setexpenseFilter}
                    />
                    <ExpensesTable
                      app={"bpm"}
                      expensesData={expensesToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setaddEditNewExpense={setaddEditNewExpense}
                      seteditDeleteExpense={seteditDeleteExpense}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                      filterToggle={filterToggle}
                      setFilterToggle={setFilterToggle}
                      expensesToRender={expensesToRender}
                      setexpensesToRender={setexpensesToRender}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Expenses);
