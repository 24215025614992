import React, { useRef, useEffect, useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import { useDispatch, useSelector } from "react-redux";
import ReportFilters from "../../ReportFilters";
import Chart from "chart.js";
import { pieChart } from "./chartDetail.js";
import CustomDropdown from "../CustomDropdown";
import { withNamespaces } from "react-i18next";
import { findReportProcessStatistics } from "~/store/ducks/iflowReports/actionTypes";
import { cloneDeep } from "lodash";
import "~/assets/css/icons.css";
import { updateUserWidget } from "~/pages/User/actions";

const ProcessStatisticsWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const widgetId = widget.id;

  const getReportProcessStatistics = (state) => {
    const reports = state.iflowReportsReducer.reportProcessStatistics;
    let report = [];
    const savedReport = reports.find((r) => r.id === widgetId);
    if (savedReport) report = savedReport.report;
    return report;
  };
  const reportProcessStatistics = useSelector(getReportProcessStatistics);

  const [pieChart_, setPieChart] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    if (reportProcessStatistics && reportProcessStatistics.length > 0) {
      let chart = cloneDeep(pieChart); //clone object, each widget instance needs its own chart object
      const types = [];
      const dataset = [];
      reportProcessStatistics.forEach((item) => {
        if (item.total_pid > 0) {
          types.push(item.type);
          dataset.push(item.total_pid);
        }
      });

      const graphColors = ["#0B1C39", "#1A2F54", "#2F4473", "#495C92"];
      let backgroundColors = [];
      let hoverBackgroundColor = [];
      for (let i = 0; i < dataset.length; i++) {
        backgroundColors.push(graphColors[i]);
        hoverBackgroundColor.push("#99ff99");
      }

      chart.data.datasets[0].backgroundColor = backgroundColors;
      chart.data.datasets[0].hoverBackgroundColor = hoverBackgroundColor;

      let labels = [];
      types.forEach((type) => {
        if (type === "closed") labels.push(t("ProcessStatisticsWidget.closed"));
        if (type === "opened") labels.push(t("ProcessStatisticsWidget.opened"));
        if (type === "treated")
          labels.push(t("ProcessStatisticsWidget.treated"));
        if (type === "leftin") labels.push(t("ProcessStatisticsWidget.leftin"));
      });

      chart.data.labels = labels;
      chart.data.datasets[0].data = dataset ? dataset : [];
      setPieChart(chart);
      setIsUpdating(false);
    }
  }, [reportProcessStatistics]);

  useEffect(() => {
    if (pieChart_ && !isUpdating) {
      const myChartRef = myRef.current.getContext("2d");
      new Chart(myChartRef, pieChart_);
    }
  }, [pieChart_, isUpdating]);

  const executeGraph = (startAt, endAt, flowId) => {
    const data = {
      payload: {
        flowId: flowId === "" ? null : flowId,
        startDate: startAt,
        endDate: endAt,
      },
      widgetId: widget.id,
    };
    dispatch(findReportProcessStatistics(data));
    setIsUpdating(true);
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("ProcessStatisticsWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>

        {!isResizing && (
          <Card.Body style={{ padding: "0px 10px" }}>
            <Row style={{ flexWrap: "nowrap", height: "90%" }}>
              <Col xs={4} md={4} lg={4}>
                <ReportFilters executeGraph={executeGraph} widget={widget} />
              </Col>

              <Col
                style={{
                  padding: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SpinnerNew open={isUpdating} />
                {reportProcessStatistics &&
                  reportProcessStatistics.length > 0 &&
                  !isUpdating && (
                    <div style={{ height: "100%", width: "auto" }}>
                      <canvas id="myPieChart" ref={myRef}></canvas>
                    </div>
                  )}
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(ProcessStatisticsWidget);
