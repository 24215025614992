import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import {
  getCRUDPartners,
  deleteCRUDPartners,
  postADDCRUDPartners,
  updateCRUDPartners,
  getCRUDCompanies,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import PartnersTable from "../components/Partners/PartnersTable";
import PartnersFilters from "../components/Partners/PartnersFilters";

const Partners = ({ t,isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const { partners_CRUD, isLoadingCRUDPartner, companies_CRUD } = useSelector(
    (state) => state.budgetManagementCRUDReducer
  );

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewPartner, setaddEditNewPartner] = useState(false);
  const [editDeletePartner, seteditDeletePartner] = useState(null);
  const [partnersToShow, setpartnersToShow] = useState(partners_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownPartners, setcleanDropdownPartners] = useState(false);
  const [partnerFilter, setpartnerFilter] = useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditPartner, setSelectedEditPartner] = useState(undefined);
  const [SelectedDeletePartner, setSelectedDeletePartner] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPartner, setselectedPartner] = useState({
    empresa: "",
    idPartner: 0,
    nome: "",
    ativo: true,
  });

  const resetBackButton = () => {
    setselectedPartner({
      empresa: "",
      idPartner: 0,
      nome: "",
      ativo: true,
    });
  };

  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedPartner((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
  };

  const handleEmpresaChange = (empresa) => {
    setselectedPartner((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditPartner != undefined)
      setSelectedEditPartner((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };

  useEffect(() => {
    if (SelectedEditPartner != (null || undefined))
      setselectedPartner(SelectedEditPartner);
  }, [SelectedEditPartner]);

  useEffect(() => {
    dispatch(getCRUDPartners());
    dispatch(getCRUDCompanies());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDPartners());
    handleReset();
  };

  const handleReset = () => {
    setpartnerFilter("");
    setpartnersToShow(partners_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditPartner(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeletePartner(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDPartners(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdatePartner = (id) => {
    if (id) {
      const payload = {
        idPartner: id,
        nome: selectedPartner?.nome,
        empresa: selectedPartner?.empresa,
        year: new Date().getFullYear(),
        ativo: selectedPartner?.ativo,
      };

      dispatch(updateCRUDPartners(id, payload));
    } else {
      const payload = {
        nome: selectedPartner?.nome,
        empresa: selectedPartner?.empresa,
        year: new Date().getFullYear(),
        ativo: true,
      };
      dispatch(postADDCRUDPartners(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setpartnersToShow(partners_CRUD);
  }, [partners_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditPartner !== undefined &&
        SelectedEditPartner?.empresa === company?.codigoEmpresa,
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.partners.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownPartners}
            resetDropdown={cleanDropdownPartners}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.partners.nome`)}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={selectedPartner?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setaddEditNewPartner(false);
    setshowEditModal(false);
    resetBackButton();
    setcleanDropdownPartners(true);
    setSelectedEditPartner(undefined);
    setselectedPartner({
      empresa: "",
      idPartner: 0,
      nome: "",
      ativo: true,
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.partners.btnVoltar`)}
            </Button>
            {addEditNewPartner ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdatePartner()}
              >
                {t(`budgetManagement.partners.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdatePartner(selectedPartner?.idPartner)
                }
              >
                {t(`budgetManagement.partners.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.partners.msgDelete.1`)}{" "}
              {SelectedDeletePartner?.empresa}{" "}
              {t(`budgetManagement.partners.msgDelete.2`)}{" "}
              {SelectedDeletePartner?.nome}?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.partners.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() => handleDelete(SelectedDeletePartner?.idPartner)}
            >
              {t(`budgetManagement.partners.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDPartner || isLoading} />
          {Object.keys(partners_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.partners.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <PartnersFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      partnersData={partners_CRUD}
                      setpartnersToShow={setpartnersToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      partnerFilter={partnerFilter}
                      setpartnerFilter={setpartnerFilter}
                    />
                    <PartnersTable
                      app={"bpm"}
                      partnersData={partnersToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setaddEditNewPartner={setaddEditNewPartner}
                      seteditDeletePartner={seteditDeletePartner}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Partners);
