import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwners,
  getUsers,
  postInsertDelegation,
  setSuccess,
  getRequestedDelegation,
} from "~/store/ducks/delegacoes/actionTypes";
import DatePicker from "~/components/DatePicker";
import SearchableDropDown from "../SearchableDropDown";
import "./scss/DelegacoesEditor.scss";
import SpinnerNew from "~/components/SpinnerNew";

const minWidth = "300px";
const fontWeight = "500";
const devLogConsole = require("~/utils/devLog");
const DelegacaoLabel = ({ label, value }) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", minWidth: minWidth }}>{label}</div>
        {Array.isArray(value) ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {value.map((e, i) => (
              <div style={{ fontWeight: fontWeight }} key={i}>
                {e.flowname}
              </div>
            ))}
          </div>
        ) : (
          <div style={{ fontWeight: fontWeight }}>{value}</div>
        )}
      </div>
      <hr />
    </div>
  );
};

const DelegacaoInput = ({ label, noHighlight, children }) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ minWidth: minWidth }}>{label}</div>
        {children}
      </div>
      {!noHighlight && <hr />}
    </div>
  );
};

const DelegacoesEditor = (props) => {
  const { t, supervisor, setIsLoadingData, isLoadingData } = props;

  const { userid } = useSelector(
    (selectorState) => selectorState.delegacoesReducer
  );
  devLogConsole(userid, "userid");

  const hasPermission = (permission) => {
    if (Array.isArray(props.data))
      return props.data[0].permissions.includes(permission);
    return props.data.permissions.includes(permission);
  };

  let newDate = new Date();
  newDate = newDate.setDate(newDate.getDate() + 7);

  const [showWarning, setShowWarning] = useState(false);
  const [changeToDisable, setChangeToDisable] = useState(false);
  // const [user]

  const [state, setState] = React.useState({
    date: "",
    error: "",
    users: [],
    owners: [],
    owner: "",
    selectedUser: "",
    read: hasPermission("R") ? "R" : "",
    Create: hasPermission("C") ? "C" : "",
    Write: hasPermission("W") ? "W" : "",
    Super: hasPermission("S") ? "S" : "",
  });

  const { users, owners, success } = useSelector(
    (selectorState) => selectorState.delegacoesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUsers({
        flowId: Array.isArray(props.data)
          ? props.data[0].flowid
          : props.data.flowid,
      })
    );
    dispatch(
      getOwners({
        flowId: Array.isArray(props.data)
          ? props.data[0].flowid
          : props.data.flowid,
      })
    );
  }, []);

  useEffect(() => {
    if (success && success.value && success.caller != "") Cancel();
    else if (success && success.caller != "")
      setState({ ...state, error: "Delegação já existe" });

    if (success && success.caller != "")
      dispatch(setSuccess({ value: false, caller: "" }));
  }, [success]);

  useEffect(() => {
    if (users)
      setState({
        ...state,
        users: users.map((e) => {
          return { label: e.username, value: e.username };
        }),
      });
  }, [users]);

  useEffect(() => {
    if (owners)
      setState({
        ...state,
        owners: owners.map((e) => {
          return { label: e.username, value: e.username };
        }),
      });
  }, [owners]);

  const Cancel = () => {
    setShowWarning(false);
    return props.onCancel();
  };

  const Create = () => {
    setShowWarning(false);
    if (
      state.selectedUser == "" ||
      (props.owner ? props.owner : state.owner) == ""
    )
      return setState({ ...state, error: "Falta user ou owner" });
    setIsLoadingData(true)
    dispatch(
      postInsertDelegation({
        userid: state.selectedUser,
        ownerid: props.owner ? props.owner : state.owner,
        expirationDate: `${state.date.toISOString().split("T")[0]}T23:59:59`,
        permissions: state.Create + state.Write + state.read + state.Super,
        flowids: Array.isArray(props.data)
          ? props.data.map((e) => e.flowid)
          : [props.data.flowid],
        parentid: undefined,
        supervisor: supervisor,
      })
    );
  };

  useEffect(() => {
    if (state.owner && props?.data?.flowid) {
      setChangeToDisable(true);
      dispatch(getRequestedDelegation(state.owner, props?.data?.flowid));
      setShowWarning(false);
      setTimeout(() => {
        setChangeToDisable(false);
      }, 2000);
    }
  }, [props?.data, state]);

  devLogConsole(changeToDisable, "changeToDisable");

  useEffect(() => {
    if (userid && userid.length > 0 && state?.owner && props?.data?.flowid) {
      devLogConsole("entrou 1");
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [props?.data?.flowid, state?.owner, userid]);


  useEffect(() => {
    if (state.error != "") {
      setIsLoadingData(false)
    }
  }, [state.error]);

  return (
    <div className="delegacoes-editor">
      <SpinnerNew open={isLoadingData} />
      <DelegacaoLabel
        label={t("bpm.delegacoes.form.flowName") + ":"}
        value={Array.isArray(props.data) ? props.data : props.data.flowname}
      />
      {props.owner ? (
        <DelegacaoLabel
          label={t("bpm.delegacoes.form.resposible") + ":"}
          value={props.owner}
        />
      ) : (
        <DelegacaoInput label={t("bpm.delegacoes.form.resposible") + ":*"}>
          <SearchableDropDown
            handleSubmit={(v) => setState({ ...state, owner: v })}
            list={state.owners}
            nomargin={true}
            disabled={changeToDisable ? true : false}
          />
          {showWarning ? (
            <p style={{ color: "red", paddingLeft: "50px" }}>
              {t("bpm.delegacoes.form.warningPhrase.warningOne")}
              {state.owner}
              {t("bpm.delegacoes.form.warningPhrase.warningTwo")}
              {userid}
              {t("bpm.delegacoes.form.warningPhrase.warningThree")}
            </p>
          ) : null}
        </DelegacaoInput>
      )}
      <DelegacaoInput label={t("bpm.delegacoes.form.endDate") + ":*"}>
        <DatePicker
          value={state.date}
          onChange={(v) => setState({ ...state, date: v })}
          name={"annotation-deadline"}
          format={"MM/dd/yyyy"}
        />
      </DelegacaoInput>
      <DelegacaoInput label={t("bpm.delegacoes.form.requestUser") + ":*"}>
        <SearchableDropDown
          handleSubmit={(v) => setState({ ...state, selectedUser: v })}
          list={state.users}
          nomargin={true}
        />
      </DelegacaoInput>
      {!Array.isArray(props.data) ? (
        <>
          <DelegacaoInput
            label={t("bpm.delegacoes.form.permissions") + ":"}
            noHighlight={true}
          />
          <Form.Check
            checked={state.read}
            onChange={() => setState({ ...state, read: !state.read })}
            label={t("bpm.delegacoes.form.read")}
          />
          <Form.Check
            checked={state.Create}
            onChange={() => setState({ ...state, Create: !state.Create })}
            label={t("bpm.delegacoes.form.create")}
          />
          <Form.Check
            checked={state.Write}
            onChange={() => setState({ ...state, Write: !state.Write })}
            label={t("bpm.delegacoes.form.write")}
          />
          <Form.Check
            checked={state.Super}
            onChange={() => setState({ ...state, Super: !state.Super })}
            label={t("bpm.delegacoes.form.super")}
          />
          <div>
            <hr />
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          marginTop: 10,
        }}
      >
        <Button variant="danger" onClick={() => Cancel()}>
          {t("bpm.delegacoes.form.cancel")}
        </Button>

        <button
          className="card-button btn btn-primary"
          onClick={() => Create()}
        >
          {t("bpm.delegacoes.form.request")}
        </button>
      </div>
      <Modal
        show={state.error != ""}
        dialogClassName="custom-modal"
        onHide={() => setState({ ...state, error: "" })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("messages.error")}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => setState({ ...state, error: "" })}
          >
            {t("bpm.delegacoes.form.cancel")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withNamespaces()(DelegacoesEditor);
