import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import SpinnerNew from "~/components/SpinnerNew";

import { getObjectiveByID, getEvaluationSystems, getEvaluationPeriods } from "~/store/ducks/portalrh/actionTypes";
import { Modal, Form, Col, Alert, Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";

const Objective = ({ ...props }) => {
  const { selectedObjective, showModal, closeModal, t } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [evaluationSystemsOptions, setEvaluationSystemsOptions] = useState([]);
  const [evaluationPeriodsOptions, setEvaluationPeriodsOptions] = useState([]);

  const { objective, isLoadingObjective, evaluationSystems, 
    isLoadingEvaluationSystems, evaluationPeriods, isLoadingEvaluationPeriods } = useSelector(
    (state) => state.portalrhReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal === true) {
      dispatch(getObjectiveByID(selectedObjective?.obj_id));
    }
  }, [showModal]);

  useEffect(() => {
    dispatch(getEvaluationSystems());
    setEvaluationSystemsOptions(evaluationSystems.map((evaluationSystem) => {
      return { value: evaluationSystem.sis_id, label: evaluationSystem.sis_descricao, selected: false };
    }));
    
    dispatch(getEvaluationPeriods(selectedObjective?.obj_sis_id));
    setEvaluationPeriodsOptions(evaluationPeriods.map((evaluationPeriod) => {
      return { value: evaluationPeriod.per_id, label: evaluationPeriod.per_dt_inicio + ' - ' + evaluationPeriod.per_dt_fim, selected: false };
    }));
  }, [showModal, selectedObjective]);



  function handleSaveButton() {
    closeModal();
    setShowAlert(false);
  }

  function handleCancelButton() {
    closeModal();
    setShowAlert(false);
  }

  const getVariantAlert = () => {
    if (Object.keys(objective).length === 0) {
      return "danger";
    } else {
      return "success";
    }
  };

  const getMessageAlert = () => {
    if (Object.keys(objective).length === 0) {
      return `${t("admin.userManagement.persist.modal.errorMessage")}`;
    } else {
      return `${t("admin.userManagement.persist.modal.updated")}`;
    }
  };

  const handleSubmit = (value) => {

  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("portalrh.performance.general.goalID") + " " + selectedObjective?.obj_id}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          noValidate
          // validated={validated}
          // onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
          style={isLoadingObjective || isLoadingEvaluationSystems || isLoadingEvaluationPeriods ? { display: "none" } : {}}
        >
          <Form.Row>
            <Col lg="3">
              <Form.Label>
                {t("portalrh.performance.general.evaluationSystem") + ":"}
              </Form.Label>
            </Col>
            <Col lg="7">
              <Dropdown list={evaluationSystemsOptions} handleSubmit={handleSubmit} placeholder={selectedObjective?.sis_designacao} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="3">
              <Form.Label>
                {t("portalrh.performance.general.evaluationPeriod") + ":"}
              </Form.Label>
            </Col>
            <Col lg="7">
              <Dropdown list={evaluationPeriodsOptions} handleSubmit={handleSubmit} placeholder={selectedObjective?.periodo} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="3">
              <Form.Label>
                {t("portalrh.performance.general.objective") + ":"}
              </Form.Label>
            </Col>
            <Col lg="9">
              <Form.Group controlId="1" className="mb-3">
                <Form.Control
                  as="textarea" 
                  rows={3}
                  placeholder=""
                  value={selectedObjective?.obj_objetivo}
                  onChange={() => { }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("admin.userManagement.persist.requiredUsername")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="3">
              <Form.Label>
                {t("portalrh.performance.general.manager") + ":"}
              </Form.Label>
            </Col>
            <Col lg="9">
              <Form.Group controlId="2" className="mb-3">
                <Form.Control
                  as="textarea" 
                  rows={3}
                  placeholder=""
                  value={selectedObjective?.obj_indicador}
                  onChange={() => { }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("admin.userManagement.persist.requiredUsername")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="3">
              <Form.Label>
                {t("portalrh.performance.general.overrunCriterion") + ":"}
              </Form.Label>
            </Col>
            <Col lg="9">
              <Form.Group controlId="3" className="mb-3">
                <Form.Control
                  as="textarea" 
                  rows={3}
                  placeholder=""
                  value={selectedObjective?.obj_criterio}
                  onChange={() => { }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {t("admin.userManagement.persist.requiredUsername")}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
        {showAlert && (
          <Alert
            variant={getVariantAlert()}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            {getMessageAlert()}
          </Alert>
        )}

        {!isLoadingObjective && (
          ""
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="card-button" onClick={() => handleSaveButton()}>
          {t("general.save")}
        </Button>
        <Button variant="danger" onClick={() => handleCancelButton()}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>

      <SpinnerNew open={isLoadingObjective} />
    </Modal>
  );
};
export default withNamespaces()(Objective);