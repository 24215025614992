import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import uuid from "uuid/v1";
import { remove, find } from "lodash";
import { BACKEND_SERVER_URL } from "~/utils/constants";
import { ROLE_ADMIN } from "~/utils/constants/roles";
import { withNamespaces } from "react-i18next";
import { hasRoles } from "~/components/security/utils";

import { Card, Col, Button, Form, Table, Alert } from "react-bootstrap";

import "~/assets/css/icons.css";
import avatarDefault from "~/assets/img/avatar/avatar-default.png";

import * as actions from "./actions";
import {
  addRoleItem,
  removeRoleItem,
  findDefaultRoles,
} from "~/pages/Admin/Role/actions";
import {
  getOrganizations,
  clearOrganizations,
} from "~/store/ducks/organizations/actionTypes";
import {
  resetPassword,
  clearResetPassword,
} from "~/store/ducks/resetPassword/actionTypes";

import LegacyUserModal from "./components/LegacyUserModal";
import RoleModal from "./components/RoleModal";
import LegacyUserRow from "./components/LegacyUserRow";
import RoleTable from "./components/RoleTable";
import PersistUserLanguage from "./components/PersistUserLanguage";
import SearchableDropDown from "~/components/SearchableDropDown/index";
import SpinnerNew from "~/components/SpinnerNew";
import Messages from "~/components/Messages";
import WarningModal from "~/components/WarningModal/WarningModal";
import PersistUserEnabled from "./components/PersistUserEnabled";
const devLogConsole = require("~/utils/devLog");
const PersistUnikUser = (props) => {
  const history = useHistory();
  const { id } = props.match.params;
  const { t } = props;
  const isEditing = !!id;

  const {
    legacyApps,
    unikUser,
    isPersisting,
    successAddUser,
    isLoadingLegacyApplications,
    isLoadingUnikUser,
  } = useSelector((state) => state.userManagementReducer);

  const { user } = useSelector((state) => state.globalReducer);
  const { defaultRoles } = useSelector((state) => state.roleReducer);
  const { organizations } = useSelector((state) => state.organizationsReducer);

  const dispatch = useDispatch();

  const maxFileSize = 32500; //bytes
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [password, setPassword] = useState("");
  const [sendResetEmail, setSendResetEmail] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [picture, setPicture] = useState(null);
  const [urlPicture, setUrlPicture] = useState(null);
  const [roles, setRoles] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [defaultOrganizationId, setDefaultOrganizationId] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [permitOpenNewTab, setPermitOpenNewTab] = useState(false);

  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);

  const [selectedLegacyApplication, setSelectedLegacyApplication] = useState(
    {}
  );
  const [legacyUsers, setLegacyUsers] = useState([]);
  const [legacyApplications, setLegacyApplications] = useState([]);

  const [showModalLegacyUser, setShowModalLegacyUser] = useState(false);
  const [showModalRole, setShowModalRole] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [changePassword, setChangePassword] = useState(false);

  const [listaAux, setListaAux] = useState([]);
  const [finList, setLista] = useState([]);
  const [isToSave, setToSave] = useState(false);

  const [isToReadOnly, setIsToReadOnly] = useState(false);
  const [selectedLegacyUser, setSelectedLegacyUser] = useState();

  const isLoadingPersistPage = isLoadingLegacyApplications || isLoadingUnikUser;

  const DEFAULT_PASSWORD = "Xpto456!";

  useEffect(() => {
    dispatch(actions.loadLegacyApps());
    dispatch(getOrganizations());
    if (id) {
      dispatch(actions.loadUnikUserById(id));
    } else {
      dispatch(findDefaultRoles()); // for new User
    }

    return () => {
      dispatch(actions.clearUnikUser());
      dispatch(clearOrganizations());
    };
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getOrganizations());
    return () => {
      dispatch(clearOrganizations());
    };
  }, [dispatch]);

  useEffect(() => {
    if (user.username) {
      setDefaultOrganizationId(user?.organization?.id);
    }
  }, [user]);

  useEffect(() => {
    if (unikUser) {
      setUsername(unikUser.username);
      setRoles(unikUser.roles);
      setLegacyUsers(unikUser.legacyUsers);
      if (unikUser.legacyApplications === null) {
        setLegacyApplications([]);
      } else {
        setLegacyApplications(unikUser.legacyApplications);
      }

      if (unikUser.detail) {
        setFirstName(unikUser.detail.firstName);
        setLastName(unikUser.detail.lastName);
        setEmail(unikUser.detail.email);
        setMobilePhone(
          unikUser.detail.mobilePhone ? unikUser.detail.mobilePhone : ""
        );
        setBirthday(
          unikUser.detail.birthday
            ? new Date(unikUser.detail.birthday).toISOString().slice(0, 10)
            : ""
        );
        setPicture(unikUser.detail.picture);
        setUrlPicture("data:image/png;base64," + unikUser.detail.picture);
      }
      if (unikUser.organization && unikUser.organization.id) {
        setOrganizationId(unikUser.organization.id);
      } else {
        setOrganizationId(defaultOrganizationId);
      }
    } else {
      setUrlPicture(avatarDefault);
      setOrganizationId(defaultOrganizationId);
    }
    if (!unikUser?.detail?.picture) {
      setUrlPicture(avatarDefault);
    }
  }, [unikUser, defaultOrganizationId]);

  useEffect(() => {
    if (defaultRoles) setRoles(defaultRoles);
  }, [defaultRoles]);

  useEffect(() => {
    if (successAddUser) {
      if (sendResetEmail && email !== "") {
        handleResetPassword();
      }
    }
    return () => {
      dispatch(actions.clearAddUser());
      dispatch(clearResetPassword());
    };
  }, [successAddUser, email]);

  useEffect(() => {
    if (sendResetEmail) {
      setPassword(DEFAULT_PASSWORD);
      setConfirmPassword(DEFAULT_PASSWORD);
    } else {
      setPassword("");
      setConfirmPassword("");
    }
  }, [sendResetEmail]);

  function openModal(legacyApp) {
    setSelectedLegacyApplication(legacyApp);
    setShowModalLegacyUser(true);
  }

  function closeModal() {
    setShowModalLegacyUser(false);
  }

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (isEditing && sendResetEmail) {
        handleResetPassword();
      }
      if (password === confirmPassword) {
        persistUnikUser();
      } else setError(t("admin.userManagement.persist.passwordsNotMatch"));
    }
  };

  const handleResetPassword = () => {
    if (!!email) {
      const data = { urlOrigin: BACKEND_SERVER_URL(), payload: email };
      dispatch(resetPassword(data));
    } else {
      devLogConsole("there's no email inserted");
    }
  };

  function handleAuxList(object) {
    let existe =
      listaAux?.findIndex((obj) => obj.application === object?.application) !==
      -1;
    if (existe) {
      const newListAux = listaAux.filter(
        (elem) => elem.application !== object.application
      );
      newListAux.push(object);
      setListaAux(newListAux);
    } else {
      listaAux.push(object);
      setListaAux(listaAux);
    }
  }

  function persistUnikUser() {
    setToSave(true);

    const organization = organizations.find(
      (item) => item.id == organizationId
    );

    const payload = {
      username,
      password,
      detail: {
        firstName,
        lastName,
        email,
        birthday,
        mobilePhone,
        picture,
      },
      roles,
      legacyUsers,
      legacyApplications,
      organization,
      extendedApplicationAttributes: listaAux,
      enabled,
      //openNewTab: permitOpenNewTab,
    };
    if (isEditing) {
      payload.id = parseInt(id);
      dispatch(actions.updateUser(payload));
    } else {
      dispatch(actions.addUser(payload));
    }
  }

  const backToSearch = () => {
    const location = {
      pathname: "/uniksystem/admin/unikuser",
      state: {
        name: "Profile management",
        icon: "icon-unik3 mr-2",
      },
    };
    history.push(location);
  };

  const handleSelectPicture = (e) => {
    const _file = e.target.files[0];
    if (_file.size < maxFileSize) {
      if (_file && _file["type"].split("/")[0] === "image") {
        setPicture(_file);
        setUrlPicture(URL.createObjectURL(_file));
      }
    } else {
      setWarningMessage(_file.name + ": " + t("general.largeFile"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const addRole = (row) => {
    if (!find(roles, { id: row.id })) {
      setRoles([...roles, row]);
    }
    dispatch(removeRoleItem(row));
  };

  const removeRole = (row) => {
    let roles_ = roles;
    remove(roles_, (role_) => role_.id === row.id);
    setRoles([...roles_]);
    dispatch(addRoleItem(row));
  };

  function pngToFile(image, filename) {
    var arr = image.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  //Converts list used in SearchableDropdown to the correct format
  const adaptListOfOrganizations = (list) => {
    const org = list.find((item) => item.id == organizationId);
    let list_ = [...organizations];
    if (org) {
      list_ = list.map((organization) => {
        if (org.id === organization.id) {
          return {
            value: organization.id.toString(),
            label: organization.description,
            selected: true,
          };
        } else
          return {
            value: organization.id.toString(),
            label: organization.description,
          };
      });
    } else {
      list_ = list.map((organization) => {
        if (organization.label === "uniksystem") {
          return {
            value: organization.id.toString(),
            label: organization.description,
            selected: true,
          };
        } else
          return {
            value: organization.id.toString(),
            label: organization.description,
          };
      });
    }
    return list_;
  };

  const handleDeleteUserPicture = () => {
    dispatch(actions.removeUserPicture(id));
    setUrlPicture(avatarDefault);
    setPicture(null);
  };

  function getExtendedAttrElement(appLabel) {
    return unikUser?.extendedApplicationAttributes?.filter(
      (appAttr) => appAttr?.application === appLabel
    )[0];
  }

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
          style={isLoadingPersistPage ? { display: "none" } : {}}
        >
          <Card.Header className="justify-content-between">
            <h6>
              <i className="icon-add mr-2" />
              {`${isEditing ? t("general.edit") : t("general.add")}  ${t(
                "admin.general.user"
              )}`}
            </h6>
          </Card.Header>

          <Card.Body>
            {showWarning && (
              <Alert
                variant="warning"
                onClose={() => setShowWarning(false)}
                dismissible
              >
                <strong>Alert: </strong>
                {warningMessage}
              </Alert>
            )}
            <Form.Row>
              <Col lg="6">
                <Form.Row>
                  <Col sm="12" md="6" lg="6">
                    <Form.Group controlId="validationCustomPhoto">
                      <Form.Label>
                        {t("admin.userManagement.persist.picture")}
                      </Form.Label>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => handleSelectPicture(e)}
                          />
                          <label htmlFor="imageUpload">
                            <i className="icon-add add-picture" />
                          </label>
                        </div>
                        {picture && (
                          <div className="avatar-delete">
                            <i
                              className="icon-trash add-picture"
                              onClick={() => {
                                setShowDeleteModal(true);
                              }}
                            />
                          </div>
                        )}
                        <div className="avatar-preview-fit-inside">
                          <div
                            style={{
                              backgroundImage: "url(" + urlPicture + ")",
                            }}
                          />
                        </div>
                        <Form.Text className="text-muted">
                          {t("general.maxFileSize") +
                            " " +
                            Math.round(maxFileSize / 1024) +
                            "kb"}
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="8" lg="6">
                    <Form.Group controlId="username">
                      <Form.Label>
                        {t("admin.userManagement.persist.username")} {asterisk}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        isInvalid={
                          username.startsWith(" ") || username.endsWith(" ")
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {username.startsWith(" ") || username.endsWith(" ")
                          ? t(
                              "admin.userManagement.persist.invalidUsernameSpace"
                            )
                          : t("admin.userManagement.persist.requiredUsername")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="8" lg="8">
                    <Form.Group controlId="validationCustomName">
                      <Form.Label>
                        {t("admin.userManagement.persist.firstName")} {asterisk}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("admin.userManagement.persist.requiredFirstName")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="8" lg="8">
                    <Form.Group controlId="validationCustomSirname">
                      <Form.Label>
                        {t("admin.userManagement.persist.lastName")} {asterisk}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("admin.userManagement.persist.requiredLastName")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="8" lg="8">
                    <Form.Group controlId="validationCustomEmail">
                      <Form.Label>
                        {t("admin.userManagement.persist.email")} {asterisk}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isInvalid={email.startsWith(" ") || email.endsWith(" ")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {username.startsWith(" ") || username.endsWith(" ")
                          ? t("admin.userManagement.persist.invalidEmailSpace")
                          : t("admin.userManagement.persist.requiredEmail")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="12" md="6" lg="5">
                    <Form.Group controlId="validationCustomContact">
                      <Form.Label>
                        {t("admin.userManagement.persist.mobile")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={mobilePhone}
                        onChange={(e) => setMobilePhone(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("admin.userManagement.persist.requiredMobile")}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm="8" md="6" lg="6">
                    <Form.Group controlId="validationCustomBirthday">
                      <Form.Label>
                        {t("admin.userManagement.persist.birthday")}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={birthday}
                        onChange={(e) => {
                          setBirthday(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                {isEditing && unikUser?.username && (
                  <Form.Row>
                    <Col sm="8" md="6" lg="6">
                      <Form.Group controlId="validationCustomBirthday">
                        <Form.Label>
                          {t("admin.userManagement.persist.setLanguage")}
                        </Form.Label>
                        <PersistUserLanguage username={unikUser.username} />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                )}
                {isEditing && (
                  <Form.Row>
                    <Col sm="12" md="6" lg="5">
                      <Form.Label>
                        {t("admin.userManagement.persist.changePassword")}
                      </Form.Label>
                      <Form.Check
                        id={uuid()}
                        type="switch"
                        label=""
                        checked={changePassword}
                        onChange={() => setChangePassword(!changePassword)}
                      />
                    </Col>
                  </Form.Row>
                )}
                <Form.Row>
                  {(changePassword || !isEditing) && (
                    <>
                      <Col sm="12" md="6" lg="5">
                        <Form.Group controlId="validationCustomPassword">
                          <Form.Label>
                            {t("admin.userManagement.persist.password")}{" "}
                            {asterisk}
                          </Form.Label>
                          <Form.Control
                            required
                            disabled={sendResetEmail}
                            type="password"
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setError("");
                            }}
                            value={password}
                            autoComplete="new-password"
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("admin.userManagement.persist.validPassword")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6" lg="5">
                        <Form.Group controlId="validationConfirmPassword">
                          <Form.Label>
                            {t("admin.userManagement.persist.confirmPassword")}{" "}
                            {asterisk}
                          </Form.Label>
                          <Form.Control
                            required
                            disabled={sendResetEmail}
                            type="password"
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setError("");
                            }}
                            value={confirmPassword}
                          />
                          {error && (
                            <Form.Text style={{ color: "#dc3545" }}>
                              {error}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Form.Row>
                {/* <Form.Row>
                  <Col sm="12" md="6" lg="5">
                    <Form.Label>
                      {t("admin.userManagement.persist.permitOpenNewTab")}
                    </Form.Label>
                    <Form.Check
                      id={uuid()}
                      type="switch"
                      label=""
                      checked={permitOpenNewTab}
                      onChange={() => setPermitOpenNewTab(!permitOpenNewTab)}
                    />
                  </Col>
                </Form.Row> */}
                <Form.Group
                  style={{ padding: "5px 5px " }}
                  controlId="checkBoxForNewPassword"
                >
                  <Form.Check
                    type="checkbox"
                    label={t("admin.userManagement.persist.requestNewPassword")}
                    onChange={(e) => {
                      setSendResetEmail(e.target.checked);
                    }}
                  />
                </Form.Group>
                {isEditing && unikUser?.username && (
                  <Form.Row>
                    <Col sm="12" md="8" lg="8">
                      <Form.Group controlId="validationCustomSirname">
                        <Form.Label
                          style={{ paddingRight: "0.5rem", fontWeight: "700" }}
                        >
                          {unikUser.enabled
                            ? t("admin.userManagement.persist.activeMessage")
                            : t("admin.userManagement.persist.inactiveMessage")}
                        </Form.Label>
                        <PersistUserEnabled
                          username={unikUser.username}
                          enabled={enabled}
                          setEnabled={setEnabled}
                          enabledUser={unikUser.enabled}
                          t={t}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                )}
              </Col>

              <Col lg="6">
                {hasRoles(user, [ROLE_ADMIN], true) && (
                  <>
                    <Form.Label>
                      {t("admin.userManagement.persist.organization")}
                      {asterisk}
                    </Form.Label>
                    <SearchableDropDown
                      name={"Empresa"}
                      required={true}
                      placeholder="Selecionar empresa"
                      list={adaptListOfOrganizations(organizations)}
                      handleSubmit={(value) => setOrganizationId(value)}
                    />
                  </>
                )}
                {hasRoles(user, [ROLE_ADMIN], true) && (
                  <>
                    <Form.Row>
                      <Col lg="12">
                        <Form.Label>
                          {t(
                            "admin.userManagement.persist.applicationTable.title"
                          )}
                        </Form.Label>
                        <div className="custom-table-2 custom-table-small-2">
                          <Table id="user-table" striped bordered hover>
                            <thead>
                              <tr>
                                <th>
                                  {t(
                                    "admin.userManagement.persist.applicationTable.applicationColumn"
                                  )}
                                </th>
                                <th>
                                  {t(
                                    "admin.userManagement.persist.applicationTable.accessColumn"
                                  )}
                                </th>
                                <th>
                                  {t(
                                    "admin.userManagement.persist.applicationTable.usersColumn"
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {legacyApps.map((legacyApp, index) => (
                                <LegacyUserRow
                                  isToReadOnly={isToReadOnly}
                                  setIsToReadOnly={setIsToReadOnly}
                                  finList={finList}
                                  isToSave={isToSave}
                                  handleAuxList={handleAuxList}
                                  listaAux={listaAux}
                                  key={legacyApp.id}
                                  username={username}
                                  legacyApp={legacyApp}
                                  legacyUsers={legacyUsers}
                                  legacyApplications={legacyApplications}
                                  openModal={openModal}
                                  isEditing={isEditing}
                                  extendedApplicationAttributes={
                                    selectedLegacyUser === undefined
                                      ? getExtendedAttrElement(legacyApp?.label)
                                      : selectedLegacyUser
                                  }
                                />
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Form.Row>
                    <hr />
                  </>
                )}

                {hasRoles(user, [ROLE_ADMIN], true) && (
                  <Form.Row>
                    <Col lg="12">
                      <Form.Label>
                        {t("admin.userManagement.persist.roles")}
                      </Form.Label>
                      <RoleTable
                        roles={roles}
                        setShowModal={setShowModalRole}
                        isLoading={false}
                        icon="icon-trash"
                        action={removeRole}
                      />
                    </Col>
                  </Form.Row>
                )}
              </Col>
            </Form.Row>
          </Card.Body>

          <Card.Footer>
            <Button variant="danger" onClick={() => backToSearch()}>
              {t("general.back")}
            </Button>
            <Button
              className="card-button"
              type="submit"
              style={{ marginLeft: "10px", marginRight: "55px" }}
            >
              {t("general.save")}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
      <LegacyUserModal
        selectedLegacyUser={setSelectedLegacyUser}
        isToReadOnly={setIsToReadOnly}
        showModal={showModalLegacyUser}
        closeModal={closeModal}
        legacyUsers={legacyUsers}
        setLegacyUsers={setLegacyUsers}
        legacyApplications={legacyApplications}
        setLegacyApplications={setLegacyApplications}
        selectedLegacyApplication={selectedLegacyApplication}
      />

      <RoleModal
        showModal={showModalRole}
        setShowModal={setShowModalRole}
        action={addRole}
      />

      <WarningModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDeleteUserPicture}
        handleCloseDeleteModal={setShowDeleteModal}
        message={t("admin.userManagement.persist.warningPicture")}
      />
      <SpinnerNew open={isLoadingPersistPage||isPersisting} />
      <Messages afterHideSuccess={backToSearch} />
    </div>
  );
};

export default withNamespaces()(PersistUnikUser);
