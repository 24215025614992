import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import SpinnerNew from "~/components/SpinnerNew";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import { getObjectives } from "~/store/ducks/portalrh/actionTypes";
import Objective from "./Objective";

const Objectives = ({ t }) => {
  const [selectedObjective, setSelectedObjective] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const { objectives, isLoadingObjectives } = useSelector(
    (state) => state.portalrhReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getObjectives());
  }, []);

  useInterval(() => {
    dispatch(getObjectives());
  }, 1000 * 60 * 5);

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "obj_id",
      text: t("portalrh.performance.tableColumns.id"),
      sort: true,
    },
    {
      dataField: "sis_designacao",
      text: t("portalrh.performance.tableColumns.system"),
      sort: true,
    },
    {
      dataField: "periodo",
      text: t("portalrh.performance.tableColumns.period"),
      sort: true,
    },
    {
      dataField: "obj_objetivo",
      text: t("portalrh.performance.tableColumns.objective"),
      sort: true,
    },
  ];

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  const rowEvents = {
    onClick: (_, row) => {
      setSelectedObjective(row);
      setShowModal(true);
    },
  };

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">

        <ToolkitProvider
          keyField="obj_id"
          data={objectives}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "10px", paddingTop: "10px", display: "flex" }}>
                <SearchBar {...props.searchProps} placeholder={t("Search")} />
              </div>

              <SpinnerNew open={isLoadingObjectives} />
              {!isLoadingObjectives && (
                <>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    pagination={paginationFactory(pagination)}
                    noDataIndication={t("No objectives available")}
                    rowEvents={rowEvents}
                    striped
                    hover
                  />
                  <Objective
                    selectedObjective={selectedObjective}
                    showModal={showModal}
                    closeModal={closeModal}
                  />
                </>
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(Objectives);