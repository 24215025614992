import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import CategoriesTable from "../components/Categories/CategoriesTable";
import CategoriesFilters from "../components/Categories/CategoriesFilters";
import {
  getCRUDCategories,
  deleteCRUDCategorie,
  postADDCRUDCategories,
  updateCRUDCategorie,
  getCRUDCompanies,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import { persist } from "react";

const Categories = ({ t, isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const { categories_CRUD, isLoadingCRUDCategories, companies_CRUD } =
    useSelector((state) => state.budgetManagementCRUDReducer);

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewCategorie, setAddEditNewCategorie] = useState(false);
  const [editDeleteCategorie, seteditDeleteCategorie] = useState(null);
  const [categoriesToShow, setcategoriesToShow] = useState(categories_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownCompanies, setCleanDropdownCompanies] = useState(false);
  const [companyFilter, setcompanyFilter] = useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditCategorie, setSelectedEditCategorie] = useState(undefined);
  const [SelectedDeleteCategorie, setSelectedDeleteCategorie] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedEditCategory, setSelectedEditCategory] = useState({
    empresa: "",
    idCategoria: 0,
    nome: "",
    precoCompra: 0,
    precoVenda: 0,
    ativo: true,
  });

  const resetBackButton = () => {
    setSelectedEditCategory({
      empresa: "",
      idCategoria: 0,
      nome: "",
      precoCompra: 0,
      precoVenda: 0,
      ativo: true,
    });
  };

  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setSelectedEditCategory((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
  };

  const handleEmpresaChange = (empresa) => {
    setSelectedEditCategory((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditCategorie != undefined)
      setSelectedEditCategorie((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };

  const handlePrecoVendaChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setSelectedEditCategory((prevCategory) => ({
      ...prevCategory,
      precoVenda: parseFloat(event.target.value),
    }));
  };

  const handlePrecoCompraChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setSelectedEditCategory((prevCategory) => ({
      ...prevCategory,
      precoCompra: parseFloat(event.target.value),
    }));
  };

  useEffect(() => {
    if (SelectedEditCategorie != (null || undefined))
      setSelectedEditCategory(SelectedEditCategorie);
  }, [SelectedEditCategorie]);

  useEffect(() => {
    dispatch(getCRUDCategories());
    dispatch(getCRUDCompanies());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDCategories());
    handleReset();
  };

  const handleReset = () => {
    setcompanyFilter("");
    setcategoriesToShow(categories_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditCategorie(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeleteCategorie(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDCategorie(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdateCategorie = (id) => {
    if (id) {
      const payload = {
        idCategoria: id,
        nome: selectedEditCategory?.nome,
        precoVenda: selectedEditCategory?.precoVenda,
        precoCompra: selectedEditCategory?.precoCompra,
        empresa: selectedEditCategory?.empresa,
        year: new Date().getFullYear(),
        ativo: selectedEditCategory?.ativo,
      };

      dispatch(updateCRUDCategorie(id, payload));
    } else {
      const payload = {
        nome: selectedEditCategory?.nome,
        precoVenda: selectedEditCategory?.precoVenda,
        precoCompra: selectedEditCategory?.precoCompra,
        empresa: selectedEditCategory?.empresa,
        year: new Date().getFullYear(),
        ativo: true,
      };
      dispatch(postADDCRUDCategories(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setcategoriesToShow(categories_CRUD);
  }, [categories_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditCategorie !== undefined &&
        SelectedEditCategorie?.empresa === company?.codigoEmpresa,
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.categories.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setCleanDropdownCompanies}
            resetDropdown={cleanDropdownCompanies}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.categories.nome`)}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={selectedEditCategory?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`precoVenda-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.categories.precoVenda`)}:
          </label>
          <input
            id={`precoVenda-input`}
            className="form-control"
            type="number"
            step="0.01"
            value={selectedEditCategory?.precoVenda}
            onChange={handlePrecoVendaChange}
            required
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`precoCompra-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.categories.precoCompa`)}:
          </label>
          <input
            id={`precoCompra-input`}
            className="form-control"
            type="number"
            step="0.01"
            value={selectedEditCategory?.precoCompra}
            onChange={handlePrecoCompraChange}
            required
          />
        </div>
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setAddEditNewCategorie(false);
    setshowEditModal(false);
    resetBackButton();
    setCleanDropdownCompanies(true);
    setSelectedEditCategorie(undefined);
    setSelectedEditCategory({
      empresa: "",
      idCategoria: 0,
      nome: "",
      precoCompra: 0,
      precoVenda: 0,
      ativo: true,
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.categories.btnVoltar`)}
            </Button>
            {addEditNewCategorie ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdateCategorie()}
              >
                {t(`budgetManagement.categories.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdateCategorie(selectedEditCategory?.idCategoria)
                }
              >
                {t(`budgetManagement.categories.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.categories.msgDelete.1`)}{" "}
              {SelectedDeleteCategorie?.empresa}{" "}
              {t(`budgetManagement.categories.msgDelete.2`)}{" "}
              {SelectedDeleteCategorie?.nome} ?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.categories.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() => handleDelete(SelectedDeleteCategorie?.idCategoria)}
            >
              {t(`budgetManagement.categories.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDCategories || isLoading} />
          {Object.keys(categories_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.categories.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <CategoriesFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      categoriesData={categories_CRUD}
                      setcategoriesToShow={setcategoriesToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      companyFilter={companyFilter}
                      setcompanyFilter={setcompanyFilter}
                    />
                    <CategoriesTable
                      app={"bpm"}
                      categoriesData={categoriesToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setAddNewCategorie={setAddEditNewCategorie}
                      seteditDeleteCategorie={seteditDeleteCategorie}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Categories);
