import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import {
  getFlowFromUser,
  setUserFlow,
} from "~/store/ducks/delegacoes/actionTypes";
import DelegacoesEditor from "./DelegacoesEditor";
import SpinnerNew from "~/components/SpinnerNew";

const Requesitar = ({ t }) => {
  const [state, setState] = React.useState({ rows: [], selFlow: undefined });
  const [isLoadingData, setIsLoadingData]= useState(false);

  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { user } = useSelector((selectorState) => selectorState.globalReducer);
  const { flows, isLoadingSuccess, isLoadingUserFlow } = useSelector(
    (selectorState) => selectorState.delegacoesReducer
  );

  const getLegacyUser = () => {
    if (!user || !user.id) return;
    return user.legacyUsers.find((u) => u.legacyApplication.label == "bpm");
  };

  const onRowSelect = (row, isSelect) => {
    if (isSelect) setState({ ...state, rows: [...state.rows, row.id] });
    else setState({ ...state, rows: state.rows.filter((x) => x !== row.id) });
  };

  const onRowSelectAll = (isSelect, rows, e) => {
    const newRows = isSelect ? rows.map((e) => e.id) : [];
    setState({ ...state, rows: newRows });
    return rows;
  };

  const selectRow = {
    mode: "checkbox",
    selected: state.rows,
    onSelect: onRowSelect,
    onSelectAll: onRowSelectAll,
  };

  useEffect(() => {
    if (flows && flows.length != 0 && !flows[0].action)
      dispatch(
        setUserFlow(
          flows.map((v, i) => ({
            ...v,
            id: i,
            ownerid: getLegacyUser().username,
            action: (
              <span
                className="btn-link"
                onClick={() => setState({ ...state, selFlow: v })}
              >
                {t("bpm.delegacoes.table.action.request")}
              </span>
            ),
          }))
        )
      );
  }, [flows, isLoadingSuccess]);

  useEffect(() => {
    if (state.selFlow) history.push(path + "/editor");
    else history.push(path);
  }, [state.selFlow]);

  useEffect(() => {
    if (user && user.id) {
      dispatch(getFlowFromUser({ username: getLegacyUser().username }));
    }
  }, [user, isLoadingSuccess]);

  const column = [
    {
      dataField: "ownerid",
      text: t("bpm.delegacoes.table.owner"),
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return <span className="order-4"></span>;
        else if (order === "asc") return <span className="caret-4-asc"></span>;
        else if (order === "desc")
          return <span className="caret-4-desc"></span>;
        return null;
      },
    },
    {
      dataField: "flowname",
      text: t("bpm.delegacoes.table.flow"),
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return <span className="order-4"></span>;
        else if (order === "asc") return <span className="caret-4-asc"></span>;
        else if (order === "desc")
          return <span className="caret-4-desc"></span>;
        return null;
      },
    },
    {
      dataField: "action",
      text: "",
    },
  ];

  const { SearchBar } = Search;

  return (
    <Switch>
      <Route path={path + "/editor"}>
        {state.selFlow ? (
          <DelegacoesEditor
            owner={getLegacyUser().username}
            onCancel={() => {setState({ ...state, selFlow: undefined }); setIsLoadingData(false)}}
            data={state.selFlow}
            supervisor={false}
            setIsLoadingData={setIsLoadingData}
            isLoadingData={isLoadingData}
          />
        ) : (
          <></>
        )}
      </Route>
      <Route path={path}>
        <div className="custom-table" style={{ paddingTop: "10px" }}>
          <button
            className="card-button btn btn-primary"
            onClick={() => {
              if (state.rows.length == 0) return;
              setState({
                ...state,
                selFlow: state.rows.map((e, i) => flows[e]),
              });
            }}
          >
            {t("bpm.delegacoes.table.action.requestSelected")}
          </button>
          <SpinnerNew open={isLoadingData || isLoadingUserFlow || isLoadingSuccess} />
          <ToolkitProvider keyField="id" data={flows} columns={column} search>
            {(props) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SearchBar
                  style={{ marginTop: 10, float: "left" }}
                  {...props.searchProps}
                  placeholder={t("general.search")}
                />
                <BootstrapTable
                  selectRow={selectRow}
                  classes="table table-striped table-hover table-bordered"
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
          <button
            className="card-button btn btn-primary"
            onClick={() => {
              if (state.rows.length == 0) return;
              setState({
                ...state,
                selFlow: state.rows.map((e, i) => flows[e]),
              });
            }}
          >
            {t("bpm.delegacoes.table.action.requestSelected")}
          </button>
        </div>
      </Route>
    </Switch>
  );
};

export default withNamespaces()(Requesitar);
