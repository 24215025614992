import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import SpinnerNew from "~/components/SpinnerNew";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getExportLogs } from "~/store/ducks/portalrh/actionTypes";

const SheduleTable = (props) => {
  const { logs, isLoadingLogs, t } = props;

  const { stringBase64 } = useSelector((state) => state.portalrhReducer);

  const dispatch = useDispatch();

  //table and toolkit creation
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "logMsg",
      text: t("portalrh.exportSchedule.logsTable.errorMessage"),
      sort: true,
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "logLine",
      text: t("portalrh.exportSchedule.logsTable.fileErrorLine"),
      sort: true,
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "logDate",
      text: t("portalrh.exportSchedule.logsTable.date"),
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "logFileName",
      text: t("portalrh.exportSchedule.logsTable.fileName"),
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  const base64ToText = (base64) => {
    return Buffer.from(base64, "base64");
  };

  const textToFile = (text) => {
    const data = new Blob([text], { type: "text/plain" });
    return data;
  };

  const downloadFile = (data) => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");

    const timestamp = `${day}${month}${year}_${hours}${minutes}`;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);
    link.download = `logs_${timestamp}.txt`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleLogsFilteres = (logs) => {
    const searchbarValue = document.getElementById("search-bar-0").value;

    if (searchbarValue === "") {
      return logs;
    } else {
      const filteredResults = logs.filter(
        (item) =>
          item.logMsg?.toLowerCase().includes(searchbarValue?.toLowerCase()) ||
          item.logLine.includes(searchbarValue) ||
          item.logDate.includes(searchbarValue) ||
          item.logFileName?.toLowerCase().includes(searchbarValue?.toLowerCase())
      );
      return filteredResults;
    }
  };

  const handleExportLogs = () => {
    const logsFiltered = handleLogsFilteres(logs);
    dispatch(getExportLogs(logsFiltered));
  };

  useEffect(() => {
    if (stringBase64.length > 0) {
      const text = base64ToText(stringBase64);
      const file = textToFile(text);
      downloadFile(file);
    }
  }, [stringBase64]);

  return (
    <div className="custom-table custom-table-small cursor-pointer">
      <ToolkitProvider keyField="id" data={logs} columns={columns} search>
        {(props) => (
          <div>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <h6 className="default-title" style={{ paddingRight: "20px" }}>
                  {t("Logs")}
                </h6>
                <SearchBar {...props.searchProps} placeholder={t("Search")} />
              </div>
              <Button
                className="card-button btn btn-primary"
                style={{ marginBottom: "8px" }}
                onClick={() => handleExportLogs()}
              >
                Exportar Logs
              </Button>
            </div>
            {isLoadingLogs ? (
              <SpinnerNew open={isLoadingLogs} />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(pagination)}
                noDataIndication={t(
                  "portalrh.exportSchedule.logsTable.noLogsAvailable"
                )}
                striped
                hover
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(SheduleTable);
