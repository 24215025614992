import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import ConsumosTable from "./ConsumosTable";
import { Card } from "react-bootstrap";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { getConsumosList } from "store/ducks/processes/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";

const RHConsumos = ({ t }) => {
  const dispatch = useDispatch();
  const { isLoadingConsumosList } = useSelector(
    (state) => state.processesReducer
  ); 
    const { collaborators } = useSelector((state) => state.portalrhReducer);
    const { user } = useSelector((state) => state.globalReducer);
  useEffect(() => {
    dispatch(getCollaborators());
  }, [dispatch]);

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  const userPortal = userNamePortalRH;

  useEffect(() => {
    if(userPortal) dispatch(getConsumosList(userPortal.id));
  }, [userPortal]);

  return (
    <div className="main-card-v2" style={{ height: "fit-content" }}>
      <SpinnerNew open={isLoadingConsumosList} />
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>{t("portalrh.rhadmin.consumos.cardTitle")}</h6>
        </Card.Header>
        <Card.Body>
            <ConsumosTable />
        </Card.Body>
      </Card>
    </div>
  );
};

export default withNamespaces()(RHConsumos);
