import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SpinnerNew from "~/components/SpinnerNew";
import SidebarContainer from "~/containers/SidebarContainer";
import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
import ApplicationMenuItem from "./ApplicationMenuItem";

const ApplicationMenu = ({ applicationName, showMenu = true, translations }) => {
  const dispatch = useDispatch();

  const { isLoadingMenu, successDelete, successEdit } = useSelector(
    (state) => state.applicationMenuReducer
  );

  useEffect(() => {
    if (applicationName) {
      dispatch(findMenuByApplicationName(applicationName));
    }
  }, [applicationName]);

  useEffect(() => {
    if ((successEdit || successDelete) && applicationName) {
      dispatch(findMenuByApplicationName(applicationName));
    }
  }, [successDelete, successEdit]);

  return (
    <>
      <SidebarContainer>
        {!isLoadingMenu && <ApplicationMenuItem showMenu={showMenu} translations={translations} />}
        <SpinnerNew open={isLoadingMenu} />
      </SidebarContainer>
    </>
  );
};
export default withNamespaces()(ApplicationMenu);
