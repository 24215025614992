import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import DepartmentsTable from "../components/Departments/DepartmentsTable";
import DepartmentsFilters from "../components/Departments/DepartmentsFilters";
import {
  getCRUDDepartments,
  deleteCRUDDepartments,
  postADDCRUDDepartments,
  updateCRUDDepartments,
  getCRUDCompanies,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";

const Departments = ({ t, isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const { departments_CRUD, isLoadingCRUDDepartment, companies_CRUD } =
    useSelector((state) => state.budgetManagementCRUDReducer);

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewDepartment, setaddEditNewDepartment] = useState(false);
  const [editDeleteDepartment, seteditDeleteDepartment] = useState(null);
  const [departmentsToShow, setdepartmentsToShow] = useState(departments_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownDepartments, setcleanDropdownDepartments] =
    useState(false);
  const [departmentFilter, setdepartmentFilter] = useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditDepartment, setSelectedEditDepartment] =
    useState(undefined);
  const [SelectedDeleteDepartment, setSelectedDeleteDepartment] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDepartment, setselectedDepartment] = useState({
    empresa: "",
    idDepartamento: 0,
    nome: "",
    ativo: true,
  });

  const resetBackButton = () => {
    setselectedDepartment({
      empresa: "",
      idDepartamento: 0,
      nome: "",
      ativo: true,
    });
  };

  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedDepartment((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
  };

  const handleEmpresaChange = (empresa) => {
    setselectedDepartment((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditDepartment != undefined)
      setSelectedEditDepartment((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };

  useEffect(() => {
    if (SelectedEditDepartment != (null || undefined))
      setselectedDepartment(SelectedEditDepartment);
  }, [SelectedEditDepartment]);

  useEffect(() => {
    dispatch(getCRUDDepartments());
    dispatch(getCRUDCompanies());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDDepartments());
    handleReset();
  };

  const handleReset = () => {
    setdepartmentFilter("");
    setdepartmentsToShow(departments_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditDepartment(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeleteDepartment(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDDepartments(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdateDepartment = (id) => {
    if (id) {
      const payload = {
        idDepartamento: id,
        nome: selectedDepartment?.nome,
        year: new Date().getFullYear(),
        empresa: selectedDepartment?.empresa,
        ativo: selectedDepartment?.ativo,
      };

      dispatch(updateCRUDDepartments(id, payload));
    } else {
      const payload = {
        nome: selectedDepartment?.nome,
        year: new Date().getFullYear(),
        empresa: selectedDepartment?.empresa,
        ativo: true,
      };
      dispatch(postADDCRUDDepartments(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setdepartmentsToShow(departments_CRUD);
  }, [departments_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditDepartment !== undefined &&
        SelectedEditDepartment?.empresa === company?.codigoEmpresa,
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.departments.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownDepartments}
            resetDropdown={cleanDropdownDepartments}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.departments.nome`)}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={selectedDepartment?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setaddEditNewDepartment(false);
    setshowEditModal(false);
    resetBackButton();
    setcleanDropdownDepartments(true);
    setSelectedEditDepartment(undefined);
    setselectedDepartment({
      empresa: "",
      idDepartamento: 0,
      nome: "",
      ativo: true,
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.departments.btnVoltar`)}
            </Button>
            {addEditNewDepartment ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdateDepartment()}
              >
                {t(`budgetManagement.departments.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdateDepartment(
                    selectedDepartment?.idDepartamento
                  )
                }
              >
                {t(`budgetManagement.departments.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.departments.msgDelete.1`)}{" "}
              {SelectedDeleteDepartment?.empresa}
              {t(`budgetManagement.departments.msgDelete.2`)}{" "}
              {SelectedDeleteDepartment?.nome} ?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.departments.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() =>
                handleDelete(SelectedDeleteDepartment?.idDepartamento)
              }
            >
              {t(`budgetManagement.departments.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDDepartment || isLoading} />
          {Object.keys(departments_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.departments.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <DepartmentsFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      departmentsData={departments_CRUD}
                      setdepartmentsToShow={setdepartmentsToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      departmentFilter={departmentFilter}
                      setdepartmentFilter={setdepartmentFilter}
                    />
                    <DepartmentsTable
                      app={"bpm"}
                      departmentsData={departmentsToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setaddEditNewDepartment={setaddEditNewDepartment}
                      seteditDeleteDepartment={seteditDeleteDepartment}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Departments);
