import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_CHECK,
  TOGGLE_CHECK,
  SAVE_DOCUMENTLIST,
  SAVE_DOCUMENTLIST_USERNAME,
  GET_DOCUMENTS,
  GET_DOCUMENTS_USERNAME,
  DELETE_DOCUMENT,
  GET_DOCUMENT,
  GET_OPERATIONS,
  GET_PREVIEW,
  UPDATE_DOCUMENT,
  GET_COLLABORATORS,
  GET_SCHEDULER,
  GET_DOCUMENTS_ADMIN,
  FIND_DOCUMENTS_BY_FILTERS,
  GET_REQUESTS_BY_USERNAME,
  GET_REQUESTS_BY_USERNAME_TYPE,
  CHECK_UPDATE_BY_USERNAME,
  GET_USER_PAYDATA,
  GET_USER_INFORMATIONS,
  GET_USER_SYNCHRONIZATION_LOG,
  GET_OBJECTIVES,
  GET_OBJECTIVE_BY_ID,
  GET_EVALUATION_SYSTEMS,
  GET_EVALUATION_PERIODS,
  UPDATE_OPERATION_STATES,
  GET_DOCS_DISCLAIMERS,
  UPDATE_DOCS_DISCLAIMER,
  UPLOAD_SCHEDULE,
  UPLOAD_ATTENDANCE,
  GET_ENTITIES,
  GET_LOGS,
  GET_ENTITIES_ATTENDANCE,
  GET_ATTENDANCE_LOGS,
  GET_EXPORT_LOGS,
  GET_EXPORT_ABSENCE_LOGS,
  GET_EXPORT_ABSENCE_DATA,
  EXPORT_TXT,
  GET_GROUP_USERS,
  GET_REGISTO_FALTAS,
  GET_TIPO_AUSENCIA,
  GET_TIPO_AUSENCIA_IDONIC,
  GET_TRABALHO_REMOTO,
  POST_TRABALHO_REMOTO,
  POST_TRABALHO_REMOTO_CONFIRM,
  GET_INFORMATION_PROCESS_REMOTE_WORK,
  APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK,
  GET_ALL_TRABALHO_REMOTO,
  GET_GRUPOS_TRABALHO_REMOTO,
  GET_INFO_SALDO_TRABALHO_REMOTO,
  GET_EXPORTVALS,
  GET_EXPORTED_TABLE,
  GET_MARCACAO_FERIAS,
  GET_ALL_MARCACAO_FERIAS,
  POST_MARCACAO_FERIAS,
  POST_MARCACAO_FERIAS_CONFIRM,
  GET_INFORMATION_PROCESS_MARCACAO_FERIAS,
  APPROVE_DELETE_REMOVE_MARCACAO_FERIAS,
  POST_REMOVE_DAYS_TRABALHO_REMOTO,
  GET_REQUESTS_MY_TASKS_WIDGET,
  GET_REQUESTS_MY_TASKS_WIDGET_PIDs,
  CANCEL_DAY_OFF_REQUEST,
  GET_CONSUMO_SALDO,
  GET_INFORMATION_PROCESS_AUTHORIZATION,
  GET_HORARIO_OPTIONS,
  POST_HORARIO_OPTIONS,
  GET_TOTAL_SALDOS_TABELA,
  POST_EXPORT_VACATIONS,
  GET_USER_PLAFOND_AND_EXPENSES,
  POST_USER_PLAFOND_AND_EXPENSES,
  DELETE_USER_PLAFOND
} from "../ducks/portalrh/actionTypes";

import {
  getCheck,
  toggleCheck,
  saveDocumentList,
  saveDocumentListWithUsername,
  getDocuments,
  getDocumentsbyUsername,
  deleteDocument,
  getDocumentBinary,
  getOperations,
  getPreviewBinary,
  updateDocument,
  getCollaborators,
  getScheduler,
  getTotalSaldosTabela,
  getDocumentsAdmin,
  findDocumentsByFilters,
  getRequestsByUsername,
  getRequestsByUsernameWithType,
  checkUpdateByUsername,
  getUserPayData,
  getByUsername,
  getUserSynchronizationLog,
  getObjectives,
  getObjectiveByID,
  getEvaluationSystems,
  getEvaluationPeriods,
  updateOperationStates,
  getDocsDisclaimers,
  updateDocsDisclaimer,
  uploadSchedule,
  getEntities,
  getLogs,
  uploadAttendance,
  getEntitiesAttendance,
  getAttendanceLogs,
  getExportLogs,
  getExportAbsenceLogs,
  getExportAbsenceData,
  exportTxt,
  getGroupUsers,
  getRegistoFaltas,
  getTipoAusencias,
  getTipoAusenciasIDONIC,
  getTrabalhoRemoto,
  getAllTrabalhoRemoto,
  postTrabalhoRemoto,
  postTrabalhoRemotoConfirm,
  getInformationProcessRemoteWork,
  approveDeleteRemoveDayRemoteWork,
  getGruposTrabalhoRemoto,
  getInfoSaldoTrabalhoRemoto,
  getExportVals,
  getExportedTable,
  getMarcacaoFerias,
  getAllMarcacaoFerias,
  postMarcacaoFerias,
  postMarcacaoFeriasConfirm,
  getInformationProcessMarcacaoFerias,
  approveDeleteRemoveMarcacaoFerias,
  postRemoveDaysTrabalhoRemoto,
  getRequestsMyTasksWidget,
  getRequestsMyTasksWidgetPIDs,
  cancelDayOffRequest,
  getConsumoSaldo,
  getInformationProcessAuthorization,
  getHorarioOptions,
  postHorarioOptions,
  postExportVacations,
  getUserPlafondAndExpenses,
  postUserPlafondAndExpenses,
  deleteUserPlafond
} from "../ducks/portalrh/sagas";

export const portalrhSagas = [
  takeEvery(GET_CHECK, getCheck),
  takeEvery(TOGGLE_CHECK, toggleCheck),
  takeLatest(SAVE_DOCUMENTLIST, saveDocumentList),
  takeLatest(SAVE_DOCUMENTLIST_USERNAME, saveDocumentListWithUsername),
  takeLatest(GET_DOCUMENTS, getDocuments),
  takeLatest(GET_DOCUMENTS_USERNAME, getDocumentsbyUsername),
  takeLatest(DELETE_DOCUMENT, deleteDocument),
  takeLatest(GET_DOCUMENT, getDocumentBinary),
  takeLatest(GET_OPERATIONS, getOperations),
  takeLatest(GET_PREVIEW, getPreviewBinary),
  takeLatest(UPDATE_DOCUMENT, updateDocument),
  takeLatest(GET_COLLABORATORS, getCollaborators),
  takeLatest(GET_EXPORTVALS, getExportVals),
  takeLatest(GET_SCHEDULER, getScheduler),
  takeLatest(GET_TOTAL_SALDOS_TABELA, getTotalSaldosTabela),
  takeLatest(GET_DOCUMENTS_ADMIN, getDocumentsAdmin),
  takeLatest(FIND_DOCUMENTS_BY_FILTERS, findDocumentsByFilters),
  takeLatest(GET_REQUESTS_BY_USERNAME, getRequestsByUsername),
  takeLatest(GET_REQUESTS_BY_USERNAME_TYPE, getRequestsByUsernameWithType),
  takeLatest(CHECK_UPDATE_BY_USERNAME, checkUpdateByUsername),
  takeLatest(GET_USER_PAYDATA, getUserPayData),
  takeLatest(GET_USER_INFORMATIONS, getByUsername),
  takeLatest(GET_USER_SYNCHRONIZATION_LOG, getUserSynchronizationLog),
  takeLatest(GET_OBJECTIVES, getObjectives),
  takeLatest(GET_OBJECTIVE_BY_ID, getObjectiveByID),
  takeLatest(GET_EVALUATION_SYSTEMS, getEvaluationSystems),
  takeLatest(GET_EVALUATION_PERIODS, getEvaluationPeriods),
  takeLatest(UPDATE_OPERATION_STATES, updateOperationStates),
  takeLatest(GET_DOCS_DISCLAIMERS, getDocsDisclaimers),
  takeLatest(UPDATE_DOCS_DISCLAIMER, updateDocsDisclaimer),
  takeLatest(UPLOAD_SCHEDULE, uploadSchedule),
  takeLatest(GET_ENTITIES, getEntities),
  takeLatest(GET_LOGS, getLogs),
  takeLatest(UPLOAD_ATTENDANCE, uploadAttendance),
  takeLatest(GET_ENTITIES_ATTENDANCE, getEntitiesAttendance),
  takeLatest(GET_ATTENDANCE_LOGS, getAttendanceLogs),
  takeLatest(GET_EXPORT_LOGS, getExportLogs),
  takeLatest(GET_EXPORT_ABSENCE_LOGS, getExportAbsenceLogs),
  takeLatest(GET_EXPORT_ABSENCE_DATA, getExportAbsenceData),
  takeLatest(EXPORT_TXT, exportTxt),
  takeLatest(GET_GROUP_USERS, getGroupUsers),
  takeLatest(GET_REGISTO_FALTAS, getRegistoFaltas),
  takeLatest(GET_TIPO_AUSENCIA, getTipoAusencias),
  takeLatest(GET_TIPO_AUSENCIA_IDONIC, getTipoAusenciasIDONIC),
  takeLatest(GET_TRABALHO_REMOTO, getTrabalhoRemoto),
  takeLatest(GET_ALL_TRABALHO_REMOTO, getAllTrabalhoRemoto),
  takeLatest(POST_TRABALHO_REMOTO, postTrabalhoRemoto),
  takeLatest(POST_TRABALHO_REMOTO_CONFIRM, postTrabalhoRemotoConfirm),
  takeLatest(
    GET_INFORMATION_PROCESS_REMOTE_WORK,
    getInformationProcessRemoteWork
  ),
  takeLatest(
    APPROVE_DELETE_REMOVE_DAY_REMOTE_WORK,
    approveDeleteRemoveDayRemoteWork
  ),
  takeLatest(GET_GRUPOS_TRABALHO_REMOTO, getGruposTrabalhoRemoto),
  takeLatest(GET_INFO_SALDO_TRABALHO_REMOTO, getInfoSaldoTrabalhoRemoto),
  takeLatest(GET_EXPORTED_TABLE, getExportedTable),

  takeLatest(GET_MARCACAO_FERIAS, getMarcacaoFerias),
  takeLatest(GET_ALL_MARCACAO_FERIAS, getAllMarcacaoFerias),
  takeLatest(POST_EXPORT_VACATIONS,postExportVacations),
  takeLatest(POST_MARCACAO_FERIAS, postMarcacaoFerias),
  takeLatest(POST_MARCACAO_FERIAS_CONFIRM, postMarcacaoFeriasConfirm),
  takeLatest(
    GET_INFORMATION_PROCESS_MARCACAO_FERIAS,
    getInformationProcessMarcacaoFerias
  ),
  takeLatest(
    APPROVE_DELETE_REMOVE_MARCACAO_FERIAS,
    approveDeleteRemoveMarcacaoFerias
  ),

  takeLatest(POST_REMOVE_DAYS_TRABALHO_REMOTO, postRemoveDaysTrabalhoRemoto),
  takeLatest(GET_REQUESTS_MY_TASKS_WIDGET, getRequestsMyTasksWidget),
  takeLatest(GET_REQUESTS_MY_TASKS_WIDGET_PIDs, getRequestsMyTasksWidgetPIDs),
  takeLatest(CANCEL_DAY_OFF_REQUEST, cancelDayOffRequest),
  takeLatest(GET_CONSUMO_SALDO, getConsumoSaldo),
  takeLatest(
    GET_INFORMATION_PROCESS_AUTHORIZATION,
    getInformationProcessAuthorization
  ),
  takeLatest(GET_HORARIO_OPTIONS, getHorarioOptions),
  takeLatest(POST_HORARIO_OPTIONS, postHorarioOptions),
  takeLatest(GET_USER_PLAFOND_AND_EXPENSES, getUserPlafondAndExpenses),
  takeLatest(POST_USER_PLAFOND_AND_EXPENSES, postUserPlafondAndExpenses),
  takeLatest(DELETE_USER_PLAFOND, deleteUserPlafond)
];
