import { differenceWith, isEqual } from "lodash";
import React, { useEffect } from "react";
import update from "react-addons-update";
import { Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "~/components/Dropdown";
import DisplayPermissionTable from "~/components/permission/DisplayPermissionTable";
import { getPermissions } from "~/pages/Admin/Permission/actions";
import { routesMenuForm } from "~/utils/menuFormRoutes";

const MenuForm = (props) => {
  const {
    availablePerms,
    setAvailablePerms,
    selectedPerms,
    setSelectedPerms,
    t,
    application,
    applicationMenu,
    setApplicationMenu,
    isEditing = false,
  } = props;

  const dispatch = useDispatch();

  const { settingsMenu: menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  const { permissions } = useSelector((state) => state.permissionReducer);
  const { menuOnlyApps } = useSelector((state) => state.applicationReducer);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  useEffect(() => {
    if (isEditing) {
      setSelectedPerms(applicationMenu.permissions);
      setAvailablePerms(
        differenceWith(permissions, applicationMenu.permissions, isEqual)
      );
    } else {
      setAvailablePerms(permissions);
    }
  }, [permissions]);

  const factoryInputText = (label, value, required, errorMsg, type) => (
    <Form.Group>
      <Form.Label>{t("admin.applicationMenu." + label)}</Form.Label>
      <Form.Control
        type={type ? type : "text"}
        value={value}
        onChange={(e) =>
          changeApplicationMenu(label?.toLowerCase(), e.target.value)
        }
        required={required}
      />
      <Form.Control.Feedback type="invalid">
        {t("admin.applicationMenu." + errorMsg)}
      </Form.Control.Feedback>
    </Form.Group>
  );

  const changeApplicationMenu = (label, value) => {
    const changedApplicationMenu = update(applicationMenu, {
      [label]: { $set: value },
    });
    setApplicationMenu(changedApplicationMenu);
  };

  const getFormattedNameMenu = (item) =>
    item.mainMenu ? t(`menu.mainItems.${item.name}`) : t(item.name);

  const addPermission = (row) => {
    //Add
    const updatedSelectedPerms = update(selectedPerms, { $push: [row] });
    setSelectedPerms(updatedSelectedPerms);

    //Remove
    setAvailablePerms(
      update(availablePerms, { $splice: [[availablePerms.indexOf(row), 1]] })
    );

    changeApplicationMenu("permissions", updatedSelectedPerms);
  };

  const removePermission = (row) => {
    //Remove
    const updatedSelectedPerms = update(selectedPerms, {
      $splice: [[selectedPerms.indexOf(row), 1]],
    });
    setSelectedPerms(updatedSelectedPerms);

    //Add
    setAvailablePerms(update(availablePerms, { $push: [row] }));

    changeApplicationMenu("permissions", updatedSelectedPerms);
  };

  const adaptListForDropdown = () =>
    menu.map((item) => {
      return {
        label: getFormattedNameMenu(item),
        value: item.id.toString(),
        selected: false,
      };
    });

  const changeApplicationMenuParent = (menu) => {
    changeApplicationMenu("menuParent", menu);
  };
  const changeApplicationRoute = (route) => {
    changeApplicationMenu("route", route);
  };

  const getMenuLabelById = () => {
    const menu = menuOnlyApps.find((menu) => menu.id === Number(application));
    return menu ? menu.label : "";
  };

  const filterRoutesByLabel = (label, routes) => {
    const modifiedLabel = label === "system_administrator" ? "admin" : label;
    const regex = new RegExp(`^${modifiedLabel}/`);
    return routes.filter((route) => regex.test(route.value));
  };

  return (
    <>
      <Form.Row>
        <Col lg="7">
          {!!application &&
            factoryInputText(
              "name",
              applicationMenu.name,
              true,
              "requiredName"
            )}
        </Col>
        <Col lg="5">
          {!!application && (
            <Form.Group>
              <Form.Label>{t("admin.applicationMenu.route")}</Form.Label>
              <Dropdown
                list={filterRoutesByLabel(getMenuLabelById(), routesMenuForm())}
                handleSubmit={changeApplicationRoute}
                emptyDefault={true}
                flowId={applicationMenu.route}
              />
            </Form.Group>
          )}
        </Col>
      </Form.Row>

      <Form.Row>
        <Col lg="7">
          {!!application &&
            factoryInputText("icon", applicationMenu.icon, false)}
        </Col>
        <Col lg="5">
          {!!application &&
            factoryInputText(
              "order",
              applicationMenu.order,
              true,
              "requiredOrder",
              "number"
            )}
        </Col>
      </Form.Row>

      {!!application && (
        <>
          <Form.Row>
            <Col lg="12">
              <Form.Group style={{ marginBottom: 0 }}>
                <Form.Label>
                  {t("admin.applicationMenu.extraConfiguration")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ overflow: "hidden" }}
                  rows="3"
                  value={applicationMenu.extraConfiguration}
                  onChange={(e) =>
                    changeApplicationMenu("extraConfiguration", e.target.value)
                  }
                />
              </Form.Group>
              <small>
                <i>{t("admin.applicationMenu.detailExtraConfig")}</i>
              </small>
            </Col>
          </Form.Row>

          <Form.Row style={{ marginTop: "1rem" }}>
            <Col lg="6">
              <Form.Group>
                <Form.Label>{t("admin.applicationMenu.menuParent")}</Form.Label>
                <Dropdown
                  list={adaptListForDropdown()}
                  handleSubmit={changeApplicationMenuParent}
                  emptyDefault={true}
                  flowId={applicationMenu.menuParent}
                />
              </Form.Group>
            </Col>
            <Col lg="6" style={{ marginTop: "1.7rem" }}>
              <Form.Check
                inline
                type="checkbox"
                label={t("admin.applicationMenu.mainMenu")}
                checked={applicationMenu.mainMenu}
                style={{ paddingBottom: 10 }}
                onChange={(e) =>
                  changeApplicationMenu("mainMenu", e.target.checked)
                }
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col lg="6">
              <Form.Label>{t("admin.applicationMenu.permissions")}</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg="6">
              <Form.Label>{t("admin.applicationMenu.available")}</Form.Label>
              <DisplayPermissionTable
                permissions={availablePerms}
                action={null}
                showSearch={true}
                addAction={addPermission}
              />
            </Col>
            <Col lg="6">
              <Form.Label>{t("admin.applicationMenu.selected")}</Form.Label>
              <DisplayPermissionTable
                permissions={selectedPerms}
                action={null}
                showSearch={true}
                removeAction={removePermission}
              />
            </Col>
          </Form.Row>
        </>
      )}
    </>
  );
};

export default withNamespaces()(MenuForm);
