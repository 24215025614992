import React from "react";
import { withNamespaces } from "react-i18next";
import {
  toLocaleDateString,
  buildDocNumberColumn,
  buildVendorNameColum,
  buildTotalAmoutColumn,
} from "~/utils";

const MobileCardDataCapture = ({
  row,
  cell,
  rowIndex,
  formatExtraData,
  t,
  buildStatusColumn,
  buildApprovedColumn,
  buildActionsColumn,
  buildDocTypeColumn,
  handleOpenMetadataModal,
}) => {
  const getValue = (key) => {
    if (key.startsWith("metadata.")) {
      const metadataKey = key.split(".")[1];
      return row.metadata ? row.metadata[metadataKey] : undefined;
    }
    return row[key];
  };

  const truncateText = (text, maxLength) => {
    return text && text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return "-";
    const currencySymbol =
      currency === "EUR" ? "€" : currency === "USD" ? "$" : currency;
    return `${amount} ${currencySymbol}`; // Append the currency symbol
  };

  const formatValue = (key, value) => {
    switch (key) {
      case "statusV1":
        return truncateText(buildStatusColumn(cell, row), 15); // Truncate statusV1 to 15 characters
      case "metadata.issue_date":
        return toLocaleDateString(value);
      case "metadata.document_identifier":
        return buildDocNumberColumn(cell, row);
      case "metadata.supplier_name":
        return buildVendorNameColum(cell, row);
      case "metadata.amount_total":
        return formatCurrency(value, getValue("metadata.currency")); // Add currency suffix
      case "approved":
        return buildApprovedColumn(cell, row);
      case "paymentDate":
        return buildDocTypeColumn(cell, row);
      default:
        return value;
    }
  };

  return (
    <div
      className="card_container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        marginBottom: "1rem", // Added spacing between cards
        backgroundColor: "whitesmoke", // Updated background color
        borderRadius: "12px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Column - Actions */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "1rem", // Spacing between actions and the rest
        }}
      >
        {buildActionsColumn(cell, row, {})} {/* Render Actions */}
      </div>

      {/* Middle Column - Document Information */}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
            marginBottom: "0.5rem",
          }}
        >
          {t("datacapture.filter." + getValue("batchFlow"))}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#555",
            marginBottom: "0.5rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={getValue("metadata.supplier_name")}
        >
          {truncateText(getValue("metadata.supplier_name"), 20)}{" "}
          {/* Truncate supplier name */}
        </div>
        <div
          style={{
            fontSize: "12px",
            color: "#888",
          }}
        >
          <span>
            {formatValue(
              "metadata.issue_date",
              getValue("metadata.issue_date")
            )}
          </span>
          <span style={{ margin: "0 0.25rem" }}>|</span>
          <span>
            <span style={{ fontSize: "smaller", marginRight: "5px" }}>
              {t("datacapture.filter.aproved")}:
            </span>
            {formatValue("approved", getValue("approved"))}
          </span>
        </div>
      </div>

      {/* Right Column - Status and Amount */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          flexShrink: 0,
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#f5a623",
            marginBottom: "0.5rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={formatValue("statusV1", getValue("statusV1"))}
        >
          {truncateText(formatValue("statusV1", getValue("statusV1")), 15)}{" "}
          {/* Truncate statusV1 */}
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          {formatValue(
            "metadata.amount_total",
            getValue("metadata.amount_total")
          )}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(MobileCardDataCapture);
