import { find, isEmpty } from "lodash";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Dropdown,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import "~/assets/css/styles.css";
import avatarDefault from "~/assets/img/avatar/avatar-default.png";
import english from "~/assets/img/icons/translate_english.svg";
import portuguese from "~/assets/img/icons/translate_portuguese.svg";
import spanish from "~/assets/img/icons/translate_spanish.svg";
import logo from "~/assets/img/logos/unik-logo.png";
import CustomToggle from "~/components/CustomToggle";
import NotificationDropdown from "~/components/Notifications/NotificationDropdown";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import i18n from "~/i18n";
import { clearPuzzleItems } from "~/pages/Admin/SystemSettings/actions";
import { logout } from "~/pages/Login/actions";
import { clearUserSettings } from "~/pages/User/actions";
import { setSelectedApplication } from "~/store/ducks/application/actionTypes";
import {
  setMenuApplication,
  toggleSideBar,
} from "~/store/ducks/applicationMenu/actionTypes";
import { clearCheckToken } from "~/store/ducks/oauth/actionTypes";
import { createBase64StringFromBinary } from "~/utils";
import { GLOBAL_SOLVER_MIDDLEWARE } from "~/utils/constants";
import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";
import {
  PERMISSION_TOOLS,
  PERMISSION_ABOUT,
} from "~/utils/constants/permissions";
import { saveChangesWorkplan } from "~/store/ducks/budgetManagement/actionTypes";
import { clearUser } from "../../actions";
import oauth from "~/utils/oauth";

const Header = (props) => {
  const {
    theme,
    handleChangeTheme,
    handleChangeLanguage,
    toggleChat,
    keycloak,
    styleSheet,
    t,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  //const [showNavBarContainer, setShowNavBarContainer] = useState(false);
  const [homeLocation, setHomeLocation] = useState("/");

  const { configuration, puzzleItems } = useSelector(
    (state) => state.adminConfigReducer
  );
  const { selectedApplication } = useSelector(
    (state) => state.applicationReducer
  );
  const { user, isLoadingUser } = useSelector((state) => state.globalReducer);
  const { authKeycloakConfig } = useSelector((state) => state.loginReducer);
  const { workplans } = useSelector((state) => state.budgetManagementReducer);

  const showBadge = true;
  const isProductionMode = configuration ? configuration.productionMode : true;
  const { url } = useRouteMatch();
  const firstName = user.detail
    ? user.detail.firstName
      ? user.detail.firstName
      : user.username
    : user.username;
  const lastName = user.detail
    ? user.detail.lastName
      ? user.detail.lastName
      : ""
    : "";
  const fullName = firstName + " " + lastName;
  const picture = user.detail
    ? user.detail.picture
      ? "data:image/png;base64, " + user.detail.picture
      : avatarDefault
    : avatarDefault;

  const [orgPicture, setOrgPicture] = useState(null);
  const [keyclocksettings, setkeyclocksettings] = useState(undefined);
  useEffect(() => {
    setkeyclocksettings(fetchData());
  }, []);

  useEffect(() => {
    if (user?.organization?.picture) {
      const createdPicture = createBase64StringFromBinary(
        user.organization.picture
      );
      if (createdPicture) {
        setOrgPicture(createdPicture);
      }
    } else {
      setOrgPicture(null);
    }
  }, [user.organization]);

  //const isAdmin = isEmpty(user.roles) ? false : user.roles[0].id === 1 ? true : false

  useEffect(() => {
    if (configuration && puzzleItems.length !== 0) {
      const { authDomains } = configuration;
      let homeLocation_ = "/";
      if (authDomains.some((i) => i.id === 2)) {
        homeLocation_ = {
          pathname: `/uniksystem/globalsolver`,
          state: { name: "Global Solver" },
        };
      }
      setHomeLocation(homeLocation_);
    }
  }, [configuration, puzzleItems]);

  const handleLogout = async () => {
    const workplanPromises = (workplans || [])
      .filter((workplan) => workplan.lock === user.username)
      .map((workplan) => {
        workplan.lock = "";
        return dispatch(
          saveChangesWorkplan({ content: workplan, show: false })
        );
      });

    await Promise.all(workplanPromises);

    [
      logout(user.username),
      clearCheckToken(),
      clearUserSettings(),
      clearUser(),
      setMenuApplication([]),
      clearPuzzleItems(),
    ].forEach((action) => dispatch(action));

    oauth.clearAppStorage();

    setTimeout(() => {
      if (keycloak.authenticated) {
        keycloak.logout();
      }
      if (document.getElementById("globalsolver")) {
        document.getElementById("globalsolver").src =
          GLOBAL_SOLVER_MIDDLEWARE.iframe;
      }
      if (
        keyclocksettings != undefined &&
        Object.keys(authKeycloakConfig).length > 0
      ) {
        const currentUrl = window.location.href;
        const match = currentUrl.match(/^(https?:\/\/[^/]+)/);
        if (
          match &&
          keyclocksettings != undefined &&
          authKeycloakConfig.platform != undefined
        ) {
          const redirectUrl = match[1];
          const redirect_uri = encodeURIComponent(redirectUrl);
          const logoutUrl =
            keyclocksettings.keycloakData.url +
            "/realms/" +
            keyclocksettings.keycloakData.realm +
            `/protocol/openid-connect/logout?redirect_uri=${redirect_uri}`;
          oauth.logout();
          window.location.href = logoutUrl;
        }
      } else {
        history.push("/");
      }
      window.location.reload();
    }, 1000);
  };

  const fetchData = async () => {
    const SERVER_URL = window.location.origin;
    try {
      const response = await fetch(
        SERVER_URL + "/uniksystem/api/open/keycloak-settings"
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching keycloak data:", error);
    }
  };
  /* const changeTheme = () => {
    handleChangeTheme(theme === "default" ? "dark" : "default");
  }; */

  const changeTheme = (selected) => {
    handleChangeTheme(selected);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleChangeLanguage(lng);
  };

  const backToHomepage = () => {
    setSelectedApplicationByLocation();
    if (window.location.hash === "#/uniksystem/globalsolver")
      window.location.reload();
    else history.push(homeLocation);
  };

  function setSelectedApplicationByLocation() {
    if (!isEmpty(puzzleItems)) {
      const firstPuzzleApp = find(puzzleItems, { nrOrder: 0 });
      dispatch(setSelectedApplication(firstPuzzleApp.application));
    }
  }

  const getApplicationLogo = () => {
    if (!isEmpty(puzzleItems)) {
      if (selectedApplication === undefined) {
        if (location.pathname.includes("uniksystem/")) {
          const arr1 = location.pathname.split("uniksystem/");
          const arr2 = arr1[1].split("/");
          const appName = arr2[0];
          const selectedPuzzle = puzzleItems.find((item) => {
            if (item.application.label !== "uniksystem") {
              if (appName === item.application.label) return item;
            } else {
              if (
                location.pathname.endsWith(item.application.label) ||
                location.pathname.includes("uniksystem/admin")
              )
                return item;
            }
          });
          if (selectedPuzzle) {
            const { application } = selectedPuzzle;
            dispatch(setSelectedApplication(application));
            return application?.logo
              ? `data:image/png;base64,${application.logo}`
              : logo;
          }
          return logo;
        }
        return logo;
      }
      return selectedApplication?.logo
        ? `data:image/png;base64,${selectedApplication.logo}`
        : logo;
    }
    return undefined;
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size[0];
  }

  const width = useWindowSize();
  const currentLogo = getApplicationLogo();
  return (
    <Navbar
      style={{
        justifyContent: "space-between",
        flex: "0 0 auto",
        flexWrap: "nowrap",
        padding: "0px auto 0px auto",
        margin: "0px",
      }}
      expand="lg"
    >
      <SpinnerNew open={!currentLogo || isLoadingUser} />
      <div
        className="navbar-header"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {!window.location.href.includes("/uniksystem/profile") &&
          !window.location.href.includes("/uniksystem/account") &&
          !window.location.href.includes("/uniksystem/about") && (
            <svg
              onClick={() => dispatch(toggleSideBar())}
              xmlns="http://www.w3.org/2000/svg"
              className="navbar-toggle d-lg-none float-left"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              data-toggle="collapse"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          )}

        <div className="stacked-logos" style={{ cursor: "pointer" }}>
          <Navbar.Brand onClick={() => backToHomepage()} className="float-left">
            {currentLogo && (
              <img
                className="logo"
                src={currentLogo}
                height={
                  styleSheet?.orgLogoSize
                    ? `${styleSheet?.orgLogoSize}px`
                    : "40"
                }
                alt=""
              />
            )}
          </Navbar.Brand>

          {width <= 650 ? (
            <Navbar.Brand className="float-left ">
              {orgPicture && (
                <img className="logo" src={orgPicture} height="40" alt="" />
              )}
            </Navbar.Brand>
          ) : null}
        </div>

        {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowNavBarContainer(!showNavBarContainer)}
            className="navbar-toggler black no-border float-right"
            width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="12" cy="5" r="1"></circle>
            <circle cx="12" cy="19" r="1"></circle>
          </svg>*/}
      </div>

      {width > 650 ? (
        <Navbar.Brand className="float-left ">
          {!isLoadingUser && (
            <img
              className="logo"
              src={orgPicture}
              height={styleSheet?.logonewSize ? styleSheet.logonewSize : "40"}
              alt=""
            />
          )}
        </Navbar.Brand>
      ) : null}

      {/*<div className={`colapse ${showNavBarContainer ? 'show' : ''}`}>*/}
      <Nav
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-end",
        }}
      >
        {!isProductionMode && (
          <Nav.Item className="header-nav-item">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} style={{ padding: "0px" }}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 0 }}
                  overlay={
                    <Tooltip id="favoritos_tooltip">
                      {t("header.changeTheme")}
                    </Tooltip>
                  }
                >
                  <div className="nav-item-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 128"
                      height="30"
                    >
                      <a className="topbar-btn">
                        {theme === "default" ? (
                          <path
                            d="M79.5,30.6c2.3,4.6,4,9.7,4,15.4c0,18.8-15.4,34.2-34.2,34.2c-5.7,0-10.8-1.1-15.4-4C39.6,87.1,51,95,64.1,95
                c18.8,0,34.2-15.4,34.2-34.2C98.3,47.7,90.9,36.3,79.5,30.6z"
                            fill="#FFFFFF"
                          />
                        ) : (
                          <>
                            <circle cx="64" cy="64" r="23.3" fill="#FFFFFF" />
                            <path
                              d="M64,34.5c-1.4,0-2.5-1.1-2.5-2.5v-5.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5V32
                  C66.5,33.4,65.4,34.5,64,34.5z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M64,104c-1.4,0-2.5-1.1-2.5-2.5V96c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v5.5C66.5,102.9,65.4,104,64,104z"
                              fill="#FFFFFF"
                            />
                            <path
                              className="st1"
                              d="M101.5,66.5H96c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h5.5c1.4,0,2.5,1.1,2.5,2.5S102.9,66.5,101.5,66.5z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M32,66.5h-5.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5H32c1.4,0,2.5,1.1,2.5,2.5S33.4,66.5,32,66.5z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M86.6,43.9c-0.6,0-1.3-0.2-1.8-0.7c-1-1-1-2.6,0-3.5l3.9-3.9c1-1,2.6-1,3.5,0c1,1,1,2.6,0,3.5l-3.9,3.9
                  C87.9,43.7,87.2,43.9,86.6,43.9z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M37.5,93c-0.6,0-1.3-0.2-1.8-0.7c-1-1-1-2.6,0-3.5l3.9-3.9c1-1,2.6-1,3.5,0c1,1,1,2.6,0,3.5l-3.9,3.9
                  C38.8,92.8,38.1,93,37.5,93z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M90.5,93c-0.6,0-1.3-0.2-1.8-0.7l-3.9-3.9c-1-1-1-2.6,0-3.5s2.6-1,3.5,0l3.9,3.9c1,1,1,2.6,0,3.5
                  C91.8,92.8,91.2,93,90.5,93z"
                              fill="#FFFFFF"
                            />
                            <path
                              d="M41.4,43.9c-0.6,0-1.3-0.2-1.8-0.7l-3.9-3.9c-1-1-1-2.6,0-3.5c1-1,2.6-1,3.5,0l3.9,3.9c1,1,1,2.6,0,3.5
                  C42.7,43.7,42,43.9,41.4,43.9z"
                              fill="#FFFFFF"
                            />
                          </>
                        )}
                      </a>
                    </svg>
                  </div>
                </OverlayTrigger>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item onClick={() => changeTheme("default")}>
                  <small>Default</small>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeTheme("dark")}>
                  <small>Dark</small>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeTheme("cma")}>
                  <small>Cma</small>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        )}

        {!styleSheet?.hideTranslations && (
          <Nav.Item className="header-nav-item">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} style={{ padding: "0px" }}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 0 }}
                  overlay={
                    <Tooltip id="favoritos_tooltip">
                      {t("header.changeLanguage")}
                    </Tooltip>
                  }
                >
                  <div className="nav-item-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 128"
                      height="30"
                    >
                      <a className="topbar-btn">
                        <path
                          d="M64,26.4c-20.7,0-37.6,16.9-37.6,37.6s16.9,37.6,37.6,37.6s37.6-16.9,37.6-37.6S84.7,26.4,64,26.4z M91.7,48.5
                    H78.5c-0.8-5.3-1.9-10.2-3.6-14.3C82,36.8,88,41.9,91.7,48.5z M60.5,32.5c1.2-0.1,2.3-0.2,3.5-0.2c1.2,0,2.3,0.1,3.5,0.2
                    c1.9,3.1,3.8,8.5,5,16H55.5C56.7,41,58.7,35.6,60.5,32.5z M32.3,64c0-3.3,0.5-6.6,1.5-9.6h15.1c-0.2,3.1-0.4,6.3-0.4,9.6
                    c0,3.2,0.1,6.5,0.4,9.6H33.7C32.8,70.6,32.3,67.3,32.3,64z M36.3,79.5h13.2c0.8,5.3,1.9,10.2,3.6,14.3C46,91.2,40,86.1,36.3,79.5z
                    M49.5,48.5H36.3C40,41.9,46,36.8,53.1,34.2C51.5,38.3,50.4,43.1,49.5,48.5z M67.5,95.5c-1.1,0.1-2.3,0.2-3.5,0.2
                    c-1.2,0-2.3-0.1-3.5-0.2c-1.9-3.1-3.8-8.5-5-16h16.9C71.3,87,69.3,92.4,67.5,95.5z M73.2,73.6H54.8c-0.2-2.9-0.4-6.2-0.4-9.6
                    c0-3.5,0.2-6.6,0.4-9.6h18.3c0.2,2.9,0.4,6.2,0.4,9.6C73.6,67.5,73.5,70.6,73.2,73.6z M74.9,93.8c1.6-4.1,2.8-8.9,3.6-14.3h13.2
                    C88,86.1,82,91.2,74.9,93.8z M79.1,73.6c0.3-3.2,0.4-6.3,0.4-9.6c0-3.2-0.1-6.5-0.4-9.6h15.1c0.9,3,1.5,6.2,1.5,9.6
                    s-0.5,6.6-1.5,9.6H79.1z"
                          fill="#FFFFFF"
                        />
                      </a>
                    </svg>
                  </div>
                </OverlayTrigger>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item onClick={() => changeLanguage("pt")}>
                  <img src={portuguese} height="20" alt="" />
                  <small> Português</small>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("en")}>
                  <img src={english} height="20" alt="" />
                  <small> English</small>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("es")}>
                  <img src={spanish} height="20" alt="" />
                  <small> Espanhol</small>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        )}

        {!isProductionMode && (
          <Nav.Item
            className="header-nav-item chat-badger"
            onClick={() => toggleChat()}
          >
            <div className="nav-item-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
                height="30"
              >
                <a className="topbar-btn">
                  <path
                    d="M50.1,74.4c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.4,0.9-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.9-0.7,1.4-1.1
                c0,0,0.5,0,0.7,0c17.1,0,31-11.6,31-26c0-2-0.3-3.9-0.8-5.8c0-0.1,0-0.2-0.1-0.3c-0.3-1.1-0.7-2.2-1.2-3.3c0-0.1-0.1-0.1-0.1-0.2
                C78.3,26.8,67.1,20,54,20c-17.1,0-31,11.6-31,26c0,12,9.8,19,9.8,19S34,72,26,79c0,0,2.7,1.4,7.2,1.4c4,0,9.4-1.1,15.7-5.2
                C49.3,75,49.7,74.7,50.1,74.4z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M95.2,79c0,0,9.8-7,9.8-19c0-10-6.7-18.6-16.6-23c0.9,2.4,1.4,5,1.5,7.6c5.5,3.7,9,9.2,9,15.4
                c0,8.6-7.3,14.1-7.3,14.1l-2,1.4L89.2,78c-0.1,0.7-0.6,4.7,1.6,9.9c-3.3-0.8-7.5-2.6-12.3-6.5l-1.7-1.4l-2.2,0c0,0-0.4,0-0.6,0
                c-5.2,0-10-1.3-14-3.4c-1.6,0.2-3.3,0.4-5,0.4c-0.8,0.6-1.7,1.2-2.5,1.8C58.1,83.2,65.7,86,74,86c0.2,0,0.7,0,0.7,0
                c8.2,6.8,15.2,8.4,20.1,8.4c4.5,0,7.2-1.4,7.2-1.4C94,86,95.2,79,95.2,79z"
                    fill="#FFFFFF"
                  />
                </a>
              </svg>
            </div>
            {showBadge && (
              <span className="chat-badge-unread btn-danger">
                <div className="chat-badger-count"></div>
              </span>
            )}
          </Nav.Item>
        )}

        <Nav.Item className="header-nav-item">
          <NotificationDropdown styleSheet={styleSheet} />
        </Nav.Item>

        <Nav.Item className="header-nav-item">
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              style={{ padding: "0px" }}
              className="dropdown-text"
            >
              <div style={{ cursor: "pointer", display: "flex" }}>
                <img
                  className="img-profile-header"
                  src={picture}
                  alt=""
                  width="30px"
                  height="30px"
                />
                {!isLoadingUser && (
                  <span className="dropdown-text dropdown-username">
                    {fullName}
                  </span>
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {!isProductionMode && (
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: `${url}/profile`,
                      state: {
                        name: "menu.uniksystemProfile",
                        icon: "icon-unik3 mr-2",
                      },
                    })
                  }
                >
                  {t("header.profile")}
                </Dropdown.Item>
              )}
              {!isProductionMode && (
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: `${url}/account`,
                      state: {
                        name: "menu.uniksystemAccount",
                        icon: "icon-unik3 mr-2",
                      },
                    })
                  }
                >
                  {t("header.account")}
                </Dropdown.Item>
              )}
              <ProtectedComponent permissions={[{ label: PERMISSION_ABOUT }]}>
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: `${url}/about`,
                      state: {
                        name: "menu.uniksystemAbout",
                        icon: "icon-unik3 mr-2",
                      },
                    })
                  }
                >
                  {t("header.about")}
                </Dropdown.Item>
              </ProtectedComponent>
              <ProtectedComponent permissions={[{ label: PERMISSION_TOOLS }]}>
                <Dropdown.Item
                  onClick={() =>
                    history.push({
                      pathname: `${url}/tools`,
                      state: {
                        name: "menu.digitalSignature",
                        icon: "icon-unik3 mr-2",
                      },
                    })
                  }
                >
                  {t("header.tools")}
                </Dropdown.Item>
              </ProtectedComponent>
              <ProtectedComponent roles={([ROLE_ADMIN], [ROLE_ORG_ADMIN])}>
                <Dropdown.Item
                  onClick={() =>
                    history.push({ pathname: `${url}/admin/settings` })
                  }
                >
                  {t("menu.admin.admin")}
                </Dropdown.Item>
              </ProtectedComponent>
              <Dropdown.Item onClick={() => handleLogout()}>
                {t("header.logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
export default withRouter(withNamespaces()(Header));
