import React from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import SpinnerNew from "~/components/SpinnerNew";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const PermissionTable = (props) => {
  const { isLoadingPermission } = useSelector(
    (state) => state.permissionReducer
  );

  const {
    t,
    permissions = [],
    addAction,
    removeAction,
    actionIcon = "icon-add",
    removeIcon = "icon-trash",
    showSearch = true,
  } = props;

  const buildActionsColumn = (_, row, {}) => (
    <>
      {addAction && (
        <i
          className={`${actionIcon} table-action-icon`}
          onClick={() => addAction(row)}
        />
      )}
      {removeAction && (
        <i
          className={`${removeIcon} table-action-red-icon`}
          onClick={() => removeAction(row)}
        />
      )}
    </>
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "description",
      text: t("admin.permission.list.description"),
      sort: true,
    },
    {
      dataField: "accao",
      text: t("admin.role.list.action"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
    ],
  };

  return (
    <div className="custom-table">
      <ToolkitProvider
        keyField="id"
        data={permissions}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            {showSearch && (
              <SearchBar
                {...props.searchProps}
                placeholder={t("admin.role.list.searchInput")}
              />
            )}
            {!isLoadingPermission && (
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory(options)}
                striped
                hover
              />
            )}
            <SpinnerNew open={isLoadingPermission} />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withNamespaces()(PermissionTable);
