import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";

import { Modal, Button, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { remove } from "lodash";

import { loadLegacyRoles } from "../actions";
import "../scss/legacy-role-table.scss";
import SpinnerNew from "~/components/SpinnerNew";
const devLogConsole = require("~/utils/devLog");
const LegacyRoleModal = (props) => {
  const {
    showModal,
    closeModal,
    selectedLegacyApplication,
    legacyApplications,
    legacyRoles,
    setLegacyRoles,
    t,
  } = props;

  const dispatch = useDispatch();

  const { legacyRoles: legacyRolesList, isLoadingLegacyRoles } = useSelector(
    (state) => state.roleReducer
  );

  useEffect(() => {
    if (selectedLegacyApplication.id) {
      dispatch(loadLegacyRoles(selectedLegacyApplication.id));
    }
  }, [dispatch, selectedLegacyApplication]);

  function handleLegacyRoles(legacyRole) {
    removeItemFromTable();
    try {
      setLegacyRoles([...legacyRoles, legacyRole]);
    } catch {
      devLogConsole(legacyRole);
      devLogConsole(legacyRoles);
    }
    closeModal();
  }

  function removeItemFromTable() {
    remove(legacyRoles, { appId: selectedLegacyApplication.id });
    remove(legacyApplications, selectedLegacyApplication);
  }

  function handleCancelButton() {
    closeModal();
  }

  //Table configs
  const buildActionColumn = (_, row, {}) => (
    <i
      className="icon-add lg-icon add-table-icon pointer"
      onClick={() => handleLegacyRoles(row)}
    />
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "description",
      text: t("admin.role.persist.modal.roleColumn"),
    },
    {
      dataField: "select",
      text: t("admin.role.persist.modal.selectColumn"),
      formatter: buildActionColumn,
      align: () => "center",
    },
  ];

  const options = {
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
    ],
    hideSizePerPage: true,
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop="static"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${selectedLegacyApplication.description} ${t(
            "admin.role.persist.modal.roles"
          )}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isLoadingLegacyRoles && (
          <Row noGutters="true">
            <Col>
              <ToolkitProvider
                keyField="id"
                data={legacyRolesList}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <SearchBar
                      {...props.searchProps}
                      placeholder={t(
                        "admin.role.persist.modal.filterDescription"
                      )}
                    />
                    <BootstrapTable
                      {...props.baseProps}
                      id="modal-role-table"
                      keyField="id"
                      pagination={paginationFactory(options)}
                      noDataIndication={t("admin.role.list.emptyTable")}
                      striped
                      hover
                    />
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={() => handleCancelButton()}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>

      <SpinnerNew open={isLoadingLegacyRoles} />
    </Modal>
  );
};

export default withNamespaces()(LegacyRoleModal);
