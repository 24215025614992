import { takeLatest } from 'redux-saga/effects';

import {
  FIND_UDW_SETTINGS,
  FIND_UDW_USERS,
  FIND_LEGACY_USERS,
  LOGIN_ADFS,
  EASYPAY_SSO,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE_BY_ID,
  COUNT_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  SEND_EMAIL,
  GET_THEME_TO_USE
} from "../ducks/udw/actionTypes";

import {
  findUDWSettings,
  findUDWUsers,
  findLegacyUsers,
  loginADFS,
  easyPaySSO,
  getEmailTemplates,
  getEmailTemplateById,
  countEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  sendEmail,
  getThemeToUse
} from "../ducks/udw/sagas";

export const udwSagas = [
  takeLatest(FIND_UDW_SETTINGS, findUDWSettings),
  takeLatest(GET_THEME_TO_USE, getThemeToUse),
  takeLatest(FIND_UDW_USERS, findUDWUsers),
  takeLatest(FIND_LEGACY_USERS, findLegacyUsers),
  takeLatest(LOGIN_ADFS, loginADFS),
  takeLatest(EASYPAY_SSO, easyPaySSO),
  takeLatest(GET_EMAIL_TEMPLATES, getEmailTemplates),
  takeLatest(GET_EMAIL_TEMPLATE_BY_ID, getEmailTemplateById),
  takeLatest(COUNT_EMAIL_TEMPLATES, countEmailTemplates),
  takeLatest(CREATE_EMAIL_TEMPLATE, createEmailTemplate),
  takeLatest(UPDATE_EMAIL_TEMPLATE, updateEmailTemplate),
  takeLatest(DELETE_EMAIL_TEMPLATE, deleteEmailTemplate),
  takeLatest(SEND_EMAIL, sendEmail),
]
