import React from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from 'react-i18next';
import uuid from "uuid/v1";
import SpinnerNew from "~/components/SpinnerNew";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const RoleTable = (props) => {

  const { isLoadingRole } = useSelector(state => state.roleReducer);

  const {
    t,
    roles = [],
    action,
    actionIcon = "icon-add"
  } = props;

  const buildActionsColumn = (_, row, { }) => (
    <i
      className={`${actionIcon} table-action-icon`}
      onClick={() => action(row)}
    />
  )

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: 'description',
      text: t('admin.permission.list.description'),
      sort: true
    },
    {
      dataField: 'accao',
      text: t('admin.role.list.action'),
      formatter: buildActionsColumn,
      headerStyle: (colum, colIndex) => {
        return { width: '80px', textAlign: 'center' };
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={roles}
      columns={columns}
      search
    >
      {
        props => (
          <div>
            <SearchBar
              {...props.searchProps}
              placeholder={t('admin.role.list.searchInput')}
            />
            <hr />
            <SpinnerNew open={isLoadingRole} />
            {
              !isLoadingRole &&
              <BootstrapTable
                key={uuid()}
                {...props.baseProps}
                bootstrap4
                pagination={paginationFactory()}
                noDataIndication={t('general.dataNotFound')}
                striped
                hover
              />
            }
          </div>
        )
      }
    </ToolkitProvider>
  )

}

export default withNamespaces()(RoleTable);
