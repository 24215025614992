import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { timeConversion } from "./utils";
import {
  buildIflowNumberColumn,
  buildIflowDateColumn,
} from "./columnFormatter";
import {
  getIflowMetadataPreview,
  setIflowMetadataPreview,
} from "~/store/ducks/processes/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import uuid from "uuid/v1";
import he from "he";
const devLogConsole = require("~/utils/devLog");
const PreviewModal = (props) => {
  const { t, showModal, closeModal, task, app } = props;

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { metadataPreview, isLoadingPreview } = useSelector(
    (state) => state.processesReducer
  );

  const [flowColumns, setFlowColumns] = useState([]);
  const [detailJspPreviews, setDetailJspPreviews] = useState([]);

  const dispatch = useDispatch();

  /*Get flow columns from extra configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );

        let flowColumns_;
        if (task && task.flowid && extraConfiguration_[task.flowid]) {
          flowColumns_ = extraConfiguration_[task.flowid].columns
            ? extraConfiguration_[task.flowid].columns
            : undefined;
        } else {
          flowColumns_ = extraConfiguration_.defaultExtraColumns
            ? extraConfiguration_.defaultExtraColumns
            : undefined;
        }
        setFlowColumns(flowColumns_);

        let detail_jsp_preview_;
        detail_jsp_preview_ = extraConfiguration_.detail_jsp_preview
          ? extraConfiguration_.detail_jsp_preview
          : [];

        setDetailJspPreviews(detail_jsp_preview_);
      }
    }
  }, [menu, task]);

  useEffect(() => {
    if (task && detailJspPreviews?.length > 0) {
      const flowid = task?.flowid.toString();
      if (detailJspPreviews.some((i) => i == flowid)) {
        const pNumber = task?.pnumber;
        const subPid = task?.subpid.toString();
        const url = `Form/detail.jsp?flowid=${flowid}&pid=${pNumber}&subpid=${subPid}&procStatus=-4&fwSearch=true&uri=Forward/forward.jsp&op=10`;
        dispatch(getIflowMetadataPreview({ url }));
      }
    }
    return () => {
      dispatch(setIflowMetadataPreview(null));
    };
  }, [task, detailJspPreviews]);

  const buildSLAColumn = (SLA) => {
    if (SLA) {
      const toDate = (dateStr) => {
        const day = dateStr.slice(0, 2);
        const month = dateStr.slice(2, 4);
        const year = dateStr.slice(4, 8);
        const hour = dateStr.slice(8, 10);
        const min = dateStr.slice(10, 12);
        const sec = dateStr.slice(12, 14);
        return new Date(year, month - 1, day, hour, min, sec);
      };
      const millis = toDate(SLA) - Date.now();
      return millis ? timeConversion(millis) : SLA;
    }
    return "";
  };

  const handleValue = (value, name) => {
    if (value && flowColumns) {
      const foundItem = flowColumns.find((i) => i[name] !== undefined);
      devLogConsole(name, name);
      if (foundItem && typeof foundItem === "object") {
        const rawType = foundItem[name]?.rawType;
        const formattedType = foundItem[name]?.formattedType;
        if (rawType === "iflowNumber") {
          return buildIflowNumberColumn(value, formattedType);
        } else if (rawType === "iflowDate") {
          return buildIflowDateColumn(value, formattedType);
        }
      }
    }
    return he.decode(value);
  };

  const renderLines = () => {
    if (
      (task && task?.metadata.length === 0) ||
      task?.metadata.every(
        (i) => i.value === null || i.value === undefined || i.value === ""
      )
    )
      return <div>{t("general.dataNotFound")}</div>;
    else
      return (
        task &&
        task.metadata.map((data, index) => {
          if (
            data.value !== undefined &&
            data.value !== null &&
            data.value !== ""
          ) {
            if (data.name === "SLA") data.value = buildSLAColumn(data.value);
            return (
              <div key={index}>
                <Row>
                  <Col sm="6">
                    <span>
                      {data.name ? t(`taskPage.general.${data.name}`) : ""}:
                    </span>
                  </Col>
                  <Col sm="6">
                    <div style={{ fontWeight: "500" }}>
                      {handleValue(data.value, data.name)}
                    </div>
                  </Col>
                </Row>
                <hr />
              </div>
            );
          }
        })
      );
  };

  const renderTable = (rows, title) => {
    devLogConsole(rows);
    const headers = [];
    const data = [];
    const columns = [];
    rows.forEach((item, i__) => {
      if (item && item.col) {
        if (item.col.some((i) => i.header === "true")) {
          item.col.forEach((i, i_) => {
            if (i.header === "true") {
              headers.push(i);
            }
          });
        } else {
          let rowObject = {};
          item.col.forEach((col, index) => {
            rowObject.id = uuid();
            rowObject[index] = col.value;
          });
          data.push(rowObject);
        }
      }
    });

    const lastHeader = headers[headers.length - 1];
    if (!lastHeader && data[0]) {
      Object.keys(data[0]).forEach((key, index) => {
        columns.push({
          dataField: index.toString(),
          text: "",
          hidden: key === "id" ? true : false,
        });
      });
    } else {
      headers.forEach((d, index) => {
        columns.push({
          dataField: index.toString(),
          text: d.value || "",
        });
      });
    }
    return (
      <div className="task-preview-table">
        <div className="custom-title" style={{ paddingLeft: "8px" }}>
          {title}
        </div>
        <BootstrapTable
          striped
          keyField="id"
          data={data || []}
          columns={columns}
          noDataIndication={t("general.dataNotFound")}
        />
      </div>
    );
  };

  const renderPreviewByDetailResponse = () => {
    if (
      (metadataPreview && metadataPreview.metadata?.length === 0) ||
      metadataPreview?.metadata.every(
        (i) => i.value === null || i.value === undefined || i.value === ""
      )
    )
      return <div>{t("general.dataNotFound")}</div>;
    else
      return (
        metadataPreview &&
        metadataPreview?.metadata.map((data, index) => {
          if (data?.value?.row?.length > 0) {
            return (
              <div key={index}>
                {renderTable(data.value.row, data?.subheader)}
              </div>
            );
          } else if (
            data.value !== undefined &&
            data.value !== null &&
            data.value !== "" &&
            typeof data.value != "object"
          ) {
            if (data.name === "SLA") data.value = buildSLAColumn(data.value);
            return (
              <div key={index} id="detailPreview">
                <Row>
                  <Col sm="6">
                    <span> {data.name}:</span>
                  </Col>
                  <Col sm="6">
                    <div style={{ fontWeight: "500" }}>
                      {handleValue(data.value, data.name)}
                    </div>
                  </Col>
                </Row>
                <hr />
              </div>
            );
          }
        })
      );
  };

  return (
    <>
      <SpinnerNew open={isLoadingPreview} />
      {!isLoadingPreview && (
        <Modal
          show={showModal}
          onHide={() => closeModal()}
          dialogClassName="modal-70w custom-modal"
          centered
        >
          <Modal.Header closeButton>
            <div className="custom-title">
              {task && task.flowname} &nbsp;
              {task && task.pnumber + " (Pré-visualização)"}
            </div>
          </Modal.Header>

          <Modal.Body>
            {metadataPreview ? renderPreviewByDetailResponse() : renderLines()}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default withNamespaces()(PreviewModal);
