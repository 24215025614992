import React, { useEffect, useState } from "react";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import "../../scss/calculos.scss";
const devLogConsole = require("~/utils/devLog");
//#endregion Calculos
Array.prototype.stanDeviate = function () {
  var i,
    j,
    total = 0,
    mean = 0,
    diffSqredArr = [];
  for (i = 0; i < this.length; i += 1) {
    total += this[i];
  }
  mean = total / this.length;
  for (j = 0; j < this.length; j += 1) {
    diffSqredArr.push(Math.pow(this[j] - mean, 2));
  }
  return Math.sqrt(
    diffSqredArr.reduce(function (firstEl, nextEl) {
      return firstEl + nextEl;
    }) / this.length
  );
};

function parseNumber(arg) {
  if (arg) return Number(arg.replace(/[,]/g, ".").replace(/[.](?=.*[.])/g, ""));
  else return null;
}

function updateTotais(tabelaProspecao, tabelaVenda, tabelaArrendamento) {
  var colMax = "2";
  var colMin = "3";
  var colMed = "4";
  var colStdDev = "5";

  var colTipoImovel = "1";
  var colTipoNeg = "3";
  var colIndHomolP = "19";

  // venda
  // percorre linhas

  var numLinhasVenda = 2;

  while (
    document.getElementById(tabelaVenda + "_" + numLinhasVenda + "_1") != null
  ) {
    var tipologia = document.getElementById(
      tabelaVenda + "_" + numLinhasVenda + "_1"
    ).textContent;

    // percorre e encontra matches na prospecao
    var arrValues = [];

    var numLinhasProspecao = 3;
    while (
      document.getElementById(
        tabelaProspecao + "_" + numLinhasProspecao + "_1"
      ) != null
    ) {
      var selectTipoImovel = document
        .getElementById(
          tabelaProspecao + "_" + numLinhasProspecao + "_" + colTipoImovel
        )
        .getElementsByTagName("select")[0];
      var strTipoImovel =
        selectTipoImovel.options[selectTipoImovel.selectedIndex].text;

      //devLogConsole(numLinhasProspecao+ ":tipologia:" + tipologia);

      //devLogConsole(numLinhasProspecao+ ":strTipoImovel:" + strTipoImovel);

      var selectTipoNeg = document
        .getElementById(
          tabelaProspecao + "_" + numLinhasProspecao + "_" + colTipoNeg
        )
        .getElementsByTagName("select")[0];
      var strTipoNeg = selectTipoNeg.options[selectTipoNeg.selectedIndex].text;

      //devLogConsole(numLinhasProspecao+ ":strTipoNeg:" + strTipoNeg);

      if (strTipoImovel == tipologia && strTipoNeg == "Venda") {
        arrValues.push(
          Number(
            parseNumber(
              document.getElementById(
                tabelaProspecao + "_" + numLinhasProspecao + "_" + colIndHomolP
              ).textContent
            )
          )
        );

        devLogConsole(
          numLinhasProspecao +
            ":valor homol:" +
            Number(
              parseNumber(
                document.getElementById(
                  tabelaProspecao +
                    "_" +
                    numLinhasProspecao +
                    "_" +
                    colIndHomolP
                ).textContent
              )
            )
        );
      }
      numLinhasProspecao++;
    }

    if (arrValues.length > 0) {
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMax
      ).textContent = Math.max
        .apply(Math, arrValues)
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMin
      ).textContent = Math.min
        .apply(Math, arrValues)
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");

      var sum = 0;
      for (var i = 0; i < arrValues.length; i++) sum += arrValues[i];

      const avg = sum / arrValues.length || 0;

      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMed
      ).textContent = avg.toFixed(2).toLocaleString("pt").replace(".", ",");
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colStdDev
      ).textContent = arrValues
        .stanDeviate()
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");
    } else {
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMax
      ).textContent = "";
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMin
      ).textContent = "";
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colMed
      ).textContent = "";
      document.getElementById(
        tabelaVenda + "_" + numLinhasVenda + "_" + colStdDev
      ).textContent = "";
    }
    numLinhasVenda++;
  }

  // arrendamento
  // percorre linhas

  var numLinhasArrendamento = 2;

  while (
    document.getElementById(
      tabelaArrendamento + "_" + numLinhasArrendamento + "_1"
    ) != null
  ) {
    tipologia = document.getElementById(
      tabelaArrendamento + "_" + numLinhasArrendamento + "_1"
    ).textContent;

    // percorre e encontra matches na prospecao
    var arrValues = [];

    var numLinhasProspecao = 3;
    while (
      document.getElementById(
        tabelaProspecao + "_" + numLinhasProspecao + "_1"
      ) != null
    ) {
      selectTipoImovel = document
        .getElementById(
          tabelaProspecao + "_" + numLinhasProspecao + "_" + colTipoImovel
        )
        .getElementsByTagName("select")[0];
      strTipoImovel =
        selectTipoImovel.options[selectTipoImovel.selectedIndex].text;

      //devLogConsole(numLinhasProspecao+ ":tipologia:" + tipologia);

      //devLogConsole(numLinhasProspecao+ ":strTipoImovel:" + strTipoImovel);

      var selectTipoNeg = document
        .getElementById(
          tabelaProspecao + "_" + numLinhasProspecao + "_" + colTipoNeg
        )
        .getElementsByTagName("select")[0];
      var strTipoNeg = selectTipoNeg.options[selectTipoNeg.selectedIndex].text;

      //devLogConsole(numLinhasProspecao+ ":strTipoNeg:" + strTipoNeg);

      if (strTipoImovel == tipologia && strTipoNeg == "Arrendamento") {
        arrValues.push(
          Number(
            parseNumber(
              document.getElementById(
                tabelaProspecao + "_" + numLinhasProspecao + "_" + colIndHomolP
              ).textContent
            )
          )
        );

        devLogConsole(
          numLinhasProspecao +
            ":valor homol:" +
            Number(
              parseNumber(
                document.getElementById(
                  tabelaProspecao +
                    "_" +
                    numLinhasProspecao +
                    "_" +
                    colIndHomolP
                ).textContent
              )
            )
        );
      }
      numLinhasProspecao++;
    }

    if (arrValues.length > 0) {
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMax
      ).textContent = Math.max
        .apply(Math, arrValues)
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMin
      ).textContent = Math.min
        .apply(Math, arrValues)
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");

      var sum = 0;
      for (i = 0; i < arrValues.length; i++) sum += arrValues[i];

      const avg = sum / arrValues.length || 0;

      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMed
      ).textContent = avg.toFixed(2).toLocaleString("pt").replace(".", ",");
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colStdDev
      ).textContent = arrValues
        .stanDeviate()
        .toFixed(2)
        .toLocaleString("pt")
        .replace(".", ",");
    } else {
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMax
      ).textContent = "";
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMin
      ).textContent = "";
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colMed
      ).textContent = "";
      document.getElementById(
        tabelaArrendamento + "_" + numLinhasArrendamento + "_" + colStdDev
      ).textContent = "";
    }
    numLinhasArrendamento++;
  }
}

function updateTabelaProspecaoIV(
  linha,
  tabelaProspecao,
  tabelaVenda,
  tabelaArrendamento
) {
  var tableid = tabelaProspecao;

  var colTipo = "1";
  var colAreaTerreno = "8";
  var colAreaBruta = "9";
  var colValor = "10";
  var colIV = "11";

  var colNeg = "12";
  var colLoc = "13";
  var colArea = "14";
  var colEstado = "15";
  var colAcab = "16";
  var colOutros = "17";

  var colCoefGlob = "18";

  var colIndHomol = "19";

  devLogConsole(tableid + "_" + linha + "_" + colTipo);
  var selectTipo = document
    .getElementById(tableid + "_" + linha + "_" + colTipo)
    ?.getElementsByTagName("select")[0];
  devLogConsole(selectTipo);
  var strTipo = selectTipo?.options[selectTipo.selectedIndex]?.text &&
    selectTipo?.options[selectTipo.selectedIndex]?.text.toLowerCase();

  var valor = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colValor)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var areaBruta = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colAreaBruta)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var areaTerreno = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colAreaTerreno)
        ?.getElementsByTagName("input")[0].value
    )
  );

  var valorNeg = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colNeg)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorLoc = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colLoc)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorArea = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colArea)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorEstado = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colEstado)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorAcab = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colAcab)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorOutros = Number(
    parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colOutros)
        ?.getElementsByTagName("input")[0].value
    )
  );
  var valorIV;

  if (strTipo && strTipo.search("terreno") != -1) {
    if (areaTerreno > 0) {
      valorIV = valor / areaTerreno;
    } else {
      valorIV = Number("0");
    }
  } else {
    if (areaBruta > 0) {
      valorIV = valor / areaBruta;
    } else {
      valorIV = Number("0");
    }
  }

  if (document.getElementById(tableid + "_" + linha + "_" + colIV))
    document.getElementById(tableid + "_" + linha + "_" + colIV).textContent =
      valorIV.toFixed(2).toLocaleString("pt").replace(".", ",");

  var valorCoefGlob =
    valorNeg * valorLoc * valorArea * valorEstado * valorAcab * valorOutros;

  if (document.getElementById(tableid + "_" + linha + "_" + colCoefGlob))
    document.getElementById(
      tableid + "_" + linha + "_" + colCoefGlob
    ).textContent = valorCoefGlob
      .toFixed(2)
      .toLocaleString("pt")
      .replace(".", ",");

  var valorIndHomol = valorCoefGlob * valorIV;
  if (document.getElementById(tableid + "_" + linha + "_" + colIndHomol))
    document.getElementById(
      tableid + "_" + linha + "_" + colIndHomol
    ).textContent = valorIndHomol
      .toFixed(2)
      .toLocaleString("pt")
      .replace(".", ",");

  updateTotais(tabelaProspecao, tabelaVenda, tabelaArrendamento);
}

//#endregion

export const GetDefaultTable = () => [
  { id: "Apartamento" },
  { id: "Moradia" },
  { id: "Terreno" },
  { id: "Loja" },
  { id: "Garagem / Estacionamento" },
  { id: "Edificio" },
  { id: "Escritório" },
  { id: "Armazém" },
];

function searchNode(id, currentNode) {
  var result = [];

  for (const [key, value] of Object.entries(currentNode)) {
    if (value == id) {
      result.push(currentNode);
      return result;
    }
    if (
      (value !== null && typeof value === "object") ||
      typeof value === "array"
    )
      result = [...result, ...searchNode(id, value)];
  }
  return result;
}

const TabelaProspecaoIVRow = ({ i, block }) => {
  const imovelMap = {
    0: "-",
    1: "Apartamento",
    2: "Moradia",
    3: "Terreno",
    4: "Loja",
    5: "Garagem/Estacionamento",
    6: "Edifício",
    7: "Escritório",
    8: "Armazém",
  };

  const lTipoNegocioMap = {
    0: "-",
    1: "Arrendamento",
    2: "Venda",
  };

  const estadoPropMap = {
    0: "-",
    1: "Novo",
    2: "Em construção",
    3: "Usado",
  };
  const [selectTipoImovel, setSelectTipoImovel] = useState(
    imovelMap[block[0]?.value.input?.value] || 0
  );
  const [selectTipoNegocio, setSelectTipoNegocio] = useState(0);
  const [selectEstadoProp, setSelectEstadoProp] = useState(0);

  useEffect(() => {
    if (block) {
      setSelectTipoImovel(block[0]?.value.input?.value || 0);
      setSelectTipoNegocio(block[2]?.value.input?.value || 0);
      setSelectEstadoProp(block[6]?.value.input?.value || 0);
    }
  }, [block]);

  return (
    <tr bgcolor="#FFFFFF">
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_1"}
        className="txt "
        align="left"
      >
        <div class="calculos-tooltip">
          <select
            defaultValue={block[0]?.value.input?.value}
            className="iflow-form-control"
            name={"lista_tipoImovelProp1[" + i + "]"}
            onChange={(e) => {
              setSelectTipoImovel(e.target.value);
            }}
          >
            <option value="0">-</option>
            <option value="1">Apartamento</option>
            <option value="8">Armaz&eacute;m</option>
            <option value="6">Edif&iacute;cio</option>
            <option value="7">Escrit&oacute;rio</option>
            <option value="5">Garagem/Estacionamento</option>
            <option value="4">Loja</option>
            <option value="2">Moradia</option>
            <option value="3">Terreno</option>
          </select>
          {imovelMap[selectTipoImovel] &&
            imovelMap[selectTipoImovel] !== "" &&
            imovelMap[selectTipoImovel] !== "-" && (
              <span class="calculos-tooltiptext">
                {imovelMap[selectTipoImovel] || ""}
              </span>
            )}
        </div>
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_2"}
        className="txt "
        align="left"
      >
        <select
          defaultValue={block[1]?.value.input?.value}
          className="iflow-form-control"
          name={"lista_tipologiaProp1[" + i + "]"}
        >
          <option value="0">-</option>
          <option value="1">0</option>
          <option value="2">1</option>
          <option value="3">2</option>
          <option value="4">3</option>
          <option value="5">4</option>
          <option value="6">5</option>
          <option value="7">6</option>
          <option value="8">7</option>
          <option value="9">8</option>
        </select>
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_3"}
        className="txt "
        align="left"
      >
        <div class="calculos-tooltip">
          <select
            defaultValue={block[2]?.value.input?.value}
            className="iflow-form-control"
            name={"lista_tipoNegocio1[" + i + "]"}
            onChange={(e) => {
              setSelectTipoNegocio(e.target.value);
            }}
          >
            <option value="0">-</option>
            <option value="1">Arrendamento</option>
            <option value="2">Venda</option>
          </select>
          {lTipoNegocioMap[selectTipoNegocio] &&
            lTipoNegocioMap[selectTipoNegocio] !== "" &&
            lTipoNegocioMap[selectTipoNegocio] !== "-" && (
              <span class="calculos-tooltiptext">
                {lTipoNegocioMap[selectTipoNegocio] || ""}
              </span>
            )}
        </div>
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_4"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[3]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_fonte[" + i + "]"}
          id={"lista_fonte[" + i + "]"}
          size="10"
          maxLength="500"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_5"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[4]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_moradaProp[" + i + "]"}
          id={"lista_moradaProp[" + i + "]"}
          size="25"
          maxLength="500"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_6"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[5]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_anoProp[" + i + "]"}
          id={"lista_anoProp[" + i + "]"}
          size="3"
          maxLength="500"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_7"}
        className="txt "
        align="left"
      >
        <div class="calculos-tooltip">
          <select
            defaultValue={block[6]?.value.input?.value}
            className="iflow-form-control"
            name={"lista_estadoProp1[" + i + "]"}
            onChange={(e) => {
              setSelectEstadoProp(e.target.value);
            }}
          >
            <option value="0">-</option>
            <option value="2">Em constru&ccedil;&atilde;o</option>
            <option value="1">Novo</option>
            <option value="3">Usado</option>
          </select>
          {estadoPropMap[selectEstadoProp] &&
            estadoPropMap[selectEstadoProp] !== "" &&
            estadoPropMap[selectEstadoProp] !== "-" && (
              <span class="calculos-tooltiptext">
                {estadoPropMap[selectEstadoProp] || ""}
              </span>
            )}
        </div>
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_8"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[7]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_areaTerrProp[" + i + "]"}
          id={"lista_areaTerrProp[" + i + "]"}
          size="3"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_9"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[8]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_areaBrutaProp[" + i + "]"}
          id={"lista_areaBrutaProp[" + i + "]"}
          size="3"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_10"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[9]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_valorProp[" + i + "]"}
          id={"lista_valorProp[" + i + "]"}
          size="3"
          maxLength="50"
          title=""
        />
      </td>
      <td id={"63_" + (i + 3) + "_11"} className="txt " align="left">
        {block[10]
          ? Number(
              block[10].value.replace(",", ".").replace(/[.](?=.*[.])/g, "")
            )
              .toFixed(2)
              .toLocaleString("pt")
              .replace(".", ",")
          : "0,00"}
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_12"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[11]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefNegoc[" + i + "]"}
          id={"lista_coefNegoc[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_13"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[12]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefLocal[" + i + "]"}
          id={"lista_coefLocal[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_14"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[13]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefArea[" + i + "]"}
          id={"lista_coefArea[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_15"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[14]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefEstado[" + i + "]"}
          id={"lista_coefEstado[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_16"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[15]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefAcab[" + i + "]"}
          id={"lista_coefAcab[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td
        onBlur={() => {
          updateTabelaProspecaoIV(i + 3, 63, 68, 71);
          return false;
        }}
        id={"63_" + (i + 3) + "_17"}
        className="txt "
        align="left"
      >
        <input
          defaultValue={block[16]?.value.input?.value}
          className="iflow-form-control"
          type="text"
          name={"lista_coefOutros[" + i + "]"}
          id={"lista_coefOutros[" + i + "]"}
          size="2"
          maxLength="50"
          title=""
        />
      </td>
      <td id={"63_" + (i + 3) + "_18"} className="txt " align="left">
        {block[17]
          ? Number(
              block[17].value.replace(",", ".").replace(/[.](?=.*[.])/g, "")
            )
              .toFixed(2)
              .toLocaleString("pt")
              .replace(".", ",")
          : "0,00"}
      </td>
      <td id={"63_" + (i + 3) + "_19"} className="txt " align="left">
        {block[18]
          ? Number(
              block[18].value.replace(",", ".").replace(/[.](?=.*[.])/g, "")
            )
              .toFixed(2)
              .toLocaleString("pt")
              .replace(".", ",")
          : "0,00"}
      </td>
    </tr>
  );
};

const TabelaProspecaoIV = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", width: "100%" }}
      className="iflow-calculos-table arraytable sortable scrollableTable scrollTable"
    >
      <thead>
        <tr bgcolor="#FFFFFF" className="header">
          <td id="63_1_1" align="center" colSpan="11">
            P - PROSPEÇÃO - IMÓVEIS CONSTRUÍDOS
          </td>
          <td id="63_1_2" align="center" colSpan="8">
            Coeficientes de Homogeneização
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="table-head">
          <td id="63_2_1" align="left">
            Tipo Imóvel
          </td>
          <td id="63_2_2" align="left">
            Tipologia T
          </td>
          <td id="63_2_3" align="left">
            Tipo Negócio
          </td>
          <td id="63_2_4" align="left">
            Fonte
          </td>
          <td id="63_2_5" align="left">
            Morada/Localização
          </td>
          <td id="63_2_6" align="left">
            Ano
          </td>
          <td id="63_2_7" align="left">
            Estado
          </td>
          <td id="63_2_8" align="left">
            Área Terreno (m2)
          </td>
          <td id="63_2_9" align="left">
            Área Bruta (m2)
          </td>
          <td id="63_2_10" align="left">
            Valor €
          </td>
          <td id="63_2_11" align="left">
            IV €/m2
          </td>
          <td id="63_2_12" align="left">
            Negoc.
          </td>
          <td id="63_2_13" align="left">
            Localiz.
          </td>
          <td id="63_2_14" align="left">
            Área
          </td>
          <td id="63_2_15" align="left">
            Estado Conserv.
          </td>
          <td id="63_2_16" align="left">
            Acab.
          </td>
          <td id="63_2_17" align="left">
            Outros
          </td>
          <td id="63_2_18" align="left">
            Coef. Global
          </td>
          <td id="63_2_19" align="left">
            Índice Homog €/m2
          </td>
        </tr>
      </thead>
      <tbody>
        {block?.row.map((e, i) =>
          i > 1 ? <TabelaProspecaoIVRow i={i - 2} block={e.col} /> : <></>
        )}
      </tbody>
    </table>
  );
};

const VendaTable = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", flex: "1" }}
      className="iflow-calculos-table arraytable sortable scrollableTable scrollTable"
    >
      <thead className="table-head">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_1_1" className="table_sub_header" align="left">
            Venda
          </td>
          <td id="68_1_2" className="table_sub_header" align="left">
            &Iacute;NDICE M&Aacute;XIMO
          </td>
          <td id="68_1_3" className="table_sub_header" align="left">
            &Iacute;NDICE M&Iacute;NIMO
          </td>
          <td id="68_1_4" className="table_sub_header" align="left">
            M&Eacute;DIA
          </td>
          <td id="68_1_5" className="table_sub_header" align="left">
            DESVIO PADR&Atilde;O
          </td>
          <td id="68_1_6" className="table_sub_header" align="left">
            &Iacute;NDICE ADOTADO
          </td>
        </tr>
      </thead>
      <tbody className="table-content">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_2_1" className="txt " align="left">
            Apartamento
          </td>
          <td id="68_2_2" className="txt " align="left">
            {block?.row[1].col[1].value}
          </td>
          <td id="68_2_3" className="txt " align="left">
            {block?.row[1].col[2].value}
          </td>
          <td id="68_2_4" className="txt " align="left">
            {block?.row[1].col[3].value}
          </td>
          <td id="68_2_5" className="txt " align="left">
            {block?.row[1].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(3, 63, 68, 71);
              return false;
            }}
            id="68_2_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[0]"
              id="INDICE_ANOTADO_VEND[0]"
              defaultValue={block?.row[1].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_3_1" className="txt " align="left">
            Moradia
          </td>
          <td id="68_3_2" className="txt " align="left">
            {block?.row[2].col[1].value}
          </td>
          <td id="68_3_3" className="txt " align="left">
            {block?.row[2].col[2].value}
          </td>
          <td id="68_3_4" className="txt " align="left">
            {block?.row[2].col[3].value}
          </td>
          <td id="68_3_5" className="txt " align="left">
            {block?.row[2].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(4, 63, 68, 71);
              return false;
            }}
            id="68_3_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[1]"
              id="INDICE_ANOTADO_VEND[1]"
              defaultValue={block?.row[2].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_4_1" className="txt " align="left">
            Terreno
          </td>
          <td id="68_4_2" className="txt " align="left">
            {block?.row[3].col[1].value}
          </td>
          <td id="68_4_3" className="txt " align="left">
            {block?.row[3].col[2].value}
          </td>
          <td id="68_4_4" className="txt " align="left">
            {block?.row[3].col[3].value}
          </td>
          <td id="68_4_5" className="txt " align="left">
            {block?.row[3].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(5, 63, 68, 71);
              return false;
            }}
            id="68_4_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[2]"
              id="INDICE_ANOTADO_VEND[2]"
              defaultValue={block?.row[3].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_5_1" className="txt " align="left">
            Loja
          </td>
          <td id="68_5_2" className="txt " align="left">
            {block?.row[4].col[1].value}
          </td>
          <td id="68_5_3" className="txt " align="left">
            {block?.row[4].col[2].value}
          </td>
          <td id="68_5_4" className="txt " align="left">
            {block?.row[4].col[3].value}
          </td>
          <td id="68_5_5" className="txt " align="left">
            {block?.row[4].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(6, 63, 68, 71);
              return false;
            }}
            id="68_5_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[3]"
              id="INDICE_ANOTADO_VEND[3]"
              defaultValue={block?.row[4].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_6_1" className="txt " align="left">
            Garagem/Estacionamento
          </td>
          <td id="68_6_2" className="txt " align="left">
            {block?.row[5].col[1].value}
          </td>
          <td id="68_6_3" className="txt " align="left">
            {block?.row[5].col[2].value}
          </td>
          <td id="68_6_4" className="txt " align="left">
            {block?.row[5].col[3].value}
          </td>
          <td id="68_6_5" className="txt " align="left">
            {block?.row[5].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(7, 63, 68, 71);
              return false;
            }}
            id="68_6_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[4]"
              id="INDICE_ANOTADO_VEND[4]"
              defaultValue={block?.row[5].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_7_1" className="txt " align="left">
            Edif&iacute;cio
          </td>
          <td id="68_7_2" className="txt " align="left">
            {block?.row[6].col[1].value}
          </td>
          <td id="68_7_3" className="txt " align="left">
            {block?.row[6].col[2].value}
          </td>
          <td id="68_7_4" className="txt " align="left">
            {block?.row[6].col[3].value}
          </td>
          <td id="68_7_5" className="txt " align="left">
            {block?.row[6].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(8, 63, 68, 71);
              return false;
            }}
            id="68_7_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[5]"
              id="INDICE_ANOTADO_VEND[5]"
              defaultValue={block?.row[6].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_8_1" className="txt " align="left">
            Escrit&oacute;rio
          </td>
          <td id="68_8_2" className="txt " align="left">
            {block?.row[7].col[1].value}
          </td>
          <td id="68_8_3" className="txt " align="left">
            {block?.row[7].col[2].value}
          </td>
          <td id="68_8_4" className="txt " align="left">
            {block?.row[7].col[3].value}
          </td>
          <td id="68_8_5" className="txt " align="left">
            {block?.row[7].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(9, 63, 68, 71);
              return false;
            }}
            id="68_8_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_VEND[6]"
              id="INDICE_ANOTADO_VEND[6]"
              defaultValue={block?.row[7].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="68_9_1" className="txt " align="left">
            Armaz&eacute;m
          </td>
          <td id="68_9_2" className="txt " align="left">
            {block?.row[8].col[1].value}
          </td>
          <td id="68_9_3" className="txt " align="left">
            {block?.row[8].col[2].value}
          </td>
          <td id="68_9_4" className="txt " align="left">
            {block?.row[8].col[3].value}
          </td>
          <td id="68_9_5" className="txt " align="left">
            {block?.row[8].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(10, 63, 68, 71);
              return false;
            }}
            id="68_9_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control txt"
              type="text"
              name="INDICE_ANOTADO_VEND[7]"
              id="INDICE_ANOTADO_VEND[7]"
              defaultValue={block?.row[8].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ArrendamentoTable = ({ block }) => {
  return (
    <table
      style={{ borderSpacing: 0, borderCollapse: "collapse", flex: "1" }}
      className="iflow-calculos-table arraytable sortable scrollableTable scrollTable"
    >
      <thead className="table-head">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_1_1" className="table_sub_header" align="left">
            Arrendamento
          </td>
          <td id="71_1_2" className="table_sub_header" align="left">
            &Iacute;NDICE M&Aacute;XIMO
          </td>
          <td id="71_1_3" className="table_sub_header" align="left">
            &Iacute;NDICE M&Iacute;NIMO
          </td>
          <td id="71_1_4" className="table_sub_header" align="left">
            M&Eacute;DIA
          </td>
          <td id="71_1_5" className="table_sub_header" align="left">
            DESVIO PADR&Atilde;O
          </td>
          <td id="71_1_6" className="table_sub_header" align="left">
            &Iacute;NDICE ADOTADO
          </td>
        </tr>
      </thead>
      <tbody className="table-content">
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_2_1" className="txt " align="left">
            Apartamento
          </td>
          <td id="71_2_2" className="txt " align="left">
            {block?.row[1].col[1].value}
          </td>
          <td id="71_2_3" className="txt " align="left">
            {block?.row[1].col[2].value}
          </td>
          <td id="71_2_4" className="txt " align="left">
            {block?.row[1].col[3].value}
          </td>
          <td id="71_2_5" className="txt " align="left">
            {block?.row[1].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(3, 63, 68, 71);
              return false;
            }}
            id="71_2_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_ARR[0]"
              id="INDICE_ANOTADO_ARR[0]"
              defaultValue={block?.row[1].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_3_1" className="txt " align="left">
            Moradia
          </td>
          <td id="71_3_2" className="txt " align="left">
            {block?.row[2].col[1].value}
          </td>
          <td id="71_3_3" className="txt " align="left">
            {block?.row[2].col[2].value}
          </td>
          <td id="71_3_4" className="txt " align="left">
            {block?.row[2].col[3].value}
          </td>
          <td id="71_3_5" className="txt " align="left">
            {block?.row[2].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(4, 63, 68, 71);
              return false;
            }}
            id="71_3_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_ARR[1]"
              id="INDICE_ANOTADO_ARR[1]"
              defaultValue={block?.row[2].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_4_1" className="txt " align="left">
            Terreno
          </td>
          <td id="71_4_2" className="txt " align="left">
            {block?.row[3].col[1].value}
          </td>
          <td id="71_4_3" className="txt " align="left">
            {block?.row[3].col[2].value}
          </td>
          <td id="71_4_4" className="txt " align="left">
            {block?.row[3].col[3].value}
          </td>
          <td id="71_4_5" className="txt " align="left">
            {block?.row[3].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(5, 63, 68, 71);
              return false;
            }}
            id="71_4_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_ARR[2]"
              id="INDICE_ANOTADO_ARR[2]"
              defaultValue={block?.row[3].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_5_1" className="txt " align="left">
            Loja
          </td>
          <td id="71_5_2" className="txt " align="left">
            {block?.row[4].col[1].value}
          </td>
          <td id="71_5_3" className="txt " align="left">
            {block?.row[4].col[2].value}
          </td>
          <td id="71_5_4" className="txt " align="left">
            {block?.row[4].col[3].value}
          </td>
          <td id="71_5_5" className="txt " align="left">
            {block?.row[4].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(6, 63, 68, 71);
              return false;
            }}
            id="71_5_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_ARR[3]"
              id="INDICE_ANOTADO_ARR[3]"
              defaultValue={block?.row[4].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_6_1" className="txt " align="left">
            Garagem/Estacionamento
          </td>
          <td id="71_6_2" className="txt " align="left">
            {block?.row[5].col[1].value}
          </td>
          <td id="71_6_3" className="txt " align="left">
            {block?.row[5].col[2].value}
          </td>
          <td id="71_6_4" className="txt " align="left">
            {block?.row[5].col[3].value}
          </td>
          <td id="71_6_5" className="txt " align="left">
            {block?.row[5].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(7, 63, 68, 71);
              return false;
            }}
            id="71_6_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control  txt"
              type="text"
              name="INDICE_ANOTADO_ARR[4]"
              id="INDICE_ANOTADO_ARR[4]"
              defaultValue={block?.row[5].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_7_1" className="txt " align="left">
            Edif&iacute;cio
          </td>
          <td id="71_7_2" className="txt " align="left">
            {block?.row[6].col[1].value}
          </td>
          <td id="71_7_3" className="txt " align="left">
            {block?.row[6].col[2].value}
          </td>
          <td id="71_7_4" className="txt " align="left">
            {block?.row[6].col[3].value}
          </td>
          <td id="71_7_5" className="txt " align="left">
            {block?.row[6].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(8, 63, 68, 71);
              return false;
            }}
            id="71_7_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control txt"
              type="text"
              name="INDICE_ANOTADO_ARR[5]"
              id="INDICE_ANOTADO_ARR[5]"
              defaultValue={block?.row[6].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_8_1" className="txt " align="left">
            Escrit&oacute;rio
          </td>
          <td id="71_8_2" className="txt " align="left">
            {block?.row[7].col[1].value}
          </td>
          <td id="71_8_3" className="txt " align="left">
            {block?.row[7].col[2].value}
          </td>
          <td id="71_8_4" className="txt " align="left">
            {block?.row[7].col[3].value}
          </td>
          <td id="71_8_5" className="txt " align="left">
            {block?.row[7].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(9, 63, 68, 71);
              return false;
            }}
            id="71_8_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control txt"
              type="text"
              name="INDICE_ANOTADO_ARR[6]"
              id="INDICE_ANOTADO_ARR[6]"
              defaultValue={block?.row[7].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
        <tr bgcolor="#FFFFFF" className="txt">
          <td id="71_9_1" className="txt " align="left">
            Armaz&eacute;m
          </td>
          <td id="71_9_2" className="txt " align="left">
            {block?.row[8].col[1].value}
          </td>
          <td id="71_9_3" className="txt " align="left">
            {block?.row[8].col[2].value}
          </td>
          <td id="71_9_4" className="txt " align="left">
            {block?.row[8].col[3].value}
          </td>
          <td id="71_9_5" className="txt " align="left">
            {block?.row[8].col[4].value}
          </td>
          <td
            onBlur={() => {
              updateTabelaProspecaoIV(10, 63, 68, 71);
              return false;
            }}
            id="71_9_6"
            className="txt "
            align="left"
          >
            <input
              className="iflow-form-control txt"
              type="text"
              name="INDICE_ANOTADO_ARR[7]"
              id="INDICE_ANOTADO_ARR[7]"
              defaultValue={block?.row[8].col[5].value.input?.value}
              size="3"
              maxLength="500"
              title=""
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const CreateCalculosProspecao = ({ blockdivision }) => {
  const [seccao, setFormData10] = React.useState("");
  const [state, setState] = React.useState([]);

  useEffect(() => {
    const setValue = (f) => {
      if (f?.variable == "seccao") setFormData10(f);
    };

    setState(searchNode("arraytable", blockdivision));

    blockdivision.forEach((b) => {
      if (Array.isArray(b.columndivision)) {
        b.columndivision.forEach((c) => {
          if (Array.isArray(c.field)) {
            c.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(c.field);
          }
        });
      } else {
        if (Array.isArray(b.columndivision?.field)) {
          b.columndivision.field.forEach((f) => {
            setValue(f);
          });
        } else {
          setValue(b.columndivision.field);
        }
      }
    });
  }, [blockdivision]);

  return (
    <>
      {seccao && (
        <div style={{ padding: "0 5px" }}>
          <CreateButtonsFromSelection field={seccao} />
        </div>
      )}
      <div
        className="custom-table-header iflow-table"
        style={{ overflowX: "auto", overflowY: "visible" }}
      >
        <TabelaProspecaoIV block={state[0]} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        {<VendaTable block={state[1]} />}
        {<ArrendamentoTable block={state[2]} />}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
      >
        <div style={{ margin: "0px", flexGrow: "1", padding: "0px 5px" }}>
          <label className="textarea field multicol" htmlFor="obsVenda">
            OBS:
          </label>
          <textarea
            rows="2"
            name="obsVenda"
            cols="50"
            className="form-control txt"
            defaultValue={searchNode("obsVenda", blockdivision)[0]?.value}
          ></textarea>
        </div>
        <div style={{ margin: "0px", flexGrow: "1", padding: "0px 5px" }}>
          <label className="textarea field multicol" htmlFor="obsArrendamento">
            OBS:
          </label>
          <textarea
            rows="2"
            name="obsArrendamento"
            cols="50"
            className="form-control txt"
            defaultValue={
              searchNode("obsArrendamento", blockdivision)[0]?.value
            }
          ></textarea>
        </div>
      </div>

      <div>
        <div style={{ margin: "0px 10px" }} className="textarea  field">
          <label className="textarea field" htmlFor="obsProp">
            Observa&ccedil;&otilde;es
          </label>
          <textarea
            rows="5"
            name="obsProp"
            cols="90"
            className="form-control txt"
            defaultValue={searchNode("obsProp", blockdivision)[0]?.value}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default CreateCalculosProspecao;
