export const FIND_UDW_SETTINGS = 'udw@FIND_UDW_SETTINGS';
export const SET_UDW_SETTINGS = 'udw@SET_UDW_SETTINGS';

export const FIND_UDW_USERS = 'udw@FIND_UDW_USERS';
export const SET_UDW_USERS = 'udw@SET_UDW_USERS';

export const SET_SSO_INFO = 'udw@SET_SSO_INFO';

export const FIND_LEGACY_USERS = 'udw@FIND_LEGACY_USERS';
export const SET_LEGACY_USERS = 'udw@SET_LEGACY_USERS';

export const LOGIN_ADFS = 'udw@LOGIN_ADFS';

export const EASYPAY_SSO = 'udw@EASYPAY_SSO';

export const GET_EMAIL_TEMPLATES = "udw@GET_EMAIL_TEMPLATES";
export const SET_EMAIL_TEMPLATES = "udw@SET_EMAIL_TEMPLATES";

export const GET_EMAIL_TEMPLATE_BY_ID = "udw@GET_EMAIL_TEMPLATE_BY_ID";
export const SET_EMAIL_TEMPLATE_BY_ID = "udw@SET_EMAIL_TEMPLATE_BY_ID";

export const COUNT_EMAIL_TEMPLATES = "udw@COUNT_EMAIL_TEMPLATES";
export const SET_COUNT_EMAIL_TEMPLATES = "udw@SET_COUNT_EMAIL_TEMPLATES";

export const CREATE_EMAIL_TEMPLATE = "udw@CREATE_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE = "udw@UPDATE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE = "udw@DELETE_EMAIL_TEMPLATE";
export const STOP_EDITING_EMAIL_TEMPLATES = "udw@STOP_EDITING_EMAIL_TEMPLATES";

export const SEND_EMAIL = "udw@SEND_EMAIL";
export const GET_THEME_TO_USE = "udw@GET_THEME_TO_USE";
export const SET_THEME_TO_USE = "udw@SET_THEME_TO_USE";

export const findUDWSettings = () => ({ type: FIND_UDW_SETTINGS });
export const getThemeToUse = (configuration, userSettings)=> ({ type: GET_THEME_TO_USE, configuration, userSettings });
export const setUDWSettings = udwSettings => ({ type: SET_UDW_SETTINGS, udwSettings });
export const setThemeToUse = theme => ({ type: SET_THEME_TO_USE, theme });
export const findUDWUsers = (legacyUsers) => ({ type: FIND_UDW_USERS, legacyUsers });
export const setUDWUsers = udwUsers => ({ type: SET_UDW_USERS, udwUsers });

export const setSSOInfo = (ssoInfo) => ({ type: SET_SSO_INFO, ssoInfo });

export const findLegacyUsers = (username) => ({ type: FIND_LEGACY_USERS, username });
export const setLegacyUsers = legacyUsers => ({ type: SET_LEGACY_USERS, legacyUsers });

export const loginADFS = (payload) => ({ type: LOGIN_ADFS, payload});

export const easyPaySSO = (payload) => ({ type: EASYPAY_SSO, payload});

export const getEmailTemplates = (appLabel, t) => { return { type: GET_EMAIL_TEMPLATES, appLabel, t } };
export const setEmailTemplates = (emailTemplates) => { return { type: SET_EMAIL_TEMPLATES, emailTemplates } };

export const getEmailTemplateById = (emailTemplateId, appLabel, t) => { return { type: GET_EMAIL_TEMPLATE_BY_ID, emailTemplateId, appLabel, t } };
export const setEmailTemplateById = (emailTemplate) => { return { type: SET_EMAIL_TEMPLATE_BY_ID, emailTemplate } };

export const countEmailTemplates = () => { return { type: COUNT_EMAIL_TEMPLATES } };
export const setCountEmailTemplates = (nEmailTemplates) => { return { type: SET_COUNT_EMAIL_TEMPLATES, nEmailTemplates } };

export const createEmailTemplate = (udwData, appData, appLabel) => { return { type: CREATE_EMAIL_TEMPLATE, udwData, appData, appLabel } };
export const updateEmailTemplate = (udwData, appData, appLabel) => { return { type: UPDATE_EMAIL_TEMPLATE, udwData, appData, appLabel } };
export const deleteEmailTemplate = (emailTemplateId, appLabel) => { return { type: DELETE_EMAIL_TEMPLATE, emailTemplateId, appLabel } };
export const stopEditingEmailTemplates = () => { return { type: STOP_EDITING_EMAIL_TEMPLATES } };

export const sendEmail = (email, files, msgVariables) => { return { type: SEND_EMAIL, email, files, msgVariables } }