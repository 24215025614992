import * as actions from "../actions";

const initialState = {
  baseWidgets: [],
  isUpdated: false,

  isSavingPuzzleItems: false,
  puzzleItems: [],
  puzzleItemsByOrgId: [],
  isProductionMode: true,

  configuration: null,
  authModes: null,

  isLoadingConfiguration: false,
  successRetrieveConfiguration: false,

  imageConfiguration: "",
  colorsStyleSheet: {}
};

export const adminConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_BASE_WIDGETS: {
      return {
        ...state,
        baseWidgets: action.data,
      };
    }
    case actions.SAVE_PUZZLE_ITEMS: {
      return { ...state, isSavingPuzzleItems: true };
    }
    case actions.SET_PUZZLE_ITEMS: {
      return { ...state, isSavingPuzzleItems: false, puzzleItems: action.data };
    }
    case actions.SET_PUZZLE_ITEMS_BY_ORG_ID: {
      return { ...state, puzzleItemsByOrgId: action.data };
    }
    case actions.CLEAR_PUZZLE_ITEMS: {
      return {
        ...state,
        puzzleItems: initialState.puzzleItems,
        puzzleItemsByOrgId: initialState.puzzleItemsByOrgId,
      };
    }

    case actions.SET_UPDATED: {
      return {
        ...state,
        isUpdated: action.updated,
      };
    }

    case actions.REQUEST_CONFIGURATION:
      return { ...state, isLoadingConfiguration: true };
    case actions.REQUEST_STYLESHEET_CONFIGURATION:
      return { ...state };
    case actions.SET_STYLESHEET_CONFIGURATION:
      return { ...state, colorsStyleSheet: action.stylesheetConfiguration };
    case actions.SET_CONFIGURATION:
      return {
        ...state,
        successRetrieveConfiguration: action.data.success,
        configuration: action.data.configuration,
      };
    case actions.SET_IMAGE_CONFIGURATION: {
      return {
        ...state,
        imageConfiguration: action.imageConfiguration,
        isLoadingConfiguration: false,
      };
    }
    case actions.SET_AUTH_MODES: {
      return {
        ...state,
        authModes: action.authModes,
      };
    }

    default:
      return state;
  }
};
