import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Tabs, Tab, Badge } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import { useHistory } from "react-router-dom";
import "../portal.scss";
import "~/assets/css/scrollbar.css";

import {
  redirectUserTo,
  getRequestsByUsername,
} from "~/store/ducks/portalrh/actionTypes";
import { AuthorizationState, AuthorizerApproves } from "../TabsEnum";

import RequestItem from "../RequestItem";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import { withNamespaces } from "react-i18next";
import CustomDropdown from "~/components/widgets/CustomDropdown";
import { updateUserWidget } from "~/pages/User/actions";
const devLogConsole = require("~/utils/devLog");
const AuthorizationsWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;

  const { allMenus } = useSelector((state) => state.portalRhMenuReducer);

  const { user } = useSelector((state) => state.globalReducer);

  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  )?.username;

  const [authorizationKey, setAuthorizationKey] = useState(
    AuthorizationState.PENDING
  );

  let pendingAuthorizationValues = useState([]);
  let otherAuthorizationValues = useState([]);

  let pendingAuthorizations = useState();
  let otherAuthorizations = useState();

  let nPendingAuthorizations = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { pathToRedirect, requests, isLoadingRequests } = useSelector(
    (state) => state.portalrhReducer
  );

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    dispatch(getRequestsByUsername(usernamePortalRh));
  }, []);

  useInterval(() => {
    dispatch(getRequestsByUsername(usernamePortalRh));
  }, 1000 * 60);

  /*Send Context to iframe*/
  useEffect(() => {
    if (pathToRedirect) {
      if (document.getElementById("portalrh")) {
        /*Display iframe*/
        document.getElementById("portalrh").style.display = "block";
      }
      let config = configuration?.loginPage;
      const context = {
        theme:
          config === "nblp"
            ? "nblp"
            : userSettings.theme
            ? userSettings.theme
            : "default",
        language: userSettings.language ? userSettings.language : "EN",
        location: "",
      };
      try {
        const object = JSON.parse(pathToRedirect);
        if (object.action && object.url) {
          history.push(`/uniksystem/portalrh/${object.url}`);
          context.location = object.action;
        }
      } catch (exception) {
        history.push(`/uniksystem/portalrh/${pathToRedirect}`);
        context.location = "fp_folha_ponto";
      }

      let event = new CustomEvent("UserContextEvent", { detail: context });
      if (document.getElementById("portalrh")) {
        if (document.getElementById("portalrh").contentWindow) {
          document
            .getElementById("portalrh")
            .contentWindow.dispatchEvent(event);
        }
      }
    }

    return () => {
      dispatch(redirectUserTo(""));
    };
  }, [pathToRedirect]);

  const processRequests = (approval) => {
    const requestsByState = requests.filter(
      (request) =>
        request.authorizer_approves === approval &&
        (request.action_state === 0 || request.action_state === 1) &&
        request.state_id === approval
    );

    let descriptions = [];
    let quantities = [];
    let flowId = {};

    let countValidRequests = 0;

    for (let i = 0; i < requestsByState.length; i++) {
      let descriptionAdded = false;
      countValidRequests += 1;

      for (let j = 0; j < descriptions.length; j++) {
        if (requestsByState[i].description === descriptions[j]) {
          descriptionAdded = true;
          quantities[j] = quantities[j] + 1;
        }
      }
      if (!descriptionAdded) {
        descriptions.push(requestsByState[i].description);
        quantities.push(1);
      }
      flowId[requestsByState[i].description] = requestsByState[i].flow_id;
    }

    //convert to object
    const requestItems = quantities.reduce(function (result, field, index) {
      result[descriptions[index]] = field;
      return result;
    }, {});

    return [requestItems, countValidRequests, flowId];
  };

  const getRequestItems = (requestValues, textColor, type, state, flowId) => {
    return (
      <div>
        {Object.keys(requestValues).map((description, index) => (
          <RequestItem
            key={description}
            description={description}
            num={Object.values(requestValues)[index]}
            textColor={textColor}
            allMenus={allMenus}
            type={type}
            t={t}
            state={state}
            flowId={flowId[description]}
          />
        ))}
      </div>
    );
  };

  if (Object.keys(requests).length > 0 && Array.isArray(requests)) {
    pendingAuthorizationValues = processRequests(1);
    otherAuthorizationValues = processRequests(0);

    pendingAuthorizations = getRequestItems(
      pendingAuthorizationValues[0],
      "text-info",
      "aut_ac_",
      AuthorizerApproves.YES,
      pendingAuthorizationValues[2]
    );
    otherAuthorizations = getRequestItems(
      otherAuthorizationValues[0],
      "text-secondary",
      "aut_ac_",
      AuthorizerApproves.NO,
      otherAuthorizationValues[2]
    );

    nPendingAuthorizations =
      pendingAuthorizationValues[1] === 0 ? "" : pendingAuthorizationValues[1];
  } else {
    pendingAuthorizationValues = [];
    otherAuthorizationValues = [];

    pendingAuthorizations = (
      <div className="pl-3">{t("portalrh.requests.noRequests")}</div>
    );
    otherAuthorizations = (
      <div className="pl-3">{t("portalrh.requests.noRequests")}</div>
    );

    nPendingAuthorizations = "";
  }

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat" style={{ padding: "2rem" }}>
        <Card.Header className="justify-content-between">
        <h6 className="h6old">
            <i className="icon-bell mr-2" />
            {t("AuthorizationsWidget.title")}
          </h6>
          <h6 className="h6old">
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>

        {!isResizing && (
          <Card.Body>
            {isLoadingRequests === "true" ? (
              <SpinnerNew open={isLoadingRequests === "true"} />
            ) : (
              <Tabs
                className="custom-tabs"
                id="authorizationsTab"
                defaultActiveKey="first"
                activeKey={authorizationKey}
                onSelect={(k) => setAuthorizationKey(k)}
              >
                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.authorizationsToApprove") + " "}
                      <Badge pill className="text-white  bg-info">
                        {nPendingAuthorizations}
                      </Badge>
                    </React.Fragment>
                  }
                  eventKey={AuthorizationState.PENDING}
                >
                  {pendingAuthorizations}
                </Tab>
                <Tab
                  mountOnEnter={true}
                  className="scrollable"
                  title={
                    <React.Fragment>
                      {t("portalrh.requests.otherAuthorizations") + " "}
                    </React.Fragment>
                  }
                  eventKey={AuthorizationState.SEEN}
                >
                  {otherAuthorizations}
                </Tab>
              </Tabs>
            )}
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default withNamespaces()(AuthorizationsWidget);
