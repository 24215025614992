import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpenAIConfiguration,
  persistOpenAIConfiguration,
  deleteOpenAiConfiguration,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ButtonAdd from "~/components/Buttons/AdicionarBtn";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  generatePrompt,
  setGeneratePrompt,
} from "~/store/ducks/openai/actionTypes";
import OpenAiDocumentConfiguration from "./OpenAiDocumentConfiguration";
import SpinnerNew from "~/components/SpinnerNew";

const OpenAIConfiguration = (props) => {
  const { t, organizationId } = props;
  const dispatch = useDispatch();
  const { openAIConfiguration, successPersistOpenAIConfiguration, successDeleteOpenaiDocument } =
    useSelector((state) => state.rhadminConfigurationReducer);
  const { isLoadingSaveDocumentExpenses, generatePromptResult } = useSelector(
    (state) => state.openaiReducer
  );

  const [openAiConfigurationState, setOpenAiConfigurationState] = useState([]);
  const [openAIID, setOpenAIID] = useState("");
  const [openAIIDChat, setOpenAIIDChat] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = React.useState("1");
  const [indexSelected, setIndexSelected] = useState(null);
  const [alertModal, setAlertModal] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severityMessage, setSeverityMessage] = useState("error");

  useEffect(() => {
    setOpenAiConfigurationState([]);
    setOpenAIID("");
    setOpenAIIDChat("");
    setIsEditing(false);
    setValue("1");
    setIndexSelected(null);
  }, []);

  useEffect(() => {
    if (organizationId) dispatch(getOpenAIConfiguration(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (openAIConfiguration) {
      setOpenAiConfigurationState(openAIConfiguration.datasets || []);
      setOpenAIID(openAIConfiguration.extractionApiKey);
      setOpenAIIDChat(openAIConfiguration.chatApiKey);
    }
  }, [openAIConfiguration]);

  useEffect(() => {
    if (Object.keys(openAiConfigurationState).length > 0) {
      openAiConfigurationState.forEach((config, index) => {
        handleUpdate(index, "documentType", config.documentType);
        handleUpdate(index, "correction", config.correction);
        handleUpdate(index, "preview", config.preview);
        handleUpdate(index, "extraction", config.extraction);
        handleUpdate(index, "flowId", config.flowId);
        handleUpdate(index, "prompt", config.prompt);
        handleUpdate(index, "fields", config.fields);
        handleUpdate(index, "documentDescription", config.documentDescription);
      });
    }
  }, []);

  const handleUpdate = (index, key, value) => {
    setOpenAiConfigurationState((prevState) => {
      const newState = [...prevState];
      newState[index][key] = value;
      return newState;
    });
  };

  useEffect(() => {
    if (successPersistOpenAIConfiguration) setIsEditing(false);
    if (successDeleteOpenaiDocument) {
      setAlertModal(true);
      setMessageAlert("Documento eliminado com sucesso")
      setSeverityMessage("success")
    }
  }, [successPersistOpenAIConfiguration, successDeleteOpenaiDocument]);

  const handleSave = () => {
    const fieldsToCheck = [
      "documentType",
      "flowId",
      "prompt",
      "fields",
      "documentDescription",
    ];
    const hasEmptyField = openAiConfigurationState.some((item) =>
      fieldsToCheck.some(
        (field) =>
          item[field] === "" ||
          item[field] === null ||
          item[field] === undefined
      )
    );

    if (hasEmptyField) {
      setAlertModal(true);
      setMessageAlert("Preencha os campos obrigatórios (Tipo de Documento, Descrição do Documento, ID do Fluxo, Prompt e Campos)")
      setSeverityMessage("error")
    }else {
      const documentTypes = openAiConfigurationState.map(item => item.documentType.trim());
      const hasDuplicate = documentTypes.some((type, index) => documentTypes.includes(type, index + 1));
    
      if (hasDuplicate) {
        setAlertModal(true);
        setMessageAlert("Esse tipo de documento já existe")
        setSeverityMessage("error")
      } else {
        const payload = {
          id: openAIConfiguration && openAIConfiguration?.id ? openAIConfiguration.id : null,
          extractionApiKey: openAIID,
          chatApiKey: openAIIDChat,
          organization_id: organizationId,
          active: true,
          datasets: openAiConfigurationState,
        };
        dispatch(persistOpenAIConfiguration(payload));
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteDoc = (docIndex) => {
    // Cria um novo array sem o item no docIndex
    const newOpenAiConfigurationState = openAiConfigurationState.filter(
      (_, index) => index !== docIndex
    );

    setOpenAiConfigurationState(newOpenAiConfigurationState);
    dispatch(deleteOpenAiConfiguration(docIndex));
  };

  const addNewDoc = () => {
    setOpenAiConfigurationState((configs) => [
      ...configs,
      {
        documentType: "",
        documentDescription: "",
        flowId: "",
        correction: false,
        extraction: false,
        preview: false,
        fields: "",
        prompt: "",
        active: true,
      },
    ]);
  };

  useEffect(() => {
    if (generatePromptResult !== "") {
      handleUpdate(indexSelected, "prompt", generatePromptResult);
      dispatch(setGeneratePrompt(""));
      setIndexSelected(null);
    }
  }, [generatePromptResult, indexSelected]);

  const handlePromptButtonClick = (index) => {
    const item = openAiConfigurationState[index];

    const text = item.fields;

    const lines = text.split(";");
    const newItems = {};

    for (let line of lines) {
      const parts = line.split(":");
      if (parts.length !== 2) continue;

      const key = parts[0].trim();
      const value = parts[1].trim();

      if (value === "") continue;

      newItems[key] = value;
    }
    dispatch(generatePrompt(newItems, item.documentDescription));
    setIndexSelected(index);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertModal(false);
    setMessageAlert("")
  };

  const renderOpenAiDocumentConfiguration = () => {
    return (
      openAiConfigurationState &&
      openAiConfigurationState.map((config, index) => {
        return (
          <OpenAiDocumentConfiguration
            setIsEditing={setIsEditing}
            setOpenAIID={setOpenAIID}
            openAiConfigurationState={openAiConfigurationState[index]}
            handleUpdate={(key, value) => handleUpdate(index, key, value)}
            openAIID={openAIID}
            deleteDoc={deleteDoc}
            index={index}
            handlePromptButtonClick={handlePromptButtonClick}
          />
        );
      })
    );
  };
  return (
    <>
      <SpinnerNew open={isLoadingSaveDocumentExpenses} />
      <Snackbar
        open={alertModal}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severityMessage}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Form.Row style={{ margin: "20px" }}>
        <Col sm="12" md="12" lg="12">
          <Form.Group controlId="validationCustom">
            <Form.Label style={{ marginTop: "20px" }}>
              <h5>Open AI Configuração</h5>
            </Form.Label>
            <br />
            <br />
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Documentos"
                      style={{ padding: "12px 16px" }}
                      value="1"
                    />
                    <Tab
                      label="Assistant"
                      style={{ padding: "12px 16px" }}
                      value="2"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Stack spacing={2} direction="row">
                    <Form.Group
                      controlId="openAIID"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Form.Label>API KEY</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ marginLeft: "15px", width: "500px" }}
                        value={openAIID}
                        onChange={(e) => {
                          setOpenAIID(e.target.value);
                          setIsEditing(true);
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <ButtonAdd text="New document type" onClick={addNewDoc} />
                  </Stack>
                  {renderOpenAiDocumentConfiguration()}
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <Form.Group
                    controlId="openAIIDChat"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Form.Label>API KEY</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ marginLeft: "15px", width: "500px" }}
                      value={openAIIDChat}
                      onChange={(e) => {
                        setOpenAIIDChat(e.target.value);
                        setIsEditing(true);
                      }}
                      autoComplete="off"
                    />
                  </Form.Group>
                </TabPanel>
              </TabContext>
            </Box>
            {isEditing && (
              <Button className="card-button" onClick={() => handleSave()}>
                {t("admin.organization.settings.saveConfig")}
              </Button>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
    </>
  );
};

export default withNamespaces()(OpenAIConfiguration);
