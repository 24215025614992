import React from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AlertTitle from "@mui/material/AlertTitle";
import { Alert, Row, Col } from "react-bootstrap";
import Chip from "@mui/material/Chip";

import TextField from "@mui/material/TextField";

const Situation = ({ t }) => {
  const { isLoadingMarcacaoFerias, marcacaoFeriasCalendario } = useSelector(
    (state) => state.portalrhReducer
  );

  const VacationInfoField = ({ label, value }) => (
    <span style={{ fontSize: "small", display: "flex" }}>
      <TextField
        id={`outlined-${label}`}
        label={label}
        variant="outlined"
        size="small"
        disabled
        InputLabelProps={{
          style: { color: "inherit" },
        }}
        InputProps={{
          style: { color: "inherit" },
        }}
      />
      <Chip label={value} />
    </span>
  );

  const fields = [
    {
      label: t("portalrh.workDays.situation.dFerias"),
      value: marcacaoFeriasCalendario.dFerias,
    },
    {
      label: t("portalrh.workDays.situation.assiduidade"),
      value: marcacaoFeriasCalendario.assiduidade,
    },
    {
      label: t("portalrh.workDays.situation.dGozarAnoAnterior"),
      value: marcacaoFeriasCalendario.dGozarAnoAnterior,
    },
    {
      label: t("portalrh.workDays.situation.outros"),
      value: marcacaoFeriasCalendario.outros,
    },
    {
      label: t("portalrh.workDays.situation.cAnoCorrente"),
      value: marcacaoFeriasCalendario.cAnoCorrente,
    },
    {
      label: t("portalrh.workDays.situation.cAnoAnterior"),
      value: marcacaoFeriasCalendario.cAnoAnterior,
    },
    {
      label: t("portalrh.workDays.situation.sPendente"),
      value: marcacaoFeriasCalendario.sPendente,
    },
    {
      label: t("portalrh.workDays.situation.gAnoAnterior"),
      value: marcacaoFeriasCalendario.gAnoAnterior,
    },
    {
      label: t("portalrh.workDays.situation.gAnoCorrente"),
      value: marcacaoFeriasCalendario.gAnoCorrente,
    },
    {
      label: t("portalrh.workDays.situation.totaisFerias"),
      value: marcacaoFeriasCalendario.totaisFerias,
    },
    {
      label: t("portalrh.workDays.situation.porGozar"),
      value: marcacaoFeriasCalendario.porGozar,
    },
  ];

  return (
    <Accordion style={{ background: "whitesmoke" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="iconExpandMoreIcon" />}
        aria-controls="panelfirst"
        id="panelfirst"
      >
        {t("portalrh.workDays.situation.situation")}
      </AccordionSummary>
      <AccordionDetails className="scrollBar-autorizersDateInformation">
        {!isLoadingMarcacaoFerias &&
        Object.keys(marcacaoFeriasCalendario).length > 0 ? (
          <Alert style={{ margin: "0px", display: "grid" }}>
            <Row>
              <Col md="6">
                {fields.slice(0, 4).map((field, index) => (
                  <VacationInfoField
                    key={index}
                    label={field.label}
                    value={field.value}
                  />
                ))}
              </Col>
              <Col md="6">
                {fields.slice(4, 9).map((field, index) => (
                  <VacationInfoField
                    key={index}
                    label={field.label}
                    value={field.value}
                  />
                ))}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <VacationInfoField
                  label={fields[9].label}
                  value={fields[9].value}
                />
              </Col>
              <Col md="6">
                <VacationInfoField
                  label={fields[10].label}
                  value={fields[10].value}
                />
              </Col>
            </Row>
          </Alert>
        ) : (
          <Alert style={{ margin: "0px", display: "grid" }}>
            <span style={{ fontSize: "small", display: "flex" }}>
              {t("portalrh.workDays.noSituationInformation")}
            </span>
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default withNamespaces()(Situation);
