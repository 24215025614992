import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { signInSAML } from "./actions";
import SpinnerNew from "~/components/SpinnerNew";

const SAMLLogin = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSignInSAML, isSAMLAuthenticated } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    const query = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    dispatch(signInSAML(query.payload));
  }, []);

  useEffect(() => {
    history.push("/");
  }, [isSAMLAuthenticated]);

  return <SpinnerNew open={isSignInSAML} />;
};

export default SAMLLogin;
