import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Notification from "./Notification";
import { withNamespaces } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SpinnerNew from "~/components/SpinnerNew";
import {
  markAsSeen,
  deleteNotification,
} from "~/store/ducks/notifications/actionTypes";
import { BsBellFill } from "react-icons/bs";
import "../notifications.scss";

const NotificationModal = ({
  t,
  showModal,
  closeModal,
  legacyNotifications,
  styleSheet,
}) => {
  const dispatch = useDispatch();
  const { isDeletingNotification } = useSelector(
    (state) => state.notificationsReducer
  );

  useEffect(() => {
    if (document.getElementById("globalsolver"))
      document.getElementById("globalsolver").style.display = "none";
  });

  const renderNotifications = () => {
    if (
      legacyNotifications &&
      Object.keys(legacyNotifications).length > 0 &&
      Array.isArray(legacyNotifications)
    ) {
      let count = 0;
      return legacyNotifications.map((item, index) => {
        if (count < legacyNotifications.length) {
          count++;

          return (
            <Notification
              key={index}
              item={item}
              countItemNotSeen={count}
              handleSeen={handleSeen}
              handleDelete={handleDelete}
              styleSheet={styleSheet}
            />
          );
        }
      });
    }
  };

  const handleSeen = (item) => {
    dispatch(markAsSeen(item.id));
  };

  const handleDelete = (item) => {
    dispatch(deleteNotification(item.id));
  };

  return (
    <>
      <Modal
        dialogClassName={
          styleSheet?.notificationsCma ? "modal-notification" : "custom-modal"
        }
        show={showModal}
        onHide={() => closeModal()}
        centered
        backdrop="static"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {styleSheet?.notificationsCma ? (
              <h6 style={{ color: "#24579e" }}>
                <BsBellFill
                  style={{ marginRight: "10px", color: "#24579e" }}
                  size={20}
                />
                {t("notifications.title")}
              </h6>
            ) : (
              <h6>
                <i className="icon-unik3 mr-2" />
                {t("notifications.title")}
              </h6>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={styleSheet?.notificationsCma ? "scrollBar-visible" : ""}
          style={
            styleSheet?.notificationsCma
              ? {
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  borderRadius: "unset",
                }
              : {}
          }
        >
          <div>{renderNotifications()}</div>
        </Modal.Body>
        {styleSheet?.notificationsCma ? "" : <Modal.Footer></Modal.Footer>}
      </Modal>

      <SpinnerNew open={isDeletingNotification} />
    </>
  );
};
export default withNamespaces()(NotificationModal);
