import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { Accordion } from "react-bootstrap";
import SidebarContainer from "~/containers/SidebarContainer";
import ApplicationMenuItem from "~/containers/Menus/components/ApplicationMenuItem";
import SpinnerNew from "~/components/SpinnerNew";

import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
const devLogConsole = require("~/utils/devLog");
const ApplicationMenu = ({ applicationName, showMenu = true }) => {

  const dispatch = useDispatch();

  const {
    isLoadingMenu,
    successDelete,
    successEdit
  } = useSelector(state => state.applicationMenuReducer);

  useEffect(() => {
    if (applicationName) {
      dispatch(findMenuByApplicationName(applicationName));
    }
  }, []);

  useEffect(() => {
    if ((successEdit || successDelete) && applicationName) {
      dispatch(findMenuByApplicationName(applicationName));
    }
  }, [successDelete, successEdit]);

  const [mainActiveKey, setMainActiveKey] = useState("")
  //devLogConsole("mainActiveKey", mainActiveKey)
  return (
    <SidebarContainer>
      <Accordion className="menu" id={`app-${applicationName}`} activeKey={mainActiveKey}>
        {
          !isLoadingMenu &&
          <ApplicationMenuItem
            applicationName={applicationName}
            showMenu={showMenu}
            setMainActiveKey={setMainActiveKey}
            mainActiveKey={mainActiveKey}
          />
        }
        <SpinnerNew open={isLoadingMenu} />
      </Accordion>
    </SidebarContainer>
  )
}
export default withNamespaces()(ApplicationMenu)
