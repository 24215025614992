import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import IntegrationColaboratorsTable from "../components/IntegrationCollaborators/IntegrationCollaboratorsTable";
import IntegrationColaboratorsFilters from "../components/IntegrationCollaborators/IntegrationCollaboratorsFilters";
import IntegrationCollaboratorsConfigureTable from "../components/IntegrationCollaborators/IntegrationCollaboratorsConfigureTable";
import {
  getCRUDCollaborators,
  getCRUDCompanies,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";

const IntegrationColaborators = ({ t, isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const { collaborators_CRUD, isLoadingCRUDCollaboratos, companies_CRUD } =
    useSelector((state) => state.budgetManagementCRUDReducer);
  const [usersToConfigure, setUsersToConfigure] = useState([]);
  const [textColaboradores, setTextColaboradores] = useState("");
  const [filterOption, setFilterOption] = useState("Todos");

  useEffect(() => {
    dispatch(getCRUDCollaborators());
    dispatch(getCRUDCompanies());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDCollaborators());
  };

  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDCollaboratos} />
          {Object.keys(collaborators_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6> {textColaboradores}</h6>
                </Card.Header>
                <Card.Body>
                  {usersToConfigure.length > 0 ? (
                    <IntegrationCollaboratorsConfigureTable
                      usersToConfigure={usersToConfigure}
                      collaboratorsData={collaborators_CRUD}
                      setTextColaboradores={setTextColaboradores}
                      setUsersToConfigure={setUsersToConfigure}
                    />
                  ) : (
                    <>
                      <IntegrationColaboratorsFilters
                        filterOption={filterOption}
                        setFilterOption={setFilterOption}
                      />
                      <IntegrationColaboratorsTable
                        app={"bpm"}
                        collaboratorsData={collaborators_CRUD}
                        setUsersToConfigure={setUsersToConfigure}
                        handleRefresh={handleRefresh}
                        setTextColaboradores={setTextColaboradores}
                        filterOption={filterOption}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withNamespaces()(IntegrationColaborators);
