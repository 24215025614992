import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getPuzzleItems } from "../Admin/SystemSettings/actions";
import { requestStylesheetConfiguration } from "~/pages/Admin/SystemSettings/actions";
import { hasPermissions, hasRoles } from "~/components/security/utils";
import { requestUser } from "~/containers/Layout/actions";
import { findMenuByApplicationName } from "~/store/ducks/applicationMenu/actionTypes";
import { applyColors } from "~/utils";
import {
  getWindowsAuthConfig,
  getWindowsAuthConfigIflow,
  getWindowsAuthConfigSignPdf,
  getKeycloakAuthConfig,
  setKeycloackAuthConfig,
  checkIsIntranetLogin,
} from "./actions/index";
import { ROLE_ADMIN, ROLE_ORG_ADMIN } from "~/utils/constants/roles";
import SpinnerNew from "~/components/SpinnerNew";
import oauth from "~/utils/oauth";

import LoginForm from "./components/LoginForm";

import { getPuzzleAccess } from "~/components/Puzzle/utils";
import { useKeycloak } from "@react-keycloak/web";

const Login = (props) => {
  const { isLoggedKeycloak, isLoading, isCma } = props;

  const { isAuthenticated, isFromIntranet } = useSelector(
    (state) => state.loginReducer
  );
  const { puzzleItems } = useSelector((state) => state.adminConfigReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const [firstPuzzleItem, setFirstPuzzleItemWithAccess] = useState(null);
  const history = useHistory();

  const { authWindows, failCredentials } = useSelector(
    (state) => state.loginReducer
  );
  const { authKeycloak } = useSelector((state) => state.loginReducer);
  const { unikUserFromlegacyUsername } = useSelector(
    (state) => state.loginReducer
  );
  const { colorsStyleSheet } = useSelector((state) => state.adminConfigReducer);

  const dispatch = useDispatch();
  const { keycloak, authenticated } = useKeycloak();

  const [keycloakLoginClicked, setKeycloakLoginClicked] = useState(false);
  const [windowsLoginClicked, setWindowsLoginClicked] = useState(false);
  const [isWaitingForWindowsResponse, setIsWaitingForWindowsResponse] =
    useState(false);
  const [isForcedAuthenticated, setIsForceAuthenticated] = useState(false);


  useEffect(() => {
    if (Object.keys(colorsStyleSheet).length > 0) applyColors(colorsStyleSheet);
  }, [colorsStyleSheet]);

  // useEffect(() => {

  //   var allCookies = document.cookie.split(';');

  //   // The "expire" attribute of every cookie is
  //   // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  //   for (var i = 0; i < allCookies.length; i++)
  //       document.cookie = allCookies[i] + "=;expires="
  //       + new Date(0).toUTCString();

  //     allCookies.innerHTML = document.cookie;
  //     window.location = ""; // TO REFRESH THE PAGE

  // }, [])

  useEffect(() => {
    if (oauth.get("jwtToken")) {
      setIsForceAuthenticated(true);
      dispatch(getPuzzleItems());
      dispatch(requestUser());
    } else {
      setIsForceAuthenticated(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getPuzzleItems());
      dispatch(requestUser());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (!isLoggedKeycloak) {
      if (keycloakLoginClicked && authKeycloak === undefined) {
        dispatch(getKeycloakAuthConfig({}));
      } else if (authKeycloak) {
        setTimeout(function () {
          if (!keycloak.authenticated || keycloak.authenticated == undefined) {
            keycloak.init().then((authenticated) => {
              keycloak.login();
            });
          }
        });
      }
    } else {
      setTimeout(function () {
        let login = keycloak.idTokenParsed.preferred_username;
        let uuid = "Xpto456!";

        let payload = {
          isToSkipConfigs: true,
          login: login,
          uuid: uuid,
          history: history,
          platform: "keycloak",
        };
        dispatch(setKeycloackAuthConfig(payload));
        dispatch(getWindowsAuthConfig(payload));
      });
    }
  }, [authKeycloak, keycloakLoginClicked, dispatch]);

  useEffect(() => {
    if (isCma && failCredentials) {
      setTimeout(function () {
        if (!keycloak.authenticated || keycloak.authenticated == undefined) {
          keycloak.init().then((authenticated) => {
            keycloak.login();
          });
        }
      });
    }
  }, [failCredentials]);

  useEffect(() => {
    let auxAuthWindows = authWindows;

    const queryParams = new URLSearchParams(window.location.search);
    const isWindowsAuthParam = queryParams.get("iswindows");

    if (isWindowsAuthParam !== null && isWindowsAuthParam !== undefined) {
    } else {
      if (!isFromIntranet) {
        if (windowsLoginClicked && authWindows === undefined) {
          const alreadyRefreshed = queryParams.get("alreadyRefreshed");

          if (alreadyRefreshed == null || alreadyRefreshed == undefined) {
            // Get the current URL
            var url = new URL(window.location.href);
            // Add a new query parameter to the URL
            queryParams.append("alreadyRefreshed", "true");
            // Construct the new URL with the updated query string
            var newUrl =
              url.origin + url.pathname + "?" + queryParams.toString();
            // Redirect the browser to the new URL
            window.location.href = newUrl;
          }

          setIsWaitingForWindowsResponse(true);

          let payload = {
            isToSkipConfigs: false,
            login: null,
            uuid: null,
            history: history,
            platform: "windows",
          };
          dispatch(
            isCma
              ? getWindowsAuthConfigSignPdf(payload)
              : getWindowsAuthConfigIflow(payload)
          );
        }
      }
    }
  }, [authWindows, windowsLoginClicked, isFromIntranet, dispatch]);

  useEffect(() => {
    if (
      (isAuthenticated || isForcedAuthenticated) &&
      puzzleItems.length !== 0 &&
      Object.keys(user).length !== 0
    ) {
      setIsWaitingForWindowsResponse(false);
      let firstPuzzleItemWithAccess, puzzleItems_;

      //If there is no puzzle items active user is redirected to about page
      if (puzzleItems.some((item) => item.active)) {
        puzzleItems.sort((a, b) => a.nrOrder - b.nrOrder);
        const activePuzzle = puzzleItems.filter((item) => item.active);

        //If user is Admin or OrgAdmin, getPuzzleAccess needs all labels even if inactive
        puzzleItems_ =
          hasRoles(user, [ROLE_ORG_ADMIN]) || hasRoles(user, [ROLE_ADMIN])
            ? puzzleItems
            : activePuzzle;

        //Find first app with puzzle access
        firstPuzzleItemWithAccess = puzzleItems_.find((puzzleItem) => {
          const label = puzzleItem.application.label;
          if (getPuzzleAccess(user, label, user.legacyUsers)) {
            return puzzleItem;
          }
        });

        //When user has no acess to any application he gets redirected to about page
        if (!firstPuzzleItemWithAccess) {
          redirectToAboutPage();
        }
        //If the first application with access is uniksystem user gets redirected to uniksystem page
        if (firstPuzzleItemWithAccess?.application?.label === "uniksystem") {
          redirectToUnikPage();
        } else {
          if (user.username !== "") {
            const app = firstPuzzleItemWithAccess?.application?.label;
            if (app !== undefined && app !== "") {
              dispatch(findMenuByApplicationName(app));
            }
          }
        }
      } else {
        redirectToAboutPage();
      }
      setFirstPuzzleItemWithAccess(firstPuzzleItemWithAccess);
    }
  }, [isAuthenticated, puzzleItems, user, history, unikUserFromlegacyUsername]);

  useEffect(() => {
    if (
      menu &&
      menu[0] &&
      puzzleItems &&
      user.username !== "" &&
      firstPuzzleItem
    ) {
      if (menu[0].application.label === firstPuzzleItem?.application?.label) {
        let menu_ = [];
        menu.forEach((menuItem) => {
          const permissions = menuItem.permissions ? menuItem.permissions : [];
          const isAvailable = () => {
            return hasPermissions(user, permissions);
          };

          if (isAvailable()) menu_.push(menuItem);
        });

        let defaultApllicationRoute = true;
        const handleRoute = (menuItem) => {
          defaultApllicationRoute = false;
          const location = { pathname: `/uniksystem/${menuItem.route}` };
          history.push(location);
        };
        menu_.forEach((menuItem) => {
          if (menuItem.extraConfiguration) {
            const extraConfiguration_ = JSON.parse(
              menuItem.extraConfiguration || false
            );
            if (window.innerWidth < 992) {
              const mobileApplicationRoute_ = JSON.parse(
                (extraConfiguration_ &&
                  extraConfiguration_.mobileApplicationRoute) ||
                  false
              );
              if (mobileApplicationRoute_) {
                handleRoute(menuItem);
              }
            } else {
              const applicationRoute_ = JSON.parse(
                (extraConfiguration_ && extraConfiguration_.applicationRoute) ||
                  false
              );
              if (applicationRoute_) {
                handleRoute(menuItem);
              }
            }
          }
        });
        if (defaultApllicationRoute) {
          const payload = {
            pathname: `/uniksystem/${firstPuzzleItem?.application?.label}`,
            state: { name: firstPuzzleItem?.application?.label },
          };
          history.push(payload);
        }
      }
    }
  }, [menu, puzzleItems, user, history, firstPuzzleItem]);

  const redirectToAboutPage = () => {
    const payload = {
      pathname: `/uniksystem/about`,
      state: { name: "Uniksystem" },
    };
    history.push(payload);
  };

  const redirectToUnikPage = () => {
    const payload = {
      pathname: `/uniksystem`,
      state: { name: "Uniksystem" },
    };
    history.push(payload);
  };

  return windowsLoginClicked || isLoading ? (
    <SpinnerNew open={windowsLoginClicked || isLoading} />
  ) : (
    <LoginForm
      setKeycloakLoginClicked={setKeycloakLoginClicked}
      setWindowsLoginClicked={setWindowsLoginClicked}
    />
  );
};

export default withRouter(Login);
