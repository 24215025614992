import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import SpinnerNew from "~/components/SpinnerNew";
import oauth from "~/utils/oauth";
import {
  saveDocumentIntoBatchOpenAi,
  clearDocumentToBatchOpenAi,
  startDocumentToBatchOpenAi,
  stopDocumentToBatchOpenAi,
  deleteBatch,
} from "~/pages/DataCapture/actions";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import {
  saveFlowIDPidBatch,
  postIflowFormExpenses,
  setAlertDocumentExpenses,
  setPidExpenses,
} from "~/store/ducks/openai/actionTypes";

const GeneralDocumentOpenAi = ({
  selectedDocType,
  datasetSelected,
  handleCloseUploadModalOpenAi,
  experiencedUser,
  t,
}) => {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const dispatch = useDispatch();

  const [onDragOver, setOnDragOver] = useState(false);
  const [maxFileSize, setMaxFileSize] = useState(1024 * 1024 * 15);
  const [maxFiles, setMaxFiles] = useState(experiencedUser ? 100 : 1);
  const [documents, setDocuments] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [documentName, setDocumentName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlertDocumentExpense, setMessageAlertDocumentExpense] =
    useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const inputFile = useRef(null);

  const { isUploadingFile, toRemoveFiles, batchOpenAi, isUploadingtoFlow } =
    useSelector((state) => state.dataCaptureReducer);
  const { user } = useSelector((state) => state.globalReducer);

  const { alertDocumentExpense, pidExpenses } = useSelector(
    (state) => state.openaiReducer
  );

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (Object.keys(pidExpenses).length > 0 && pidExpenses.pid) {
      batchOpenAi.documentsOpenai.forEach((document, index, array) => {
        const payload = {
          pid: pidExpenses.pid.toString(),
          flowId: datasetSelected.flowId.toString(),
          documentId: document.id.toString(),
        };
        //dispatch(saveFlowIDPidBatch(payload));
      });
      dispatch(clearDocumentToBatchOpenAi());
    }
  }, [pidExpenses]);

  useEffect(() => {
    if (alertDocumentExpense != "") {
      setOpenAlert(true);
      if (alertDocumentExpense === "success") {
        setMessageAlertDocumentExpense(t(`openai.${alertDocumentExpense}`));
      } else {
        setMessageAlertDocumentExpense(t(`openai.${alertDocumentExpense}`));
      }
      setTypeAlert(alertDocumentExpense);
    }
  }, [alertDocumentExpense]);

  useEffect(() => {
    if (toRemoveFiles) {
      handleRemoveFiles();
    }
  }, [toRemoveFiles]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlertDocumentExpenses(""));
    setOpenAlert(false);
    setMessageAlertDocumentExpense("");
    setTypeAlert("");
    dispatch(setPidExpenses({}));
  };

  const getPreviewImage = () => {
    const file = documents[0];
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : "data:image/jpg;base64," + document.data;
    }
  };

  const handleRemoveFile = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      const removedDocument = updatedDocuments.splice(index, 1)[0];
      if (updatedDocuments.length === 0 && inputFile.current) {
        inputFile.current.value = "";
        dispatch(clearDocumentToBatchOpenAi());
      }
      if (
        batchOpenAi.documentsOpenai &&
        batchOpenAi.documentsOpenai.length > 0
      ) {
        for (const batch of batchOpenAi.documentsOpenai) {
          if (
            batch.documents[0].filename === removedDocument.name &&
            batch.id
          ) {
            dispatch(deleteBatch(batch.id));
          }
        }
      }
      return updatedDocuments;
    });
  };

  const handleRemoveFiles = () => {
    setDocuments([]);
    setDocumentName("");
    dispatch(clearDocumentToBatchOpenAi());
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const handleResetState = () => {
    handleRemoveFiles();
    handleCloseUploadModalOpenAi();
  };

  const handleOnChoose = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) =>
      ["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
        file.type
      )
    );

    if (validFiles.length > 0) {
      handleMultipleFiles(validFiles);
    } else {
      setWarningMessage(t("portalrh.expenses.fileType"));
    }
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleMultipleFiles = (files) => {
    const handleSaveDocsIntoBatch = (files) => {
      dispatch(setPidExpenses({}));
      dispatch(setAlertDocumentExpenses(""));
      setOpenAlert(false);
      setMessageAlertDocumentExpense("");
      setTypeAlert("");
      const id = null;
      const flow = selectedDocType;
      const batchDTO = {
        id,
        flow,
        document: {
          files,
          status: DocumentStatus.HUMAN_REVISION,
        },
        docType: datasetSelected.documentType,
      };
      dispatch(saveDocumentIntoBatchOpenAi(batchDTO));
    };

    let totalfilesize = files.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );
    if (totalfilesize > maxFileSize) {
      setWarningMessage(t("portalrh.expenses.fileSize"));
      return;
    }

    setDocuments((prevDocuments) => [...prevDocuments, ...files]);

    files.forEach((file) => {
      setDocumentName(file.name);
    });
    handleSaveDocsIntoBatch(files);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

    const handleSaveDocs = async () => {
      dispatch(startDocumentToBatchOpenAi());
      const documentIds = [];
      for (const document of batchOpenAi.documentsOpenai) {
        documentIds.push(document.documents[0].id);
      }
      const url = "/startProcess?Authorization=" + oauth.getAccessToken();
      const payload = {
        flowid: datasetSelected.flowId,
        userId: user.id,
        organizationId: user.organization.id,
        documentIds: documentIds,
      };
    
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await dispatch(postIflowFormExpenses(payload, url));
    
      handleRemoveFiles();
      dispatch(stopDocumentToBatchOpenAi());
    };

  const isDragAndDropEnabled = documents.length === 0;

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlertDocumentExpense}
        </Alert>
      </Snackbar>
      <SpinnerNew open={isUploadingFile || isUploadingtoFlow} />
      <div
        className="main-card-v2"
        style={{ display: "block", padding: "0px" }}
      >
        <Card bsPrefix="card-flat">
          <Card.Header
            className="justify-content-between"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={isMobile ? { fontSize: "18px" } : { margin: "0" }}>
              {t("datacapture.general.fileUploadTitle")}
            </h4>
          </Card.Header>
          <Card.Body
            className="scrollBar-visibleModal"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              flexGrow: 1, // Allow the body to grow
              overflowY: "auto", // Add scroll if content overflows
            }}
          >
            <div
              style={{
                margin: "0px 0px 2rem",
                width: "100%",
                alignSelf: isMobile ? "auto" : "center",
              }}
            >
              <div style={{ display: !isMobile ? "flex" : "" }}>
                <div
                  className="dropAreaExpenses"
                  onDrop={(e) => {
                    handleOnDrop(e);
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                  onDragEnter={() => {
                    setOnDragOver(true);
                  }}
                  onDragLeave={() => {
                    setOnDragOver(false);
                  }}
                  style={
                    onDragOver
                      ? { background: "#fff" }
                      : { background: "#efefef" }
                  }
                >
                  <span>
                    <i className={"icon-submit portal-image-upload-icon"} />
                    <div
                      className={"portal-label-main"}
                      style={{ padding: "2rem" }}
                    >
                      {t("portalrh.documentManagement.dropToUpload")}
                    </div>
                  </span>
                  <input
                    multiple={true}
                    id="file"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(e) => handleOnChoose(e)}
                    accept=".pdf, .png,.jpg,.jpeg"
                  />
                </div>
                {isMobile && (
                  <div className="dc-label">
                    {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    width: isMobile ? "100%" : "50%",
                    textAlignLast: "center",
                    marginTop: isMobile ? "1rem" : "",
                    height: "0px",
                  }}
                >
                  {(!isMobile || documents.length > 0) && (
                    <h6 style={{ placeContent: "center", padding: "0px" }}>
                      {t("openai.expenseLoaded")}
                    </h6>
                  )}
                  {documents.length > 0 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(100px, 1fr))",
                        gap: "10px",
                        placeContent: "space-evenly",
                      }}
                    >
                      {documents.map((document, index) => (
                        <div key={index} className="document-preview-container">
                          <img
                            src={getPreviewImage(document)}
                            className={"portal-image-preview-one"}
                            alt="preview"
                          />
                          <CancelIcon
                            color="error"
                            onClick={() => handleRemoveFile(index)}
                          />
                          <OverlayTrigger
                            key={`tooltip-${index}`}
                            placement="bottom"
                            overlay={<Tooltip>{document.name}</Tooltip>}
                          >
                            <small>{abbreviateString(document.name, 28)}</small>
                          </OverlayTrigger>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {!isMobile && (
                <div className="dc-label">
                  {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <div
              style={{
                width: "100%",
                textAlignLast: "right",
                marginTop: "3rem",
              }}
            >
              <Button
                className="card-button"
                disabled={
                  documents.length > 0 && selectedDocType != "" ? false : true
                }
                onClick={() => {
                  documents.length > 0 && handleSaveDocs();
                }}
              >
                {t("openai.upload")}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(GeneralDocumentOpenAi);
