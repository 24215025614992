import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import saml from "~/assets/img/icons/saml-color.svg";
import { withNamespaces } from "react-i18next";
import { Typography } from "@mui/material";
import { Button } from "react-bootstrap";

import * as actions from "../actions";

const SAMLAuthentication = ({ old = false, t }) => {
  const dispatch = useDispatch();

  const { successRetrieveConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  const { isRequestingSAMLUrl, samlRequestUrl, isSignInSAML } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    if (samlRequestUrl) {
      window.open(samlRequestUrl, "_self");
      dispatch(actions.clearSamlRequestUrl());
    }
  }, [samlRequestUrl]);

  const handleLoginSaml = () => {
    dispatch(actions.getSAMLRequestURL());
  };

  return (
    <>
      {old ? (
        <div id="third-auth-button" style={{ textAlign: "center" }}>
          <div className="col-md-12">
            <div className="login-or">
              <hr className="hr-or" />
              <small className="span-or">{t("login.signinWith")}</small>
            </div>
          </div>
          <Button
            variant="outline-light"
            className="login-button"
            style={{ backgroundColor: "#4285f4" }}
            disabled={!successRetrieveConfiguration}
            onClick={handleLoginSaml}
          >
            {isRequestingSAMLUrl || isSignInSAML
              ? t("login.loading")
              : t("login.samlAuth")}
          </Button>
        </div>
      ) : (
        <div
          style={{ textAlign: "center", pointerEvents: "auto" }}
          onClick={handleLoginSaml}
          role="button"
        >
          <img className="logo-windows" alt="SAML" src={saml} />
          <Typography variant="caption" display="block" gutterBottom>
            SAML
          </Typography>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(SAMLAuthentication);
