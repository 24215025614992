// routesToCheck.js
export const routesToCheck = [
  { route: "bpm/delegacoes/gerir", selected: "bpm/delegacoes" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/generic" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/remoteWork" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/insertPresence" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/expense" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/vacations" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/marcacaoFerias" },
  { route: "portalrh/personalarea/frame/Pedidos", selected: "portalrh/personalarea/absenceRecord" },
  { route: "portalrh/personalarea/frame/Minhas%20Informações", selected: "portalrh/personalarea/document-management" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/marcacaoFerias" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/generic" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/remoteWork" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/insertPresence" },
  { route: "portalrh/myteam/frame/Férias Equipa", selected: "portalrh/myteam/generic" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/absenceRecord" },
  { route: "portalrh/myteam/frame/authorizations", selected: "portalrh/myteam/expense" },
  { route: "portalrh/myteam/frame/Férias Equipa", selected: "portalrh/myteam/remoteWork" }
];