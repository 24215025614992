import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Alert, Card, Button, Form, Col } from "react-bootstrap";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import "~/assets/css/icons.css";
import ShedulePreviewCard from "./ShedulePreviewCard";
import {
  uploadSchedule,
  getEntities,
  getLogs,
} from "~/store/ducks/portalrh/actionTypes";
import Dropdown from "~/components/Dropdown";
import { adaptListFoEntities } from "~pages/PortalRH/components/DocumentManagement/handleDropdownList";
import Messages from "~/components/Messages";
import SpinnerNew from "~/components/SpinnerNew";
import SheduleTable from "./SheduleTable";
const devLogConsole = require("~/utils/devLog");
const ExportShedules = ({ t }) => {
  const SUPPORTED_FILES_TYPES = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const { uploadingSchedule, entities, isLoadingEntities, logs, isLoadingLogs } = useSelector(
    (state) => state.portalrhReducer
  );

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [shedule, setShedule] = useState();
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [onDragOver, setOnDragOver] = useState(false);
  const [base64File, setBase64File] = useState("");
  const [selectedEntityId, setSelectedEntityId] = useState("");
  const [resetEntitiesDropdown, setResetEntitiesDropdown] = useState(false);

  const maxFileSize = 1024 * 1024 * 15; //15 Mb

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  });

  useEffect(() => {
    devLogConsole(uploadingSchedule);
  }, [uploadingSchedule]);

  useEffect(() => {
    if (entities.length === 0) {
      dispatch(getEntities());
    }
    dispatch(getLogs());
  }, []);

  useEffect(() => {
    if (shedule !== undefined && shedule !== null) {
      getBase64(shedule, (result) => {
        setBase64File(result.split(";base64,")[1]);
      });
    }
  }, [shedule]);

  function getBase64(file, cb) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      devLogConsole("Error: ", error);
    };
  }

  const resetStates = () => {
    setShowExtraFields(false);
    setShedule(undefined);
    setWarningMessage("");
    setShowWarning(false);
    setOnDragOver(false);
    setSelectedEntityId("");
    setResetEntitiesDropdown(true);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    handleSheduleFile(event.dataTransfer.files[0]);
    event.preventDefault();
  };

  const handleSheduleFile = (file) => {
    if (!SUPPORTED_FILES_TYPES.includes(file.type)) {
      setWarningMessage(t("portalrh.documentManagement.checkFileType"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    } else {
      if (file.size > maxFileSize) {
        setWarningMessage(t("portalrh.documentManagement.exceedFileSize"));
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        setShowExtraFields(true);
        setShedule(file);
      }
    }
  };

  const handleSaveShedule = () => {
    const uploadData = {
      fileName: shedule.name,
      suffix: "xlsx",
      base64File: base64File,
    };
    dispatch(uploadSchedule(uploadData, selectedEntityId));
    resetStates();
  };

  return (
    <div className="main-card-v2" style={{ width: "100%" }}>
      <Card
        bsPrefix="card-flat"
        style={{ minHeight: "80vh", overflow: "auto" }}
      >
        <Card.Header
          className="justify-content-between"
          style={{ marginBottom: "20px" }}
        >
          <h6 className="default-title">
            {t("portalrh.exportSchedule.title")}
          </h6>
        </Card.Header>
        <Card.Body>
          <div style={{ marginLeft: "15%", marginRight: "15%" }}>
            {showWarning && (
              <Alert
                variant="warning"
                onClose={() => setShowWarning(false)}
                dismissible
              >
                <strong>Alert: </strong>
                {warningMessage}
              </Alert>
            )}

            {!showExtraFields ? (
              <>
                <div
                  className="dropArea"
                  onDrop={(e) => handleOnDrop(e)}
                  onClick={() => handleClick()}
                  onDragEnter={() => setOnDragOver(true)}
                  onDragLeave={() => setOnDragOver(false)}
                  style={
                    onDragOver
                      ? { background: "#fff" }
                      : { background: "#efefef" }
                  }
                >
                  <span>
                    <i
                      className={"icon-submit portal-image-upload-icon-active"}
                    />
                    <div className={"portal-label-main-active slide-in-bottom"}>
                      {t("portalrh.documentManagement.dropToUpload")}
                    </div>
                  </span>
                  <input
                    id="file"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(e) => handleSheduleFile(e.target.files[0])}
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="dc-label">
                    {t("portalrh.documentManagement.totalFileSize") +
                      " 15 Mb, " +
                      t("portalrh.documentManagement.maxFiles") +
                      " 1"}
                  </div>
                  <small>{t("portalrh.exportSchedule.youCanUpload")}</small>
                </div>
              </>
            ) : (
              shedule !== undefined &&
              shedule !== null && (
                <div
                  style={{
                    minHeight: "200px",
                    height: "30vh",
                    caretColor: "transparent",
                    overflowY: "scroll",
                    textAlign: "center",
                  }}
                >
                  <ShedulePreviewCard
                    shedule={shedule}
                    resetStates={resetStates}
                  />
                </div>
              )
            )}

            <div style={{ marginTop: "40px", marginBottom: "40px" }}>
              <Form.Row>
                <Col sm={2}>
                  <Form.Label
                    style={{ marginTop: "10px" }}
                    className="default-label"
                  >
                    {t("portalrh.exportSchedule.entity") + ": "}
                  </Form.Label>
                </Col>
                <Col sm={8}>
                  <Dropdown
                    list={adaptListFoEntities(entities)}
                    handleSubmit={setSelectedEntityId}
                    resetDropdown={resetEntitiesDropdown}
                    setResetDropdown={setResetEntitiesDropdown}
                    emptyDefault={true}
                    required
                  />
                </Col>
              </Form.Row>
            </div>

            <div style={{ textAlign: "right", marginTop: "40px" }}>
              <Button
                variant="danger"
                onClick={() => {
                  resetStates();
                }}
              >
                {t("general.cancel")}
              </Button>
              <Button
                className="card-button"
                disabled={
                  shedule !== undefined &&
                    shedule !== null &&
                    selectedEntityId !== undefined &&
                    selectedEntityId !== null &&
                    selectedEntityId !== ""
                    ? false
                    : true
                }
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  shedule !== undefined &&
                    shedule !== null &&
                    selectedEntityId !== undefined &&
                    selectedEntityId !== null &&
                    selectedEntityId !== "" &&
                    handleSaveShedule();
                }}
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
          
          <SheduleTable
            logs={logs}
            isLoadingLogs={isLoadingLogs}
          />

          <SpinnerNew open={uploadingSchedule} />
          <Messages />
        </Card.Body>
      </Card>
    </div>
  );
};
export default withNamespaces()(ExportShedules);
