import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import {
  getCRUDCollabs,
  deleteCRUDCollabs,
  postADDCRUDCollabs,
  updateCRUDCollabs,
  getCRUDCompanies,
  getCRUDCategories,
  getCRUDTeams,
  getCRUDDepartments,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import CollaboratorsTable from "../components/Collaborators/CollaboratorsTable";
import CollaboratorsFilters from "../components/Collaborators/CollaboratorsFilters";

const Collaborators = ({ t, isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const {
    collabs_CRUD,
    isLoadingCRUDCollab,
    companies_CRUD,
    teams_CRUD,
    categories_CRUD,
    departments_CRUD,
  } = useSelector((state) => state.budgetManagementCRUDReducer);

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewCollab, setaddEditNewCollab] = useState(false);
  const [editDeleteCollab, seteditDeleteCollab] = useState(null);
  const [collabsToShow, setcollabsToShow] = useState(collabs_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownCollabs, setcleanDropdownCollabs] = useState(false);
  const [collabFilter, setcollabFilter] = useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditCollab, setSelectedEditCollab] = useState(undefined);
  const [SelectedDeleteCollab, setSelectedDeleteCollab] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [collaboratorsToRender, setcollaboratorsToRender] =
    useState(collabs_CRUD);

  const [selectedCollab, setselectedCollab] = useState({
    empresa: "",
    idColaborador: 0,
    nome: "",
    idCategoria: "0",
    idDepartamento: "0",
    idEquipa: "0",
    estado: "1",
    precoCompra: 0,
    precoVenda: 0,
    usrNumFuncionario: "",
  });

  const resetBackButton = () => {
    setselectedCollab({
      empresa: "",
      idColaborador: 0,
      nome: "",
      idCategoria: "0",
      idDepartamento: "0",
      idEquipa: "0",
      estado: "1",
      precoCompra: 0,
      precoVenda: 0,
      usrNumFuncionario: "",
    });
  };

  const activeArray = [
    {
      estado: t(`budgetManagement.collaborators.ativo`),
      value: 1,
    },
    {
      estado: t(`budgetManagement.collaborators.inativo`),
      value: 0,
    },
  ];

  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        nome: event.target.value,
      }));
  };

  const handleEmpresaChange = (empresa) => {
    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };

  const handleCategoryChange = (idCategoria) => {
    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      idCategoria,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        idCategoria,
      }));
  };

  const handleAtivoChange = (estado) => {
    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      estado,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        estado,
      }));
  };

  const handleTeamChange = (idEquipa) => {
    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      idEquipa,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        idEquipa,
      }));
  };

  const handleDepartmentChange = (idDepartamento) => {
    setselectedCollab((prevCategory) => ({
      ...prevCategory,
      idDepartamento,
    }));
    if (SelectedEditCollab != undefined)
      setSelectedEditCollab((prevCategory) => ({
        ...prevCategory,
        idDepartamento,
      }));
  };
  const handlePrecoVendaChange = (event) => {
    event.persist();

    const updatePrecoVenda = (prevCategory) => ({
      ...prevCategory,
      precoVenda:
        event.target.value !== ""
          ? parseFloat(event.target.value)
          : event.target.value,
    });

    setselectedCollab(updatePrecoVenda);

    if (SelectedEditCollab !== undefined) {
      setSelectedEditCollab(updatePrecoVenda);
    }
  };

  const handlePrecoCompraChange = (event) => {
    event.persist();

    const updatePrecoCompra = (prevCategory) => ({
      ...prevCategory,
      precoCompra:
        event.target.value !== ""
          ? parseFloat(event.target.value)
          : event.target.value,
    });

    setselectedCollab(updatePrecoCompra);

    if (SelectedEditCollab !== undefined) {
      setSelectedEditCollab(updatePrecoCompra);
    }
  };

  useEffect(() => {
    if (SelectedEditCollab != (null || undefined))
      setselectedCollab(SelectedEditCollab);
  }, [SelectedEditCollab]);

  useEffect(() => {
    dispatch(getCRUDCollabs());
    dispatch(getCRUDCompanies());
    dispatch(getCRUDCategories());
    dispatch(getCRUDTeams());
    dispatch(getCRUDDepartments());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDCollabs());
    handleReset();
  };

  const handleReset = () => {
    setcollabFilter("");
    setcollabsToShow(collabs_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
    setFilterToggle(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditCollab(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeleteCollab(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDCollabs(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdateCollab = (id) => {
    if (id) {
      const payload = {
        idColaborador: id,
        nome: selectedCollab?.nome,
        empresa: selectedCollab?.empresa,
        year: new Date().getFullYear(),
        idCategoria: parseInt(selectedCollab?.idCategoria),
        idDepartamento: parseInt(selectedCollab?.idDepartamento),
        idEquipa: parseInt(selectedCollab?.idEquipa),
        estado: parseInt(selectedCollab?.estado),
        precoCompra: selectedCollab?.precoCompra,
        precoVenda: selectedCollab?.precoVenda,
        usrNumFuncionario: selectedCollab?.usrNumFuncionario,
      };
      dispatch(updateCRUDCollabs(id, payload));
    } else {
      const payload = {
        nome: selectedCollab?.nome,
        empresa: selectedCollab?.empresa,
        year: new Date().getFullYear(),
        categoria: parseInt(selectedCollab?.idCategoria),
        departamento: parseInt(selectedCollab?.idDepartamento),
        equipa: parseInt(selectedCollab?.idEquipa),
        estado: 1,
        precoCompra: selectedCollab?.precoCompra,
        precoVenda: selectedCollab?.precoVenda,
        usrNumFuncionario: null,
      };
      dispatch(postADDCRUDCollabs(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    collabs_CRUD.map((collaborator) => {
      if (collaborator?.estado == null) collaborator.estado = 0;
    });
    setcollabsToShow(collabs_CRUD);
  }, [collabs_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditCollab !== undefined &&
        SelectedEditCollab?.empresa === company?.codigoEmpresa,
    }));
  };

  const renderFilterTeam = () => {
    return teams_CRUD.map((team) => ({
      label: team?.empresa + " - " + team?.nome,
      value: team?.idEquipa.toString(),
      selected:
        SelectedEditCollab?.idEquipa.toString() === team?.idEquipa.toString(),
    }));
  };

  const renderFilterCategory = () => {
    return categories_CRUD.map((category) => ({
      label: category?.empresa + " - " + category?.nome,
      value: category?.idCategoria.toString(),
      selected:
        SelectedEditCollab !== undefined &&
        SelectedEditCollab?.idCategoria.toString() ===
          category?.idCategoria.toString(),
    }));
  };

  const renderFilterDepartment = () => {
    return departments_CRUD.map((department) => ({
      label: department?.empresa + " - " + department?.nome,
      value: department?.idDepartamento.toString(),
      selected:
        SelectedEditCollab?.idDepartamento.toString() ===
        department?.idDepartamento.toString(),
    }));
  };

  const renderFilterAtivo = () => {
    return activeArray.map((estado) => ({
      label: estado?.estado,
      value: estado?.value.toString(),
      selected:
        SelectedEditCollab?.estado.toString() === estado?.value.toString(),
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownCollabs}
            resetDropdown={cleanDropdownCollabs}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.nome`)}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={selectedCollab?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`categoria-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.categoria`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCategory()}
            handleSubmit={handleCategoryChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownCollabs}
            resetDropdown={cleanDropdownCollabs}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`departamento-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.departamento`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterDepartment()}
            handleSubmit={handleDepartmentChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownCollabs}
            resetDropdown={cleanDropdownCollabs}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`team-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.equipa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterTeam()}
            handleSubmit={handleTeamChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownCollabs}
            resetDropdown={cleanDropdownCollabs}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`precoVenda-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.precoVenda`)}:
          </label>
          <input
            id={`precoVenda-input`}
            className="form-control"
            type="number"
            step="0.01"
            value={selectedCollab?.precoVenda}
            onChange={handlePrecoVendaChange}
            required
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`precoCompra-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.collaborators.precoCompa`)}:
          </label>
          <input
            id={`precoCompra-input`}
            className="form-control"
            type="number"
            step="0.01"
            value={selectedCollab?.precoCompra}
            onChange={handlePrecoCompraChange}
            required
          />
        </div>
        {SelectedEditCollab && (
          <div className="inputsLiftCrud" style={{ width: "100%" }}>
            <label
              htmlFor={`team-input`}
              className="labelsLiftCrud"
              style={{ width: "auto" }}
            >
              {t(`budgetManagement.collaborators.estado`)}:
            </label>
            <Dropdown
              width={"100%"}
              list={renderFilterAtivo()}
              handleSubmit={handleAtivoChange}
              emptyDefault={true}
              setResetDropdown={setcleanDropdownCollabs}
              resetDropdown={cleanDropdownCollabs}
            />
          </div>
        )}
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setaddEditNewCollab(false);
    setshowEditModal(false);
    resetBackButton();
    setcleanDropdownCollabs(true);
    setSelectedEditCollab(undefined);
    setselectedCollab({
      empresa: "",
      idColaborador: 0,
      nome: "",
      idCategoria: "0",
      idDepartamento: "0",
      idEquipa: "0",
      estado: "1",
      precoCompra: 0,
      precoVenda: 0,
      usrNumFuncionario: "",
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.collaborators.btnVoltar`)}
            </Button>
            {addEditNewCollab ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdateCollab()}
              >
                {t(`budgetManagement.collaborators.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdateCollab(selectedCollab?.idColaborador)
                }
              >
                {t(`budgetManagement.collaborators.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.collaborators.msgDelete.1`)}{" "}
              {SelectedDeleteCollab?.empresa}{" "}
              {t(`budgetManagement.collaborators.msgDelete.2`)}{" "}
              {SelectedDeleteCollab?.nome}?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.collaborators.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() => handleDelete(SelectedDeleteCollab?.idColaborador)}
            >
              {t(`budgetManagement.collaborators.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDCollab || isLoading} />
          {Object.keys(collabs_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.collaborators.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <CollaboratorsFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      collabsData={collabs_CRUD}
                      setcollabsToShow={setcollabsToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      collabFilter={collabFilter}
                      setcollabFilter={setcollabFilter}
                    />
                    <CollaboratorsTable
                      app={"bpm"}
                      collabsData={collabsToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setaddEditNewCollab={setaddEditNewCollab}
                      seteditDeleteCollab={seteditDeleteCollab}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                      teams_CRUD={teams_CRUD}
                      categories_CRUD={categories_CRUD}
                      departments_CRUD={departments_CRUD}
                      filterToggle={filterToggle}
                      setFilterToggle={setFilterToggle}
                      collaboratorsToRender={collaboratorsToRender}
                      setcollaboratorsToRender={setcollaboratorsToRender}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(Collaborators);
