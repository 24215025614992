import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from 'react-i18next';
import { capitalize, cloneDeep } from 'lodash';

import { Badge, Modal, Button, Row, Col } from 'react-bootstrap';
import '~/assets/css/styles.css';
import '~/assets/css/icons.css';
import SpinnerNew from "~/components/SpinnerNew";

import { saveMetadataDocument } from '~/pages/DataCapture/actions';

import { toLocaleString } from '~/utils';
import {
  getColorByStatusDocument,
  translateStatusDocument
} from '~/pages/DataCapture/utils';

import { DocumentStatus } from '~/pages/DataCapture/utils/DataCaptureEnum';
const devLogConsole = require("~/utils/devLog");
const MetadataModalMock = props => {

  const { t, isEditing, showModal, closeModal, document } = props;

  const dispatch = useDispatch();

  const {
    metadata,
    isLoadingMetadata,
    isSavingMetadataDocument
  } = useSelector(state => state.dataCaptureReducer);

  const [vendorName, setVendorName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  //const [emissionDate, setEmissionDate] = useState("");
  //const [vendorTaxId, setVendorTaxId] = useState("");
  //const [clientTaxId, setClientTaxId] = useState("");
  const [currency, setCurrency] = useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [vatAmount, setVatAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");


  useEffect(() => {
    if (metadata) {
      setVendorName(nullValueFormatter(metadata.vendorName, ''));
      setInvoiceNumber(nullValueFormatter(metadata.invoiceNumber, ''));
      setClientName(nullValueFormatter(metadata.emissionDate, ''));
      setClientName(nullValueFormatter(metadata.vendorTaxId, ''));
      setClientName(nullValueFormatter(metadata.clientTaxId, ''));
      setCurrency(nullValueFormatter(metadata.currency, ''));
      setBaseAmount(nullValueFormatter(metadata.baseAmount, ''));
      setVatAmount(nullValueFormatter(metadata.vatAmount, ''));
      setTotalAmount(nullValueFormatter(metadata.totalAmount, ''));
      setClientName(nullValueFormatter(metadata.clientName, ''));
      setClientAddress(nullValueFormatter(metadata.clientAddress, ''));
      setClientPhone(nullValueFormatter(metadata.clientPhone, ''));
      setVendorAddress(nullValueFormatter(metadata.vendorAddress, ''));
      setVendorPhone(nullValueFormatter(metadata.vendorPhone, ''));
    }
  }, [metadata])

  const nullValueFormatter = (value, text) => value ? value : text;

  /*
  const createTextInput = (value, _onChange) => (
    isEditing ?
      <Form.Control
        type="text"
        value={value}
        onChange={e => _onChange(e.target.value)}
      /> :
      <small>{nullValueFormatter(value, 'Não informado')}</small>
  );

  const createNumberInput = (value, _onChange) => (
    isEditing ?
      <Form.Control
        type="number"
        value={value}
        step="0.01"
        onChange={e => _onChange(e.target.value)}
      /> :
      <small>{nullValueFormatter(value, 'Não informado')}</small>
  );
*/

  //TODO change class to invoice ou citizen_card
  const handleSaveMetadataDocument = () => {
    let _document = cloneDeep(document);

    _document.status = DocumentStatus.REVISED;

    _document.metadata = {
      'document:class': 'invoice',
      invoiceNumber, clientName, clientAddress, clientPhone,
      vendorName, vendorAddress, vendorPhone,
      totalAmount, currency, vatAmount, baseAmount
    }
    dispatch(saveMetadataDocument(_document));
    closeModal();
  }

  const generateImageView = () => (
    document ? 'data:image/jpg;base64, ' + document.data : ''
  )

  const generatePdfView = () => {

    return document ? 'data:application/pdf;base64, ' + document.data : 'about:blank'
  }
  //devLogConsole(document && document.filetype)
  return (
    <Modal
      dialogClassName="modal-datacapture-detail"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {capitalize(t('datacapture.general.invoice'))}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          !isLoadingMetadata &&
          <>
            <Row>
              <Col lg="5">
                <Row>
                  <Col sm="6">
                    {
                      document &&
                      <Badge
                        pill
                        variant={getColorByStatusDocument(document.status)}
                        style={{ marginBottom: 15 }}
                      >
                        {t(translateStatusDocument(document.status))}
                      </Badge>
                    }
                    <h1 className="display-4">
                      {
                        vendorName && <strong>{`${vendorName} - `}</strong>
                      }
                      <span>{`${totalAmount} ${currency}`}</span>
                    </h1>
                    {
                      document &&
                      <small>{`Uploaded at ${toLocaleString(document.createdDate)}`}</small>
                    }
                  </Col>
                </Row>
                <hr />

                <Row style={{ marginTop: 20 }}>
                  <Col lg="3">
                    <strong>NIPC:</strong>
                  </Col>
                  <Col sm="4">
                    506365123
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <strong>Código validação</strong>
                  </Col>
                  <Col sm="4">
                    8MP4VUHZHXBL
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col lg="3">
                    <strong>Data:</strong>
                  </Col>
                  <Col sm="4">
                    15/04/2020
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <strong>Dívida:</strong>
                  </Col>
                  <Col sm="4">
                    Situação Regularizada
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <strong>Assinado:</strong>
                  </Col>
                  <Col sm="4">
                    Não
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col lg="3">
                    <strong>Original:</strong>
                  </Col>
                  <Col sm="4">
                    Não
                  </Col>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </Col>

              <Col lg="7">
                {
                  (document && document.filetype === 'application/pdf') &&
                  <iframe
                    id="pdf-document"
                    src={generatePdfView()}
                    style={{ height: '100%', width: '100%' }}
                  />
                }
                {
                  (document && document.filetype !== 'application/pdf') &&
                  <img alt="" src={generateImageView()} className="document-preview" />
                }
              </Col>
            </Row>
          </>
        }

        <SpinnerNew open={isLoadingMetadata || isSavingMetadataDocument} />
      </Modal.Body >

      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => closeModal()}
        >
          {t('general.close')}
        </Button>
        {
          isEditing &&
          <Button
            className="modal-submit-button"
            style={{ marginLeft: "10px" }}
            onClick={() => handleSaveMetadataDocument()}
          >
            {t('general.save')}
          </Button>
        }
      </Modal.Footer>
    </Modal >
  )

}

export default withNamespaces()(MetadataModalMock);
