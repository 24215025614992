import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CreateTableTextInput from "./CreateTableTextInput";
import CreateDateInput from "../CreateDateInput";
import CreateTextInput from "../CreateTextInput";
import CreateAlertModal from "./CreateAlertModal";
import uuid from "uuid/v1";

const CreateCalculosVistorias = (props) => {
  const { blockdivision, renderColumnContent, styleSheet } = props;

  const [dataRealizacao, setFormData1] = useState("");
  const [obraRealizada, setFormData2] = useState("");
  const [tableVistorias, setTable] = useState("");
  const [fieldTerreno, setFieldTerreno] = useState(false);

  const [valorComercial, setFormData3] = useState("");
  const [custoConstrucao, setFormData4] = useState("");

  const childCount = useRef(0);

  const alertModal = useRef(null);

  useEffect(() => {
    if (blockdivision) {
      //find form data
      let dataRealizacao;
      let obraRealizada;
      let tableVistorias;
      let valorComercial;
      let custoConstrucao;
      let fieldTerreno;

      const setValue = (f) => {
        if (f?.variable == "dataRealizacao") dataRealizacao = f;
        if (f?.variable == "obraRealizada") obraRealizada = f;

        if (f?.type == "arraytable") {
          if (f?.row[0]?.col[0]?.value === "Vistoria") {
            tableVistorias = f;
          }
        }

        if (f?.type === "textlabel" && f?.text === "Valor do Terreno")
          fieldTerreno = f;
        if (f?.type === "textlabel" && f?.text === "Valor antes de obras")
          fieldTerreno = f;
        if (
          f?.type === "textlabel" &&
          f?.text === "Valor do Terreno/Valor antes de obras"
        )
          fieldTerreno = f;

        if (f?.variable == "valorComercialPA") {
          valorComercial = f?.value;
        }
        if (f?.type === "textlabel" && f?.text === "C. Const. c/encargos")
          custoConstrucao = f?.value;
      };

      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });
      setFormData1(dataRealizacao);
      setFormData2(obraRealizada);
      setFormData3(formatter.format(Number(parseCurrency(valorComercial))));
      setFormData4(formatter.format(Number(parseCurrency(custoConstrucao))));
      setTable(tableVistorias);
      setFieldTerreno(fieldTerreno);
    }
  }, [blockdivision]);

  const onChildRender = () => {
    childCount.current = childCount.current + 1;
    if (childCount.current == 26) {
      updateTotais(13);
    }
  };

  const formatter = new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  });

  function parseCurrency(arg) {
    return arg.replace(/[^\d,]/g, "").replace(/,/g, ".");
  }

  function updateTotais(tabelaVistoria) {
    let colPeso = "2";
    let colExecutado = "3";
    let colTotal = "4";

    let numLinhas = 2;
    let totalPeso = 0;
    let totalExecutado = 0;
    while (
      document.getElementById(
        tabelaVistoria + "_" + numLinhas + "_" + colPeso
      ) != null
    ) {
      totalPeso += Number(
        document
          .getElementById(tabelaVistoria + "_" + numLinhas + "_" + colPeso)
          .getElementsByTagName("input")[0].value
      );
      totalExecutado += Number(
        document.getElementById(
          tabelaVistoria + "_" + numLinhas + "_" + colTotal
        ).textContent
      );
      numLinhas++;
    }

    if (document.getElementById("total"))
      document.getElementById("total").textContent = totalPeso.toFixed(2) + "%";

    if (document.getElementById("totalExecutado"))
      document.getElementById("totalExecutado").textContent =
        totalExecutado.toFixed(2) + "%";

    if (document.getElementById("obraRealizada"))
      document.getElementById("obraRealizada").textContent =
        totalExecutado.toFixed(2);

    if (document.getElementById("perActual"))
      document.getElementById("perActual").textContent =
        totalExecutado.toFixed(2) + "%";

    let valorComercialPA = Number(
      parseCurrency(document.getElementById("valorComercial").textContent)
    );
    let valorComercialActual = 0;

    if (document.getElementById("valorTerreno")) {
      let valorTerreno = Number(
        parseCurrency(document.getElementById("valorTerreno").textContent)
      );
      let custoConstrucao = Number(
        parseCurrency(document.getElementById("custoConstrucao").textContent)
      );

      let valorComercialAtualPA1 = valorComercialPA * (totalExecutado / 100);
      let valorComercialAtualPA2 =
        custoConstrucao * (totalExecutado / 100) + valorTerreno;

      if (valorComercialAtualPA1 > valorComercialAtualPA2) {
        valorComercialActual = valorComercialAtualPA1;
      } else {
        valorComercialActual = valorComercialAtualPA2;
      }
    } else if (document.getElementById("valorAntesObras")) {
      let valorAntesObras = Number(
        parseCurrency(document.getElementById("valorAntesObras").textContent)
      );

      valorComercialActual =
        (valorComercialPA - valorAntesObras) * (totalExecutado / 100) +
        valorAntesObras;
    } else {
      valorComercialActual = 0;
    }

    if (document.getElementById("valorComercialActual"))
      document.getElementById("valorComercialActual").textContent =
        formatter.format(valorComercialActual.toFixed(2));
  }

  function updateVistoria(linha, tabelaVistoria) {
    let colPeso = "2";
    let colExecutado = "3";
    let colTotal = "4";

    let numLinhas = 2;
    let totalPeso = 0;
    let totalExecutado = 0;
    while (
      document.getElementById(
        tabelaVistoria + "_" + numLinhas + "_" + colPeso
      ) != null
    ) {
      let valPeso = Number(
        document
          .getElementById(tabelaVistoria + "_" + numLinhas + "_" + colPeso)
          .getElementsByTagName("input")[0].value
      );

      totalPeso += Math.round(valPeso * 100) / 100;
      numLinhas++;
    }

    if (totalPeso > 100) {
      alertModal.current.handleShowModal(
        "A soma dos pesos tem que ser igual ou inferior a 100"
      );
      document
        .getElementById(tabelaVistoria + "_" + linha + "_" + colPeso)
        .getElementsByTagName("input")[0].value = "";
    }

    let valPeso = Number(
      document
        .getElementById(tabelaVistoria + "_" + linha + "_" + colPeso)
        .getElementsByTagName("input")[0].value
    );
    let valExecutado = Number(
      document
        .getElementById(tabelaVistoria + "_" + linha + "_" + colExecutado)
        .getElementsByTagName("input")[0].value
    );

    if (Math.round(valExecutado * 100) / 100 > 100) {
      alertModal.current.handleShowModal(
        "A percentagem do valor executado tem que ser igual ou inferior a 100"
      );
      document
        .getElementById(tabelaVistoria + "_" + linha + "_" + colExecutado)
        .getElementsByTagName("input")[0].value = "";
      document.getElementById(
        tabelaVistoria + "_" + linha + "_" + colTotal
      ).textContent = "0.00";
    } else {
      let valTotal = (valPeso * valExecutado) / 100;
      document.getElementById(
        tabelaVistoria + "_" + linha + "_" + colTotal
      ).textContent = valTotal.toFixed(2);
    }

    updateTotais(tabelaVistoria);
  }

  const renderTable = (table, onBlur) => {
    if (table && Array.isArray(table.row)) {
      return table.row.map((r, index) => {
        if (index != 0) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  if (i == 1 || i == 2) {
                    return (
                      <CreateTableTextInput
                        key={i}
                        id={`13_${index + 1}_${i + 1}`}
                        name={
                          c?.value?.input
                            ? c?.value?.input?.variable
                            : c?.value?.variable
                        }
                        value={
                          c?.value?.input ? c?.value?.input?.value : c?.value
                        }
                        linha={index + 1}
                        onBlur={onBlur}
                        onChildRender={onChildRender}
                        disabled={c?.value?.input ? false : true}
                      />
                    );
                  } else if (i === 3) {
                    if (
                      r.col[1].value?.input?.value &&
                      r.col[2].value?.input?.value
                    ) {
                      return (
                        <td key={i} id={`13_${index + 1}_${i + 1}`}>
                          {(
                            (Number(r.col[1].value?.input?.value) *
                              Number(r.col[2].value?.input?.value)) /
                            100
                          ).toFixed(2)}
                        </td>
                      );
                    } else {
                      return (
                        <td key={i} id={`13_${index + 1}_${i + 1}`}>
                          {(
                            (Number(r.col[1].value) * Number(r.col[2].value)) /
                            100
                          ).toFixed(2)}
                        </td>
                      );
                    }
                  } else
                    return (
                      <td key={i} id={`13_${index + 1}_${i + 1}`}>
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const style1 = {
    textAlign: "end",
    fontWeight: "bold",
    fontSize: "13px",
  };

  const style2 = {
    flexGrow: "1",
    fontSize: "13px",
  };

  const style3 = {
    flexGrow: "0.25",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  const style4 = {
    textAlign: "end",
    fontWeight: "bold",
    fontSize: "14px",
  };

  const hr = (
    <div
      style={{
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        border: "0",
        borderTop: "1px solid rgba(0,0,0,.1)",
      }}
    ></div>
  );

  return (
    <>
      {blockdivision[0]?.columndivision && (
        <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
          {renderColumnContent(blockdivision[0].columndivision, styleSheet)}
        </Col>
      )}
      <br />
      <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
        {dataRealizacao && (
          <CreateDateInput
            field={dataRealizacao}
            key={"dataRealizacao"}
            justifyContent={"flex-start"}
            id="dataRealizacao"
          />
        )}

        {obraRealizada && (
          <CreateTextInput
            field={obraRealizada}
            key={"obraRealizada"}
            id="obraRealizada"
          />
        )}
      </Col>
      <br />
      <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr>
              <th id="13_1_1" style={{ width: "20%", paddingBottom: "10px" }}>
                Vistoria
              </th>
              <th id="13_1_2" style={{ width: "15%", paddingBottom: "10px" }}>
                Peso Atividade (%)
              </th>
              <th id="13_1_3" style={{ width: "15%", paddingBottom: "10px" }}>
                Atividade Executada (%)
              </th>
              <th id="13_1_4" style={{ width: "12%", paddingBottom: "10px" }}>
                Total Executado (%)
              </th>
            </tr>
          </thead>

          <tbody>{renderTable(tableVistorias, updateVistoria)}</tbody>
        </table>
      </Col>
      <div
        style={{ flexWrap: "nowrap", marginTop: "10px", textAlign: "right" }}
      >
        <Form.Group as={Row}>
          <Col key={`columndivision_${uuid()}`}>
            <Form.Label column style={{ flexGrow: "1" }}>
              Total
              <Form.Label
                id="total"
                column
                style={{ ...style4, display: "inline" }}
              >
                {""}
              </Form.Label>
            </Form.Label>
          </Col>
          <Col
            key={`columndivision_${uuid()}`}
            style={{flexGrow:'0.7'}}
          >
            <Form.Label column style={{ flexGrow: "1"}}>
              Total Executado
              <Form.Label id="totalExecutado" column style={{...style4, display:"inline"}}>
              {" "}
            </Form.Label>
            </Form.Label>
          </Col>
        </Form.Group>
        {hr}
      </div>

      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        <Col key={`columndivision_${uuid()}`} style={style3}>
          <Form.Group as={Row}>
            <Form.Label column style={style2}>
              Valor Comercial
            </Form.Label>
            <Form.Label column id="valorComercial" style={style1}>
              {valorComercial}
            </Form.Label>
          </Form.Group>
          {hr}
        </Col>

        <Col key={`columndivision_${uuid()}`} style={style3}>
          <Form.Group as={Row}>
            <Form.Label column style={style2}>
              C. Const. c/encargos
            </Form.Label>
            <Form.Label column id="custoConstrucao" style={style1}>
              {custoConstrucao}
            </Form.Label>
          </Form.Group>
          {hr}
        </Col>

        {fieldTerreno.text === "Valor do Terreno/Valor antes de obras" && (
          <Col key={`columndivision_${uuid()}`} style={style3}>
            <Form.Group as={Row}>
              <Form.Label column style={style2}>
                Valor do Terreno/Valor antes de obras
              </Form.Label>
              <Form.Label id="valorTerrenoAntesObras" column style={style1}>
                {formatter.format(Number(parseCurrency(fieldTerreno?.value)))}
              </Form.Label>
            </Form.Group>
            {hr}
          </Col>
        )}
        {fieldTerreno.text === "Valor do Terreno" && (
          <Col key={`columndivision_${uuid()}`} style={style3}>
            <Form.Group as={Row}>
              <Form.Label column style={style2}>
                Valor do Terreno
              </Form.Label>
              <Form.Label id="valorTerreno" column style={style1}>
                {formatter.format(Number(parseCurrency(fieldTerreno?.value)))}
              </Form.Label>
            </Form.Group>
            {hr}
          </Col>
        )}
        {fieldTerreno.text === "Valor antes de obras" && (
          <Col key={`columndivision_${uuid()}`} style={style3}>
            <Form.Group as={Row}>
              <Form.Label column style={style2}>
                Valor antes de obras
              </Form.Label>
              <Form.Label id="valorAntesObras" column style={style1}>
                {formatter.format(Number(parseCurrency(fieldTerreno?.value)))}
              </Form.Label>
            </Form.Group>
            {hr}
          </Col>
        )}

        <Col key={`columndivision_${uuid()}`} style={style3}>
          <Form.Group as={Row}>
            <Form.Label column style={style2}>
              % Actual
            </Form.Label>
            <Form.Label id="perActual" column style={style1}>
              {" "}
            </Form.Label>
          </Form.Group>
          {hr}
        </Col>

        <Col key={`columndivision_${uuid()}`} style={style3}>
          <Form.Group as={Row}>
            <Form.Label column style={style2}>
              Valor Comercial Actual
            </Form.Label>
            <Form.Label id="valorComercialActual" column style={style1}>
              {" "}
            </Form.Label>
          </Form.Group>
          {hr}
        </Col>
      </div>

      {blockdivision[7]?.columndivision && (
        <Col key={`columndivision_${uuid()}`} style={{ flexGrow: "1" }}>
          {renderColumnContent(blockdivision[7].columndivision, styleSheet)}
        </Col>
      )}

      <CreateAlertModal ref={alertModal} />
    </>
  );
};

export default CreateCalculosVistorias;
