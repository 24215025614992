import React, { useEffect, useState } from "react";
import Dropdown from "~/components/Dropdown";
import { withNamespaces } from "react-i18next";
import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";

const MiddleWares = ({ tabKey, t }) => {
  const dispatch = useDispatch();

  const [selectedMiddleware, setSelectedMiddleware] = useState(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [cleanDropdown, setCleanDropdown] = useState(false);

  const { syncMWList, isLoadingMWList, resSyncMWList } = useSelector(
    (state) => state.systemSettingsApplicationsReducer
  );

  const [formData, setFormData] = useState({
    appLabel: "",
    username: "",
  });

  useEffect(() => {
    dispatch(actions.getSyncListMW());
  }, []);

  const handleMiddlewareSelection = (middleware) => {
    setSelectedMiddleware(middleware);
    setShowAdditionalFields(!!middleware);
    setCheckboxChecked(false);
    setFormData((prevState) => ({
      ...prevState,
      appLabel: middleware,
    }));
  };

  const handleUsernameSelection = (selected) => {
    setSelectedUsername(selected);
    setFormData((prevState) => ({
      ...prevState,
      username: selected,
    }));
  };
  const renderMiddlwaresList = () => {
    return syncMWList?.synchronization?.apps.map((e) => {
      return {
        label: e.description,
        value: e.label,
        selected: false,
      };
    });
  };

  const renderUsernamesList = () => {
    if (selectedMiddleware) {
      const middlewareObj = syncMWList?.synchronization?.apps.find(
        (app) => app.label === selectedMiddleware
      );
      if (middlewareObj) {
        return middlewareObj.existentUsernames.map((username) => ({
          value: username,
          label: username,
        }));
      }
    }

    return [];
  };

  const handleSubmitSync = () => {
    if (formData.appLabel == undefined || formData.appLabel == null || formData.appLabel == "") return;
    dispatch(actions.postSyncListMW(formData));
    setFormData({
      appLabel: "",
      username: "",
    });
    setIsModalShowing(true);
    setCleanDropdown(true);
  };

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper-30">
        <div>{resSyncMWList}</div>
      </div>
    );
  }

  return (
    <>
      <SpinnerNew open={isLoadingMWList} />
      {!isLoadingMWList && (
        <>
        <h6>Middleware to Backend</h6>
          <Form.Row className="d-flex align-items-center" style={{ marginTop: '20px', padding:'8px' }}>
            <Col lg="6">
              <Form.Label>Sync Middleware(s) to UDW</Form.Label>
              <Dropdown
                list={renderMiddlwaresList()}
                handleSubmit={handleMiddlewareSelection}
                emptyDefault={true}
                placeholder="Select Middleware"
                required
                resetDropdown={cleanDropdown}
                setResetDropdown={setCleanDropdown}
              />
            </Col>
            {showAdditionalFields && (
              <Col lg="2" style={{ marginBottom: "8px" }}>
                <Form.Check
                  type="checkbox"
                  label="Apenas User Específico?"
                  checked={checkboxChecked}
                  onChange={(e) => setCheckboxChecked(e.target.checked)}
                />
              </Col>
            )}
            {checkboxChecked && (
              <Col lg="3">
                <Form.Label
                  style={{ alignSelf: "center", marginRight: "10px" }}
                >
                  Usernames
                </Form.Label>
                <Dropdown
                  style={{ width: "100%" }}
                  list={renderUsernamesList()}
                  handleSubmit={handleUsernameSelection}
                  emptyDefault={true}
                  placeholder="Select Username"
                  required
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
              </Col>
            )}
            <Col lg="12" style={{ textAlignLast: "end" }}>
              <Button
                className="card-button-green"
                onClick={() => handleSubmitSync()}
              >
                Confirmar
              </Button>
            </Col>
          </Form.Row>
        </>
      )}

      {!isLoadingMWList && (
        <Modal
          className="easypay-modal"
          showModal={isModalShowing}
          setShowModal={setIsModalShowing}
          headerContent={"Syncronization Info"}
          bodyContent={createBody()}
          key={uuid()}
        ></Modal>
      )}
    </>
  );
};

export default withNamespaces()(MiddleWares);
