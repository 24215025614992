import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "react-addons-update";
import { withNamespaces } from "react-i18next";
import SpinnerNew from "~/components/SpinnerNew";
import { Modal, Button, Form, Col } from "react-bootstrap";
import {
  updateTemplateProfile,
  getTemplatesList,
} from "store/ducks/processes/actionTypes";
import TemplateTableList from "./TemplateTableList";
import isEmpty from "lodash/isEmpty";

const ModalTemplate = ({
  openModalTemplate,
  handleCloseModalTemplate,
  selectedTemplate,
  setSelectedTemplate,
  t,
}) => {
  const dispatch = useDispatch();
  const { isLoadingTemplates, templateDetail, templateDocumentsAll } =
    useSelector((state) => state.processesReducer);

  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [unselectedTemplates, setUnselectedTemplates] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedTemplate) {
      if (!isEmpty(templateDetail)) {
        setDescription(selectedTemplate.descricao);
        setSelectedTemplates(templateDetail.selecionados);
        setUnselectedTemplates(templateDetail.disponiveis);
      }
    } else if (!isEmpty(templateDocumentsAll)) {
      setUnselectedTemplates(templateDocumentsAll);
    }
  }, [templateDetail, templateDocumentsAll, dispatch]);

  const addTemplate = (template) => {
    const index = unselectedTemplates.indexOf(template);
    const unselectedTemplates_ = update(unselectedTemplates, {
      $splice: [[index, 1]],
    });
    const selectedTemplates_ = update(selectedTemplates, { $push: [template] });
    setUnselectedTemplates(unselectedTemplates_);
    setSelectedTemplates(selectedTemplates_);
  };

  const removeTemplate = (template) => {
    const index = selectedTemplates.indexOf(template);
    const selectedTemplates_ = update(selectedTemplates, {
      $splice: [[index, 1]],
    });
    const unselectedTemplates_ = update(unselectedTemplates, {
      $push: [template],
    });
    setUnselectedTemplates(unselectedTemplates_);
    setSelectedTemplates(selectedTemplates_);
  };

  const handleSave = () => {
    const payload = {
      id: selectedTemplate?.id ?? 0,
      descricao: description,
      documentos: selectedTemplates,
      ativo: selectedTemplate?.ativo ?? 1,
    };
    dispatch(updateTemplateProfile(payload));
    handleCloseModalCleanStates();
    dispatch(getTemplatesList());
  };

  const handleCloseModalCleanStates = () => {
    setSelectedTemplates([]);
    setUnselectedTemplates([]);
    setDescription("");
    handleCloseModalTemplate();
  };

  const handleCheckboxChange = (id) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      return prevSelectedTemplates.map((template) => {
        if (template.id === id) {
          return { ...template, obrigatorio: !template.obrigatorio };
        }
        return template;
      });
    });
  };

  return (
    <>
      <Modal
        show={openModalTemplate}
        onHide={handleCloseModalCleanStates}
        dialogClassName="custom-modal modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("portalrh.rhadmin.gestaoTemplates.gestao")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoadingTemplates && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  marginBottom: "3rem",
                }}
              >
                <b>{t("portalrh.rhadmin.gestaoTemplates.perfil")}:</b>
                <Form.Control
                  type="text"
                  style={{ width: "35%" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <hr />
              </div>

              <Form.Row>
                <Col sm="12" md="12" lg="6">
                  <Form.Group controlId="label">
                    <Form.Label>
                      {t("portalrh.rhadmin.gestaoTemplates.docsDisp")}
                    </Form.Label>
                    <TemplateTableList
                      template={unselectedTemplates}
                      action={addTemplate}
                      showCheckbox={false}
                      actionIcon="icon-add"
                    />
                  </Form.Group>
                </Col>

                <Col sm="12" md="12" lg="6">
                  <Form.Group controlId="label">
                    <Form.Label>
                      {t("portalrh.rhadmin.gestaoTemplates.docsAss")}
                    </Form.Label>
                    <TemplateTableList
                      template={selectedTemplates}
                      action={removeTemplate}
                      actionIcon="icon-trash"
                      handleCheckboxChange={handleCheckboxChange}
                      showCheckbox={true}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          )}
        </Modal.Body>{" "}
        <Modal.Footer>
          <Button
            variant="danger"
            style={{ minHeight: "44px" }}
            onClick={handleCloseModalCleanStates}
          >
            {t("portalrh.rhadmin.gestaoTemplates.close")}
          </Button>
          <Button className="card-button" onClick={handleSave}>
            {selectedTemplate
              ? t("portalrh.rhadmin.gestaoTemplates.save")
              : t("portalrh.rhadmin.gestaoTemplates.create")}
          </Button>
        </Modal.Footer>
        <SpinnerNew open={isLoadingTemplates} />
      </Modal>
    </>
  );
};

export default withNamespaces()(ModalTemplate);
