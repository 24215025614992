import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonRemove from "~/components/Buttons/RemoveButton";
import SearchableDropDown from "~/components/SearchableDropDown";
import WarningModal from "~/components/WarningModal/WarningModal";
import { updateUserSettingsADatacapture } from "~/pages/Admin/User/actions";
import { deleteDocuments, findAllBatchs } from "~/pages/DataCapture/actions";
import "./AutomatedDocumentTable.css";
import SavedSuccessfullyModal from "./SavedSuccessfullyModal";

const frequency = [
  { label: "Every 1 hour", value: "0 * * * *" },
  { label: "Every 2 hours", value: "0 */2 * * *" },
  { label: "Every 4 hours", value: "*/4 * * *" },
  { label: "Every 8 hours", value: "*/8 * * *" },
  { label: "Daily", value: "0 8 * * *", selected: true },
  { label: "Weekly", value: "0 8 * * MON" },
];

const DataCaptureSettingsModal = (props) => {
  const { t, showModal, closeModal, simpleFlow } = props;
  const dispatch = useDispatch();

  const [modalClassName, setModalClassName] = useState("modal-tarefa-65w");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const [email, setEmail] = useState("");
  const [docDigitizerKey, setDocDigitizerKey] = useState("");
  const [callbackEndpoint, setCallbackEndpoint] = useState("");
  const [digestFrequency, setDigestFrequency] = useState("0 8 * * *");

  const [frequencyList, setFrequencyList] = useState(frequency);

  const { user } = useSelector((state) => state.globalReducer);
  const { batchs } = useSelector((state) => state.dataCaptureReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showModal) {
      handleShowSettingsModal();
    }
  }, [showModal]);

  useEffect(() => {
    if (user && user.settings) {
      user.settings.notificationEmail
        ? setEmail(user.settings.notificationEmail)
        : setEmail(user.detail.email);
      user.settings.callbackEndpoint
        ? setCallbackEndpoint(user.settings.callbackEndpoint)
        : setCallbackEndpoint("");
      setDocDigitizerKey(user?.organization?.doc_digitizerapikey);
      const savedFrequency = user?.settings?.digestFrequency;

      if (user.organization && simpleFlow) {
        setShowNotifications(user.organization.emailDigest);
      }

      if (savedFrequency) {
        const frequency_ = frequency.map((item) => {
          if (item.value === savedFrequency) item.selected = true;
          else item.selected = false;
          return item;
        });
        setFrequencyList(frequency_);
        setDigestFrequency(savedFrequency);
      }
    } else {
      setEmail(user.detail.email);
      setDocDigitizerKey(user?.organization?.doc_digitizerapikey);
    }
  }, [user]);

  useEffect(() => {
    if (
      showModal &&
      !showDeleteModal &&
      !showSettingsModal &&
      !showSuccessModal
    ) {
      handleShowSettingsModal();
    }
  }, [showDeleteModal, showSuccessModal]);

  const handleDeleteDocuments = () => {
    if (user && user.username) dispatch(deleteDocuments(user.username));
  };

  const handleCloseSettingsModal = () => {
    closeModal();
    setShowSettingsModal(false);
  };

  const handleCloseTemporarilySettingsModal = () => {
    setShowSettingsModal(false);
  };

  const saveSettings = () => {
    const payload = {
      ...user,
      settings: {
        callbackEndpoint: callbackEndpoint,
        notificationEmail: email,
        digestFrequency: digestFrequency,
      },
    };
    dispatch(updateUserSettingsADatacapture(payload));
    setShowSuccessModal(true);
  };

  const handleShowSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const batchs_ = batchs.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  let docs = [];
  batchs_.forEach((batch) => {
    batch.documents.forEach((docs_) => {
      docs.push(docs_);
    });
  });

  const pendingDocs = docs.filter((doc) => {
    return (
      doc.statusV1 === "PENDING" ||
      doc.statusV1 === "LOADED" ||
      doc.statusV1 === "WAITING_SUBMISSION"
    );
  });

  const handleRefreshDocument = (docs) => {
    docs && docs.length > 0 && dispatch(findAllBatchs());
  }; 

  return (
    <>
      <Modal
        dialogClassName={modalClassName}
        show={showSettingsModal}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header
          className="justify-content-between"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title style={isMobile ? { fontSize: "18px" } : {}}>
            {t("datacapture.settingsModal.title")}
          </Modal.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="icon-refresh settings-hover-pointer"
              onClick={() => {
                handleRefreshDocument(pendingDocs);
              }}
              style={{ marginRight: "30px", fontSize: "1.4rem" }}
            />
            <i
              className="icon-remove mr-4 settings-hover-pointer"
              onClick={() => handleCloseSettingsModal()}
            />
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "0" }}>
          <Form>
            <div
              style={
                isMobile
                  ? { display: "flex", flexDirection: "column" }
                  : { display: "flex", padding: "1.25rem" }
              }
            >
              {showNotifications && (
                <Card.Body>
                  <div>
                    <Form.Label
                      style={
                        isMobile
                          ? {
                              marginBottom: "15px",
                              marginLeft: "-6px",
                              padding: "0",
                              fontWeight: "500",
                              fontSize: "17px",
                            }
                          : {
                              marginBottom: "15px",
                              marginLeft: "-6px",
                              padding: "0",
                              fontWeight: "500",
                              fontSize: "1.5rem",
                            }
                      }
                      column="lg"
                    >
                      {t("datacapture.settingsModal.subNotification")}
                    </Form.Label>
                    <div className="card_group2">
                      <div>
                        <Form.Row>
                          <Form.Group
                            style={{ width: "90%" }}
                            controlId="email"
                          >
                            <Form.Label>
                              {t("datacapture.settingsModal.email")}
                            </Form.Label>
                            <Form.Control
                              className="datacapture-look-editable"
                              type="email"
                              value={email || ""}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                              {t("datacapture.settingsModal.persist.email")}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </div>
                      <div>
                        <Form.Row>
                          <Form.Group
                            style={{ width: "90%" }}
                            controlId="digestFrequency"
                          >
                            <Form.Label>
                              {t("datacapture.settingsModal.digestFrequency")}
                            </Form.Label>
                            <SearchableDropDown
                              hover="hover"
                              list={frequencyList}
                              handleSubmit={(value) =>
                                setDigestFrequency(value)
                              }
                            />
                          </Form.Group>
                        </Form.Row>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              )}
              <Card.Body>
                <div>
                  <Form.Label
                    style={
                      isMobile
                        ? {
                            marginBottom: "15px",
                            marginLeft: "-6px",
                            padding: "0",
                            fontWeight: "500",
                            fontSize: "17px",
                          }
                        : {
                            marginBottom: "15px",
                            marginLeft: "-6px",
                            padding: "0",
                            fontWeight: "500",
                            fontSize: "1.5rem",
                          }
                    }
                    column="lg"
                  >
                    {t("datacapture.settingsModal.subOrganization")}
                  </Form.Label>
                  <div>
                    <div className="card_group2">
                      <Form.Row>
                        <Form.Group
                          style={{ width: "90%" }}
                          controlId="docDigitizerKey"
                        >
                          <Form.Label>
                            {t("datacapture.settingsModal.docDigitizerKey")}
                          </Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            value={docDigitizerKey || ""}
                            //disabled={isEditing}
                            onChange={(e) => setDocDigitizerKey(e.target.value)}
                            //setDocDigitizerKey(e.target.value.trim())
                            //}
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("datacapture.settingsModal.docDigitizerKey")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </div>
                    <div>
                      <Form.Row>
                        <Form.Group
                          style={{ width: "90%" }}
                          controlId="callbackEndpoint"
                        >
                          <Form.Label>
                            {t("datacapture.settingsModal.callbackEndpoint")}
                          </Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            value={callbackEndpoint || ""}
                            onChange={(e) =>
                              setCallbackEndpoint(e.target.value)
                            }
                            autoComplete="off"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(
                              "datacapture.settingsModal.persist.callbackEndpoint"
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </div>
                  </div>
                </div>
              </Card.Body>

              <Card.Body>
                <Form.Row style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonRemove
                    onClick={() => {
                      setShowDeleteModal(true);
                      handleCloseTemporarilySettingsModal();
                    }}
                    text={t("datacapture.settingsModal.resetButton")}
                  />
                </Form.Row>
              </Card.Body>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="card-button"
                  onClick={() => {
                    saveSettings();
                    handleCloseSettingsModal();
                  }}
                >
                  {t("datacapture.settingsModal.saveButton")}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <WarningModal
        showDeleteModal={showDeleteModal}
        handleDelete={handleDeleteDocuments}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleCloseParentModal={handleCloseSettingsModal}
        message={"This will clear all your documents and data. Do you confirm?"}
      />

      <SavedSuccessfullyModal
        showSuccessModal={showSuccessModal}
        handleCloseSuccessModal={handleCloseSuccessModal}
        handleCloseParentModal={handleCloseSettingsModal}
      />
    </>
  );
};

export default withNamespaces()(DataCaptureSettingsModal);
