export const FIND_ALL_BATCHS = "FIND_ALL_BATCHS";
export const FIND_ALL_BATCHS_WITH_BINARY = "FIND_ALL_BATCHS_WITH_BINARY";
export const FIND_BATCH_BY_FILTERS = "FIND_BATCH_BY_FILTERS";
export const SET_BATCHS = "SET_BATCHS";
export const SET_BATCHS_WITH_BINARY = "SET_BATCHS_WITH_BINARY";
export const FIND_BATCH_BY_ID = "FIND_BATCH_BY_ID";
export const SET_BATCH_BY_ID = "SET_BATCH_BY_ID";
export const CLEAR_BATCHS = "CLEAR_BATCHS";
export const CLEAR_BATCHS_WITH_BINARY = "CLEAR_BATCHS_WITH_BINARY";

export const FIND_ALL_CUSTOM_BATCHS = "FIND_ALL_CUSTOM_BATCHS";
export const SET_CUSTOM_BATCHS = "SET_CUSTOM_BATCHS";
export const FIND_ALL_BINARY_CUSTOM_BATCHS = "FIND_ALL_BINARY_CUSTOM_BATCHS";
export const SET_CUSTOM_BINARY_BATCHS = "SET_CUSTOM_BINARY_BATCHS";

export const SAVE_DOCUMENT_INTO_BATCH = "SAVE_DOCUMENT_INTO_BATCH";
export const ADD_DOCUMENT_TO_BATCH = "ADD_DOCUMENT_TO_BATCH";
export const ADD_DOCUMENT_TO_BATCH_OPENAI = "ADD_DOCUMENT_TO_BATCH_OPENAI";
export const SUCCESS_SAVE_DOCUMENT_BATCH = "SUCCESS_SAVE_DOCUMENT_BATCH";
export const FAIL_SAVE_DOCUMENT_BATCH = "FAIL_SAVE_DOCUMENT_BATCH";
export const CLEAR_SAVED_BATCH = "CLEAR_SAVED_BATCH";

export const SAVE_DOCUMENLIST_INTO_BATCH = "SAVE_DOCUMENLIST_INTO_BATCH";

export const HANDLE_UPDATE_APPROVED = "HANDLE_UPDATE_APPROVED";
export const HANDLE_CHANGED_APPROVED = "HANDLE_CHANGED_APPROVED";
export const HANDLE_RESET_APPROVED = "HANDLE_RESET_APPROVED";
export const REFRESH_APPROVED_DATE = "REFRESH_APPROVED_DATE";

export const HANDLE_UPDATE_BATCH = "HANDLE_UPDATE_BATCH";
export const SET_UPDATED_BATCH = "SET_UPDATED_BATCH";
export const HANDLE_DELETE_BATCH = "HANDLE_DELETE_BATCH";
export const SET_DELETED_BATCH = "SET_DELETED_BATCH";

export const HANDLE_DELETE_DOCUMENT = "HANDLE_DELETE_DOCUMENT";
export const SET_DELETED_DOCUMENT = "SET_DELETED_DOCUMENT";
export const HANDLE_REMOVE_DOCUMENT = "HANDLE_REMOVE_DOCUMENT";

export const HANDLE_DELETE_DOCUMENTS = "HANDLE_DELETE_DOCUMENTS";
export const SET_DELETED_DOCUMENTS = "SET_DELETED_DOCUMENTS";
export const HANDLE_REMOVE_DOCUMENTS = "HANDLE_REMOVE_DOCUMENTS";

export const REFRESH_DOCUMENT = "REFRESH_DOCUMENT";
export const SET_REFRESHED_DOCUMENT = "SET_REFRESHED_DOCUMENT";
export const CLEAR_IS_REFRESHED = "CLEAR_IS_REFRESHED";

export const HANDLE_UPDATE_DOCUMENTS = "HANDLE_UPDATE_DOCUMENTS";
export const SET_UPDATED_DOCUMENTS = "SET_UPDATED_DOCUMENTS";

export const HANDLE_UPDATE_DOCUMENT = "HANDLE_UPDATE_DOCUMENT";
export const SET_UPDATED_DOCUMENT = "SET_UPDATED_DOCUMENT";

export const GET_METADATA_DOCUMENT = "GET_METADATA_DOCUMENT";
export const SET_METADATA_DOCUMENT = "SET_METADATA_DOCUMENT";

export const GET_METADATA_DOCUMENT_FILENAME = "GET_METADATA_DOCUMENT_FILENAME";

export const GET_DOCUMENT = "GET_DOCUMENT";
export const SET_DOCUMENT = "SET_DOCUMENT";

export const SAVE_METADATA_DOCUMENT = "SAVE_METADATA_DOCUMENT";
export const SET_SAVED_METADATA_DOCUMENT = "SET_SAVED_METADATA_DOCUMENT";

export const SUBMIT_TO_IFLOW = "SUBMIT_TO_IFLOW";

export const GET_IFLOW_CONFIGURATION = "GET_IFLOW_CONFIGURATION";
export const SET_IFLOW_CONFIGURATION = "SET_IFLOW_CONFIGURATION";

export const HANDLE_SAVE_PO_DOCUMENT = "HANDLE_SAVE_PO_DOCUMENT";
export const REMOVE_FILES_DATACAPTURE = "REMOVE_FILES_DATACAPTURE";

export const SAVE_DOCUMENT_INTO_BATCH_OPENAI =
  "SAVE_DOCUMENT_INTO_BATCH_OPENAI";
export const CLEAR_SAVED_BATCH_OPENAI = "CLEAR_SAVED_BATCH_OPENAI";
export const START_SAVED_BATCH_OPENAI = "START_SAVED_BATCH_OPENAI";
export const STOP_SAVED_BATCH_OPENAI = "STOP_SAVED_BATCH_OPENAI";

export const CLEAR_FILE_UPLOADING_BATCH = "CLEAR_FILE_UPLOADING_BATCH";

//Read
export const findAllBatchs = () => ({ type: FIND_ALL_BATCHS });
export const findAllBatchsWithBinary = () => ({
  type: FIND_ALL_BATCHS_WITH_BINARY,
});
export const findBatchsByFilters = (filters) => ({
  type: FIND_BATCH_BY_FILTERS,
  filters,
});
export const setBatchs = (batchs) => ({ type: SET_BATCHS, batchs });
export const setBatchsWithBinary = (batchs) => ({
  type: SET_BATCHS_WITH_BINARY,
  batchs,
});
export const findBatchById = (id) => ({ type: FIND_BATCH_BY_ID, id });
export const setBatchById = (batch) => ({ type: SET_BATCH_BY_ID, batch });
export const clearBatchs = () => ({ type: CLEAR_BATCHS });
export const clearBatchsWithBinary = () => ({ type: CLEAR_BATCHS_WITH_BINARY });

export const findAllCustomBatchs = (filteredDocuments) => ({
  type: FIND_ALL_CUSTOM_BATCHS,
  filteredDocuments,
});
export const setCustomBatchs = (custombatchs) => ({
  type: SET_CUSTOM_BATCHS,
  custombatchs,
});
export const findAllCustomBinaryBatchs = () => ({
  type: FIND_ALL_BINARY_CUSTOM_BATCHS,
});

//Upload
export const saveDocumentIntoBatch = (batchDTO) => ({
  type: SAVE_DOCUMENT_INTO_BATCH,
  batchDTO,
});
export const saveDocumentIntoBatchOpenAi = (batchDTO) => ({
  type: SAVE_DOCUMENT_INTO_BATCH_OPENAI,
  batchDTO,
});
export const addDocumentToBatchOpenai = (document) => ({
  type: ADD_DOCUMENT_TO_BATCH_OPENAI,
  document,
});
export const clearFileUploadingBatch = () => ({ type: CLEAR_FILE_UPLOADING_BATCH });
export const clearDocumentToBatchOpenAi = () => ({
  type: CLEAR_SAVED_BATCH_OPENAI,
});
export const startDocumentToBatchOpenAi = () => ({
  type: START_SAVED_BATCH_OPENAI,
});
export const stopDocumentToBatchOpenAi = () => ({
  type: STOP_SAVED_BATCH_OPENAI,
});
export const addDocumentToBatch = (document) => ({
  type: ADD_DOCUMENT_TO_BATCH,
  document,
});
export const successSaveDocumentIntoBatch = (savedBatch) => ({
  type: SUCCESS_SAVE_DOCUMENT_BATCH,
  savedBatch,
});
export const removeFilesDatacapture = (toRemoveFiles) => ({
  type: REMOVE_FILES_DATACAPTURE,
  toRemoveFiles,
});
export const failSaveDocumentIntoBatch = (errorMessage) => ({
  type: FAIL_SAVE_DOCUMENT_BATCH,
  errorMessage,
});
export const clearSavedBatch = () => ({ type: CLEAR_SAVED_BATCH });
export const saveDocumentListIntoBatch = (docList) => ({
  type: SAVE_DOCUMENLIST_INTO_BATCH,
  docList,
});

export const updateApproved = (batchId, documentId) => ({
  type: HANDLE_UPDATE_APPROVED,
  batchId,
  documentId,
});
export const resetApproved = () => ({ type: HANDLE_RESET_APPROVED });
export const refreshApprovedDate = (batchId, documentId, newDate) => ({
  type: REFRESH_APPROVED_DATE,
  batchId,
  documentId,
  newDate,
});
export const approvedChanged = () => ({ type: HANDLE_CHANGED_APPROVED });
export const updateBatch = (payload) => ({
  type: HANDLE_UPDATE_BATCH,
  payload,
});
export const setUpdatedBatch = (updatedBatch) => ({
  type: SET_UPDATED_BATCH,
  updatedBatch,
});

export const deleteBatch = (id) => ({ type: HANDLE_DELETE_BATCH, id });
export const setDeletedBatch = (isDeletedBatch) => ({
  type: SET_DELETED_BATCH,
  isDeletedBatch,
});

export const deleteDocument = (document) => ({
  type: HANDLE_DELETE_DOCUMENT,
  document,
});
export const setDeletedDocument = (isDeletedDocument) => ({
  type: SET_DELETED_DOCUMENT,
  isDeletedDocument,
});
export const removeDocument = (document) => ({
  type: HANDLE_REMOVE_DOCUMENT,
  document,
});

export const deleteDocuments = (username) => ({
  type: HANDLE_DELETE_DOCUMENTS,
  username,
});
export const setDeletedDocuments = (isDeletedDocuments) => ({
  type: SET_DELETED_DOCUMENTS,
  isDeletedDocuments,
});
export const removeDocuments = () => ({ type: HANDLE_REMOVE_DOCUMENTS });

export const refreshDocument = (document) => ({
  type: REFRESH_DOCUMENT,
  document,
});
export const setRefreshedDocument = (document) => ({
  type: SET_REFRESHED_DOCUMENT,
  document,
});
export const clearIsRefreshed = (document) => ({ type: CLEAR_IS_REFRESHED });

export const updateDocuments = (documents) => ({
  type: HANDLE_UPDATE_DOCUMENTS,
  documents,
});
export const setUpdatedDocuments = (documents) => ({
  type: SET_UPDATED_DOCUMENTS,
  documents,
});

export const acceptDuplicate = (document) => ({
  type: HANDLE_UPDATE_DOCUMENT,
  document,
});
export const setUpdatedDocument = (document) => ({
  type: SET_UPDATED_DOCUMENT,
  document,
});

export const getMetadataDocument = (document) => ({
  type: GET_METADATA_DOCUMENT,
  document,
});
export const getMetadataDocumentFilename = (document) => ({
  type: GET_METADATA_DOCUMENT_FILENAME,
  document,
});
export const setMetadataDocument = (data) => ({
  type: SET_METADATA_DOCUMENT,
  data,
});

export const getDocument = (document) => ({ type: GET_DOCUMENT, document });
export const setDocument = (data) => ({ type: SET_DOCUMENT, data });

export const saveMetadataDocument = (metadataDocument) => ({
  type: SAVE_METADATA_DOCUMENT,
  metadataDocument,
});
export const setSavedMetadataDocument = (document) => ({
  type: SET_SAVED_METADATA_DOCUMENT,
  document,
});

export const submitToiFlow = (doc, flowId) => ({
  type: SUBMIT_TO_IFLOW,
  doc,
  flowId,
});

export const getIflowConfiguration = (application) => ({
  type: GET_IFLOW_CONFIGURATION,
  application,
});

export const setIflowConfiguration = (configuration) => ({
  type: SET_IFLOW_CONFIGURATION,
  configuration,
});

export const savePoNumber = (document) => ({
  type: HANDLE_SAVE_PO_DOCUMENT,
  document,
});
