import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Button, Form, Spinner } from "react-bootstrap";

import * as authDomain from "../utils/AuthDomain";
import { GRANT_TYPE_PASSWORD } from "~/utils/constants";

import * as actions from "../actions";
import { createSimpleUser } from "~/pages/Admin/User/actions";

import { logout } from "~/pages/Login/actions";
import { clearUser } from "~/containers/Layout/actions";
import { clearUserSettings } from "~/pages/User/actions";

import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { Box, Stack } from "@mui/material";

const devLogConsole = require("~/utils/devLog");
const RegularAuthentication = ({ old = false, t, isStyled }) => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [lDAPSignInWithValue, setLDAPSignInWithValue] = useState("");
  const [customizedLogin, setcustomizedLogin] = useState(isStyled);

  const {
    isLoadingLogin,
    globalSolverAuthenticated,
    simpleUserCreated,
    isSetLDAPSignIn,
    isAuthenticated,
    failLDPA,
  } = useSelector((state) => state.loginReducer);
  //devLogConsole("isSetLDAPSignIn",isSetLDAPSignIn);
  const { configuration, successRetrieveConfiguration } = useSelector(
    (state) => state.adminConfigReducer
  );

  //Login global solver success!
  useEffect(() => {
    if (globalSolverAuthenticated) {
      const payload = {
        username,
        password,
      };
      //devLogConsole("creating simple user")
      dispatch(createSimpleUser(payload));
    }
  }, [dispatch, globalSolverAuthenticated]);

  //Simple user created!
  useEffect(() => {
    if (simpleUserCreated) {
      const payload = {
        username,
        password,
        grant_type: GRANT_TYPE_PASSWORD,
      };
      //devLogConsole("requesting login SU")
      dispatch(actions.requestLogin(payload));
    }
  }, [dispatch, simpleUserCreated]);

  useEffect(() => {
    if (Object.keys(isSetLDAPSignIn).length > 0) {
      setLDAPSignInWithValue(true);
    } else {
      setLDAPSignInWithValue(false);
    }
  }, [isSetLDAPSignIn]);
  const handleLogin = (e) => {
    e.preventDefault();

    // Clear previous user's state
    dispatch(clearUserSettings());
    dispatch(clearUser());

    const payload = {
      username,
      password,
      grant_type: GRANT_TYPE_PASSWORD,
    };

    if (!configuration) return;

    if (username === "admin") {
      dispatch(actions.requestLogin(payload, true));
    } else if (authDomain.isLDAP() || authDomain.isADUniksystem()) {
      dispatch(actions.LDAPSignIn(payload));
      if (authDomain.isADUniksystem() && isAuthenticated) {
        dispatch(actions.requestLogin(payload));
      }
    } else if (
      authDomain.isIFlowSaml() ||
      authDomain.isGlobalSolver() ||
      authDomain.isUnikSystem()
    ) {
      dispatch(actions.requestLogin(payload));
    } else {
      dispatch(actions.requestLoginGlobalSolver({ username, password }));
    }
  };

  useEffect(() => {
    if (failLDPA && authDomain.isADUniksystem()) {
      const payload = {
        username,
        password,
        grant_type: GRANT_TYPE_PASSWORD,
      };
      dispatch(actions.requestLogin(payload));
    }
  }, [failLDPA]);

  return (
    <>
      {old ? (
        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formBasicEmail">
            <h1 className="label-credentials">{t("login.username")}</h1>
            <Form.Control
              type="text"
              autofill="false"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t("login.requiredUsername")}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <h1 className="label-credentials">{t("login.password")}</h1>
            <Form.Control
              type="password"
              required
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {t("login.requiredPassword")}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicCheckbox" className="checkbox">
            <div style={{ marginLeft: "15px" }}>
              <Form.Check type="checkbox" label={t("login.rememberMe")} />
            </div>
          </Form.Group>
          <div className="buttons">
            <Button
              type="submit"
              variant="outline-light"
              className={`${
                customizedLogin ? `${customizedLogin}Style` : "login-button"
              }`}
              style={{ backgroundColor: "#4484f4" }}
              disabled={!successRetrieveConfiguration}
            >
              {isLoadingLogin && (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("login.loading")}
                </>
              )}
              {!isLoadingLogin && t("login.submit")}
            </Button>
          </div>
        </Form>
      ) : (
        <Form onSubmit={handleLogin}>
          <Stack spacing={4} sx={{ marginBottom: "2rem", marginTop: "2rem" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AccountCircleOutlinedIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <div className="floating-label">
                <Form.Control
                  type="text"
                  name="new-username" // Adicione este atributo
                  id="new-username" // Adicione este atributo
                  autoComplete="new-username" // Use autoComplete em vez de autofill
                  placeholder=" "
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label>{t("login.username")}</label>
                <Form.Control.Feedback type="invalid">
                  {t("login.requiredUsername")}
                </Form.Control.Feedback>
              </div>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LockOpenOutlinedIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <div className="floating-label">
                <Form.Control
                  type="password"
                  name="new-password" // Adicione este atributo
                  id="new-password" // Adicione este atributo
                  autoComplete="new-password" // Use autoComplete em vez de autofill
                  placeholder=" "
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label>{t("login.password")}</label>
                <Form.Control.Feedback type="invalid">
                  {t("login.requiredPassword")}
                </Form.Control.Feedback>
              </div>
            </Box>
          </Stack>
          <div className="buttons">
            <LoadingButton
              type="submit"
              loading={isLoadingLogin}
              className={`${
                customizedLogin ? `${customizedLogin}Style` : "login-button"
              }`}
              variant="contained"
              disabled={!successRetrieveConfiguration}
              size="medium"
              sx={{ textTransform: "none" }}
            >
              {!isLoadingLogin && t("login.submit")}
            </LoadingButton>
          </div>
        </Form>
      )}
    </>
  );
};

export default withNamespaces()(RegularAuthentication);
