import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";

import {
  persistPermission,
  getPermissionById,
  clearPermission,
} from "./actions";
import { Card, Col, Button, Form } from "react-bootstrap";

import SpinnerNew from "~/components/SpinnerNew";
import Messages from "../../../components/Messages";
import ProtectedPage from "../../../components/security/ProtectedPage";

const PersistPermission = (props) => {
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [create, setCreate] = useState(false);
  const [read, setRead] = useState(false);
  const [update, setUpdate] = useState(false);
  const [_delete, setDelete] = useState(false); //delete is a reserved word
  const [selectAll, setSelectAll] = useState(false);

  const { permission, isLoadingPermission, isPersisting } = useSelector(
    (state) => state.permissionReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props.match.params;
  const { t } = props;
  const isEditing = !!id;

  useEffect(() => {
    if (id) {
      dispatch(getPermissionById(id));
    }

    return () => {
      dispatch(clearPermission());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (permission) {
      setDescription(permission.description);
      setLabel(permission.label);
      setCreate(permission.create);
      setRead(permission.read);
      setUpdate(permission.update);
      setDelete(permission.delete);
      setSelectAll(
        permission.create &&
          permission.read &&
          permission.update &&
          permission.delete
      );
    }
  }, [permission]);

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setCreate(true);
      setRead(true);
      setUpdate(true);
      setDelete(true);
    } else {
      setCreate(false);
      setRead(false);
      setUpdate(false);
      setDelete(false);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      persist();
    }
  };

  function persist() {
    const payload = {
      description,
      label,
      create,
      read,
      update,
      delete: _delete,
      active: true,
    };
    payload.id = isEditing ? parseInt(id) : null;
    dispatch(persistPermission(payload));
  }

  const backToSearch = () => {
    history.push("/uniksystem/admin/permission");
  };

  return (
    <ProtectedPage roles={["ADMIN"]}>
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <Card.Header className="justify-content-between">
              <h6>
                <i className="icon-add mr-2" />
                {`${isEditing ? t("general.edit") : t("general.add")}  ${t(
                  "admin.general.permission"
                )}`}
              </h6>
            </Card.Header>

            <Card.Body style={isLoadingPermission ? { display: "none" } : {}}>
              <Form.Row>
                <Col sm="12" md="8" lg="6">
                  <Form.Group controlId="description">
                    <Form.Label>
                      {t("admin.permission.persist.description")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={description || ""}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("admin.permission.persist.requiredDescription")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm="12" md="8" lg="6">
                  <Form.Group controlId="label">
                    <Form.Label>
                      {t("admin.permission.persist.label")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={label || ""}
                      disabled={isEditing}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("admin.permission.persist.requiredLabel")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm="12" md="8" lg="6">
                  <Form.Group controlId="actions">
                    <Form.Label>
                      {t("admin.permission.persist.actions")}
                    </Form.Label>
                    <br />
                    <Form.Check
                      inline
                      id={uuid()}
                      type="checkbox"
                      label="Select All"
                      checked={(create && read && update && _delete) || false}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                    <br />
                    <Form.Check
                      inline
                      id={uuid()}
                      type="checkbox"
                      label="Create"
                      checked={create || false}
                      onChange={(e) => setCreate(e.target.checked)}
                    />
                    <Form.Check
                      inline
                      id={uuid()}
                      type="checkbox"
                      label="Read"
                      checked={read || false}
                      onChange={(e) => setRead(e.target.checked)}
                    />
                    <Form.Check
                      inline
                      id={uuid()}
                      type="checkbox"
                      label="Update"
                      checked={update || false}
                      onChange={(e) => setUpdate(e.target.checked)}
                    />
                    <Form.Check
                      inline
                      id={uuid()}
                      type="checkbox"
                      label="Delete"
                      checked={_delete || false}
                      onChange={(e) => setDelete(e.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Card.Body>

            <Card.Footer>
              <Button variant="danger" onClick={backToSearch}>
                {t("general.back")}
              </Button>
              <Button
                className="card-button"
                type="submit"
                style={{ marginLeft: "10px", marginRight: "55px" }}
              >
                {t("general.save")}
              </Button>
            </Card.Footer>

          </Form>
        </Card>

        <SpinnerNew open={isPersisting || isLoadingPermission} />
        <Messages afterHideSuccess={backToSearch} />
      </div>
    </ProtectedPage>
  );
};

export default withNamespaces()(PersistPermission);
