import React , { useState }from "react";
import CreateTableTextarea from "../CreateTableTextarea";
import CreateTableSelectInput from "../CreateTableSelectInput";
import CreateTableTextInput from "../CreateTableTextInput";
import Modal from "~/pages/Easypay/components/Modal";
import uuid from "uuid/v1";

export const hasNestedValue = (obj, key, value, visited = new Set()) => {
  if (visited.has(obj)) return;
  visited.add(obj);
  if (Object.keys(obj).includes(key) && obj[key] == value) return true;

  for (const val of Object.values(obj)) {
    if (val && typeof val === "object") {
      const v = hasNestedValue(val, key, value, visited);
      if (v) return true;
    }
  }
  return false;
};


export const inputFormatter = (cell, row, rowIndex, formatExtraData) => {
  let tableInput;
  if (row[formatExtraData.index].input.type === "tabletext") {
    const isTextarea =
      row[formatExtraData.index].input?.maxlength >= 35 ||
      row[formatExtraData.index].input?.size >= 35
        ? true
        : false;
    tableInput = isTextarea ? (
      <CreateTableTextarea field={row[formatExtraData.index].input} />
    ) : (
      <CreateTableTextInput
        //style={{ borderRadius: "0.25rem" }}
        field={row[formatExtraData.index].input}
      />
    );
  }

  return (
    <div
      key={uuid()}
      style={{ padding: "5px 2px 5px 2px", margin: "-0.75rem" }}
    >
      {tableInput}
    </div>
  );
};

export const selectFormatter = (cell, row, rowIndex, formatExtraData) => {
  let tableInput;
  if (row[formatExtraData.index].input.type === "tableselection")
    tableInput = (
      <CreateTableSelectInput field={row[formatExtraData.index].input} position="relative" />
    );
  return (
    <div
      key={uuid()}
      style={{ padding: "5px 2px 5px 2px", margin: "-0.75rem" }}
    >
      {tableInput}
    </div>
  );
};


/*
export const numberFormatter = (cell, row, rowIndex, formatExtraData) => {
  const value = row[formatExtraData.index];
  const parsed = parseInt(value);

  return <div key={uuid()}>{isNaN(parsed) ? value : parsed}</div>;
};*/

export const numberFormatter = (cell, row) => {
  const parsed = parseInt(cell);
  return isNaN(parsed) ? cell : parsed;
};
