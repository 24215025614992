import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, Tab, Tabs } from "react-bootstrap";

import SpinnerNew from "~/components/SpinnerNew";
import AutomatedDocumentTable from "./components/AutomatedDocumentTable";
import DataCaptureSettingsModal from "./components/DataCaptureSettingsModal";
import "./AutomatedDataCapture.css";
import "~/assets/css/icons.css";
import NonFinAutomatedDocumentTable from "./components/NonFinAutomatedDocumentTable";
import { getOpenAIConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import UploadModal from "./components/UploadModal";
import UploadModalOpenAi from "./components/UploadModalOpenAi";
import UploadSingleDoc from "./components/UploadSingleDoc";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import {
  clearDocumentToBatchOpenAi,
  clearSavedBatch,
  findAllBatchs,
  deleteBatch,
} from "~/pages/DataCapture/actions";
import { setPidExpenses } from "~/store/ducks/openai/actionTypes";

const AutomatedDatacapture = ({
  t,
  simpleFlow,
  datacaptureConfiguration,
  showUploadModal,
  setShowUploadModal,
  showUploadModalOpenAi,
  setShowUploadModalOpenAi,
}) => {
  const { isLoadingUpdateBatch, isLoadingDeleteBatch, batchOpenAi } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { pidExpenses } = useSelector((state) => state.openaiReducer);

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [defaultKey, setDefaultKey] = useState("financial-document");
  const [fromTabUploadModal, setFromTabUploadModal] = useState(defaultKey);
  const [docTypeSelected, setDocTypeSelected] = useState("financial-document");
  const [docTypeExtraConfiguration, setDocTypeExtraConfiguration] = useState(
    []
  );
  const [selectedDocType, setSelectedDocType] = useState("");
  const [datasetSelected, setDatasetSelected] = useState({});
  const [isSingleDoc, setIsSingleDoc] = useState(null);
  const [showSingleDocModal, setShowSingleDocModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOpenAIConfiguration(user?.organization?.id));
      dispatch(getOrganizationById(user.organization.id));
    }
  }, [user]);

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const closeSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setFromTabUploadModal(docTypeSelected);
    dispatch(findAllBatchs());
    dispatch(clearSavedBatch());
  };

  const handleCloseUploadModalOpenAi = () => {
    if (batchOpenAi.documentsOpenai && batchOpenAi.documentsOpenai.length > 0) {
      for (const batch of batchOpenAi.documentsOpenai) {
        if (batch.id) {
          dispatch(deleteBatch(batch.id));
        }
      }
    }
    if (pidExpenses) {
      dispatch(setPidExpenses({}));
    }
    setShowUploadModalOpenAi(false);
    dispatch(findAllBatchs());
    dispatch(clearDocumentToBatchOpenAi());
  };

  const handleCloseSingleDocModal = () => {
    setShowSingleDocModal(false);
    dispatch(findAllBatchs());
    dispatch(clearSavedBatch());
  }

  useEffect(() => {
    if (menu) {
      let app = "";
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/");
        app = arr3[0];
      }
      const menuItem = menu.find(
        (item) => item.route === `${app}/submit-document`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const doctypeExtraConfiguration_ =
          extraConfiguration_ && extraConfiguration_.docTypes;
        setDocTypeExtraConfiguration(doctypeExtraConfiguration_);
        const isSingleDoc_ =
          extraConfiguration_ && extraConfiguration_.singleDoc;
        setIsSingleDoc(isSingleDoc_);
      }
    }
  }, [menu]);
  return (
    <>
      <UploadModal
        docTypeSelected={docTypeSelected}
        showUploadModal={
          showUploadModal ? fromTabUploadModal === docTypeSelected : false
        }
        handleCloseUploadModal={handleCloseUploadModal}
        simpleFlow={simpleFlow}
        datacaptureConfiguration={datacaptureConfiguration}
        docTypeExtraConfiguration={docTypeExtraConfiguration}
      />

      <UploadModalOpenAi
        selectedDocType={selectedDocType}
        datasetSelected={datasetSelected}
        showModalOpenAi={showUploadModalOpenAi}
        handleCloseUploadModalOpenAi={handleCloseUploadModalOpenAi}
      />

      <UploadSingleDoc
        selectedDocType={selectedDocType}
        datasetSelected={datasetSelected}
        showModal={showSingleDocModal}
        setShowModal={setShowSingleDocModal}
        handleClose={handleCloseSingleDocModal}
      />
      <div className="main-card-v2">
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("DataCaptureWidget.title")}</h6>
            <h6
              className="data-capture-settings"
              onClick={() => {
                openSettingsModal();
              }}
            >
              <i className="icon-gear mr-2" />
            </h6>
          </Card.Header>
          <Tabs
            className="custom-tabs"
            id="automatedDocumentTabs"
            defaultActiveKey={defaultKey}
            onSelect={() => {}}
          >
            {datacaptureConfiguration.map((e, index) => {
              return (
                <Tab
                  eventKey={e.documentType}
                  title={t("datacapture.filter." + e.documentType)}
                  key={index}
                >
                  {e.documentType !== "financial-document" ? (
                    <NonFinAutomatedDocumentTable
                      defaultTab={defaultKey}
                      docTypeSelected={e.documentType}
                      key={index}
                      openSettingsModal={openSettingsModal}
                      simpleFlow={simpleFlow}
                      datacaptureConfiguration={datacaptureConfiguration}
                      showUploadModal={
                        showUploadModal
                          ? fromTabUploadModal === e.documentType
                          : false
                      }
                      setShowUploadModal={setShowUploadModal}
                      setFromTabUploadModal={setFromTabUploadModal}
                      setDocTypeSelected={setDocTypeSelected}
                    />
                  ) : (
                    <AutomatedDocumentTable
                      defaultTab={defaultKey}
                      key={index + "-fin"}
                      openSettingsModal={openSettingsModal}
                      simpleFlow={simpleFlow}
                      datacaptureConfiguration={datacaptureConfiguration}
                      showUploadModal={
                        showUploadModal
                          ? fromTabUploadModal === e.documentType
                          : false
                      }
                      setShowUploadModal={setShowUploadModal}
                      setFromTabUploadModal={setFromTabUploadModal}
                      setDocTypeSelected={setDocTypeSelected}
                      docTypeExtraConfiguration={docTypeExtraConfiguration}
                      setShowUploadModalOpenAi={setShowUploadModalOpenAi}
                      setSelectedDocType={setSelectedDocType}
                      setDatasetSelected={setDatasetSelected}
                      isSingleDoc={isSingleDoc}
                      setShowSingleDocModal={setShowSingleDocModal}
                    />
                  )}
                </Tab>
              );
            })}
          </Tabs>
        </Card>
      </div>
      <DataCaptureSettingsModal
        closeModal={closeSettingsModal}
        showModal={showSettingsModal}
        simpleFlow={simpleFlow}
      />
      <SpinnerNew
        open={isLoadingDeleteBatch || isLoadingUpdateBatch}
      />
    </>
  );
};
export default withNamespaces()(AutomatedDatacapture);
