import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import {
  Modal,
  Button,
  Form,
  Col,
  ButtonGroup,
  ToggleButton,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { pdfjs } from "react-pdf";
import SpinnerNew from "~/components/SpinnerNew";
import { useDispatch, useSelector } from "react-redux";
import StampComponent from "./StampComponent";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  findUserSettingsStamp,
  getSignatureCoordinates,
} from "~/store/ducks/processesSignature/actionTypes";
import { updateSignatureReduced } from "~/pages/User/actions";

import { SIGNATURE_COORDINATES_X, SIGNATURE_COORDINATES_Y } from "../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const commentPreviewLetterSize = 12;
const commentPreviewSpacing = 5;
const commentPreviewFont = "Arial";
const devLogConsole = require("~/utils/devLog");
const SignPdfModal = ({
  t,
  showSignModal,
  handleCloseSignModal,
  testSignPdf,
  file, //base64
  isLoadingIflowDocument,
  styleSheet,
  username,
  field,
  selectedToSign,
}) => {
  const dispatch = useDispatch();

  const {
    nextSignatureCoordinates,
    userSettingsStamp,
    isLoadingSignatureCoordinates,
  } = useSelector((selectorState) => selectorState.processesSignatureReducer);

  useEffect(() => {
    if (username && showSignModal) {
      dispatch(findUserSettingsStamp(username));
    }
  }, [username, showSignModal]);

  const getDocIdorHash = (file) => {
    let result = file.id;
    if (file.id.includes("ref>/iFlow/document?hdoc=")) {
      result = file.id.split("hdoc=")[1];
    }
    return result;
  };

  const isHash = (file) => {
    if (file.id.includes("ref>/iFlow/document?hdoc=")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (showSignModal && styleSheet && styleSheet.signaturecma) {
      if (selectedToSign && selectedToSign.length == 1) {
        selectedToSign.forEach((el) => {
          if (el.file) {
            if (getDocIdorHash(el?.file)) {
              const payload = {
                isHash: isHash(el?.file),
                docid: getDocIdorHash(el?.file),
                flowid: el?.flowid,
                pid: el?.pid,
                subpid: el?.subpid,
              };
              dispatch(getSignatureCoordinates(payload));
            }
          }
        });
      }

      setRadioValue(null);
    }
  }, [field, styleSheet, showSignModal, selectedToSign]);

  //relative signature coordinates
  const [signatureCoordinates, setSignatureCoordinates] = useState({
    x: SIGNATURE_COORDINATES_X,
    y: SIGNATURE_COORDINATES_Y,
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [lastPage, setLastPage] = useState(true);
  const [reduced, setReduced] = useState(false);
  const [givenLocation, setGivenLocation] = useState("");
  const [givenReason, setGivenReason] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(765);
  const [canvasHeight, setCanvasHeight] = useState(1000);
  const [longText, setLongText] = useState("");
  const [longTextCanvasData, setLongTextCanvasData] = useState("");
  const [radioValue, setRadioValue] = useState("1");
  const [stampAll, setStampAll] = useState(false);
  const [stampImage, setStampImage] = useState(undefined);
  const [stampWithoutSign, setStampWithoutSign] = useState(false);
  const [addStamp, setAddStamp] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  //draw rectangle
  const [rectangleOffsets, setRectangleOffsets] = useState({ x: 0, y: 0 });
  const [isDown, setIsDown] = useState(false);
  //used by backend relative coords
  const [rectCoordBottomLeft, setRectCoordBottomLefts] = useState({
    x: 0,
    y: 0,
  });
  const [rectCoordTopLeft, setRectCoordsTopLeft] = useState({ x: 0, y: 0 });
  const [rectCoordBottomRight, setRectCoordsBottomRight] = useState({
    x: 0,
    y: 0,
  });

  const [activeKey, setTabKey] = useState("0");
  const [simpleSignatureRadio, setSimpleSignatureRadio] = useState(2);

  const myRef = useRef(null);

  useEffect(() => {
    if (numPages && lastPage) {
      setPageNumber(numPages);
    }
  }, [numPages, lastPage]);

  useEffect(() => {
    if (pageNumber == numPages) setLastPage(true);
    else setLastPage(false);
  }, [pageNumber]);

  useEffect(() => {
    if (
      userSettingsStamp &&
      userSettingsStamp.signatureReduced !== undefined &&
      userSettingsStamp.signatureReduced !== null
    ) {
      setReduced(userSettingsStamp.signatureReduced);
      //setReduced(true);
    }
  }, [userSettingsStamp]);

  useEffect(() => {
    if (
      pageNumber &&
      nextSignatureCoordinates &&
      selectedToSign &&
      selectedToSign.length == 1
    ) {
      let docid = getDocIdorHash(selectedToSign[0]?.file);
      const foundItemKey = Object.keys(nextSignatureCoordinates).find(
        (key) => key === docid
      );
      if (nextSignatureCoordinates[foundItemKey]) {
        const foundItem = nextSignatureCoordinates[foundItemKey].find(
          (el) => el.nr == pageNumber
        );
        if (
          foundItem &&
          foundItem.coordenadasProximaAssinatura &&
          foundItem.coordenadasProximaAssinatura.x &&
          foundItem.coordenadasProximaAssinatura.y
        ) {
          //if (!foundItem.validNextSignature) {
          //setWarningMessage(t("signature.alertChoosePosition"));
          if (activeKey !== "1" && radioValue !== "2") {
            setSignatureCoordinates(foundItem.coordenadasProximaAssinatura);
          }
        }
      }
    }
  });

  useEffect(() => {
    if (selectedToSign.length !== 1) {
      setSignatureCoordinates({
        x: SIGNATURE_COORDINATES_X,
        y: SIGNATURE_COORDINATES_Y,
      });
    }
  }, [selectedToSign.length]);

  const onMouseDown = (e) => {
    if (radioValue === "2") {
      e.preventDefault();
      e.stopPropagation();
      const bounds = e.target.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;
      setRectangleOffsets({ x: x, y: y });
      setIsDown(true);
    }
  };

  const onMouseUp = (e) => {
    if (radioValue === "2") {
      e.preventDefault();
      e.stopPropagation();
      setIsDown(false);
    }
  };
  const onMouseOut = (e) => {
    if (radioValue === "2") {
      e.preventDefault();
      e.stopPropagation();
      setIsDown(false);
    }
  };

  const onMouseMove = (e) => {
    if (radioValue === "2") {
      e.preventDefault();
      e.stopPropagation();
      if (!isDown) {
        return;
      }
      const canvas = document.getElementById("a4-canvas-longText");
      const context = canvas.getContext("2d");
      context.strokeStyle = "rgba(255,165,0, 0.9)";
      // get the current mouse position
      const bounds = e.target.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;

      // clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // draw a new rect from the start position
      // to the current mouse position

      //get canvas start position coords
      const xrelStart = (rectangleOffsets.x / bounds.width).toFixed(2);
      const yrelStart = (rectangleOffsets.y / bounds.height).toFixed(2);
      const xCanvasStart = canvas.width * xrelStart;
      const yCanvasStart = canvas.height * yrelStart;
      /*get canvas width and height*/
      //find width
      const xrelMouse = (x / bounds.width).toFixed(2);
      const xCanvasMouse = canvas.width * xrelMouse;
      const widthRectCanvas = xCanvasMouse - xCanvasStart;
      //find height
      const yrelMouse = (y / bounds.height).toFixed(2);
      const yCanvasMouse = canvas.height * yrelMouse;
      const heightRectCanvas = yCanvasMouse - yCanvasStart;

      const canvasData = {
        canvas: canvas,
        context: context,
        xCanvasStart: xCanvasStart,
        yCanvasStart: yCanvasStart,
        widthRectCanvas: widthRectCanvas,
        heightRectCanvas: heightRectCanvas,
      };

      setLongTextCanvasData(canvasData);
      renderCommentTextArea(canvasData);

      //save coords for backend
      const xBottomLeftrel = xrelStart;
      const yBottomLeftrel = 1 - yrelMouse;
      setRectCoordBottomLefts({ x: xBottomLeftrel, y: yBottomLeftrel });
      //bottomRight
      const xBottomRightrel = xrelMouse;
      const yBottomRightrel = 1 - yrelMouse;
      setRectCoordsBottomRight({ x: xBottomRightrel, y: yBottomRightrel });
      //topLeft
      const xTopLeftrel = xrelStart;
      const yTopLeftrel = 1 - yrelStart;
      setRectCoordsTopLeft({ x: xTopLeftrel, y: yTopLeftrel });

      //place signature
      const canvas_ = document.getElementById("a4-canvas");
      const yrelTopLeft = (
        (canvas.height * (1 - yBottomLeftrel) + 100) /
        canvas.height
      ).toFixed(2); //top left
      setSignatureCoordinates({ x: xBottomLeftrel, y: yrelTopLeft });
      const xCanvas_ = canvas_.width * xrelStart;
      const yCanvas_ = canvas_.height * yrelMouse;
      drawSignature(canvas_, xCanvas_, yCanvas_);
    }
  };

  useEffect(() => {
    if (longTextCanvasData !== "") {
      renderCommentTextArea(longTextCanvasData);
    }
  }, [longText, longTextCanvasData]);

  //prints the Comment text area
  const renderCommentTextArea = (canvasData) => {
    const {
      canvas,
      context,
      xCanvasStart,
      yCanvasStart,
      widthRectCanvas,
      heightRectCanvas,
    } = canvasData;

    context.clearRect(0, 0, canvas.width, canvas.height);

    context.strokeRect(
      xCanvasStart,
      yCanvasStart,
      widthRectCanvas,
      heightRectCanvas
    );
    context.font = "18px serif";
    context.fillStyle = "rgb(255,165,0)";
    context.fillText(
      t("signature.drawCommentBlockTitle"),
      xCanvasStart,
      yCanvasStart,
      widthRectCanvas
    );
    if (!styleSheet?.signaturecma) {
      context.textBaseline = "bottom";
    }
    context.fillStyle = "black";
    context.font = commentPreviewLetterSize + "px " + commentPreviewFont;

    //wraps text in the box (respects new lines) and prints it
    const lines = longText.split("\n");
    var line = "";
    var lineIndex = 0;
    for (var i = 0; i < lines.length; i++) {
      const words = lines[i].split(" ");
      while (words.length != 0 || line !== "") {
        const word = words[0];
        if (
          words.length == 0 ||
          context.measureText(line).width +
            context.measureText(word).width +
            commentPreviewSpacing >=
            widthRectCanvas
        ) {
          if (
            lineIndex * commentPreviewLetterSize +
              commentPreviewSpacing +
              commentPreviewLetterSize >
            heightRectCanvas
          ) {
            return;
          }
          const lineHeight = commentPreviewLetterSize + commentPreviewSpacing;
          const yText =
            yCanvasStart + lineIndex * lineHeight + commentPreviewLetterSize;
          // const lineHeight = commentPreviewLetterSize + commentPreviewSpacing;
          // const lines = longText.split("\n").length;
          // const yText = yCanvasStart + heightRectCanvas - (lines - lineIndex) * lineHeight;
          context.fillText(
            line,
            xCanvasStart + commentPreviewSpacing,
            yText,
            widthRectCanvas,
            heightRectCanvas
          );
          line = "";
          lineIndex++;
        } else {
          words.shift();
          line = line + word + " ";
        }
      }
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onRenderSuccess() {
    const elements = document.getElementsByClassName("react-pdf__Page__canvas");
    if (elements[0]) {
      elements[0].width && setCanvasWidth(elements[0].width);
      elements[0].height && setCanvasHeight(elements[0].height);
    }
  }

  const hideHelper = () => {
    const helper = document.getElementById("helper");
    if (helper) helper.style.display = "none";
  };

  function onLoadError() {
    hideHelper();
    setDisabled(true);
  }

  const drawSignature = (canvas, xCanvas, yCanvas) => {
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "rgba(41,128,185, 0.5)";
    context.beginPath();
    context.rect(
      xCanvas,
      yCanvas,
      canvas.width * (styleSheet?.signaturecma ? 0.28 : 0.3),
      canvas.width * (styleSheet?.signaturecma ? 0.28 : 0.36) * 0.45
    );
    context.fill();
    context.fillStyle = "rgba(41,128,185)";
    context.font = "18px serif";
    context.textBaseline = "bottom";
    context.fillText(t("signature.drawImgBlockTitle"), xCanvas, yCanvas, 250);
  };

  const renderSeloCC = () => {
    return (
      <>
        <Form.Label column>
          <h4
            style={{ fontSize: "16px", marginBottom: "0px", marginTop: "10px" }}
          >
            {t("signature.signatureSubHeader")}
          </h4>
        </Form.Label>
        <div className="signature-col-wrapper">
          <Col sm="12" md="12" lg="12">
            <Form.Row>
              <Form.Label>{t("signature.givenReason")}</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                value={givenReason}
                onChange={(e) => {
                  setGivenReason(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
          <Col sm="12" md="12" lg="12">
            <Form.Row>
              <Form.Label>{t("signature.givenLocation")}</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                style={{ marginBottom: "10px" }}
                value={givenLocation}
                onChange={(e) => {
                  setGivenLocation(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
        </div>
      </>
    );
  };

  const handleRelativeCoords = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (radioValue === "1") {
      const bounds = e.target.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const y = e.clientY - bounds.top;
      const xrel = (x / bounds.width).toFixed(2);
      const yrel = (y / bounds.height).toFixed(2); //bottom left
      const yrelTopLeft = ((y + 100) / bounds.height).toFixed(2); //top left
      setSignatureCoordinates({ x: xrel, y: yrelTopLeft });
      const canvas = document.getElementById("a4-canvas");
      const xCanvas = canvas.width * xrel;
      const yCanvas = canvas.height * yrel;
      drawSignature(canvas, xCanvas, yCanvas);
    }
  };

  const renderOptions = () => {
    if (numPages) {
      const options = [];
      for (let i = 1; i <= numPages; i++) {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return options;
    }
  };

  function parseNumber() {
    if (pageNumber) {
      const parsed = Number.parseInt(pageNumber);
      if (Number.isNaN(parsed)) {
        return 1;
      }
      return parsed;
    }
    return 1;
  }

  const radios = [
    { name: t("signature.placeSignature"), value: "1" },
    { name: t("signature.placeComment"), value: "2" },
  ];

  const handleSign = () => {
    if (styleSheet?.signaturecma) {
      let isCustomLogo = false;
      let stampAll_ = false;
      let stampImage_ = stampImage;
      //Assinatura Simples && Outras opções
      if (activeKey === "0" || activeKey === "2") {
        if (simpleSignatureRadio == 2 || simpleSignatureRadio == 3) {
          isCustomLogo = true;
        }
        if (simpleSignatureRadio == 3) {
          stampAll_ = true;
        }
        if (simpleSignatureRadio == 1) {
          stampImage_ = null;
        }
        testSignPdf(
          signatureCoordinates,
          pageNumber,
          lastPage,
          reduced,
          "", //givenLocation
          "", //givenReason
          rectCoordBottomLeft,
          rectCoordTopLeft,
          rectCoordBottomRight,
          "", //longText
          stampAll_,
          stampImage_,
          false, //stampWithoutSign
          isCustomLogo
        );
      } else if (activeKey === "1") {
        if (simpleSignatureRadio == 2 || simpleSignatureRadio == 3) {
          isCustomLogo = true;
        } else if (simpleSignatureRadio == 1) {
          isCustomLogo = false;
          stampImage_ = null;
        }

        if (simpleSignatureRadio == 3) {
          stampAll_ = true;
        }
        //Assinatura avançada
        testSignPdf(
          signatureCoordinates,
          pageNumber,
          lastPage,
          reduced,
          givenLocation,
          givenReason,
          rectCoordBottomLeft,
          rectCoordTopLeft,
          rectCoordBottomRight,
          longText,
          stampAll_,
          stampImage_,
          false, //stampWithoutSign
          isCustomLogo
        );
      }
    } else {
      //Default
      testSignPdf(
        signatureCoordinates,
        pageNumber,
        lastPage,
        reduced,
        givenLocation,
        givenReason,
        rectCoordBottomLeft,
        rectCoordTopLeft,
        rectCoordBottomRight,
        longText,
        stampAll,
        stampImage,
        stampWithoutSign
      );
    }
  };

  const renderPageChooser = () => {
    return (
      radioValue && (
        <>
          {numPages > 1 && <br />}
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginBottom: "5px",
            }}
          >
            {numPages > 1 && (
              <>
                <div style={{ display: "flex", padding: "0px" }}>
                  <div style={{ padding: "0.375rem 15px" }}>
                    {t("signature.page")} &nbsp;
                  </div>
                  <Form.Control
                    as="select"
                    style={{ width: "auto", maxHeight: "40px" }}
                    onChange={(e) => setPageNumber(e.target.value)}
                    value={pageNumber || ""}
                  >
                    {renderOptions()}
                  </Form.Control>
                  <div style={{ padding: "0.375rem 0" }}>
                    {" "}
                    &nbsp; {t("signature.of")} {numPages}
                  </div>
                </div>

                <Col sm="6" md="6" lg="6">
                  <Form.Row>
                    <Form.Check
                      type="switch"
                      id="last-page"
                      label={t("signature.lastPage")}
                      style={{ marginBottom: "10px", marginLeft: "15px" }}
                      checked={lastPage}
                      onChange={() => setLastPage((lastPage) => !lastPage)}
                    />
                  </Form.Row>
                </Col>
              </>
            )}
          </div>
        </>
      )
    );
  };

  const renderSignature = (isTabs) => {
    return (
      <>
        {!isTabs ? (
          <Form.Label column>
            <h4 style={{ fontSize: "16px" }}>
              {t("signature.signatureSubHeader")}
            </h4>
          </Form.Label>
        ) : (
          <br />
        )}
        {styleSheet?.signatureType === "simplified" ? (
          <>
            {" "}
            <div className="signature-col-wrapper">
              <Col sm="12" md="12" lg="12">
                <Form.Row>
                  <Form.Check
                    type="switch"
                    id="reduced"
                    label={t("signature.reduced")}
                    style={{ marginBottom: "10px" }}
                    checked={reduced}
                    onChange={() => setReduced((reduced) => !reduced)}
                  />
                </Form.Row>
              </Col>
            </div>
          </>
        ) : (
          <>
            <div className="signature-col-wrapper">
              <Col sm="12" md="12" lg="12">
                <Form.Row>
                  <Form.Check
                    type="switch"
                    id="reduced"
                    label={t("signature.reduced")}
                    style={{ marginBottom: "10px" }}
                    checked={reduced}
                    onChange={() => setReduced((reduced) => !reduced)}
                  />
                </Form.Row>
              </Col>
              <Col sm="12" md="12" lg="12">
                <Form.Row>
                  <Form.Label>{t("signature.givenReason")}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    value={givenReason}
                    onChange={(e) => {
                      setGivenReason(e.target.value);
                    }}
                  />
                </Form.Row>
              </Col>
              <Col sm="12" md="12" lg="12">
                <Form.Row>
                  <Form.Label>{t("signature.givenLocation")}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    style={{ marginBottom: "10px" }}
                    value={givenLocation}
                    onChange={(e) => {
                      setGivenLocation(e.target.value);
                    }}
                  />
                </Form.Row>
              </Col>
            </div>
          </>
        )}
      </>
    );
  };

  const renderOthers = () => {
    return (
      <>
        <div className="signature-col-wrapper">
          <>
            <Form.Label column style={{ paddingLeft: "0px" }}>
              <h4 style={{ fontSize: "16px" }}>
                {t("signature.carimboSubHeader")}
              </h4>
            </Form.Label>
            <StampComponent setStampImage={setStampImage} username={username} />
            <Form.Label column style={{ paddingLeft: "0px" }}>
              <h4 style={{ fontSize: "16px" }}>{t("signature.seloCC")}</h4>
            </Form.Label>
            <Form.Check
              type="switch"
              id="reduced"
              label={t("signature.seloReduced")}
              style={{ marginBottom: "10px" }}
              checked={reduced}
              onChange={(e) => {
                if (userSettingsStamp) {
                  const payload = userSettingsStamp;
                  payload.signatureReduced = e.target.checked;
                  dispatch(updateSignatureReduced(payload));
                }
                setReduced((reduced) => !reduced);
              }}
            />
          </>
        </div>
        {/*renderSignButton()*/}
      </>
    );
  };

  const renderComment = () => {
    return (
      <div className="signature-col-wrapper">
        <Form.Label column>
          <h4 style={{ fontSize: "16px" }}>{t("signature.longText")}</h4>
        </Form.Label>
        <Col sm="12" md="12" lg="12">
          <Form.Row>
            <textarea
              className="form-control"
              type="textarea"
              rows={3}
              cols={50}
              style={{ marginBottom: "10px" }}
              value={longText}
              onChange={(e) => setLongText(e.target.value)}
            />
          </Form.Row>
        </Col>
      </div>
    );
  };

  const renderRadioOptions = () => {
    const options = [
      {
        id: 1,
        name: "signature-radio-1",
        label: "signature.seloCC",
      },
      {
        id: 2,
        name: "signature-radio-2",
        label: "signature.rubricarUltima",
      },
      {
        id: 3,
        name: "signature-radio-3",
        label: "signature.rubricarTodas",
      },
    ];
    return (
      <div className="signature-col-wrapper">
        <Col>
          <Form.Row style={{ flexWrap: "nowrap" }}>
            {options.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexWrap: "nowrap", flexGrow: "1" }}
                >
                  <input
                    id={item.id}
                    type="checkbox"
                    name="signature-radio-1"
                    checked={simpleSignatureRadio == item.id ? true : false}
                    style={{ marginRight: "10px" }}
                    onChange={(e) => {
                      if (
                        activeKey === "1" &&
                        (item.id === 2 || item.id === 3)
                      ) {
                        setRadioValue("1");
                        setLongText("");
                      }
                      setSimpleSignatureRadio(
                        simpleSignatureRadio === item.id ? null : item.id
                      );
                    }}
                  />
                  <div
                    className={
                      simpleSignatureRadio == item.id
                        ? "text-primary-color"
                        : ""
                    }
                  >
                    {t(item.label)}
                  </div>
                </div>
              );
            })}
          </Form.Row>
        </Col>
      </div>
    );
  };

  const renderAssinaturaAvancada = () => {
    if (activeKey === "1") {
      if (simpleSignatureRadio == 1) {
        //selo SS avançado
        return (
          <>
            {renderSeloCC()}
            {renderComment()}
            {renderSelectPosRadios()}
          </>
        );
      }
    }
  };

  const renderTabs = () => {
    return (
      <Tabs
        className="custom-tabs"
        style={{ borderBottom: "none" }}
        defaultActiveKey={activeKey}
        onSelect={(k) => {
          //if Tab avançada set colocar assinatura
          if (k === "1") {
            setRadioValue("1");
          }
          setTabKey(k);
        }}
      >
        <Tab eventKey={"0"} title={t("signature.signatureSimpleSubHeader")}>
          {renderRadioOptions()}
          {renderSelectedToSign()}
          {renderSignButton()}
        </Tab>
        {selectedToSign && (
          <Tab eventKey={"1"} title={t("signature.signatureAdvancedSubHeader")}>
            {renderRadioOptions()}
            {renderSelectedToSign()}
            {renderAssinaturaAvancada()}
            {renderSignButton()}
            {renderPageChooser()}
          </Tab>
        )}
        <Tab eventKey={"2"} title={t("signature.otherOptionsSubHeader")}>
          {renderOthers()}
        </Tab>
      </Tabs>
    );
  };

  const renderStampFeatures = (isTabs) => {
    return (
      <div style={{ display: "flex", marginTop: "10px" }}>
        <Col>
          <Form.Row>
            <Form.Check
              type="switch"
              id="stampAll"
              label={t("signature.stampAll")}
              style={{ marginBottom: "10px" }}
              checked={stampAll}
              onChange={() => setStampAll((stampAll) => !stampAll)}
            />
          </Form.Row>
        </Col>
        <Col>
          <Form.Row>
            <Form.Check
              type="switch"
              id="stampWithoutSign"
              label={t("signature.stampWithoutSign")}
              style={{ marginBottom: "10px", opacity: isTabs ? "0" : "1" }}
              checked={stampWithoutSign}
              onChange={() =>
                setStampWithoutSign((stampWithoutSign) => !stampWithoutSign)
              }
            />
          </Form.Row>
        </Col>
      </div>
    );
  };

  const renderStamp = (isTabs) => {
    return (
      <>
        <div className="signature-col-wrapper">
          {!isTabs ? (
            <Form.Label column>
              <h4 style={{ fontSize: "16px" }}>
                {t("signature.carimboSubHeader")}
              </h4>
            </Form.Label>
          ) : (
            <br />
          )}

          {!isTabs ? (
            <>
              <Col>
                <Form.Row>
                  <Form.Check
                    type="switch"
                    id="stamp"
                    label={t("signature.useStamp")}
                    style={{ marginBottom: "10px" }}
                    checked={addStamp}
                    onChange={() => setAddStamp((addStamp) => !addStamp)}
                  />
                </Form.Row>
              </Col>
              {addStamp && (
                <>
                  <StampComponent
                    setStampImage={setStampImage}
                    username={username}
                  />
                  {renderStampFeatures(isTabs)}
                </>
              )}
            </>
          ) : (
            <>
              <StampComponent
                setStampImage={setStampImage}
                username={username}
              />
              {renderStampFeatures(isTabs)}
            </>
          )}
        </div>
      </>
    );
  };

  const renderSelectedToSign = () => {
    return (
      <>
        <div className="signature-col-wrapper">
          {selectedToSign.map((el, i) => {
            return (
              <Form.Label
                column
                key={i}
                style={{ backgroundColor: i % 2 == 0 ? "" : "#e7e6e6" }}
              >
                {el?.file?.name}
              </Form.Label>
            );
          })}
          <br />
        </div>
      </>
    );
  };

  const renderSelectPositionRadios = () => {
    return (
      <div className="signature-col-wrapper">
        <Form.Label column>
          <h4 style={{ fontSize: "16px" }}>{t("signature.selectOption")}</h4>
        </Form.Label>
        <ButtonGroup className="mb-2">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="secondary"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        {radioValue && (
          <div id="helper">
            <Alert variant="info">
              {radioValue === "1"
                ? t("signature.signHelper")
                : t("signature.signHelperDragMouse")}
            </Alert>
          </div>
        )}
      </div>
    );
  };

  const renderSelectPosRadios = () => {
    const options = [
      { name: t("signature.placeComment"), value: "2" },
      { name: t("signature.placeSignature"), value: "1" },
    ];

    return (
      <div className="signature-col-wrapper">
        <Col>
          <Form.Row style={{ flexWrap: "nowrap", marginBottom: "10px" }}>
            {options.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexWrap: "nowrap", flexGrow: "1" }}
                >
                  <input
                    id={item.value}
                    type="checkbox"
                    name="select-option-radio-1"
                    checked={radioValue == item.value ? true : false}
                    style={{ marginRight: "10px" }}
                    onChange={(e) => {
                      setRadioValue(
                        radioValue === item.value ? null : item.value
                      );
                    }}
                  />
                  <div
                    className={
                      radioValue === item.value ? "text-primary-color" : ""
                    }
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </Form.Row>
        </Col>

        {radioValue && (
          <div id="helper">
            <Alert variant="info">
              {radioValue === "1"
                ? t("signature.signHelper")
                : t("signature.signHelperDragMouse")}
            </Alert>
          </div>
        )}
      </div>
    );
  };

  const renderSignButton = () => {
    const isDisabled =
      styleSheet && styleSheet.signaturecma
        ? isLoadingSignatureCoordinates
          ? true
          : false
        : false;

    return (
      <>
        <Button
          className="card-button signature-button"
          disabled={disabled}
          style={{
            border: "none",
            marginBottom: "15px",
            width: "100px",
            opacity: isDisabled ? 0.5 : 1,
          }}
          onClick={() => {
            if (!isDisabled) {
              handleCloseSignModal();
              handleSign();
            }
          }}
        >
          {t("signature.signButton")}
        </Button>
        <br />
      </>
    );
  };
  const renderPreview = (isDefault = false) => {
    let isHidden = true;
    if (isDefault) {
      isHidden = false;
    } else {
      //Tab avançado
      if (activeKey === "1") {
        if (radioValue) {
          isHidden = false;
        } else {
          isHidden = true;
        }
      } else {
        isHidden = true;
      }
    }

    return (
      <div
        id="preview-main"
        style={{ position: "relative", display: isHidden ? "none" : "block" }}
      >
        {file && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            className="preview-canvas-wrapper"
          >
            {pageNumber && (
              <Page
                pageNumber={parseNumber()}
                canvasRef={myRef}
                onRenderSuccess={onRenderSuccess}
                onLoadError={onLoadError}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            )}
          </Document>
        )}
        <canvas
          id="a4-canvas"
          onClick={(e) => {
            {
              handleRelativeCoords(e);
            }
          }}
          width={canvasWidth}
          height={canvasHeight}
          style={{
            zIndex: "100",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          onMouseDown={(e) => onMouseDown(e)}
          onMouseUp={(e) => onMouseUp(e)}
          onMouseMove={(e) => onMouseMove(e)}
          onMouseOut={(e) => onMouseOut(e)}
        ></canvas>
        <canvas
          id="a4-canvas-longText"
          width={canvasWidth}
          height={canvasHeight}
          style={{
            zIndex: "99",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        ></canvas>
      </div>
    );
  };

  //allways draw signature
  const canvas = document.getElementById("a4-canvas");
  if (signatureCoordinates && canvas) {
    const xCanvas = canvas.width * signatureCoordinates.x;
    const yCanvas = canvas.height * signatureCoordinates.y - 100;
    //drawSignature(canvas, xCanvas, yCanvas);
  }

  return (
    <Modal
      dialogClassName="modal-50w custom-modal"
      scrollable
      backdrop="static"
      show={showSignModal}
      onHide={() => handleCloseSignModal()}
    >
      <Modal.Header closeButton>
        <div className="custom-title"> {t("signature.signPdf")}</div>
      </Modal.Header>
      <Modal.Body
        className={styleSheet?.processScroll ? "scrollBar-visible" : ""}
      >
        {warningMessage && <Alert variant="warning">{warningMessage}</Alert>}

        {styleSheet?.signaturecma || styleSheet?.signatureType === "cma" ? (
          <>
            {renderTabs()}
            {renderPreview()}
          </>
        ) : styleSheet?.signatureType === "simplified" ? (
          <>
            {renderSelectedToSign()}
            {renderSignature()}
            {renderPageChooser()}
            {renderSignButton()}
            {renderPreview(true)}
          </>
        ) : (
          <>
            {renderSelectedToSign()}
            {renderSignature()}
            {renderStamp()}
            {renderPageChooser()}
            {renderComment()}
            {renderSelectPositionRadios()}
            {renderSignButton()}
            {renderPreview(true)}
          </>
        )}

        <SpinnerNew open={isLoadingIflowDocument} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
export default withNamespaces()(SignPdfModal);
