import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import BuyModal from "./BuyModal";
import NoAccessModel from "./NoAccessModal";
import CreatePuzzleItem from "./CreatePuzzleItem";
import { getIcon, getLocation, addToggle, addStubApps } from "./puzzleItems";
import { withNamespaces } from "react-i18next";
import { isEmpty } from "lodash";
import { getPuzzleAccess } from "./utils";
import "../../assets/css/styles.css";
import "../../assets/css/icons.css";

const Puzzle = () => {
  const { user } = useSelector((state) => state.globalReducer);
  const { puzzleItems } = useSelector((state) => state.adminConfigReducer);
  const [apps, setApps] = useState(undefined);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const [show, setShow] = useState(false);
  const [selectedApp, setSelectedApp] = useState(undefined);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);
  //set initial states

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const [isExpand, setExpand] = useState(false);

  const isVersion2 =
    (window.location.href.includes("/") &&
      window.location.href.split("/").pop() === "uniksystem") ||
    window.location.href.includes("uniksystem/datacapture") ||
    window.location.href.includes("uniksystem/dashboard") ||
    window.location.href.includes("uniksystem/onboarding") ||
    window.location.href.includes("uniksystem/validprocs") ||
    window.location.href.includes("uniksystem/rh") ||
    window.location.href.includes("uniksystem/financeira") ||
    window.location.href.includes("portalrh") ||
    window.location.href.includes("bpm") ||
    window.location.href.includes("uniksystem/admin") ||
    window.location.href.includes("adatacapture") ||
    window.location.href.includes("budgetmgr") ||
    window.location.href.includes("claimsmgt") || 
    window.location.href.includes("openai")
      ? true
      : false;

  useEffect(() => {
    const apps = puzzleItems.filter((item) => item.active === true);
    apps.sort((a, b) => a.nrOrder - b.nrOrder);
    if (user.legacyUsers && apps.length !== 0) {
      const applications = [];
      apps.forEach((item) => {
        const label = item.application.label;
        if (getPuzzleAccess(user, label, user.legacyUsers)) {
          item.icon = getIcon(label);
          item.location = getLocation(label);
          item.hasAccess = getPuzzleAccess(user, label, user.legacyUsers);
          applications.push(item);
        }
      });
      setApps(applications);
    }
  }, [puzzleItems, user]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const renderModal =
    selectedApp && selectedApp.active && !selectedApp.hasAccess ? (
      <NoAccessModel
        selectedApp={selectedApp}
        handleClose={handleClose}
        show={show}
      />
    ) : (
      <BuyModal
        selectedApp={selectedApp}
        handleClose={handleClose}
        show={show}
      />
    );

  let renderPuzzle = [];
  const apps_ = [];
  if (apps) {
    //const apps_ = [];
    apps.forEach((item) => {
      if (item.application.label === "bpm") {
        //hide BPM if user has no perfil BPM
        if (item.hasAccess) apps_.push(item);
      } else apps_.push(item);
    });

    let puzzle = [];
    if (isExpand) {
      apps_.map((item) => {
        puzzle.push(item);
      });
      addStubApps(apps_, isExpand).map((item) => puzzle.push(item));
      if (puzzle.length > 3) {
        const index = puzzle.length - 3;
        puzzle.splice(index, 0, addToggle());
      } else puzzle.splice(0, 0, addToggle());
    } else {
      puzzle.push(addToggle());
    }

    puzzle.map((app, index) => {
      const { application, hasAccess, active } = app;
      let className = "";

      if (isVersion2) {
        className = hasAccess
          ? "tile-v2-" + (index + 1) + " tile-v2-opacity"
          : "tile-v2-" + (index + 1) + " tile-v2-disabled";
      } else {
        className = hasAccess
          ? "tile" + (index + 1) + " tile-opacity"
          : "tile" + (index + 1) + " tile-disabled";
      }
      if (app.application.label === "") {
        renderPuzzle.push(
          <CreatePuzzleItem
            className={className}
            item={app}
            setSelectedApp={setSelectedApp}
            setExpand={setExpand}
            isExpand={isExpand}
            handleShow={handleShow}
            key={`${app.application.label}-${index}`}
          />
        );
      } else {
        const tooltip = {
          description: application.description,
          showCart: !active,
        };
        renderPuzzle.push(
          <CreatePuzzleItem
            className={className}
            item={app}
            setSelectedApp={setSelectedApp}
            setExpand={setExpand}
            isExpand={isExpand}
            handleShow={handleShow}
            tooltip={tooltip}
            key={`${app.application.label}-${index}`}
          />
        );
      }
    });
  }

  let renderRows = [];
  for (let i = 0; i < renderPuzzle.length; i = i + 4) {
    let items = [];
    for (let j = i; j < i + 4; j++) {
      items.push(renderPuzzle[j]);
    }
    renderRows.push(
      <Row
        key={i}
        style={{ margin: "0px", padding: "0px", flexWrap: "nowrap" }}
      >
        {items}
      </Row>
    );
  }

  const isAdmin = isEmpty(user.roles)
    ? false
    : user.roles.some((role) => role.id === 1)
    ? true
    : false;
  let puzzleClass = "puzzle-wrapper";
  if (isVersion2) {
    puzzleClass = "puzzle-wrapper-v2";
  }

  return (
    <>
     <div
        className={puzzleClass}
        id="puzzle"
        style={{
          backgroundColor: isExpand ? "rgb(238, 238, 238)" : "transparent",
          opacity: isExpand ? 1 : 0.6,
        }}
      >
        {apps_.length > 1 ? renderRows : isAdmin ? renderRows : null}
      </div>
      {renderModal}
    </>
  );
};

export default withNamespaces()(Puzzle);
