import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card } from "react-bootstrap";
import {
  getCRUDClientDirectors,
  deleteCRUDClientDirectors,
  postADDCRUDClientDirectors,
  updateCRUDClientDirectors,
  getCRUDCompanies,
  getCRUDTeams,
  getCRUDPartners,
} from "~/store/ducks/budgetManagementCRUD/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";
import Modal from "~/pages/Easypay/components/Modal";
import { Button } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import ClientDirectorsTable from "../components/ClientDirectors/ClientDirectorsTable";
import ClientDirectorsFilters from "../components/ClientDirectors/ClientDirectorsFilters";

const ClientDirector = ({ t,isOrgGroupAdmin }) => {
  const dispatch = useDispatch();
  const {
    clientDirectors_CRUD,
    isLoadingCRUDClientDirector,
    companies_CRUD,
    teams_CRUD,
    partners_CRUD,
  } = useSelector((state) => state.budgetManagementCRUDReducer);

  const [filterOption, setFilterOption] = useState("");
  const [addEditNewClientDirector, setaddEditNewClientDirector] =
    useState(false);
  const [editDeleteClientDirector, seteditDeleteClientDirector] =
    useState(null);
  const [clientDirectorsToShow, setclientDirectorsToShow] =
    useState(clientDirectors_CRUD);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [cleanDropdownClientDirectors, setcleanDropdownClientDirectors] =
    useState(false);
  const [clientDirectorFilter, setclientDirectorFilter] =
    useState(filterOption);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [SelectedEditClientDirector, setSelectedEditClientDirector] =
    useState(undefined);
  const [SelectedDeleteClientDirector, setSelectedDeleteClientDirector] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [clientDirectorsToRender, setclientDirectorsToRender] =
    useState(clientDirectors_CRUD);

  const [selectedClientDirector, setselectedClientDirector] = useState({
    empresa: "",
    idClientDiretor: 0,
    nome: "",
    idEquipa: "0",
    idPartner: "0",
    udwUser: "",
    estado: "1",
  });

  const resetBackButton = () => {
    setselectedClientDirector({
      empresa: "",
      idClientDiretor: 0,
      nome: "",
      idEquipa: "0",
      idPartner: "0",
      udwUser: "",
      estado: "1",
    });
  };
  const handleNomeChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      nome: event.target.value,
    }));
    if (SelectedEditClientDirector != undefined)
      setSelectedEditClientDirector((prevCategory) => ({
        ...prevCategory,
        nome: event.target.value,
      }));
  };
  const handleudwUserChange = (event) => {
    event.persist(); // Mantém o evento sintético original

    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      udwUser: event.target.value,
    }));
    if (SelectedEditClientDirector != undefined)
      setSelectedEditClientDirector((prevCategory) => ({
        ...prevCategory,
        udwUser: event.target.value,
      }));
  };

  const handleEmpresaChange = (empresa) => {
    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      empresa,
    }));
    if (SelectedEditClientDirector != undefined)
      setSelectedEditClientDirector((prevCategory) => ({
        ...prevCategory,
        empresa,
      }));
  };
  const handleTeamChange = (idEquipa) => {
    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      idEquipa,
    }));
    if (SelectedEditClientDirector != undefined)
      setSelectedEditClientDirector((prevCategory) => ({
        ...prevCategory,
        idEquipa,
      }));
  };
  const handlePartnerChange = (idPartner) => {
    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      idPartner,
    }));
    if (SelectedEditClientDirector != undefined)
      setSelectedEditClientDirector((prevCategory) => ({
        ...prevCategory,
        idPartner,
      }));
  };

  const handleAtivoChange = (estado) => {
    setselectedClientDirector((prevCategory) => ({
      ...prevCategory,
      estado,
    }));
    if (SelectedEditClientDirector != undefined)
      setselectedClientDirector((prevCategory) => ({
        ...prevCategory,
        estado,
      }));
  };

  useEffect(() => {
    if (SelectedEditClientDirector != (null || undefined)) {
      if (SelectedEditClientDirector.udwUser == null) {
        SelectedEditClientDirector.udwUser = "";
      }
      setselectedClientDirector(SelectedEditClientDirector);
    }
  }, [SelectedEditClientDirector]);

  useEffect(() => {
    dispatch(getCRUDClientDirectors());
    dispatch(getCRUDCompanies());
    dispatch(getCRUDTeams());
    dispatch(getCRUDPartners());
  }, []);

  const handleRefresh = () => {
    dispatch(getCRUDClientDirectors());
    handleReset();
    setFilterToggle(false);
  };

  const handleReset = () => {
    setclientDirectorFilter("");
    setclientDirectorsToShow(clientDirectors_CRUD);
    setCleanDropdown(true);
    setshowEditModal(false);
    setshowDeleteModal(false);
  };
  const handleOpenEditModal = (row) => {
    setshowEditModal(true);
    setSelectedEditClientDirector(row);
  };
  const handleOpenDeleteModal = (row) => {
    setshowDeleteModal(true);
    setSelectedDeleteClientDirector(row);
  };

  const handleDelete = (id) => {
    dispatch(deleteCRUDClientDirectors(id));
    setIsLoading(true);
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  const handleCreateUpdateClientDirector = (id) => {
    if (id) {
      const payload = {
        idClientDiretor: id,
        nome: selectedClientDirector?.nome,
        year: new Date().getFullYear(),
        empresa: selectedClientDirector?.empresa,
        idPartner: parseInt(selectedClientDirector?.idPartner),
        idEquipa: parseInt(selectedClientDirector?.idEquipa),
        udwUser: selectedClientDirector?.udwUser,
        estado: parseInt(selectedClientDirector?.estado),
      };
      dispatch(updateCRUDClientDirectors(id, payload));
    } else {
      const payload = {
        nome: selectedClientDirector?.nome,
        year: new Date().getFullYear(),
        empresa: selectedClientDirector?.empresa,
        partner: parseInt(selectedClientDirector?.idPartner),
        equipa: parseInt(selectedClientDirector?.idEquipa),
        udwUser: selectedClientDirector?.udwUser,
        estado: 1,
      };
      dispatch(postADDCRUDClientDirectors(payload));
    }
    setIsLoading(true);
    handlebackbuttonModal();
    setTimeout(() => {
      handleRefresh();
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    clientDirectors_CRUD.map((clientDirector) => {
      if (clientDirector?.estado == null) clientDirector.estado = 0;
    });
    setclientDirectorsToShow(clientDirectors_CRUD);
  }, [clientDirectors_CRUD]);

  const renderFilterCompany = () => {
    return companies_CRUD.map((company) => ({
      label: company?.codigoEmpresa,
      value: company?.codigoEmpresa,
      selected:
        SelectedEditClientDirector !== undefined &&
        SelectedEditClientDirector?.empresa === company?.codigoEmpresa,
    }));
  };
  const renderFilterTeam = () => {
    return teams_CRUD.map((team) => ({
      label: team?.empresa + " - " + team?.nome,
      value: team?.idEquipa.toString(),
      selected: SelectedEditClientDirector?.idEquipa.toString() === team?.idEquipa.toString(),
    }));
  };
  const renderFilterPartner = () => {
    return partners_CRUD.map((partner) => ({
      label: partner?.empresa + " - " + partner?.nome,
      value: partner?.idPartner.toString(),
      selected: SelectedEditClientDirector?.idPartner.toString() === partner?.idPartner.toString(),
    }));
  };

  const activeArray = [
    {
      estado: t(`budgetManagement.clientdirector.ativo`),
      value: 1,
    },
    {
      estado: t(`budgetManagement.clientdirector.inativo`),
      value: 0,
    },
  ];
  const renderFilterAtivo = () => {
    return activeArray.map((estado) => ({
      label: estado?.estado,
      value: estado?.value.toString(),
      selected: SelectedEditClientDirector?.estado.toString() === estado?.value.toString(),
    }));
  };

  const createEditCategoryBody = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`empresa-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.clientdirector.empresa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterCompany()}
            handleSubmit={handleEmpresaChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownClientDirectors}
            resetDropdown={cleanDropdownClientDirectors}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`nome-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.clientdirector.nome`)}:
          </label>
          <input
            id={`nome-input`}
            className="form-control"
            type="text"
            value={selectedClientDirector?.nome}
            onChange={handleNomeChange}
            required
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`team-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.clientdirector.equipa`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterTeam()}
            handleSubmit={handleTeamChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownClientDirectors}
            resetDropdown={cleanDropdownClientDirectors}
          />
        </div>
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`partner-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.clientdirector.parceiro`)}:
          </label>
          <Dropdown
            width={"100%"}
            list={renderFilterPartner()}
            handleSubmit={handlePartnerChange}
            emptyDefault={true}
            setResetDropdown={setcleanDropdownClientDirectors}
            resetDropdown={cleanDropdownClientDirectors}
          />
        </div>
        {SelectedEditClientDirector && (
          <div className="inputsLiftCrud" style={{ width: "100%" }}>
            <label
              htmlFor={`team-input`}
              className="labelsLiftCrud"
              style={{ width: "auto" }}
            >
              {t(`budgetManagement.clientdirector.estado`)}:
            </label>
            <Dropdown
              width={"100%"}
              list={renderFilterAtivo()}
              handleSubmit={handleAtivoChange}
              emptyDefault={true}
              setResetDropdown={setcleanDropdownClientDirectors}
              resetDropdown={cleanDropdownClientDirectors}
            />
          </div>
        )}
        <div className="inputsLiftCrud" style={{ width: "100%" }}>
          <label
            htmlFor={`udwUser-input`}
            className="labelsLiftCrud"
            style={{ width: "auto" }}
          >
            {t(`budgetManagement.clientdirector.udwUser`)}:
          </label>
          <input
            id={`udwUser-input`}
            className="form-control"
            type="text"
            value={selectedClientDirector?.udwUser}
            onChange={handleudwUserChange}
            required
          />
        </div>
      </div>
    );
  };

  const handlebackbuttonModal = () => {
    setaddEditNewClientDirector(false);
    setshowEditModal(false);
    resetBackButton();
    setcleanDropdownClientDirectors(true);
    setSelectedEditClientDirector(undefined);
    setselectedClientDirector({
      empresa: "",
      idClientDiretor: 0,
      nome: "",
      idEquipa: "0",
      idPartner: "0",
      udwUser: "",
      estado: "1",
    });
  };

  function createEditBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="easypay-card-text-blue-disabled"
              style={{ color: "#4484f4", fontWeight: "900", fontSize: "20px" }}
            >
              {createEditCategoryBody()}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => {
                handlebackbuttonModal();
              }}
            >
              {t(`budgetManagement.clientdirector.btnVoltar`)}
            </Button>
            {addEditNewClientDirector ? (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() => handleCreateUpdateClientDirector()}
              >
                {t(`budgetManagement.clientdirector.btnCriarNovo`)}
              </Button>
            ) : (
              <Button
                style={{ margin: "10px", backgroundColor: "green" }}
                className="card-button"
                onClick={() =>
                  handleCreateUpdateClientDirector(
                    selectedClientDirector?.idClientDiretor
                  )
                }
              >
                {t(`budgetManagement.clientdirector.btnUpdate`)}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  function createDeleteBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div className="easypay-prova-de-vida-wrapper">
          <div
            className="easypay-prova-de-vida-text"
            style={{ marginBottom: "30px" }}
          >
            <div className="easypay-sub-header-3-warning">
              {t(`budgetManagement.clientdirector.msgDelete.1`)}{" "}
              {SelectedDeleteClientDirector?.empresa}{" "}
              {t(`budgetManagement.clientdirector.msgDelete.2`)}{" "}
              {SelectedDeleteClientDirector?.nome} ?
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ margin: "10px" }}
              className="card-button"
              onClick={() => setshowDeleteModal(false)}
            >
              {t(`budgetManagement.clientdirector.btnVoltar`)}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "red" }}
              className="card-button"
              onClick={() =>
                handleDelete(SelectedDeleteClientDirector?.idClientDiretor)
              }
            >
              {t(`budgetManagement.clientdirector.btnDelete`)}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isOrgGroupAdmin && (
        <>
          <SpinnerNew open={isLoadingCRUDClientDirector || isLoading} />
          {Object.keys(clientDirectors_CRUD).length > 0 && (
            <div className={"main-card-v2"}>
              <Card bsPrefix="card-flat">
                <Card.Header className="justify-content-between">
                  <h6>{t(`budgetManagement.clientdirector.header`)}</h6>
                </Card.Header>
                <Card.Body>
                  <>
                    <ClientDirectorsFilters
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      clientDirectorsData={clientDirectors_CRUD}
                      setclientDirectorsToShow={setclientDirectorsToShow}
                      cleanDropdown={cleanDropdown}
                      setCleanDropdown={setCleanDropdown}
                      handleReset={handleReset}
                      clientDirectorFilter={clientDirectorFilter}
                      setclientDirectorFilter={setclientDirectorFilter}
                    />
                    <ClientDirectorsTable
                      app={"bpm"}
                      clientDirectorsData={clientDirectorsToShow}
                      handleRefresh={handleRefresh}
                      filterOption={filterOption}
                      setaddEditNewClientDirector={setaddEditNewClientDirector}
                      seteditDeleteClientDirector={seteditDeleteClientDirector}
                      handleOpenEditModal={handleOpenEditModal}
                      handleOpenDeleteModal={handleOpenDeleteModal}
                      setshowEditModal={setshowEditModal}
                      teams_CRUD={teams_CRUD}
                      partners_CRUD={partners_CRUD}
                      filterToggle={filterToggle}
                      setFilterToggle={setFilterToggle}
                      clientDirectorsToRender={clientDirectorsToRender}
                      setclientDirectorsToRender={setclientDirectorsToRender}
                    />
                  </>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal showModal={showEditModal} bodyContent={createEditBody()} />
          <Modal showModal={showDeleteModal} bodyContent={createDeleteBody()} />
        </>
      )}
    </>
  );
};

export default withNamespaces()(ClientDirector);
