import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import SpinnerNew from "~/components/SpinnerNew";
import { BsFillEyeFill, BsPlusCircleFill } from "react-icons/bs";
import ModalTopDynamic from "./ModalTopDynamic";

import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import {
  exportClaims,
  getDataTops,
} from "~/store/ducks/claimsManagement/actionTypes";

const SubTabelaResultados = ({
  t,
  takeClickedBoolean,
  assunto,
  handleGoBackAction,
  setRowClicked,
  dataRowFromParent,
}) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch();

  const { subData, isLoadingSubDataResults } = useSelector(
    (state) => state.claimsManagementReducer
  );

  const [isLoading, setIsloading] = useState(isLoadingSubDataResults);
  const [showModalClicked, setShowModalClicked] = useState(false);
  const [dataRow, setDataRow] = useState({});
  const [newData, setNewData] = useState(subData);

  useEffect(() => {
    if (Object.keys(subData).length > 0) {
      if (subData?.subTable?.rows?.length > 0) {
        for (let i = 0; i < subData?.subTable?.rows?.length; i++) {
          // data.rows[i].getTop2.toFixed(1);
/*           subData.subTable.rows[i].getRelevante = subData.subTable.rows[
            i
          ].getRelevante.slice(0, -1);
 */
          subData.subTable.rows[i].getRelevante =
            Number(subData.subTable.rows[i].getRelevante);

        }

        let auxData = subData;

        // if (contador === 1) {
        let newHeaders = auxData.subTable.headers;
        newHeaders = newHeaders.filter((element, index) => {
          return index < 8;
        });

        auxData.subTable.headers = newHeaders;

        let fieldsToDelete = [
          "getTop4",
          "getTop5",
          "getTop6",
          "getTop7",
          "getTop8",
          "getTop9",
          "getTop10",
          "getTop11",
          "getTop12",
          "getTop13",
          "getTop14",
          "getTop15",
          "getTop16",
          "getTop17",
          "getTop18",
          "getTop19",
          "getTop20",
        ];

        for (var r = 0; r < auxData.subTable.rows.length; r++) {
          for (var d = 0; d < fieldsToDelete.length; d++) {
            delete auxData.subTable.rows[r][fieldsToDelete[d]];
          }
        }
        // }

        setNewData(auxData);
        setIsloading(false);

        // setContador(contador + 1);
      } else {
        let auxData = subData;

        let newHeaders = auxData.subTable.headers;
        newHeaders = newHeaders.filter((element, index) => {
          return index < 8;
        });

        auxData.subTable.headers = newHeaders;

        let fieldsToDelete = [
          "getTop4",
          "getTop5",
          "getTop6",
          "getTop7",
          "getTop8",
          "getTop9",
          "getTop10",
          "getTop11",
          "getTop12",
          "getTop13",
          "getTop14",
          "getTop15",
          "getTop16",
          "getTop17",
          "getTop18",
          "getTop19",
          "getTop20",
        ];

        for (var r = 0; r < auxData.subTable?.rows?.length; r++) {
          for (var d = 0; d < fieldsToDelete?.length; d++) {
            delete auxData.subTable.rows[r][fieldsToDelete[d]];
          }
        }

        setNewData(auxData);
        setIsloading(false);
      }
    }
  }, [subData]);

  useEffect(() => {
    if (Object.keys(subData).length > 0) {
      const modalButton = {
        dataField: "getModalButton",
        text: "",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        },
      };

      //const newInsert = subData?.subTable?.headers?.push(modalButton);
    }
  }, [subData]);

  const handleBack = () => {
    handleGoBackAction();
  };

  function handleSeeMoreClick(event, index) {
    setTimeout(() => {
      let obj = {
        claimId: dataRowFromParent?.claimId,
        categoryId: subData?.subTable?.categoryId,
        boolean: false,
        index: index,
      };
      dispatch(getDataTops(obj));

      setDataRow(event);
      setShowModalClicked(true);
    }, 100);
  }

  // const downloadXls = () => {
  //   //1 = EXCEL
  //   dispatch(exportClaims(1, category, null));
  // };

  const rowEventEditMod = {
    onClick: (e, row, rowIndex) => {
      setDataRow(row);
    },
  };

  function ordenarPorPercentagemRelevante(rows) {
      // sort by value
      return rows.sort(function (a, b) {
        return Number(a.getRelevante) - Number(b.getRelevante);
      }).reverse();
  }

  return isLoading ? (
    <SpinnerNew open={isLoading} />
  ) : (
    <>
      <div style={{ paddingTop: "30px" }}>
        <Button
          onClick={handleBack}
          className="card-button"
          style={{ marginBottom: "15px", marginLeft: "15px" }}
        >
          Voltar
        </Button>

        <div className="custom-table">
          <ToolkitProvider
            keyField="id"
            data={newData?.subTable?.rows ? ordenarPorPercentagemRelevante(newData?.subTable?.rows) : []}
            columns={
              newData?.subTable?.headers ? newData?.subTable?.headers : []
            }
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    <SearchBar
                      {...props.searchProps}
                      placeholder={t("general.search")}
                    />

                    {/* <div style={{ paddingLeft: "20px" }}>
                        <SimpleButton
                          variant={"liftworld-button-primary"}
                          disabled={!newData?.rows}
                          onClick={() => {
                            downloadXls();
                          }}
                          text={"Exportar"}
                        />
                      </div> */}
                  </div>
                </div>
                <div>
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    {assunto}
                  </h3>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  responsive
                  bootstrap4
                  rowEvents={rowEventEditMod}
                  pagination={paginationFactory({
                    sizePerPage: 5,
                    sizePerPageList: [],
                  })}
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  pageSize={5}
                  hover
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        {showModalClicked ? (
          <ModalTopDynamic
            setShowModalClicked={setShowModalClicked}
            waitForResults={true}
            dataRow={dataRow}
          />
        ) : null}
      </div>
    </>
  );
};
export default withNamespaces()(SubTabelaResultados);
