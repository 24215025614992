import React, { useEffect, useState } from "react";
import uuid from "uuid/v1";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import greenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import { Col, Row } from "react-bootstrap";
import {
  getDatacaptureConfiguration,
  clearDatacaptureConfiguration,
} from "~/store/ducks/datacaptureConfiguration/actionTypes";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import {
  exportClaims,
  getSubClaims,
} from "~/store/ducks/claimsManagement/actionTypes";
import SpinnerNew from "~/components/SpinnerNew";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import "~/assets/css/icons.css";
import { getMetadataDocument } from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";
import "~/pages/ADataCapture/components/AutomatedDatacapture/AutomatedDataCapture.css";

import Modal from "~/pages/Easypay/components/Modal";
import paginationFactory from "react-bootstrap-table2-paginator";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import PopupTable from "./PopupTable";

const CorreioEletronico = ({ t, setShowUploadModal, category }) => {
  const { previewDocument } = useSelector((state) => state.dataCaptureReducer);

  const { isLoadingGetComplaints, subClaims } = useSelector(
    (state) => state.claimsManagementReducer
  );

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalReducer);

  const { datacaptureConfiguration } = useSelector(
    (state) => state.datacaptureConfigurationReducer
  );

  const { SearchBar } = Search;

  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);

  const [currentAnacom, setCurrentAnacom] = useState([]);
  const [currentUnik, setCurrentUnik] = useState([]);
  const [currentAssuntos, setCurrentAssuntos] = useState([]);

  const [unikList, setUnikList] = useState([]);
  const [anacomList, setAnacomList] = useState([]);
  const [assuntosList, setAssuntosList] = useState([]);
  const [isModalWaiting, setIsModalWaiting] = useState(false);
  const [currentText, setCurrentText] = useState("");

  const { complaints } = useSelector((state) => state.claimsManagementReducer);

  const handleOpenMetadataModal = (row) => {
     setCurrentText(row?.reclamacao);
    dispatch(getSubClaims(category, 0, 50000, row?.claimId));
    setIsModalWaiting(true);
  };

  useEffect(() => {
    if (subClaims) {
      setIsModalWaiting(false);
      if (subClaims === null || subClaims === undefined) return;
      if (subClaims?.length === 0) return;
      if (complaints?.idsValues === null || complaints?.idsValues === undefined)
        return;
      if (complaints?.idsValues?.length === 0) return;

      const currentUnikArray = subClaims?.unikClassifications;

      const currentAnacomArray = subClaims?.anacomClassifications;

      const currentAssuntosArray = subClaims?.assuntosClassifications;

      for (var i = 0; i < currentUnikArray?.length; i++) {
        if (
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != null &&
          currentAnacomArray.filter(
            (elem) =>
              elem.motivoIndicio === currentUnikArray[i]?.motivoIndicioId
          )[0] != undefined
        ) {
          currentUnikArray[i].check = "t";
        } else {
          currentUnikArray[i].check = "f";
        }
      }

      const unikSize = Math.ceil(currentUnikArray.length / 5);
      const anacomSize = Math.ceil(currentAnacomArray.length / 5);
      const assuntosSize = Math.ceil(currentAssuntosArray.length / 5);

      var currentUnikPagination = [];
      var currentAnacomPagination = [];
      var currentAssuntosPagination = [];

      for (var i = 1; i <= unikSize; i++) {
        currentUnikPagination.push(i);
      }

      for (var i = 1; i <= anacomSize; i++) {
        currentAnacomPagination.push(i);
      }

      for (var i = 1; i <= assuntosSize; i++) {
        currentAssuntosPagination.push(i);
      }
      setUnikList(currentUnikPagination);
      setAnacomList(currentAnacomPagination);
      setAssuntosList(currentAssuntosPagination);
      setCurrentAnacom(currentAnacomArray);
      setCurrentUnik(currentUnikArray);
      setCurrentAssuntos(currentAssuntosArray);
      setIsModalErrorShowing(true);
    } else {
      setIsModalErrorShowing(false);
    }
  }, [subClaims]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getDatacaptureConfiguration(user.organization.id));
    return () => dispatch(clearDatacaptureConfiguration());
  }, [user && user.organization && user.organization.id]);

  const buildActionsColumn = (_, row, {}) => (
    <>
      {
        <i
          className="icon-detail table-action-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenMetadataModal(row, false);
          }}
        />
      }
    </>
  );

  function checkFormatter(cell) {
    if (cell === "t") {
      return <img className="anacom-checkmark-img" src={greenCheckmark} />;
    } else return "";
  }

  const generateImageView = (doc) =>
    doc ? "data:image/jpg;base64, " + doc.data : "";
  const generatePdfView = (doc) => {
    if (doc && doc.data !== "") {
      function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1];
        try {
          var bstr = atob(arr[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        } catch (e) {
          return new Blob(["No data found"], { type: "text/html" });
        }
      }
      //convert to blob because of Data protocol URL size limitations
      let blob = dataURLtoBlob("data:application/pdf;base64, " + doc.data);
      const temp_url = window.URL.createObjectURL(blob);
      return temp_url;
    }
  };

  const downloadXls = () => {
    //1 = EXCEL
    dispatch(exportClaims(1, category, null));
  };

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBody() {
    return (
      <div>
        <Row>
          <>
            <Col lg="7">
              <PopupTable
                naoclass={true}
                isNaoClassificadas={true}
                anacomList={currentAnacom}
                assuntosList={currentAssuntos}
                unikList={currentUnik}
                anacomPagination={anacomList}
                assuntosPagination={assuntosList}
                unikPagination={unikList}
              />
            </Col>

            <Col lg="5">
              <div className={`${"liftworld-textarea-input-field-wrapper"}`}>
                <textarea
                  className={`liftworld-textarea-input-field`}
                  type={"textarea"}
                  disabled="true"
                  value={currentText}
                />
              </div>
          </Col>
          </>
        </Row>
      </div>
    );
  }

  function recBodyFormatter(cell) {
    if (cell != undefined && cell != null && cell != "") return "s";
    else return "n";
  }

  function reclamacaoFormatter(cell) {
    return cell?.slice(0, 15);
  }

  const columns = [
    {
      dataField: "numeroProcesso",
      text: "Nº Processo",
      sort: false,
      headerStyle: { width: "150px" },
    },
    {
      dataField: "data",
      text: "Data",
      sort: true,
      headerStyle: { width: "fit-content" },
    },
    {
      dataField: "hora",
      text: "Hora",
      sort: false,
      headerStyle: { width: "60px" },
    },
    {
      dataField: "emailReclamante",
      text: "Remetente",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: reclamacaoFormatter,
    },
    {
      dataField: "toEmail",
      text: "Destinatário",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: reclamacaoFormatter,
    },
    {
      dataField: "cCEmail",
      text: "Cc",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: reclamacaoFormatter,
    },
    {
      dataField: "assunto",
      text: "Assunto (15car)",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: reclamacaoFormatter,
    },
    {
      dataField: "reclamacao",
      text: "Corpo (15car)",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: reclamacaoFormatter,
    },
   /*  {
      dataField: "anexos",
      text: "Anexos(s/n)",
      sort: false,
      headerStyle: { width: "fit-content" },
    }, */
   /*  {
      dataField: "reclamacao",
      text: "Corpo contêm reclamação(s/N)",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: recBodyFormatter,
    }, */
    {
      dataField: "iDDocumento",
      text: "Ações",
      sort: false,
      headerStyle: { width: "fit-content" },
      formatter: buildActionsColumn,
    },
  ];

  return (
    <div>
      <div className="main-card-v2">
        <div className="custom-table">
          <ToolkitProvider
            keyField="id"
            data={complaints?.claims ? complaints?.claims : []}
            columns={columns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    {true && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )}
                    <div style={{ paddingLeft: "15px" }} />
                    {/* <SimpleButton
                      variant={"liftworld-button-primary"}
                      disabled={
                        isLoadingGetComplaints ||
                        complaints?.claims?.length === 0
                      }
                      onClick={() => {
                        downloadXls();
                      }}
                      text={"Exportar"}
                    /> */}
                  </div>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table"
                  responsive
                  bootstrap4
                  pagination={paginationFactory({
                    sizePerPage: 5,
                    sizePerPageList: [],
                  })}
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  //rowEvents={rowEvents}
                  pageSize={5}
                  hover
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <div style={{ maxWidth: "80%" }}>
          <Modal
            className="easypay-modal"
            showModal={isModalErrorShowing}
            setShowModal={setIsModalErrorShowing}
            headerContent={"Classificação"}
            bodyContent={createBody()}
            key={uuid()}
            nomax={true}
          ></Modal>
          <Modal
            className="easypay-modal"
            showModal={isModalWaiting}
            setShowModal={setIsModalWaiting}
            headerContent={"Classificação"}
            bodyContent={createBodyWaiting()}
            key={uuid()}
          ></Modal>
        </div>
      </div>
      <SpinnerNew open={isLoadingGetComplaints} />
    </div>
  );
};
export default withNamespaces()(CorreioEletronico);
