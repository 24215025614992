import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import SpinnerNew from "~/components/SpinnerNew";
import { Modal } from "react-bootstrap";
import { BsFillEyeFill, BsPlusCircleFill } from "react-icons/bs";
import {
  getDataResults,
  exportClaims,
} from "~/store/ducks/claimsManagement/actionTypes";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import "../assets/scss/_anacomTable.scss";
const devLogConsole = require("~/utils/devLog");
const ModalTopDynamic = ({
  t,
  setShowModalClicked,
  waitForResults,
  dataRow,
}) => {
  const { SearchBar } = Search;

  const { dataTops } = useSelector(
    //criar esta saga com variavel resultsModal
    (state) => state.claimsManagementReducer
  );

  useEffect(() => {
    devLogConsole(dataTops);
    if (dataTops?.length > 0) {
      for (let i = 0; i < dataTops.length; i++) {
        // data.rows[i].getTop2.toFixed(1);
        dataTops[i] = dataTops[i]?.slice(0, -1);
        dataTops[i] = Number(dataTops[i])?.toFixed(1) + "%";
      }

      setWaitForResultsLoading(false);
    }
  }, [dataTops]);

  const [waitForResultsLoading, setWaitForResultsLoading] =
    useState(waitForResults);

  const [isAssunto, setIsAssunto] = useState(
    !dataRow?.hasOwnProperty("getMotivoIndicio")
  );
  const [labelTitulo, setLabelTitulo] = useState(
    isAssunto ? "Assunto" : "Motivo/Indício"
  );
  const [titulo, setTitulo] = useState(
    isAssunto ? dataRow?.getNome : dataRow?.getMotivoIndicio
  );
  const [percentagem, setPercentagem] = useState(dataRow?.getPercRelevante);
  const [relevante, setRelevante] = useState(dataRow?.getRelevante);

  return waitForResultsLoading ? (
    <SpinnerNew open={waitForResultsLoading} />
  ) : (
    <Modal centered show={true} onHide={() => setShowModalClicked(false)}>
      <Modal.Header>
        <Modal.Title>
          <font color="#0d6ff956">{labelTitulo}:</font> {titulo} <br></br>
          <font color="#0d6ff956">Aceitação minima:</font> {percentagem}
          <br></br>
          <font color="#0d6ff956">Relevante:</font> {relevante}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        <table style={{ border: "1px solid" }}>
          <tr>
            <th
              style={{
                border: "1px solid",
                padding: "0px 20px 0 20px",
                fontSize: "18px",
              }}
            >
              {" "}
              Top
            </th>
            <th
              style={{
                border: "1px solid",
                padding: "0px 20px 0 20px",
                fontSize: "20px",
              }}
            >
              %
            </th>
          </tr>
          {dataTops.map((item, index) => (
            <>
              <tr key={index}>
                <td style={{ border: "1px solid", padding: "0px 20px 0 20px" }}>
                  Top{index + 1}
                </td>
                <td style={{ border: "1px solid", padding: "0px 20px 0 20px" }}>
                  {item}
                </td>
              </tr>
            </>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShowModalClicked(false)}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default withNamespaces()(ModalTopDynamic);
