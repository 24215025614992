import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "~/utils/constants";

import { googleSignIn } from "../actions";
import { FcGoogle } from "react-icons/fc";
import { Typography } from "@mui/material";

const GoogleAuthentication = ({ old = false }) => {
  const dispatch = useDispatch();

  const successResponseGoogle = (response) => {
    //setUsername(response.profileObj.email.split("@")[0]);
    dispatch(googleSignIn(response));
  };

  return (
    <>
      {old ? (
        <div id="third-auth-button">
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Google"
            onSuccess={successResponseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      ) : (
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Google"
          onSuccess={successResponseGoogle}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <div
              style={{ textAlign: "center", pointerEvents: "auto" }}
              onClick={renderProps.onClick}
              role="button"
            >
              <FcGoogle size={30} />
              <Typography variant="caption" display="block" gutterBottom>
                Google
              </Typography>
            </div>
          )}
        />
      )}
    </>
  );
};

export default withNamespaces()(GoogleAuthentication);
