import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import keycloak from "~/assets/img/icons/keycloak.svg";
import { Typography } from "@mui/material";

const KeycloakAuthentication = (props) => {
  const { click, auto, old = false } = props;

  useEffect(() => {
    if (auto) {
      click();
    }
  }, [auto]);

  return (
    <>
      {old ? (
        <button className="btn-keycloak" onClick={() => click()}>
          <div style={{ display: "flex" }}>
            <div>
              <img className="logo-keycloak" alt="keycloak" src={keycloak} />
            </div>
            <div
              style={{
                flexGrow: "1",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Keycloak
            </div>
          </div>
        </button>
      ) : (
        <div
          style={{ textAlign: "center", pointerEvents: "auto" }}
          onClick={() => click()}
          role="button"
        >
          <img className="logo-keycloak" alt="keycloak" src={keycloak} />
          <Typography variant="caption" display="block" gutterBottom>
            Keycloak
          </Typography>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(KeycloakAuthentication);
