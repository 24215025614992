import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findDocument } from "store/ducks/iflow/actionTypes";
import { getDocumentByWorkplan } from "~/store/ducks/budgetManagement/actionTypes";

const BudgetDocumentPreviewPageParcial = ({ type, workId }) => {
  const dispatch = useDispatch();
  const { currentDocument } = useSelector((state) => state.iflowReducer);
  const { documentInfo } = useSelector(
    (state) => state.budgetManagementReducer
  );

  const [fileType, setFileType] = useState("pdf");

  useEffect(() => {
    dispatch(getDocumentByWorkplan({ workPlanId: workId }));
  }, []);

  useEffect(() => {
    if (Object.keys(documentInfo).length > 0) {
      dispatch(findDocument(documentInfo.documentParcialId));
    }
  }, [documentInfo]);

  useEffect(() => {
    if (currentDocument && currentDocument.filename) {
      const fileType = currentDocument.filename.split(".").pop();
      setFileType(fileType?.toLowerCase());
    }
  }, [currentDocument]);

  const generatePdfView = (doc) => {
    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1];
      try {
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      } catch (e) {
        return new Blob(["No data found"], { type: "text/html" });
      }
    }
    //convert to blob because of Data protocol URL size limitations
    let blob = dataURLtoBlob("data:application/pdf;base64, " + doc);
    const temp_url = window.URL.createObjectURL(blob);
    return temp_url;
  };

  const generateImageView = (doc) =>
    doc ? "data:image/jpg;base64, " + doc : "";

  return (
    <div>
      {currentDocument &&
        (fileType === "pdf" ? (
          <iframe
            id="pdf-document"
            title="pdf-document"
            src={generatePdfView(currentDocument.datadoc)}
            style={{
              height: "100%",
              width: "100%",
              minHeight: "100vh",
              padding: "30px",
            }}
          />
        ) : (
          <img
            src={generateImageView(currentDocument.datadoc)}
            className="document-preview"
            alt="document-preview"
          />
        ))}
    </div>
  );
};
export default BudgetDocumentPreviewPageParcial;
