import React, { useEffect, useRef, useState } from "react";
import "@material-ui/core/Icon";
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";

const ClampTo = (max, v) => ((v % max) + max) % max;

const TimeSelector = (props) => {
  return (
    <div className="data-time-selector">
      <div
        className="data-arrow"
        onClick={() => props.onChange(props.value + 1)}
      >
        <IoIosArrowUp />
      </div>
      <span>{props.value}</span>
      <div
        className="data-arrow"
        onClick={() => props.onChange(props.value - 1)}
      >
        <IoIosArrowDown />
      </div>
    </div>
  );
};

/**
 *
 *  {
 *      yearRange: { start: number, end: number }
 *      minDate?: Date
 *      maxDate?: date
 *      value: Date
 *      text: string
 *      name: string
 *      onChange: (v: Date) => void,
 *      diabled: boolean,
 *      className: string
 *  } = props
 */
const DatePicker = (props) => {
  const { t, className, autoAdjust, fontSize} = props;
  const [styleSheet, setFormStyleSheet] = useState("");
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  useEffect(() => {
    if (menu) {
      let app = "";
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        //devLogConsole(styleSheet_)
        setFormStyleSheet(styleSheet_);
      }
    }
  }, [menu]);

  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  const getUserLanguage = () => {
    const language = userSettings.language;
    const languageMap = {
      "pt": "pt-PT",
      "en": "en-US",
      "es": "es-ES"
    };

    if (languageMap[language]) {
      return languageMap[language];
    } else {
      return "pt-PT";
    }

  };

  const weekdays = {
    pt: ["D", "S", "T", "Q", "Q", "S", "S"],
    en: ["S", "M", "T", "W", "T", "F", "S"],
    es: ["D", "L", "M", "X", "J", "V", "S"]
  };

  const languageUser = userSettings?.language
  ? userSettings.language.trim()
  : "en";
  

  const [state, setState] = React.useState({
    isSelecting: false,
    isSelectingMonth: false,
    isSelectingYear: false,
    isSelectingTime: false,
    curYear: new Date().getFullYear(),
    curMonth: 0,
    temp: props.value ? props.value : new Date(),
    distance: 0,
    dateSelected: false,
  });

  const focusRef = useRef(null);

  useEffect(() => {
    if (state.isSelecting) {
      setState({
        ...state,
        temp: props.value ? new Date(props.value) : new Date(),
        curYear: props.value ? new Date(props.value).getFullYear() :new Date().getFullYear(),
        curMonth: props.value ? new Date(props.value).getMonth() : new Date().getMonth(),
      });
      focusRef.current.focus();
    }
  }, [state.isSelecting, focusRef]);

  useEffect(() => {
    var firstD = state.temp;
    firstD = new Date(state.curYear, state.curMonth, 1);

    const sunday = new Date(state.curYear, firstD.getMonth(), -1);
    var distance =
      firstD.getDay() -
      new Date(sunday.setDate(sunday.getDate() - sunday.getDay() + 7)).getDay();
    setState({ ...state, distance });
  }, [state.temp, state.curMonth, state.curYear]);

  useEffect(() => {
        if (state.dateSelected) onFinish();
  }, [state.dateSelected]);

  const onSelectDate = (day) => {
    state.temp.setDate(day + 1);
    state.temp.setFullYear(state.curYear);
    state.temp.setMonth(state.curMonth);
    setState({ ...state, temp: new Date(state.curYear,state.curMonth,day + 1), dateSelected: true });
  };

  const onSelectMonth = (month) => {
    setState({ ...state, curMonth: month, isSelectingMonth: false });
  };

  const onSelectYear = (year) => {
    setState({ ...state, curYear: year, isSelectingYear: false });
  };

  const onFinish = () => {
    if (typeof props.onChange === "function") {
      props.onChange(state.temp);
      setState({
        ...state,
        isSelecting: false,
        isSelectingMonth: false,
        isSelectingYear: false,
        dateSelected: false,
      });
    }
  };

  const onCancel = () => {
    setState({
      ...state,
      temp: undefined,
      dateSelected: true,
    });
  };

  const showYear = () =>
    (props.format && props.format?.toLowerCase().includes("yy")) ||
    !props.format;
  const showMonth = () =>
    (props.format && props.format?.toLowerCase().includes("mm")) ||
    !props.format;
  const showTime = () => {
    if (!props.format) return true;

    var lc = props.format && props.format?.toLowerCase();
    return lc.includes("hh") || lc.includes(":mm") || lc.includes("ss");
  };

  const changeMonth = (change) => {
    const newMonth = ClampTo(12, state.curMonth + change);
    const newYear =
      newMonth == 11 && change == -1
        ? state.curYear - 1
        : newMonth == 0 && change == 1
        ? state.curYear + 1
        : state.curYear;
  
    setState({
      ...state,
      curMonth: newMonth,
      curYear: newYear,
    });
  };

  const isToday = (year, month, day) => {
    var t = new Date();
    return (
      t.getFullYear() == year && t.getMonth() == month && t.getDate() == day
    );
  };

  const showHours = () =>
    (props.format && props.format?.toLowerCase().includes("hh")) ||
    !props.format;
  const showMinut = () =>
    (props.format && props.format?.toLowerCase().includes(":mm")) ||
    !props.format;
  const showSecon = () =>
    (props.format && props.format?.toLowerCase().includes("ss")) ||
    !props.format;

  const deselectAll = {
    isSelectingMonth: false,
    isSelectingYear: false,
    isSelectingTime: false,
  };

  const dateToString = () =>
    props.value == undefined || props.value == ""
      ? props.disabled
        ? ""
        : props.format
      : props.format
      ? moment(state.isSelecting ? state.temp : props.value).format(
          props.format
            .replace("yyyy", "YYYY")
            .replace("yy", "YY")
            .replace("/mm", "/MM")
            .replace("dd", "DD")
        )
      : state.isSelecting
      ? state.temp.toLocaleDateString()
      : props.value.toLocaleDateString();

  const yearRef = useRef(null);
  const parentRef = useRef(null);
  useEffect(() => {
    if (state.isSelectingYear) {
      yearRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
      parentRef.current.scrollLeft = 0;
    }
  }, [state.isSelectingYear]);

  return (
    <div className="date-picker" style={autoAdjust ? { width: "100%" } : {}}>
      <input
        className={`form-control${props.disabled ? " disabled" : ""}${styleSheet != undefined && styleSheet?.ultraCompact ? " ultracompactForm" : ""}`}
        style={{ fontSize: `${fontSize}px` }}
        readOnly
        value={dateToString()}
        onFocus={() =>
          setState({ ...state, isSelecting: props.disabled ? false : true })
        }
        placeholder={t("general.datePicker.choose")}
      />
      <input
        name={props.name}
        style={{ display: "none" }}
        readOnly
        value={
          props.value
            ? moment(props.value).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
            : ""
        }
      />
      <div
        tabIndex={0}
        className={
          className
            ? className + (state.isSelecting ? " active" : "")
            : "date-picker-selector" + (state.isSelecting ? " active" : "")
        }
        ref={focusRef}
        onBlur={() => {
          onFinish();
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="date-picker-top">
          {showYear() && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setState({
                  ...state,
                  ...deselectAll,
                  isSelectingYear: !state.isSelectingYear,
                });
              }}
              className="data-picker-top-button"
            >
              {state.curYear}
            </span>
          )}
          <div style={{ flex: 1 }} />
          {showTime() && (
            <span
              onClick={() =>
                setState({
                  ...state,
                  ...deselectAll,
                  isSelectingTime: !state.isSelectingTime,
                })
              }
              className="data-picker-top-button"
            >
              {(state.temp?.getHours() < 10 ? "0" : "") +
                state.temp?.getHours() +
                ":" +
                (state.temp?.getMinutes() < 10 ? "0" : "") +
                state.temp?.getMinutes() +
                ":" +
                (state.temp?.getSeconds() < 10 ? "0" : "") +
                state.temp?.getSeconds()}
            </span>
          )}
        </div>
        <div className="date-picker-content">
          {state.isSelectingYear ? (
            <>
              <div ref={parentRef} className="date-picker-months">
                {Array.from(
                  Array(props.yearRange.end - props.yearRange.start),
                  (e, i) => (
                    <span
                      key={i}
                      ref={
                        props.yearRange.start + i == state.temp?.getFullYear()
                          ? yearRef
                          : null
                      }
                      className={
                        props.yearRange.start + i == state.temp?.getFullYear()
                          ? "data-selected"
                          : ""
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSelectYear(props.yearRange.start + i);
                      }}
                    >
                      {props.yearRange.start + i}
                    </span>
                  )
                )}
              </div>
            </>
          ) : state.isSelectingMonth ? (
            <>
              <div className="date-picker-months">
                {Array.from(Array(12), (e, i) => (
                  <span
                    key={i}
                    className={
                      i == state.temp?.getMonth() ? "data-selected" : ""
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onSelectMonth(i);
                    }}
                  >
                    {new Date(2000, i, 1)
                      .toLocaleString(getUserLanguage(), {
                        month: "short",
                      })
                      .replace(".", "")}
                  </span>
                ))}
              </div>
            </>
          ) : state.isSelectingTime ? (
            <div className="date-picker-time">
              <TimeSelector
                value={state.temp?.getHours()}
                onChange={(v) => {
                  state.temp.setHours(ClampTo(24, v));
                  setState({ ...state, temp: state.temp });
                }}
              />
              <TimeSelector
                value={state.temp?.getMinutes()}
                onChange={(v) => {
                  state.temp.setMinutes(ClampTo(60, v));
                  setState({ ...state, temp: state.temp });
                }}
              />
              <TimeSelector
                value={state.temp?.getSeconds()}
                onChange={(v) => {
                  state.temp.setSeconds(ClampTo(60, v));
                  setState({ ...state, temp: state.temp });
                }}
              />
            </div>
          ) : (
            <>
              <div className="date-picker-week">
                {showMonth() && (
                  <div className="date-picker-month-select">
                    <div className="data-arrow" onClick={() => changeMonth(-1)}>
                      <IoIosArrowBack />
                    </div>
                    {showMonth() && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setState({
                            ...state,
                            ...deselectAll,
                            isSelectingMonth: !state.isSelectingMonth,
                          });
                        }}
                        className="data-picker-top-button"
                      >
                        {new Date(2000, state.curMonth + 1, 0)
                          .toLocaleString(getUserLanguage(), { month: "long" })
                          .replace(".", "")}
                      </span>
                    )}
                    <div className="data-arrow" onClick={() => changeMonth(1)}>
                      <IoIosArrowForward />
                    </div>
                  </div>
                )}
                {weekdays[languageUser].map((day, index) => (
                  <span key={index}>{day}</span>
                ))}
              </div>
              <div className="date-picker-days">
                {Array.from(Array(state.distance), (e, i) => (
                  <span key={i}></span>
                ))}
                {Array.from(
                  Array(
                    new Date(state.curYear, state.curMonth + 1, 0).getDate()
                  ),
                  (e, i) => (
                    <span
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSelectDate(i);
                      }}
                      className={
                        "data-valid" +
                        (isToday(state.curYear, state.curMonth, i + 1)
                          ? " " + t("general.datePicker.today")
                          : "") +
                        (state.temp?.getDate() == i + 1 &&
                        state.temp?.getMonth() == state.curMonth &&
                        state.temp?.getFullYear() == state.curYear
                          ? " selected-date"
                          : "")
                      }
                    >
                      {i + 1}
                    </span>
                  )
                )}
              </div>
            </>
          )}
        </div>
        <div className="date-picker-bottom">
          {!state.isSelectingMonth &&
          !state.isSelectingYear &&
          !state.isSelectingTime ? (
            <>
              <button
                className="card-button btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  state.temp = new Date();
                  setState({
                    ...state,
                    temp: state.temp,
                    dateSelected: true,
                  });
                }}
              >
                {t("general.datePicker.today")}
              </button>

              <button
                className="btn btn-danger"
                style={{ marginLeft: "15px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel();
                }}
              >
                {props.value ? t("general.datePicker.clear") : t("general.datePicker.cancel")}
              </button>
              {/*<button
                onClick={(e) => {
                  onFinish();
                  e.preventDefault();
                }}
              >
                {t("general.datePicker.select")}
              </button>*/}
            </>
          ) : (
            <>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setState({
                    ...state,
                    isSelectingMonth: false,
                    isSelectingYear: false,
                    isSelectingTime: false,
                  });
                }}
              >
                {t("general.datePicker.back")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  text: "",
  yearRange: {
    start: new Date().getFullYear() - 50,
    end: new Date().getFullYear() + 50,
  },
};

export default withNamespaces()(DatePicker);
