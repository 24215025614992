import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import '~/assets/css/icons.css';
import '~/assets/css/styles.css';
import MetadataModal from './MetadataModal';

import SpinnerNew from "~/components/SpinnerNew";

import { DocumentStatus } from '~/pages/DataCapture/utils/DataCaptureEnum';

import {
  getColorByStatusDocument,
  translateStatusDocument
} from '~/pages/DataCapture/utils';

import {
  refreshDocument,
  getMetadataDocument
} from '~/pages/DataCapture/actions';

import { toLocaleString } from '~/utils';
import MetadataModalMock from './MetadataModalMock';

const DocumentTable = ({ t, documents }) => {

  /* MOCK - BEGIN*/
  let mockDocuments = [];
  mockDocuments.push("CC Mário Brito.pdf");
  mockDocuments.push("CC_DªPB.pdf");
  mockDocuments.push("CC_SrºCB.pdf");
  mockDocuments.push("CONT - Bankinter.pdf");
  mockDocuments.push("CONT - Garval.pdf");
  mockDocuments.push("CRC - Davion.pdf");
  mockDocuments.push("Dec Negativa Fisco.pdf");
  mockDocuments.push("Dec Negativa SS.pdf");
  mockDocuments.push("Declaração empresa em não dificuldades.pdf");
  mockDocuments.push("Declaração Manutenção dos postos de trabalho.pdf");
  mockDocuments.push("Declaração Partilha Informação SGM.pdf");
  mockDocuments.push("Declaração sob compromisso de honra sobre dívidas.pdf");
  mockDocuments.push("Livrança SGM.pdf");
  mockDocuments.push("Cartão do Cidadão JOÃO BASTOS.pdf");
  mockDocuments.push("Certidão Finanças(2).pdf");
  mockDocuments.push("Certidão Finanças.pdf");
  mockDocuments.push("Certidão Permanente.pdf");
  mockDocuments.push("Certidão Segurança Social.pdf");
  mockDocuments.push("Comunicação EGL(2).pdf");
  mockDocuments.push("Comunicação EGL.pdf");
  mockDocuments.push("Contrato Garantia SGM.pdf");
  mockDocuments.push("Declaração de Partilha de Informação.pdf");
  mockDocuments.push("Livrança da SGM.pdf");

  const [showMetadataModalMock, setShowMetadataModalMock] = useState(false);
  /* MOCK - END */

  const dispatch = useDispatch();

  const { isRefreshing, isSavingMetadataDocument } = useSelector(state => state.dataCaptureReducer);

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [isEditingMetadata, setEditingMetadata] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleOpenMetadataModal = (row, isEditing) => {
    /* MOCK - BEGIN */
    if (mockDocuments.includes(row.filename)) {
      setShowMetadataModalMock(true);
      setSelectedDocument(row);
      setEditingMetadata(isEditing);
      return;
    }
    /* MOCK - END */

    setEditingMetadata(isEditing);
    dispatch(getMetadataDocument(row.id));
    setShowMetadataModal(true);
    setSelectedDocument(row);
  }

  const handleCloseDetailModal = () => {
    setShowMetadataModal(false);
  }

  /* MOCK - BEGIN */
  const handleCloseDetailModalMock = () => {
    setShowMetadataModalMock(false);
  }
  /* MOCK - END */

  const handleRefreshDocument = row => {
    dispatch(refreshDocument(row));
  }

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const buildActionsColumn = (_, row, { }) => (
    <>

      {DocumentStatus.LOADED !== row.statusV1 &&
        DocumentStatus.REVISED !== row.statusV1 &&
        DocumentStatus.REVIEWED !== row.statusV1

        &&
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t('datacapture.general.refreshDocument'))}
        >
          <i
            className="icon-refresh table-action-icon"
            onClick={() => handleRefreshDocument(row)}
          />
        </OverlayTrigger>
      }
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('datacapture.general.detailDocument'))}
      >
        < i
          className="icon-detail table-action-icon"
          onClick={() => handleOpenMetadataModal(row, false)}
        />
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('datacapture.general.editDocument'))}
      >
        <i
          className="icon-edit table-action-icon"
          onClick={() => handleOpenMetadataModal(row, true)}
        />
      </OverlayTrigger>
    </>
  );

  const buildStatusColumn = (_, row, { }) => {

    const variant = getColorByStatusDocument(row.statusV1);
    const translatedStatus = translateStatusDocument(row.statusV1);
    return <Badge pill variant={variant}>{t(translatedStatus)}</Badge>

  }

  const buildDocTypeColumn = (_, row, { }) => {
    return row.metadata && row.metadata["document:class"] ? t(`datacapture.general.${row.metadata["document:class"]}`) : t('datacapture.general.undetermined')
  }

  const columns = [
    {
      dataField: 'metadata.documentClass',
      text: t('datacapture.general.documentClass'),
      formatter: buildDocTypeColumn
    },
    {
      dataField: 'createdDate',
      text: t('datacapture.general.created'),
      formatter: (_, row, { }) =>
        toLocaleString(row.createdDate)
    },
    {
      dataField: 'filename',
      text: t('datacapture.general.filename')
    },
    {
      dataField: 'statusV1',
      text: t('datacapture.general.status'),
      formatter: buildStatusColumn,
    },
    {
      dataField: 'actions',
      text: t('datacapture.general.actions'),
      formatter: buildActionsColumn,
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      }
    }];

  return (
    <div>
      <h1 className="display-4">{t('datacapture.general.uploadedFiles')}</h1>
      <BootstrapTable
        keyField='id'
        data={documents}
        columns={columns}
        noDataIndication={t('datacapture.general.dataNotFound')}
        hover
        striped
      />

      <MetadataModal
        showModal={showMetadataModal}
        closeModal={handleCloseDetailModal}
        isEditing={isEditingMetadata}
        document={selectedDocument}
      />

      <MetadataModalMock
        showModal={showMetadataModalMock}
        closeModal={handleCloseDetailModalMock}
        isEditing={isEditingMetadata}
        document={selectedDocument}
      />
      <SpinnerNew open={isRefreshing || isSavingMetadataDocument} />
    </div>
  )
}
export default withNamespaces()(DocumentTable)
