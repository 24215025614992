import React from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { loginADFS } from "~/store/ducks/udw/actionTypes";
import loginIntegrado from "~/assets/img/icons/login-finger-password-3.svg";
import { Typography } from "@mui/material";
const devLogConsole = require("~/utils/devLog");
const UdwAdfsSignIn = ({ old = false, t }) => {
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    const payload = { entityProvider: "1" };
    devLogConsole(payload);
    dispatch(loginADFS(payload));
  };
  return (
    <>
    {old ? (
        <button className="btn-adfs" onClick={handleLogin}>
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: "1", justifyContent: "center", alignItems: "center" }}>
                    Login Integrado
                </div>
            </div>
        </button>
    ) : (
        <div
            style={{ textAlign: "center", pointerEvents: "auto" }}
            onClick={handleLogin}
            role="button"
        >
            <img
                className="logo-windows"
                alt="Login Integrado"
                src={loginIntegrado}
            />
            <Typography variant="caption" display="block" gutterBottom>
                Login Integrado
            </Typography>
        </div>
    )}
</>
  );
};

export default withNamespaces()(UdwAdfsSignIn);
